import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApirestService } from './apirest.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SessionService {
    public userSession = new BehaviorSubject('');

    public configurations = new BehaviorSubject(false);
    currentConfigurations = this.configurations.asObservable();

    constructor( public service: ApirestService, private router: Router) { }

    setUserSession(user: string){
        if(this.userSession.getValue() != user){
            this.userSession.next(user);
        }
    }

    getUserSession(): Observable<any>{
        return this.userSession.asObservable();
    }

    validUserSession()
	{
        let res;
        this.service.queryPost('me', FormData).subscribe(
            response=>
            {      
                let result = response.json();
                if(null != result.user)
                {
                	this.setUserSession(JSON.stringify(result.user));
                  localStorage.setItem('user', JSON.stringify(result.user));
                }
                else
                {
                  localStorage.clear();
                  this.router.navigate(['/login']);
                }
            },
            err => 
            {
              // console.log(err);
            }
        );
	}

    updateConfigurations(currentConfigurations: boolean) 
    {    
        this.configurations.next(currentConfigurations);
    }

}
