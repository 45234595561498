import { Component, OnInit,AfterViewInit , Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BlockedService } from '../components/services/blocked.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blocked',
  templateUrl: './blocked.component.html',
  styleUrls: ['./blocked.component.scss']
})
export class BlockedComponent implements OnInit, AfterViewInit  {
    public _namePlan: string;
    public _numEventsPlan: number; 
    public _valuePlan: number;

    private unsubscribes: Subscription[] = [];
    modalRefBlocked: BsModalRef | null;
    @ViewChild('modalBlocked') modalBlocked;
    @Input() showModal: boolean = true;

    public _title:string = '';
    public _content:string = '';
    public user = {};

  	constructor(private service: ApirestService,
                private modalService: BsModalService,
                private cdr: ChangeDetectorRef, 
                private blockedService: BlockedService,
                private router: Router) { }

  	ngOnInit() {
      this.getQuantityProject();
      this._title = '¡Tu prueba gratis ha finalizado!';
      this._content = 'Esperamos que hayas podido explorar todas las herramientas con las que buscamos facilitar tu trabajo. <br> Te invitamos a seguir planeando eventos soñados con nosotros';
      const dataSuscribe = this.blockedService.openModal.subscribe(data => {
        if(data && false === this.showModal)
        {
          this.showModal = data;
          this.openModal();
        }
      });

      this.unsubscribes.push(dataSuscribe);
  	}
    ngAfterViewInit ()
    {
      if(this.showModal)
      {
        this.openModal();
      }
    }

    private getQuantityProject()
    {
      let user = JSON.parse(localStorage.getItem('user'));
      let body = new FormData;
      const dataSuscribes = this.service.queryPost('get-quantity-project-for-company', body).subscribe(
        success => {
            let response = success.json();
            this._namePlan = response[0].name;
            this._numEventsPlan = response[0].cant_events;
            this._valuePlan = response[0].value;
            user.plan_id = response[0].id;
            localStorage.setItem('user', JSON.stringify(user));
            this.user = JSON.parse(localStorage.getItem("user"));
            this.cdr.detectChanges();
            /*if(1 == response[1])
            {
              this._title = 'No se pudo realizar el pago';
              this._content = `<p>Ocurrio un problema al realizar el pago, Puedes cambiar el metodo de pago en configuración de pagos</p><br/>
              <p>Deseas cambiarlo?</p>`;
            }
            else
            {
              this._content = `<p> En este momento tienes 
                <b>${this._numEventsPlan}</b> eventos activos tu plan actual es 
                <b>${this._namePlan}</b> con valor 
                <b>$ ${this._valuePlan}</b></p><br/>
              <p>Deseas continuar?</p>`;
            }*/
        },
        err => {

        }
      );
      this.unsubscribes.push(dataSuscribes);
    }

    public openModal()
    {
      setTimeout(() => {
        if(this.showModal){
          this.modalRefBlocked = this.modalService.show(this.modalBlocked, { class: 'modal-lg modal-dialog-centered' });
          this.showModal = false;
        }
      }, 10);
    }

    public closeModal() 
    {
      if (this.modalRefBlocked) {
        this.modalRefBlocked.hide();
      }
    }

    public goTo(url)
    { 
      if (this.modalRefBlocked) {
        this.modalRefBlocked.hide();
      }
      this.router.navigate([url]);
    }
}
