import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef  } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { getEventsInPeriod } from 'calendar-utils';
import { SessionService } from '../session.service';
import * as $ from 'jquery';

registerLocaleData(localeEs);

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  public project = "";
  locale = 'es';
  view: CalendarView = CalendarView.Month;
  
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  public event = {id: 0, project_id: 0, event_type_id: 0, event: '', date: ''};
  public eventTypes = [];
  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;

  public eventDetails = {
    project_id: "",
    project_name: "",
    project_type_id: "",
    type_name: "",
    name_activity: "",
    start: "",
    end: "",
    color: null,
    checkList: false, 
    done: false
  }

  @ViewChild('eventForm')eventForm;
  constructor(private service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }


  ngOnInit() {
    this.SessionService.validUserSession();  
    this.getEvents();
    this.getEventTypes();
  }

  getEventTypes(){
    let url = 'get-types-project';
      
    this.service.queryGet(url).subscribe(
        response=>
        {      
            let result = response.json(); 
            this.eventTypes = result;
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  handleEvent(type, event, template){
    
    this.eventDetails['project_id'] = event.project_id;
    this.eventDetails['project_name'] = event.project_name;
    this.eventDetails['type_name'] = event.type_name;
    this.eventDetails['name_activity'] = event.name_activity;
    this.eventDetails['project_type_id'] = event.type;
    
    if(this.eventDetails['type_name'] == "Lista de chequeo"){
      this.eventDetails['checkList'] = true;
      
      let url = "project/checklist/task/"+event.type;
      this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json(); 
              if(result.done == 1){
                this.eventDetails['done'] = true;
              }else{
                this.eventDetails['done'] = false;
              }
              
              this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
          },
          err => 
          {
              console.log(err);
          }
      );

    }else{
      this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }
    
  }

  closeModal(){
    this.modalRef.hide();
    this.eventDetails = {
      project_id: "",
      project_name: "",
      project_type_id: "",
      type_name: "",
      name_activity: "",
      start: "",
      end: "",
      color: null,
      checkList: false, 
      done: false
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  getEvents(){
    let user = JSON.parse(localStorage.getItem('user'));
		let url = "get-calendar/"+user['company_id'];
    this.events = [];
		this.service.queryGet(url).subscribe(
        response=>
        {      
            let result = response.json(); 
            //this.events = result

            for(let i = 0; i < result.length; i++){

              let title  = "";
              if(result[i].name_activity){
                title = result[i].project_name + "("+ result[i].name_activity +")";
              }else{
                title = result[i].project_name;
              }
              let event = {
                  project_id: result[i].project_id,
                  type: result[i].type,
                  type_name: result[i].type_name,
                  project_name: result[i].project_name,
                  project_type_id: result[i].project_type_id,
                  title: title,
                  name_activity: result[i].name_activity,
                  start: addHours(startOfDay(result[i].date), 2),
                  end: result[i].date,
                  color: {
                    primary: result[i].color,
                    secondary: "#ccc"
                  },
                  draggable: false,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true
                  },
              };
              this.events.push(event);
            }
            let today = document.getElementById('today');

            if(today)
            {
              today.click();
            }

        },
        err => 
        {
            console.log(err);
        }
    );
  }

  saveEvent(){

        let body = new FormData();
        body.append('id', this.event['id']+'');
        body.append('project_id', this.project);
        body.append('event_type_id', this.event['event_type_id']+'');
        body.append('event', this.event['event']);
        body.append('date', this.event['date']);

        this.service.queryPost('project/calendar', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Hide the canvas
                    this.closeModal();
                    this.toastr.success(result.message);
                    this.getEvents();
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
  }


  updatecheckListTask(){
    // this.eventDetails['done'] = !this.eventDetails['done']; 
    let done = 0;
    if(this.eventDetails['done']){
        done = 1;
    }
    let body = new FormData();
    body.append('id', this.eventDetails['project_type_id']+'');
    body.append('done', done+'');

    this.service.queryPost('project/checklist/task-update', body).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Hide the canvas
                //this.toastr.success(result.message);
                
            }
            else
            {
               
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }


  viewDetails(){
    
    let url = "";
    
    switch (this.eventDetails['type_name']) {
      case "calendario":
        url = "project/"+this.singleton.encrypt(this.eventDetails['project_id'])+"/calendar"
        this.router.navigate([url]);
        break;
      case "Lista de chequeo":
        url = "project/"+this.singleton.encrypt(this.eventDetails['project_id'])+"/check"
        this.router.navigate([url]);
        break;
      case "plan pagos":
        url = "project/"+this.singleton.encrypt(this.eventDetails['project_id'])+"/providers"
        let provider = this.singleton.encrypt(this.eventDetails['project_type_id']);
        this.router.navigate([url],  { queryParams: {view: provider}});
        break;
      case "cronograma":
        url = "project/"+this.singleton.encrypt(this.eventDetails['project_id'])+"/schedule";
        let data = this.singleton.encrypt(this.eventDetails['project_type_id']);
        this.router.navigate([url], { queryParams: {view: data} });
        break;
      case "evento":
        url = "project/"+this.singleton.encrypt(this.eventDetails['project_id'])+"/home"
        this.router.navigate([url]);
        break;
      default:
        break;
    }
    
    if(this.modalRef){
      this.modalRef.hide();
    }
    
  }

  public changeViewCalendar(data)
  {
    this.view = data
    if('week' == data || 'day' == data)
    {
      $("html, body").animate({
          scrollTop: 700
      }, 2000);
    }
  }

}
