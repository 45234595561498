import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SingletonService } from './singleton.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
	constructor(private singeltonService: SingletonService,
				private router: Router){

	}
  	canActivate(next: ActivatedRouteSnapshot) {

      let user = JSON.parse(localStorage.getItem('user'));

      if(user && user['rol_id'] && 0 === user['rol_id']) {
        return true;
      }

  		this.singeltonService.getSession();
  		let currentUrl = next.routeConfig.path;
  		// Variable donde se va almacenar las rutas de permisos permisos
  		let rutesPermission = ['documents', 'calendar', 'contacts', 'projects'];
      let route = rutesPermission.find(r => r === currentUrl);
      if(undefined !== route)
      {
  			if(!this.singeltonService.permissions.includes(currentUrl))
  			{
  				this.router.navigate(['/home']);
  				return false;
  			}
  		}
    	return true;
  	}
}
