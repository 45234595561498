import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';
import { SingletonService } from '../singleton.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

	public company = {id: 0, company_name: '', department: '', city: '', country:'', city_name: '', phone: '',name: '', email: '', password: '', confirmPassword: '', terms: '', address: '', facebook: '', instagram: '', youtube: '', description: '', website:'', twiter: ''};
    private departmentsList = [];
    private departmentSelected = "";
    private citiesList = [];
    public countriesList = [];
    //public viewDeparment = false;
	private user = null;
    public validUrlFacebook:boolean = false;
    public validUrlInstagram:boolean = false;
    public validUrlWebsite:boolean = false;
    public validEmail:boolean = false;
    public validName:boolean = false;


	constructor(public service: ApirestService,
                private router: Router,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                public singletonService: SingletonService,
                public dataService:DataServiceService,
                private translate: TranslateService,
                private SessionService: SessionService) 
  	{ 
      window.scroll(0,0);
  	}

	ngOnInit() {
        this.SessionService.validUserSession(); 
        this.getDepartmentsList();
		this.user = JSON.parse(localStorage.getItem('user'));
		this.company.id =this.user.company.id;	
        this.getCompanyData();
        this.getCountryList();
	}

    getCountryList(){
        let url = 'get-countries'
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.countriesList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getDepartmentsList(){

        let url = 'get-department/'+this.company.country;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.departmentsList = result;
                //this.changeDepartment();
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    changeCountry(){
        
        if(this.company.country != ""){
            /*this.company.city_name = "";*/
            let url = 'get-department/'+this.company.country;
            this.service.queryGet(url).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    this.departmentsList = result;
                },
                err => 
                {
                    console.log(err);
                }
            );
        }
    }

    changeDepartment(){
        
        if(this.company.department != ""){
            this.company.city_name = "";
            let url = 'get-cities/'+this.company.department;
            this.service.queryGet(url).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    this.citiesList = result;
                },
                err => 
                {
                    console.log(err);
                }
            );
        }
    }

	getCompanyData(){
		let body = new FormData;
        //body.append('id', this.company.id+'');

        this.service.queryPost('company/'+this.company.id, body).subscribe(
            response=>
            {      

                let result = response.json(); 

                this.company.company_name = result.name;
                this.company.phone = result.phone_number;
                this.company.name = result.user_name_master;
                this.company.department = result.department_id == null ? '' : result.department_id;
                this.changeDepartment();
                this.company.city = result.city_id == null ? '' : result.city_id;
                this.company.country = JSON.parse(result.country_id);
                this.company.city_name = result.city_name;
                this.company.address = result.address;
                this.company.facebook = result.facebook;
                this.company.instagram = result.instagram;
                this.company.youtube = result.youtube;
                this.company.email = result.email;
                this.company.website = result.website;
                this.company.description = result.description;
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	updateCompany(){
        this.validUrlFacebook = false;
        this.validUrlInstagram = false;
        this.validUrlWebsite = false;
        this.validName = false;
        this.validEmail = false;

        if('' === this.company.company_name)
        {
            this.toastr.error('El campo nombre es requerido.', 'Error', { positionClass:'toast-top-center' });
            window.scrollTo( 0, 5 );
            this.validName = true;
            return;
        }

        if('' !== this.company.email && !this.singletonService.validateEmail(this.company.email))
        {
            this.toastr.error('El formato del correo electrónico es inválido.', 'Error', { positionClass:'toast-top-center' });
            window.scrollTo( 0, 5 );
            this.validEmail = true;
            return;
        }

        if('' != this.company.facebook && !this.isValidUrl(this.company.facebook))
        {
            this.toastr.error('Url en el campo Facebook inválida','Error', {positionClass: 'toast-top-center'});
            this.validUrlFacebook = true;
        }

        if('' != this.company.instagram && !this.isValidUrl(this.company.instagram))
        {
            this.toastr.error('Url en el campo Instagram inválida','Error', {positionClass: 'toast-top-center'});
            this.validUrlInstagram = true;
        }

        if('' != this.company.website && !this.isValidUrl(this.company.website))
        {
            this.toastr.error('Url en el campo Website inválida','Error', {positionClass: 'toast-top-center'});
            this.validUrlWebsite = true;
        }

        if(this.validUrlFacebook || this.validUrlInstagram || this.validUrlWebsite)
        {
            return;
        }
		let body = new FormData;
        body.append('id', this.company.id+'');
        body.append('department_id', this.company.department);
        body.append('city_id', this.company.city);
        body.append('country_id', this.company.country);
        body.append('city_name', this.company.city_name);
        body.append('name', this.company.company_name);
        body.append('phone_number', this.company.phone);
        body.append('address', this.company.address);
        body.append('email', this.company.email);
        body.append('website', this.company.website);
        body.append('facebook', this.company.facebook);
        body.append('instagram', this.company.instagram);
        body.append('twiter', this.company.twiter);
        body.append('youtube', this.company.youtube);
        body.append('description', this.company.description);

        this.service.queryPost('company', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success) 
                {
                    this.toastr.success(result.message);
                    
                    let user = JSON.parse(localStorage.getItem('user'));
                    user.company = result.company;
                    localStorage.setItem('user', JSON.stringify(user));

                    this.dataService.changeUser(JSON.stringify(user));
                    this.router.navigate(['/home']);
                }
                else
                {
                   let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
	}

    /**
     * Funcion para validar campos tipo url
     *
     * @param url string
     * @return boolean
     */
    isValidUrl(url)
    {
        let pattern = /^(http|https)\:\/\/[a-z0-9\.-]+\.[a-z]{2,4}/gi;

        if(url.match(pattern))
        {
            return true;
        }
        return false;
    }
}
