import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-schedule-project',
  templateUrl: './schedule-project.component.html',
  styleUrls: ['./schedule-project.component.scss']
})
export class ScheduleProjectComponent implements OnInit {
	public userSession = null;

    //Variables for documents list with (pagination, select rows, search)
    private documents = [];
    private pagesNumber = 10; //Default value
    private search = '';
    private pages = [];
    private currentPage = 1;


    public templateId = null;
    public templateSchedule = {id: 0, branding: 0, description: '', name: '', days: 0,  template_type_id: 0, template_schedule: []};
    public items = [];
    public newDayToSchedule = {id: 0, template_id: 0, template_type_id: 0, name: ''};
    public toDelete = 0;
	public newItem = {id: 0, activity: '', day: '', dayname: '', date: '', hour: '', responsible: '', comments: '', contact_id: 0};
		

    public newActivityTask = {id: 0, template_schedule_id: 0, day_number: 0, activity: '', order: 0, comments: ''};

    public dayName = {id: 0, name: ''};
    public typeToDelete = "";
    public inputRow = false;
    public inputActivityTask = null;
    public project:any = 0;
    private projectIdEncry:string = '';

    public listView = true;
    public scheduleView = false;
    public showTemplateSelector = true;
    public templateSelected = "";
    public templateDescription = "";
    public templatesSchedulesList = [];

    public scheduleList = [];

    public showOptionsSchedule = true;


    //public inputRow = false;
    public daysList = [];
    public posToEdit = -1;

    public showSelectTemplates = false;
	public typeCheckList = 1; //Check list from template, 1 without template
	
	public newAssociation = {name: ''};
	public contactsProject = [];
	public postUpdate = null;

	public onlyView = false;

	public idFocus = "";

	public archiveProject = false;
	public openDay = null;
	public activePosition = null;

    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;
    @ViewChild('register')register;
    constructor(public service: ApirestService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private singleton: SingletonService,
				private lastlocation: Location,
				private dataService:DataServiceService,
        private modalService: BsModalService,
				private route: ActivatedRoute,
				private SessionService: SessionService) 
        { 
          window.scroll(0,0);
        }

	ngOnInit() {
		this.SessionService.validUserSession();  
		let id = localStorage.getItem('project');
		this.projectIdEncry = id;
		this.project = this.singleton.decrypt(id);
		this.singleton.getProjectData(this.project);
		if(this.route.snapshot.paramMap.get("id")){
			this.templateId = this.singleton.decrypt(this.route.snapshot.paramMap.get("id"));
		}
		
		this.getScheduleList('');
		this.getSchedulesTemplate();
		this.getContactsProject();

		let user = JSON.parse(localStorage.getItem('user'));
		this.userSession = user;   
		
		this.route.queryParams.subscribe(params=> {
			if(params['view']){
				this.editSchedule(this.singleton.decrypt(params['view']), true);
			}
			
		})

		if(localStorage.getItem('show') == '0'){
	      this.archiveProject = true;
	    }
	}


	getContactsProject(){
		let url = 'project-contact?projectId=' + this.project + '&rows=100&status=1';
		this.service.queryGet(url).subscribe(
			response=>
			{      
				let result = response.json(); 
				this.contactsProject = result.data;

			},
			err => 
			{
				console.log(err);
			}
		);
	}

	validateTemplateProject(){
    	let url = "project/template?projectId="+this.project+"&templateType=3";

		this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json();

              if(result.data.length == 0){
                //this.showSelectTemplates = true;
              }else{
                //this.getChecklistItemsById(result.data[0].id);
              }
              
          },
          err => 
          {
              console.log(err);
          }
      );
  }

	getSchedulesTemplate(){
		let url = "template?templateType=4&rows=100";

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.templatesSchedulesList = result.templates.data;
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getScheduleList(page){
		let url = 'project/schedule?projectId=' + this.project;
		url += '&rows=' + this.pagesNumber;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.scheduleList = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                console.log(err);
            }
        );
	} 

	getTemplateDetails(){
		let url = 'template/' + this.templateId+'?type=4';

		this.service.queryGet(url).subscribe(
		  response=>
		  {      
		        let result = response.json(); 
		        
		        this.templateSchedule['id'] = this.templateId;
		        this.templateSchedule['branding'] = result.branding;
		        this.templateSchedule['description'] = result.description;
		        this.templateSchedule['name'] = result.name;

		        this.newDayToSchedule = {id: 0, template_id: result.id, template_type_id: result.template_type_id, name: ''};
		        
		        this.templateSchedule['template_schedule'] = result.template_schedule;
		       
		  },
		  err => 
		  {
		      console.log(err);
		  }
		);
	}

	changeSelect(){
	    if(this.templateSelected != ""){
	      this.service.queryGet('template/'+this.templateSelected+'?type=4').subscribe(
	          response=>
	          {      
	            let result = response.json(); 
	             
	            this.templateSchedule['id'] = result.id;
	            this.templateSchedule['branding'] = result.branding;
	            this.templateSchedule['description'] = result.description;
	            this.templateSchedule['name'] = result.name;
	            //this.templateSchedule['days'] = result.days;
	            this.templateSchedule['template_type_id'] = result.template_type_id;
	            this.templateSchedule['template_schedule'] = result.template_schedule; 
	            this.templateDescription = result.description;
	            this.showOptionsSchedule = false;
	          },
	          err => 
	          {
	              console.log(err);
	          }
	      );
	    }
	    
	}


	editSchedule(id, view = null){

		if(view != null){
			this.onlyView = true;
		}else{
			this.onlyView = false;
		}
		this.templateId = id;
		this.service.queryGet('project/template/'+id).subscribe(
            response=>
            {      
				let result = response.json(); 
				this.daysList = [];
                this.templateSchedule['id'] = result.id;
	            	//this.templateSchedule['branding'] = result.template.branding;
	            	this.templateSchedule['description'] = result.description;
	            	this.templateSchedule['name'] = result.name;
	            	//this.templateSchedule['days'] = result.days;
	            	this.templateSchedule['template_type_id'] = result.template_type_id;
	            	this.templateSchedule['template_schedule'] = result.project_schedule; 

	            	/* this.showOptionsSchedule = true;
								this.showTemplateSelector = false; */
								
								for (var i = 0; i < result.items.length; i++) 
                {
                  if(i == 0)
                  {
                    this.daysList.push(
											{
												id: result.items[i].id, 
												daynumber: result.items[i].day, 
												dayname: result.items[i].day_name,
												date: result.items[i].date,
												project_id: this.project, 
												project_template_id: this.templateSchedule['id'], 
												showItems: 1,
												activities: [
													{
														id: result.items[i].id, 
														name: result.items[i].activity, 
														day: result.items[i].day, 
														dayname: result.items[i].day_name,
														date: result.items[i].date,
														hour: result.items[i].hour,
														responsible: result.items[i].responsible,
														comments: result.items[i].comments,
														contact_id: result.items[i].contact_id,
														contact_name: result.items[i].contact_name,
														hour_complete: result.items[i].hour_complete
													}
												]
											}
										);
                  }
                  else
                  {
                    let exists = false;
                    let pos = 0;

                    for(var j = 0; j < this.daysList.length; j++)
                    {
                      if(this.daysList[j].daynumber == result.items[i].day)
                      {
                        exists = true;
                        pos = j;
                      }
                    }
                    if(exists)
                    {
                      let tmpActivities = {
												id: result.items[i].id, 
												name: result.items[i].activity, 
												day: result.items[i].day, 
												dayname:result.items[i].day_name,
												date: result.items[i].date,
												hour: result.items[i].hour,
												responsible: result.items[i].responsible,
												comments: result.items[i].comments,
												contact_id: result.items[i].contact_id,
												contact_name: result.items[i].contact_name,
												hour_complete: result.items[i].hour_complete
											}
                      this.daysList[pos].activities.push(tmpActivities);
                    }
                    else
                    {
                      let tmpItems = {
												id: result.items[i].id, 
												daynumber: result.items[i].day, 
												dayname: result.items[i].day_name,
												date: result.items[i].date,
												project_id: this.project, 
												project_template_id: this.templateSchedule['id'], 
												showItems: 1,
												activities: [
												{
													id: result.items[i].id,
													name: result.items[i].activity, 
													day: result.items[i].day, 
													dayname:result.items[i].day_name,
													date: result.items[i].date,
													hour: result.items[i].hour,
													responsible: result.items[i].responsible,
													comments: result.items[i].comments,
													contact_id: result.items[i].contact_id,
													contact_name: result.items[i].contact_name,
													hour_complete: result.items[i].hour_complete
												}
											]}
                      this.daysList.push(tmpItems);
                    }
                  }
                }

								
								this.viewScheduleDiv();
								
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	openAddModal(template){
		this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });

		document.getElementById('template').focus();
	}	

	addItem(){
		var body = new FormData();
		body.append('id', this.newItem['id']+'');
		body.append('project_id', this.project+'');
		body.append('project_template_id', this.templateSchedule['id']+'');
		body.append('name', this.newItem['activity']);
		body.append('day_number', this.newItem['day']);
		body.append('date', moment(this.newItem['date']).format('YYYY-MM-DD'));
		body.append('contact_id',this.newItem['contact_id'+'']);

		this.service.queryPost('project/schedule', body).subscribe(
		  response=>
		  {      
		    let result = response.json(); 
		    
		    if(result.success)
		    {
		      
					//Show success message
					this.inputRow = false;
		      this.toastr.success(result.message);
		      this.editSchedule(this.templateSchedule['id']);
		      this.newItem = {id: 0, activity: '', day: '', dayname: '', date: '', hour: '', responsible: '', comments: '', contact_id: 0};
		      
		    }
		    else
		    {
		      let message = '';
		      if(result.message.length > 0)
		      {
		        result.message.forEach(function(element) {
		          message += element+'<br>';
		        });
		      }

		      this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
		    }
		  },
		  err => 
		  {
		    console.log(err);
		  }
		);
	}


	async dobleclick(id, type){
		if(this.userSession['rol_id'] != 0){
			this.inputRow = false;

			//await this.getDataToUpdateItem(id, categoryId);
			if(!this.onlyView){
				this.postUpdate = id;
			}
			let idInput = type+id;
			await this.singleton.sleep(100);
			document.getElementById(idInput).focus();
		}
	}



	viewFormActivityTask(i, template_schedule_id){
		this.inputActivityTask= i;
		this.newActivityTask['template_schedule_id'] = template_schedule_id
	}

	addActivityTask(project_schedule_id){
		let user = JSON.parse(localStorage.getItem('user'));
		var body = new FormData();
		body.append('id', this.newActivityTask['id']+'');
		body.append('project_schedule_id', project_schedule_id+'');
		body.append('user_id', user['id']);
		body.append('date_hour', this.newActivityTask['day_number']+'');
		body.append('activity', this.newActivityTask['activity']);
		body.append('comments', this.newActivityTask['comments']);
		body.append('order', 1+'');

		this.service.queryPost('project/schedule/item', body).subscribe(
			response=>
			{      
				let result = response.json(); 
				
				if(result.success)
				{
					
					//Show success message
					this.newActivityTask = {id: 0, template_schedule_id: 0, day_number: 0, activity: '', order: 0, comments: ''};
					this.inputActivityTask= null;
					this.toastr.success(result.message);
					this.getTemplateDetails();

				}
				else
				{
					let message = '';
					if(result.message.length > 0)
					{
						result.message.forEach(function(element) {
							message += element+'<br>';
						});
					}

					this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
				}
			},
			err => 
			{
				console.log(err);
			}
		);
	}


	addTemplateProject()
	{	
		if(1 == this.typeCheckList && "" == this.templateSelected){
			this.toastr.error('Debe seleccionar una plantilla para crear el minuto a minuto');
            return;
		}

		let body = new FormData();

		let name = '';
		let description = '';
		if(this.typeCheckList == 1){
	      	for(let i = 0; i < this.templatesSchedulesList.length; i++){
	        	name = this.templatesSchedulesList[i].name;
	        	description = this.templatesSchedulesList[i].description;
			}
			body.append('template_id', this.templateSchedule['id']+'');
    	}else{
			body.append('template_id', 0+'');
    	}

    	if(this.newAssociation['name'] == ""){
      		this.toastr.error('Debe ingresar un nombre al cronograma');
      		return;
		}
		
		
		body.append('id', 0+'');
		body.append('project_id', this.project+'');
		body.append('template_type_id', '4');
		body.append('name', this.newAssociation['name']);
		body.append('description', description);

		let url = "project/template";

		this.service.queryPost(url, body).subscribe(
			response=>
			{      
				let result = response.json(); 
				
				if(result.success)
				{
						this.viewScheduleDiv();
						//Show success message
						this.toastr.success(result.message);
						this.showOptionsSchedule = true;
						this.editSchedule(result.projectTemplate.id);
						this.closeModal();
				}
				else
				{
					let message = '';
					if(result.message.length > 0)
					{
						result.message.forEach(function(element) {
							message += element+'<br>';
						});
					}
					this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
				}
			},
			err => 
			{
				console.log(err);
			}
		);
	}



	closeModal(){
		this.modalRef.hide();
		this.newItem = {id: 0, activity: '', day: '', dayname: '', date: '', hour: '', responsible: '', comments: '', contact_id: 0};
		this.typeCheckList = 1;
		this.templateSelected = "";
		this.newAssociation.name = "";
	}


	confirmDelete(template, type, id){
		this.toDelete = id;
		this.typeToDelete = type;
		this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
	}

	cancelDelete(){
		this.modalRefDelete.hide();
		this.toDelete = null;
		this.typeToDelete = "";
		this.newItem = {id: 0, activity: '', day: '', dayname: '', date: '', hour: '', responsible: '', comments: '', contact_id: 0};
	}

	showAddDayToSchedule(){

	}

	addDaytoSchedule(){
	    var body = new FormData();
	    body.append('id', this.newItem['id']+'');
	    body.append('template_id', this.templateId);
	    body.append('template_type_id', this.newItem['day']);
	    body.append('name', this.newItem['activity']);
	    body.append('order', 1+'');

	    this.service.queryPost('template/type', body).subscribe(
	      response=>
	      {      
	        let result = response.json(); 
	        
	        if(result.success)
	        {
	          
	          //Show success message
	          this.toastr.success(result.message);
	          this.getTemplateDetails();
	          this.modalRef.hide();
	          this.newItem = {id: 0, activity: '', day: '', dayname: '', date: '', hour: '', responsible: '', comments: '', contact_id: 0};
	          this.newDayToSchedule = {id: 0, template_id: 0, template_type_id: 0, name: ''};
	        }
	        else
	        {
	          let message = '';
	          if(result.message.length > 0)
	          {
	            result.message.forEach(function(element) {
	              message += element+'<br>';
	            });
	          }

	          this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
	        }
	      },
	      err => 
	      {
	        console.log(err);
	      }
	    );
  	}

	deleteItem()
	{

		let url = "";

		if(this.typeToDelete == "item"){
	      url = "project/schedule/item/"+this.toDelete;
		}

		if(this.typeToDelete == "schedule"){
	    	url = "project/schedule/"+this.toDelete;
		}

	    this.service.queryDelete(url).subscribe(
	      	response=>
	      	{      
	          	let result = response.json(); 

	          	if(result.success)
	          	{
	              	//Reload the list 
								
					if(this.typeToDelete == "item"){
						this.editSchedule(this.templateSchedule['id']);
					}

					if(this.typeToDelete == "schedule"){
						this.getScheduleList(this.currentPage);
					}

					this.modalRefDelete.hide();
	              	this.toDelete = null;
					this.toastr.success(result.message);
	          	}
	      	},
	      	err => 
	      	{
	          	console.log(err);
	      	}
	    );
	}


	/**********************************/

	async addDay()
	{
		
    	this.inputRow = false;
		this.postUpdate = null;
		await 	this.editSchedule(this.templateSchedule['id']);
		
		let day = 1;
		if(this.daysList.length > 0){
			day = day+this.daysList.length;
		}

		let objet = {id: 0, daynumber: day, dayname: '', date: null,project_id: this.project, project_template_id: this.templateSchedule['id'], activities: [], showItems: 1};
		

		let e = this;
		setTimeout(()=>{
			e.daysList.push(objet);
		}, 600);
		
		this.activePosition = day - 1;
		this.openDay = day - 1;
		window.scrollTo(0,document.body.scrollHeight);
	}

	addctivity(pos){
		if('' == this.newItem['activity']){
			this.inputRow = false;
		} else {
			this.postUpdate = -1;
			let contact = '';
			if(this.newItem['contact_id'] != 0){
				contact = this.newItem['contact_id']+'';
			}
			let activity = {
					id: 0, 
					name: this.newItem['activity'], 
					day:  this.daysList[pos]['daynumber'], 
					dayname: this.daysList[pos]['dayname'], 
					date: this.newItem['date'], 
					hour: this.newItem['hour'], 
					responsible: this.newItem['responsible'], 
					comments: this.newItem['comments'],
					contact_id: contact
			};

			this.daysList[pos].activities.push(activity);
			this.newItem = {id: 0, activity: '', day: '', dayname: '', date: '', hour: '', responsible: '', comments: '', contact_id: 0};
			this.inputRow = false;
			
			let body = new FormData();
			body.append('data', JSON.stringify(this.daysList));
			this.service.queryPost('project/schedule/item', body).subscribe(
				response=>
				{      
					let result = response.json(); 
					if(result.success)
					{
						//this.toastr.success(result.message);
						this.editSchedule(this.templateSchedule['id']);
						this.posToEdit = -1;
					}else{
						this.editSchedule(this.templateSchedule['id']);
						this.toastr.error(result.message);
					}

				},
				err => 
				{
						console.log(err);
				}
			);
		}
	}

	editDay(position){
		this.activePosition = position;
	}

	editActivity(dayPos, itemPos, close, type = null){
		//console.log(type, close);
		if(this.userSession['rol_id']){
			/*this.activePosition = position;*/
			if(type === 'day')
			{
				for(let i = 0; i < this.daysList.length; i++){
					for(let j = 0; j < this.daysList[i].activities.length;j++)
					{
						this.daysList[i].activities[j].dayname = this.daysList[i].dayname;
					}
				}
			}
			let x = event.preventDefault;
			let body = new FormData();
			body.append('data', JSON.stringify(this.daysList));
			this.service.queryPost('project/schedule/item', body).subscribe(
					response=>
					{      
						let result = response.json(); 
						if(type !== 'day')
						{
							if(type == 'save'){
								this.postUpdate = null;
								this.editSchedule(this.templateSchedule['id']);
							}else{
								
								let contactId = this.daysList[dayPos]['activities'][itemPos]['contact_id'];
								if(contactId != null){
									for(let i = 0; i < this.contactsProject.length; i++){
										if(contactId == this.contactsProject[i].id){
											this.daysList[dayPos]['activities'][itemPos]['contact_name'] = this.contactsProject[i].name;
										}
									}
								}
								let hour = this.daysList[dayPos]['activities'][itemPos]['hour'];
								if(hour != null){
									this.daysList[dayPos]['activities'][itemPos]['hour_complete'] = moment(hour, 'HH:mm').format("hh:mm a");
								}
							}
						}
						else if(close == 1){
							this.activePosition = null;
						}
					},
					err => 
					{
							console.log(err);
					}
			);
		}
		
	}

	validateHour(hour){

	}

	setFocus(prev ,id){
		console.log(prev)
		let idfocus = prev+''+id;
		this.idFocus = idfocus;
	}

	Focus(){
		if(this.idFocus != ''){
			console.log(this.idFocus);
			document.getElementById(this.idFocus).focus();
		}
	}

	deleteDayActivity(pos, activityPos){
		this.daysList[pos].activities.splice(activityPos, 1);
	}

	  
  	async addRow(pos){
		this.inputRow = true;
    	this.postUpdate = -1;
		this.posToEdit = pos;
		
		let idInput = "newItem"+pos;
	    await this.singleton.sleep(100);
	    document.getElementById(idInput).focus();
  	}

  	cancelAddrow(){
    	this.inputRow = false;
		this.posToEdit = -1;
		this.postUpdate = -1;
  	}


	viewListDiv(){
		this.daysList = [];
		this.listView = true;
		this.scheduleView = false;
		this.showOptionsSchedule = true;
		this.showTemplateSelector = true;
		this.templateSchedule = {id: 0, branding: 0, description: '', name: '', days: 0,  template_type_id: 0, template_schedule: []};
		this.posToEdit = -1;
		this.postUpdate = null;
		this.getScheduleList('');
	}

	viewScheduleDiv(){
		this.listView = false;
		this.scheduleView = true;
	}

	export(id,type){
		/**
		 * 
		 * when I want to export check list, note, schedule and budget I need to send "1"
		 * with these a send id as a project_template_id, when is documenta send project_document_id and 
		 * other I send project_id
		 */

		let report_type = 1 
		let temp = {id: id, type: type, report_type: report_type};
		this.dataService.changeModal(temp);
	}

	public projectData = {id: 0, department_id: 0, city_id: 0, color: '', date: '', image:'', name:'', name_project_type: '',  other_type_name:'',project_type_id: 0, user_id: 0, url_image_project: ''};


	previewfunc(id){

		this.dataService.currentProject.subscribe(project => {
			if(null != project){
				this.projectData['name'] = project.name_project;
				this.projectData['name_project_type'] = project.name_project_type; 
				this.projectData['url_image_project'] = project.url_image_project;
			}
		});
	  
		  var prtContent = document.getElementById(id);
		  debugger;
		  var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
		  var title = this.projectData['name'];
		  var title_html = '<title>Cronograma -'+title+'</title>';
		  WinPrint.document.write('<style > body{font-family: Roboto, sans-serif; font-size: 12px;}  a{display: none;} img{display: none;} .logo {display: block;} .title{font-weight: 700;padding: 25px;font-size: 2em;color: #A3777E;} thead {padding: 7px; background: #F1C1B0;color: #FFF;padding: 5px 0;text-align: left;width: 100%;vertical-align: bottom; border-bottom: 2px solid #F1C1B0;}  .col-md-3.table-a {display: inline-grid; margin: 21px; width: 25%;}  .header-groups { color: black; display: inline-block;font-size: 12px;font-weight: 700;margin-bottom: 2px;vertical-align: middle;margin-left: 14px;}.italic-text{font-style: italic;} td, th {padding: 4px 0px;} .print-header{border-bottom: 2px solid #d8d8d8;background: #d8d8d8;padding: 5px;} table{font-size: 12px; width:100%} footer {font-style: italic;color: #AAB7A0;margin-top: 15px;}</style>');
	  
		  WinPrint.document.write(title_html);
		  WinPrint.document.write('<div class="title">'+title+'</div>');
		  
		  WinPrint.document.write(prtContent.innerHTML);
		  WinPrint.document.write('<footer>Generado por Confetti Planner - confettiplanner.com - Registra tus eventos y comparte</footer>');

		  WinPrint.document.close();
		  WinPrint.focus();
		  WinPrint.print();
		  WinPrint.close();


	    /*let url = 'project/document/'+id;
	    let body = new FormData();
	    body.append('id', id+'');
	    body.append('type', 'pdf');
	    body.append('report_type', 1+'');
	    body.append('view', 1+'');

    	this.service.queryPost('project/exports', body).subscribe(
	      	response=>
	      	{      
		        let result = response.json(); 
		        if(result.success){
					let pdf = result.url;
		            this.singleton.pdf = result.url;
		            this.router.navigate([`/project/${this.projectIdEncry}/preview`]);
	        	}
      		},
      		err => 
  			{
        		console.log(err);
      		}
    	);  */
  	}

  	reloadPage(){
  		this.getScheduleList('');
		this.getSchedulesTemplate();
		this.getContactsProject();
  	}

  	openAuxDay(position){
  		let currentShow = this.daysList[position]['showItems'];
		if(0 == currentShow || undefined == currentShow){
			this.daysList[position].showItems = 1;
		} else {
			this.daysList[position].showItems = 0;; 
		}
        /*if(position == this.openDay){
            this.openDay = null;
        } else {

            this.openDay = position;
        }*/
    }
    
    goToTemplates(){
        this.closeModal();
        this.router.navigate([`/templates`]);
    }
}
