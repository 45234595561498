import { NgModule, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { UsersComponent } from './users/users.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { ProfileComponent } from './profile/profile.component';
import { DocumentsComponent } from './documents/documents.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ContactsComponent } from './contacts/contacts.component';
import { CompanyComponent } from './company/company.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { TeamcompanyComponent } from './teamcompany/teamcompany.component';
import { HelpComponent } from './help/help.component';
import { BrandingComponent } from './branding/branding.component';
import { TemplatesComponent } from './templates/templates.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { BudgetTemplateComponent } from './budget-template/budget-template.component';
import { NotesTemplateComponent } from './notes-template/notes-template.component';
import { DocumentTemplateComponent } from './document-template/document-template.component';
import { ScheduleTemplateComponent } from './schedule-template/schedule-template.component';
import { CheckListComponent } from './check-list/check-list.component';
import { RegisterProjectComponent } from './register-project/register-project.component';
import { ProjectsLayoutComponent } from './projects-layout/projects-layout.component';
import { HomeprojectComponent } from './homeproject/homeproject.component';
import { AdminCategoriesProjectComponent } from './admin-categories-project/admin-categories-project.component';
import { NotesProjectComponent } from './notes-project/notes-project.component';
import { InspirationProjectComponent } from './inspiration-project/inspiration-project.component';
import { CheckListProjectComponent } from './check-list-project/check-list-project.component';
import { ProvidersProjectComponent } from './providers-project/providers-project.component';
import { BudgetProjectComponent } from './budget-project/budget-project.component';
import { GuestsProjectComponent } from './guests-project/guests-project.component';
import { AccommodationsProjectComponent } from './accommodations-project/accommodations-project.component';
import { CalendarProjectComponent } from './calendar-project/calendar-project.component';
import { ScheduleProjectComponent } from './schedule-project/schedule-project.component';
import { ParticipantsProjectComponent } from './participants-project/participants-project.component';
import { LoginCompanyComponent } from './login-company/login-company.component';
import { PasswordsComponent } from './passwords/passwords.component';
import { PreviewsComponent } from './previews/previews.component';
import { PreviewTemplateComponent } from './preview-template/preview-template.component';
import { ListnotificationsComponent } from './listnotifications/listnotifications.component';
import { ClientGuardService } from './client-guard.service';
import { TeamGuardService } from './team-guard.service';
import { CompanyGuardService } from './company-guard.service';
import { BlockedGuardGuard } from './blocked-guard.guard';
import { AdminGuard } from './admin.guard';
import { SessionGuard } from './session.guard';
import { PermissionsGuard } from './permissions.guard';
import { PaymentSettingsComponent } from './payment-settings/payment-settings.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';



const routes: Routes = [
	{ path: 'reset-password/:token', component: PasswordsComponent },
	{ path: '', component: LandingPageComponent },	
  	{ path: 'login', component: LoginComponent },
  	{ path: 'confirm-email/:id', component: ConfirmEmailComponent },
  	{ path: 'signup/:company/:project/:userId', component: LoginCompanyComponent },
  	{ path: 'wp/:slug', component: LoginCompanyComponent },
  	{ path: '',
	    component: LayoutComponent,
	    canActivate:[PermissionsGuard],
	    children: 
	    [
			{ path: 'home', component: HomeComponent, canActivate:[ClientGuardService, PermissionsGuard] }, 
			{ path: 'listnotifications', component: ListnotificationsComponent, canActivate:[ClientGuardService, PermissionsGuard] }, 
			{ path: 'calendar', component: CalendarComponent, canActivate:[ClientGuardService, TeamGuardService, PermissionsGuard] }, 
			{ path: 'contacts', component: ContactsComponent, canActivate:[ClientGuardService, TeamGuardService, PermissionsGuard] }, 
			{ path: 'users/:company_id', component: UsersComponent, canActivate:[AdminGuard, PermissionsGuard] },
			{ path: 'permissions', component: PermissionsComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'profile', component: ProfileComponent },
			{ path: 'documents', component: DocumentsComponent, canActivate:[ClientGuardService, TeamGuardService, PermissionsGuard] },
			{ path: 'company', component: CompanyComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'notifications', component: NotificationsComponent},
			{ path: 'teamcompany', component: TeamcompanyComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'help', component: HelpComponent},
			{ path: 'branding', component: BrandingComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'templates', component: TemplatesComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'budgettemplate/:id', component: BudgetTemplateComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'notestemplate/:id', component: NotesTemplateComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'documenttemplate/:id', component: DocumentTemplateComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'scheduletemplate/:id', component: ScheduleTemplateComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'checklisttemplate/:id', component: CheckListComponent, canActivate:[ClientGuardService, CompanyGuardService, PermissionsGuard] },
			{ path: 'registerproject', component: RegisterProjectComponent, canActivate:[ClientGuardService, PermissionsGuard] },
			{ path: 'preview', component: PreviewTemplateComponent, canActivate:[ClientGuardService, PermissionsGuard] },
			{ path: 'payment-setting', component: PaymentSettingsComponent, canActivate:[ClientGuardService, PermissionsGuard] },
			{ path: 'users/:company_id/projects/:user_id', component: ProjectsListComponent, canActivate:[ClientGuardService,AdminGuard, PermissionsGuard]}

	    ] 
	},
	{
		path: 'project/:project',
		component: ProjectsLayoutComponent,
		children:
		[
			{ path: 'preview', component: PreviewsComponent},
			{ path: 'notifications', component: ListnotificationsComponent }, 
			{ path: 'home', component: HomeprojectComponent }, 
			{ path: 'categories', component: AdminCategoriesProjectComponent }, 
			{ path: 'notes', component: NotesProjectComponent }, 
			{ path: 'inspiration', component: InspirationProjectComponent }, 
			{ path: 'check', component: CheckListProjectComponent }, 
			{ path: 'providers', component: ProvidersProjectComponent }, 
			{ path: 'budget', component: BudgetProjectComponent }, 
			{ path: 'guest', component: GuestsProjectComponent }, 
			{ path: 'accommodations', component: AccommodationsProjectComponent }, 
			{ path: 'calendar', component: CalendarProjectComponent }, 
			{ path: 'schedule', component: ScheduleProjectComponent }, 
			{ path: 'participants', component: ParticipantsProjectComponent },
		]
	},
	{ path:'**', redirectTo:'home' }

];

@NgModule({
  imports: [ 
						RouterModule.forRoot(routes),
						NgxPageScrollCoreModule.forRoot({duration: 2500})
						],
	exports: [ RouterModule ]
})
export class AppRoutingModule {

}