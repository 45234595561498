import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SingletonService } from '../singleton.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

	private userId;
   constructor(public service: ApirestService,
                 private toastr: ToastrService,
                  private router: Router,
                  private route: ActivatedRoute,
                  private singleton: SingletonService) { }

   ngOnInit(){
	  	this.userId = this.route.snapshot.paramMap.get("id");
	  	this.activeUser();
  	}

  	activeUser()
  	{
	  	let body = new FormData;
	  	body.append('id', this.userId);
	  	this.service.queryPostRegular('active-user', body).subscribe(
	      response=>
	      {      
	         let result = response.json(); 
	           
	         if(result.success) 
	         {
            this.toastr.success('Tu cuenta se ha activado correctamente, ya puedes ingresar a la plataforma');
	         	this.router.navigate(['login'], { queryParams: { email: result.email}});
	         }
         },
         err => 
         {
            console.log(err);
         }
	   ); 
  	}

}
