import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"cuttext"
})
export class CutTextPipe implements PipeTransform{
  
  transform(value:string, limite:number) : string{
    let data = value.split(" ");
    let val = "";
    for(let i = 0; i < limite; i++){
    	if(i != 0){
        if(data[i] != undefined){
    		  val += " "+data[i];
        }
    		
    	}else{
    		val += data[i];
    	}
    }
    return val;
  }

}