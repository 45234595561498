import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-schedule-template',
  templateUrl: './schedule-template.component.html',
  styleUrls: ['./schedule-template.component.scss']
})
export class ScheduleTemplateComponent implements OnInit {
    public templateId = null;
    public templateType = null;
    public templateSchedule = {id: 0, branding: 0, description: '', name: '', days: 0,  template_type_id: 0, template_schedule: []};
    
    public newDayToSchedule = {id: 0, template_id: 0, template_type_id: 0, name: ''};
    public newItem = {id: 0, activity: '', day: ''};
    public newActivityTask = {id: 0, template_schedule_id: 0, day_number: 0, activity: '', order: 0};


    public typeToDelete = "";
    public toDelete = 0;

    public inputRow = false;


    public daysList = [];
    public openDay = 0;
    public activeDay = null;
    public dayToDelete = 0;
    public idDayToDelete = 0;
    public inputCalendar = false;
    public posToEdit = -1;
    public postUpdate = null;
    public editItem = "";

    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;
    @ViewChild('register')register;
    constructor(public service: ApirestService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private singleton: SingletonService,
        private lastlocation: Location,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private SessionService: SessionService) 
        { 
          window.scroll(0,0);
        }

  ngOnInit() {
    this.SessionService.validUserSession();
      let id = this.singleton.decrypt(this.route.snapshot.paramMap.get("id"));

      this.templateId = id;
      this.getTemplateDetails();
   
  }

  getTypesContacts(){
    let user = JSON.stringify(localStorage.getItem('user'));
    let url = '/contact-types/' + user['company_id'];

      this.service.queryGet(url).subscribe(
          response=>
          {      
            let result = response.json();          
          },
          err => 
          {
              console.log(err);
          }
      );
  }

  /**
   * Get template data
   */
  getTemplateDetails(){
    let url = 'template/' + this.templateId+'?type=4';

      this.service.queryGet(url).subscribe(
          response=>
          {      
                //We need to clear dayList to prevent duplicated data
                this.daysList = [];
                let result = response.json(); 
                if(false === result.success)
                {
                    if(result.message)
                    {
                        if('redirect' == result.message)
                        {
                          window.history.back();
                        }
                    }
                }
                else
                {
                  for (var i = 0; i < result.items.length; i++) 
                  {
                    if(i == 0)
                    {
                      this.daysList.push({id: result.items[i].id, daynumber: result.items[i].day, dayname: result.items[i].day_name, activities: [{id: result.items[i].id, name: result.items[i].activity, day: result.items[i].day, dayname:result.items[i].day_name}], showItems: 1});
                    }
                    else
                    {
                      let exists = false;
                      let pos = 0;

                      for(var j = 0; j < this.daysList.length; j++)
                      {
                        if(this.daysList[j].daynumber == result.items[i].day)
                        {
                          exists = true;
                          pos = j;
                        }
                      }
                      if(exists)
                      {
                        let tmpActivities = {id: result.items[i].id, name: result.items[i].activity, day: result.items[i].day, dayname:result.items[i].day_name, showItems: 1}
                        this.daysList[pos].activities.push(tmpActivities);
                      }
                      else
                      {
                        let tmpItems = {id: result.items[i].id, daynumber: result.items[i].day, dayname: result.items[i].day_name, activities: [{id: result.items[i].id, name: result.items[i].activity, day: result.items[i].day, dayname:result.items[i].day_name}], showItems: 1}
                        this.daysList.push(tmpItems);
                      }
                    }
                  }
                  this.templateSchedule['id'] = this.templateId;
                  this.templateType = result.template_type_id;
                  this.templateSchedule['branding'] = result.branding;
                  this.templateSchedule['description'] = result.description;
                  this.templateSchedule['name'] = result.name;

                  this.newDayToSchedule = {id: 0, template_id: result.id, template_type_id: result.template_type_id, name: ''};
                  
                  this.templateSchedule['template_schedule'] = result.template_schedule;
                }

                let totalDays = this.daysList.length;
                if(0 == totalDays){
                    let objet = {id: 0, daynumber: 1, dayname: '', activities: [], showItems: 1};
                    this.daysList.push(objet);
                }

          },
          err => 
          {
              console.log(err);
          }
      );
  }


  changeDayName(pos, target){
    
    let itemToupdate = this.daysList[pos].activities;
    let length = itemToupdate.length;
    for(let i = 0; i < length; i++){
        this.daysList[pos].activities[i].dayname = this.daysList[pos].dayname;
    }
  }

  addDay(){

    this.postUpdate = -1;
    this.inputRow = false;

    let day = 1;
    if(this.daysList.length > 0){
      day = day+this.daysList.length;
    }

    let objet = {id: 0, daynumber: day, dayname: '', activities: [], date: '', showItems: 1};
    this.daysList.push(objet);
    let totalDays = this.daysList.length; 
    if(totalDays > 0){
        totalDays = totalDays - 1;
    }
    this.openDay = totalDays;
    this.inputCalendar = true;
    window.scrollTo(0,document.body.scrollHeight);
  }

  async dobleClick(id){
    this.postUpdate = id;
    this.inputRow = false;
    let idInput = "item"+id;
    await this.singleton.sleep(100);
    document.getElementById(idInput).focus();
  }

  editActivity(close, event = null){
		let body = new FormData();
    body.append('data', JSON.stringify(this.daysList));
    body.append('template_id', this.templateId+'');

		this.service.queryPost('template/schedule/item', body).subscribe(
			response=>
			{      
				let result = response.json(); 
				if(result.success)
				{
					//this.toastr.success(result.message);
					this.postUpdate = null;
					this.getTemplateDetails();
					
				}else{
					this.toastr.error(result.message);
					this.getTemplateDetails();
				}

			},
			err => 
			{
					console.log(err);
			}
		);
	}


  addctivity(pos){
    if(null == this.newItem['activity'] ||  '' == this.newItem['activity']){
      /*this.toastr.error('El campo actividad es obligatorio', 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
      return;*/
      this.newItem = {id: 0, activity: '', day: ''};
      this.inputRow = false;
      
    } else {

      this.postUpdate = -1;
      let activity = {id: 0, name: this.newItem['activity'], day:  this.daysList[pos].daynumber, dayname: this.daysList[pos].dayname};
      this.daysList[pos].activities.push(activity);
      this.newItem = {id: 0, activity: '', day: ''};
      this.inputRow = false;
      let body = new FormData();
      body.append('data', JSON.stringify(this.daysList));
      body.append('template_id', this.templateId+'');
      this.service.queryPost('template/schedule/item', body).subscribe(
          response=>
          {      
            let result = response.json(); 
            if(result.success)
            {
              //this.toastr.success(result.message);
              this.getTemplateDetails();
            }else{
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }

          },
          err => 
          {
              console.log(err);
          }
      );
    }
  }

  deleteDayActivity(pos, activityPos){
    this.daysList[pos].activities.splice(activityPos, 1);
  }



  async addRow(pos){

    this.inputRow = true;
    this.posToEdit = pos;
    this.postUpdate = -1;
    let idInput = "newItem"+pos;
    await this.singleton.sleep(100);
    document.getElementById(idInput).focus();
    /*let activity = {id: 0, name: ''};
    this.daysList[pos].activities.push(activity);
    this.postUpdate = 0;*/
  }

  cancelAddrow(){
    this.inputRow = false;
    this.posToEdit = -1;
    this.postUpdate = null;
    this.newItem = {id: 0, activity: '', day: ''};
  }



  closeModal(){
    this.modalRef.hide();
    this.newItem = {id: 0, activity: '', day: ''};
  }


  confirmDelete(template, type, id){
    this.toDelete = id;
    this.typeToDelete = type;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = null;
    this.typeToDelete = "";
    this.newItem = {id: 0, activity: '', day: ''};
  }


  deleteItem(){
    if(this.toDelete > 0){
      let url = "";
      if(this.typeToDelete == "itemPrincipal"){
        url = "template/schedule/item/"+this.toDelete;
      }else{
        url = "template/schedule/item/"+this.toDelete;
      }
      this.service.queryDelete(url).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                this.getTemplateDetails();
                this.modalRefDelete.hide();
                this.toDelete = null;
                this.typeToDelete = '';
            }
            else {
                this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
      );
    } else {
      this.cancelAddrow();
    }
  }

  saveInfo(){
    let message = "Plantilla";
    this.toastr.success("Plantilla de minuto a minuto guardada correctamente");
    this.singleton.editTemplate = 0;
    this.router.navigate(['templates']);
  }


  goback(){
    this.singleton.editTemplate = this.templateId;
    this.singleton.typeTemplate = this.templateType;
    this.router.navigate(['/templates']);
  }

  editDay(position){
    this.activeDay = position;
  }

  confirmDeleteDay(template, position, dayId){
    this.dayToDelete = position;
    this.idDayToDelete = dayId;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

    deleteSelectDay(){

        if(this.idDayToDelete == 0){
            this.daysList.splice(this.dayToDelete, 1);
            this.modalRefDelete.hide();

        } else {

            let url = "template/schedule/item/" + this.templateId + '/' + this.dayToDelete;

            this.service.queryDelete(url).subscribe(
              response=>
              {      
                  let result = response.json(); 

                  if(result.success)
                  {
                      //Reload the list users
                      this.getTemplateDetails();
                      this.modalRefDelete.hide();
                      this.toDelete = null;
                      this.typeToDelete = '';
                      
                  }
              },
              err => 
              {
                  console.log(err);
              }
            );
        }
    }

    saveDay(position){

        //this.activeDay = null;
        //{id: 0, daynumber: day, dayname: '', activities: [], date: ''};
        let body = new FormData();
        body.append('daynumber', this.daysList[position]['day']);
        body.append('dayname', this.daysList[position]['dayname']);
        body.append('date', this.daysList[position]['date']);
        this.service.queryPost('template/schedule/item', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            if(result.success)
            {
                //this.toastr.success(result.message);
                this.activeDay = null;
                this.inputCalendar = false;
                this.getTemplateDetails();
            }else{
                this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }

        },
        err => 
        {
            console.log(err);
        }
    );

    }

    openSection(position){
      let currentShow = this.daysList[position]['showItems'];
      if(0 == currentShow || undefined == currentShow){
        this.daysList[position].showItems = 1;
      } else {
        this.daysList[position].showItems = 0;; 
      }
    }

}
