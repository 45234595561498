import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-budget-template',
  templateUrl: './budget-template.component.html',
  styleUrls: ['./budget-template.component.scss']
})
export class BudgetTemplateComponent implements OnInit {
  public templateId = null;
  public templateType = null;
  public template = {id: 0, branding: 0, company_id: 0, description: '', name: '', template_budget_items: null, template_type_id: 0, user_id: 0};

  public itemsDisplay = [];
  public valuetotal = 0;

  public postUpdate = null;

  public toDelete = null;
  public typeDelete = null;

  public toAdd = null;
  public typeAdd = null;
  public newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
  public newCategory = {id: 0, project_id: 0, name: '', order:0};
  public categoriesList = [];


  public showCategoryInput = false;

  public rowInputItems = false;
  public rowPostToAdd = -1;

  public categoryInput = null;
  public postUpdateCategory  = null;
  public activeCat = null;

  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }

    ngOnInit() {
      this.SessionService.validUserSession();  
      let id = this.singleton.decrypt(this.route.snapshot.paramMap.get("id"));

      this.templateId = id;
      this.getTemplateDetails();
      this.getCategories();
    }

    getCategories(){
      let user = JSON.parse(localStorage.getItem('user'));
      let url = 'categories?companyId='+user['company_id'];
      
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                let data = result.data;
                for(let i = 0; i < data.length; i++){
                   let temp = {value: data[i].id, display: data[i].name};
                   this.categoriesList.push(temp);
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    async dobleclickCategory(id, categoryId){
      this.postUpdate = -1;
      this.rowInputItems = false;
      this.postUpdateCategory = id;

      let idInput = "category"+id;
      await this.singleton.sleep(100);
      document.getElementById(idInput).focus();
    }

    updateCategoryName(id, name, close){

      if(name == ''){
        this.toastr.error("Debe ingresar un nombre para la categoria");
        return;
      }

      let body = new FormData();
      body.append('id', id);
      body.append('name', name);

      this.service.queryPost('categories', body).subscribe(
        response=>
        {      
          let result = response.json(); 
          this.getCategories();
          if(result.success)
          {
            this.postUpdateCategory = null;
            this.getTemplateDetails();
          }
          else
          {
            this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }


    getTemplateDetails(){
      let url = 'template/' + this.templateId+'?type=1';
      
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                if(false === result.success)
                {
                    if(result.message)
                    {
                        if('redirect' == result.message)
                        {
                          window.history.back();
                        }
                    }
                }
                else
                {
                  this.template['id'] = this.templateId;
                  this.templateType = result.template_type_id;
                  this.template['branding'] = result.branding;
                  this.template['company_id'] = result.company_id;
                  this.template['description'] = result.description;
                  this.template['name'] = result.name;
                  this.template['template_budget_items'] = result.template_budget_items;
                  this.template['template_type_id'] = result.template_type_id;
                  this.template['user_id'] = result.user_id;

                  this.itemsDisplay = [];

                  //Recorremos el array de items que llega para agruparlos por categoria
                  for(let i = 0; i < result.items.length; i++ ){
                    //Si es el primer item de la lista se crea el array a visualizar y se le inserta el primer dato
                    if(i == 0){
                      let object = {
                        id: result.items[i].category_id, 
                        name: result.items[i].name_category, 
                        order: result.items[i].category_order, 
                        items: [],
                        showItems: 1
                      };

                      this.itemsDisplay.push(object);

                      let itemsList = {
                        category_id:result.items[i].category_id,  
                        id: result.items[i].id, 
                        name: result.items[i].name, 
                        value: result.items[i].value, 
                        order: result.items[i].order, 
                        notes: result.items[i].notes
                      };

                      this.itemsDisplay[0].items.push(itemsList);
                    }else{
                      //Asumimos que cuando no es el primer item del array su cateogia aun no ha sido ingresada en el array de visualización, por ende procedemos a validar ésto
                        let existCategory = false;
                        let posExist = 0;
                        //Recorremos el array de visualización buscando el id de la categoria
                        for(let j = 0; j < this.itemsDisplay.length; j++){
                          //Si encontramos un id = al id de la categoria es porque ya ha sido añadida dicha categoria y solo debemos adicionar el item
                          if(this.itemsDisplay[j].id == result.items[i].category_id){
                            existCategory = true;
                            posExist = j;
                          }
                        }
                        if(existCategory){

                          let itemsList = {category_id:result.items[i].category_id, id: result.items[i].id, name: result.items[i].name, value: result.items[i].value, order: result.items[i].order, notes: result.items[i].notes, showItems:1};

                          this.itemsDisplay[posExist].items.push(itemsList);
                        
                        }else{
                          let object = {id: result.items[i].category_id, name: result.items[i].name_category, order: result.items[i].category_order,  items: [], showItems:1};
                          this.itemsDisplay.push(object);
                          
                          //Recorremos el array de visualización buscando el id de la categoria
                          for(let j = 0; j < this.itemsDisplay.length; j++){
                            //Si encontramos un id = al id de la categoria es porque ya ha sido añadida dicha categoria y solo debemos adicionar el item
                            if(this.itemsDisplay[j].id == result.items[i].category_id){
                              let itemsList = {category_id: result.items[i].category_id, id: result.items[i].id, name: result.items[i].name, value: result.items[i].value, order: result.items[i].order, notes: result.items[i].notes, showItems:1};
                              this.itemsDisplay[j].items.push(itemsList); 
                            }
                          }

                        }
                    }
                  }

                  this.calculateTotals();
                }

                let totalItems = this.itemsDisplay.length;
                if(0 == totalItems){
                  this.showCategoryToInput();
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }



    calculateTotals(){
      let total = 0;
      for(let i = 0; i < this.itemsDisplay.length; i++){
        let tempItems = this.itemsDisplay[i].items;
        for(let j = 0; j < tempItems.length; j++){
          total = total + tempItems[j].value;
        }
      }
      this.valuetotal = total;
    }

    confirmDelete(template, id, typeDelete){
      this.toDelete = id;
      
      this.typeDelete = typeDelete;
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    saveData(close){
      if('' == this.newItem['name'] || null == this.newItem['name']){
        this.cancelAddItem();

      } else {

        var body = new FormData();
        let url = "";
        body.append('id', this.newItem['id']+'');
        body.append('category_id', this.newItem['category_id']+'')
        body.append('template_id', this.templateId+'')
        body.append('name', this.newItem['name']);
        body.append('value', this.newItem['value']);
        body.append('notes', this.newItem['notes']);
        body.append('order', this.newItem['order'].toString());
        body.append('category_order', this.newItem['category_order'].toString());

        url = "template/budget/items";
        this.service.queryPost(url, body).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          if(result.success)
          {
            //Show success message
            //this.toastr.success(result.message);
            this.cancelAddItem();
            this.getTemplateDetails();
            // this.getCategories();
            this.typeAdd = null;
            this.newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
            this.newCategory = {id: 0, project_id: 0, name: '', order: 0};
            
          }
          else
          {
            this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
     }
    }


    addCategoryInput(event){
        this.showCategoryInput = false;
        let name = "";
        let validateInsert = true;
        
        //We need to validate if its a new category
        //when its true-> we need to add on itemsDisplay else, we need to register on DB and then insert on itemsDisplay
        if(event.value != event.display){
            this.newCategory['id'] = event.value;

            for(let i = 0; i < this.itemsDisplay.length; i++){
                if(this.itemsDisplay[i].id == this.newCategory.id){
                    validateInsert = false;
                }
            }

            if(validateInsert){
                this.itemsDisplay.push({
                    id: this.newCategory.id, 
                    name: event.display,
                    order: 0,
                    items: [{
                    category_id: this.newCategory.id,  
                    id: 0, 
                    name: 'default', 
                    value: '0', 
                    order: '', 
                    notes: ''
                    }],
                    showItems: 1
                });

                let position = this.itemsDisplay.length;
                if(position > 0){
                    position = position -1;
                }
                this.activeCat = position;
                this.newItem = {id: 0, category_id: this.newCategory.id, category_order:0, contact_id: 0, template_id: 0, name: 'default', value: '', notes: '', order: 0};
                this.saveData(0);
                this.newCategory = {id: 0, project_id: 0, name: '', order:0};

            }else{
              this.toastr.warning('Ésta categoría ya ha sido insertada');
            }
      }else{
        if(undefined != event.value && '' != event.value && null != event.value){          
          this.addCategory(event.value);
        } else {
            this.showCategoryInput = false;
        }
      }
    }


    removeCategoryInput(){

    }


    addCategory(name){
      let user = JSON.parse(localStorage.getItem('user'));
      let company = user['company_id'];
      var body = new FormData();
      body.append('id', '0');
      body.append('company_id', company+'')
      body.append('name', name+'')

      this.service.queryPost('categories', body).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          if(result.success)
          {
            let category = result.category;
            // this.itemsDisplay.push({id: category.id, name: category.name});
            this.itemsDisplay.push({
              id: category.id, 
              name: category.name,
              order: 0,
              items: [{
                category_id: category.id,  
                id: 0, 
                name: 'default', 
                value: '0', 
                order: '', 
                notes: ''
              }]
            });
            let position = this.itemsDisplay.length;
            if(position > 0){
              position = position -1;
            }
            this.activeCat = position;
            this.newItem = {id: 0, category_id: category.id, category_order:0, contact_id: 0, template_id: 0, name: 'default', value: '', notes: '', order:0};
            this.saveData(1);
          }
          else
          {
            let message = '';
            if(result.message.length > 0)
            {
              result.message.forEach(function(element) {
                message += element+'<br>';
              });
            }

            this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }


    cancelAddCategory(){
      this.newCategory = {id: 0, project_id: 0, name: '', order:0};
      this.showCategoryInput = false;
    }

    async showAddItem(pos, idCategory){
      this.postUpdate = -1;
      this.newItem = {id: 0, category_id: idCategory, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
      this.rowInputItems = true;
      this.rowPostToAdd = pos;
      this.showCategoryInput = false;

      let idInput = "newItem"+pos;
      await this.singleton.sleep(100);
      document.getElementById(idInput).focus();
    }

    cancelAddItem(){
      this.rowInputItems = false;
      this.rowPostToAdd = -1;
    }

    async showCategoryToInput(){
      this.postUpdate = -1;
      this.rowInputItems = false;
      this.showCategoryInput = true;
      this.postUpdateCategory = -1;

      let idInput = "newCategory";
      await this.singleton.sleep(100);

      let parent = document.getElementById(idInput);
      let childP1 = parent.children[0];
      let childP2 = childP1.children[0];
      let childP3 = childP2.children[0];
      let childP4 = childP3.children[0];
      let childP5 = childP4.children[0];
      childP5.setAttribute("id", "_"+idInput);
      document.getElementById('_'+idInput).focus();
      childP5.removeAttribute("id");
    }

    async dobleclick(id){
      this.rowInputItems = false;
      this.showCategoryInput = false;
      this.postUpdateCategory = -1;

      if(id != ''){
        
        this.newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
        if(id != null){
          await this.getDataToUpdateItem(id);
        }
        this.postUpdate = id;

        let idInput = "item"+id;
        await this.singleton.sleep(100);
        document.getElementById(idInput).focus();
      }
    }

    getDataToUpdateItem(id){
      
       //Recorremos el array de items
       for(let i = 0; i < this.itemsDisplay.length; i++){
        if(this.itemsDisplay[i].items){
          let items = this.itemsDisplay[i].items;
          if(items.length > 0 ){
            for(let j = 0; j < items.length; j++){
              if(items[j].id == id){
                this.newItem['id'] = id;
                this.newItem['category_id'] = this.itemsDisplay[i].id;
                this.newItem['category_order'] = this.itemsDisplay[i].order;
                this.newItem['template_id'] = this.templateId;
                this.newItem['name'] = items[j].name;
                this.newItem['value'] = items[j].value;
                this.newItem['notes'] = items[j].notes;
                this.newItem['order'] = items[j].order;
                //this.newItem = items.notes;
                //this.updateItem();
    
              }
            }
          }
        }
      }
    }

    updateItem(close, reload = true){
      
        var body = new FormData();
        body.append('id', this.newItem['id']+'');
        body.append('category_id', this.newItem['category_id']+'');
        body.append('template_id', this.newItem['template_id']+'');
        body.append('name', this.newItem['name']);
        body.append('category_order', this.newItem['category_order'].toString());
        body.append('order', this.newItem['order'].toString());
        /*   body.append('value', this.newItem['value']);
        body.append('notes', this.newItem['notes']); */

        let url = "template/budget/items";

        this.service.queryPost(url, body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {
              
              //Show success message
              //this.toastr.success(result.message);
              
                this.postUpdate = null;
                this.typeAdd = null;
                this.newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
                this.newCategory = {id: 0, project_id: 0, name: '', order:0};
                console.log(reload);
                if(reload)
                {
                    this.getTemplateDetails();
                }
            }
            else
            {
              //this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
          },
          err => 
          {
            console.log(err);
          }
        );
      
    }

    saveInfo(){
      this.toastr.success("Plantilla de presupuesto guardada correctamente");
      this.singleton.editTemplate = 0;
      this.router.navigate(['templates']);
    }

    deleteElement(){

      if(this.typeDelete == 'itemcat'){
        let url = 'template/budget/item/'+this.toDelete;

        this.service.queryDelete(url).subscribe(
          response=>
          {      
              let result = response.json(); 

              if(result.success)
              {
                  //Reload the list users
                  this.getTemplateDetails();
                  this.modalRefDelete.hide();
                  this.toDelete = null;
                  //this.toastr.success(result.message);
              }
          },
          err => 
          {
              console.log(err);
          }
        );

      }
      else if(this.typeDelete == 'category'){
        let pos = null;

        for(let i = 0; i < this.itemsDisplay.length; i++){
          if(this.toDelete == this.itemsDisplay[i].id){
            pos = i;
          }
        }
        
        this.itemsDisplay.splice(1, pos);
        this.modalRefDelete.hide();

      }

    }

    getArrayToDelete(){
      let categoryId = this.toDelete;
      // let arrayToDelete = [];
      
      for(let i = 0; i < this.itemsDisplay.length; i++){
        let items = this.itemsDisplay[i].items;
        
        for(let j = 0; j < items.length; j++){
          if(items[j].category_id == categoryId){
            // arrayToDelete.push(items[j].id);
            this.toDelete = items[j].id;
            this.typeDelete = 'itemcat';
            this.deleteElement();
          }
        }
         
      }

      
      // for(let i = 0; i < arrayToDelete.length; i++){
      //   this.toDelete = arrayToDelete[i];
      //   this.typeDelete = 'itemcat';
      //   this.deleteElement();
      // }
    }

    cancelDelete(){
      this.modalRefDelete.hide();
      this.toDelete = null;
      this.typeDelete = null;
    }


    closeModal(){
      this.modalRef.hide();
      this.toAdd = null;
      this.typeAdd = null;
      
    }


    goback(){
      this.singleton.editTemplate = this.templateId;
      this.singleton.typeTemplate = this.templateType;
      this.router.navigate(['/templates']);
    }

    onItemDrop(event, catPos, itemPos){ 
      if(!event.dragData.items)
      {
        let prevPos = this.itemsDisplay[catPos].items.indexOf(event.dragData);
        this.itemsDisplay[catPos].items[prevPos].order = itemPos; 
        let length =this.itemsDisplay[catPos].items.length;
        
        for (let i = 0; i < length; i ++) 
        {
          if(i != prevPos && i > 0)
          {
            if(itemPos > prevPos && itemPos >= i && 1 != this.itemsDisplay[catPos].items[i].order)
            {
              this.itemsDisplay[catPos].items[i].order--;
            }
            else if(itemPos < prevPos && itemPos <= i && prevPos >= i)
            {
              this.itemsDisplay[catPos].items[i].order++;
            }
          }
        }

        this.itemsDisplay[catPos].items.sort(function (i1,i2) {
           if (i1.order < i2.order) { //comparación lexicogŕafica
              return -1;
           } else if (i1.order > i2.order) {
              return 1;
           } 
           return 0;
        });
        this.updateOrder();
      }
    }

    onItemDropCat(event, catNewPos){ 
        if(event.dragData.items)
        {
            let prevPos = this.itemsDisplay.indexOf(event.dragData);
            this.itemsDisplay[prevPos].order = catNewPos;
            let length = this.itemsDisplay.length;

            for (let i = 0; i < length; i ++) 
            {
              if(i != prevPos)
              {
                if(i <= catNewPos && catNewPos > prevPos && 0 < this.itemsDisplay[i].order)
                {
                  this.itemsDisplay[i].order--;
                }
                else if(i >= catNewPos && catNewPos < prevPos && prevPos >= i)
                {
                  this.itemsDisplay[i].order++;
                }
              }
            }

            this.itemsDisplay.sort(function (i1,i2) {
                if (i1.order < i2.order) { //comparación lexicogŕafica
                    return -1;
                } else if (i1.order > i2.order) {
                    return 1;
                } 
                return 0;
            });
            this.updateOrder();
        }
    }

    async updateOrder()
    {
      
        let lengthCat = this.itemsDisplay.length;
        for(let i = 0; i < lengthCat; i ++)
        {
            let lengthItem = this.itemsDisplay[i].items.length;
            for(let j = 0; j < lengthItem; j++)
            {
              this.newItem = {
                id: this.itemsDisplay[i].items[j].id, 
                category_id: this.itemsDisplay[i].id, 
                category_order:this.itemsDisplay[i].order,
                contact_id: 0,
                template_id: this.templateId,
                name: this.itemsDisplay[i].items[j].name,
                value: this.itemsDisplay[i].items[j].value,
                notes: this.itemsDisplay[i].items[j].notes,
                order:this.itemsDisplay[i].items[j].order,
              };
              console.log(this.itemsDisplay[i].items[j].name, this.itemsDisplay[i].items[j].order);
              await this.singleton.sleep(200);
              this.updateItem(1, false);
            }
        }
    }

    openCategory(position){
        let currentShow = this.itemsDisplay[position]['showItems'];
        if(0 == currentShow || undefined == currentShow){
            this.itemsDisplay[position].showItems = 1;
            //this.activeCat = position;
        } else {
            this.itemsDisplay[position].showItems = 0;; 
        }
        this.activeCat = null;
    }
}
