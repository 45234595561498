import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SingletonService } from '../singleton.service';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';
import { isArray } from 'util';

@Component({
  selector: 'app-accommodations-project',
  templateUrl: './accommodations-project.component.html',
  styleUrls: ['./accommodations-project.component.scss']
})
export class AccommodationsProjectComponent implements OnInit {
  public toDelete = 0;
  public typeToDelete = null;
  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;

  private pagesNumber = 15; //Default value
  private search = '';
  private pages = [];
  private currentPage = 1;

  public table = {id: 0, project_id: 0, number: '', name: '', chairs: '', form: 0, before:0};
  
  public project = 0;
  private projectIdEncry = '';
  public guestGroups = [];
  public guestWithoutGroups = [];
  public tablesList = [];
  public lastPosChairAsigTable = -1;
  public draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost 
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  public userSession = null;
  public archiveProject = false;

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private dataService:DataServiceService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
  { 
      window.scroll(0,0);
  }

  ngOnInit() {
    
    this.SessionService.validUserSession();
    let id = localStorage.getItem('project');
    this.projectIdEncry = id;
    this.project = this.singleton.decrypt(id);
    this.singleton.getProjectData(this.project);
    this.getTables('');
    this.getGuestGroups();
    let user = JSON.parse(localStorage.getItem('user'));
    this.userSession = user;

    if(localStorage.getItem('show') == '0'){
      this.archiveProject = true;
    }
  }


  getGuestGroups(){
    this.service.queryGet('get-group-guest/'+this.project).subscribe(
      response=>
      {      
        let result = response.json(); 
        this.guestGroups = result.guestWithGroup;
        this.guestWithoutGroups = result.guestWithoutGroup;
        
      },
      err => 
      {
        console.log(err);
      }
    );
  }

  getTables(page){
     let url = 'table?projectId=' + this.project + '&rows='+this.pagesNumber;
      url += '&search=' + this.search;

      if(page != '')
      {
          url += '&page=' + page;            
      }

      this.service.queryGet(url).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          this.tablesList = result.data;
          
          for(let i = 0; i < this.tablesList.length; i++){
            let Listchairs = [];
            for(let j = 0; j < this.tablesList[i].chairs; j++){
              Listchairs.push({'id': j, guest: '', id_guest: 0});
            }
            let lastPositon = 0;
            if(this.tablesList[i].guests.length > 0){
              let tempPos = 0;
              let guestLength = this.tablesList[i].guests.length;
              for(let l = 0; l < Listchairs.length; l++){
                if(tempPos < guestLength){
                  let pos = this.tablesList[i].guests[tempPos]['position'];
                  lastPositon = pos;
                  if(l == pos){
                    Listchairs[l]['id'] = this.tablesList[i].guests[tempPos]['id'];
                    Listchairs[l]['id_guest'] = this.tablesList[i].guests[tempPos]['id_guest'];
                    Listchairs[l]['guest'] = this.tablesList[i].guests[tempPos]['guest'];
                    tempPos++
                  }
                  
                }               
              }
            }
            
            this.tablesList[i]['ListChairs'] = Listchairs;
            this.tablesList[i]['lastPosition'] = lastPositon; 
          }

          this.currentPage = result['current_page'];
          this.pages = this.singleton.pagination(result);
        },
        err => 
        {
          console.log(err);
        }
      );
  }


  saveTable(){
    if(0 != this.table['id'] && this.lastPosChairAsigTable > (parseInt(this.table['chairs'])-1))
    {
      this.toastr.error("No se puede reducir el número de sillas pues tiene invitados asignados");
      return;
    }
    if(this.table['number'] == ''){
      this.toastr.error('Debe ingresar el número de la mesa');
      return;
    }else{
      let number = 0
      if(this.tablesList.length > 0){
        for(let i = 0; i < this.tablesList.length; i++){
          if((this.tablesList[i].number == this.table['number']) && (this.tablesList[i].id != this.table['id'])){
            
            this.toastr.error("Éste número de mesa ya existe, debe ingresar un número diferente para continuar");
            return;
          }
        }
      }
    }

    if(this.table['chairs'] == '' || this.table['chairs'] == '0'){
      this.toastr.error('Debe ingresar el número de sillas');
      return;
    }

    let body = new FormData();
    body.append('id', this.table['id']+'');
    body.append('project_id', this.project+'');
    body.append('number', this.table['number']+'');
    
    if(this.table['name'] != null){
      body.append('name', this.table['name']);
    }
    
    body.append('chairs', this.table['chairs']+'');
    body.append('form', this.table['form']+'');

    this.service.queryPost('table', body).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reinit the global user variable
                this.table = {id: 0, project_id: 0, number: '', name: '', chairs: '', form: 0, before:0};
                //Show success message
                //this.toastr.success(result.message);
                //Reload the list users
                this.getTables('');
                this.getGuestGroups();
                this.modalRef.hide();
            }
            else
            {
                let message = ''; 
               if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }else{
                            message = result.message;
                        }

                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }


  editTable(template, id,data){
    this.lastPosChairAsigTable = data['lastPosition'];
    let url = 'table/'+id;
      
      this.service.queryGet(url).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            this.table = {
              id: result.id, 
              project_id: result.project_id, 
              number: result.number, 
              name: result.name, 
              chairs: result.chairs, 
              form: result.form,
              before: result.cant_guest,

            };
            
            this.openModal(template);
          },
          err => 
          {
              
          }
      ); 
  }

  openModal(template, lastPosition = 0){
    this.toDelete = null;
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });

    if(this.table.id == 0){
      document.getElementById('tableNumber').focus();
    }
  }

  closeModal(){
    this.modalRef.hide();
    this.table = {id: 0, project_id: 0, number: '', name: '', chairs: '', form: 0, before:0};
    this.toDelete = null;
  }

  confirmDelete(template, id, typeDelete = null){
    this.toDelete = id;
    if(typeDelete != null){
      this.typeToDelete = typeDelete;
    }
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = null;
  }


  deleteElement(){
      let url = "table/"+this.toDelete;

      if(this.typeToDelete != null){
        url = "table-guest/"+this.toDelete;
      }
      this.service.queryDelete(url).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                this.getTables('');
                this.getGuestGroups();
                if(this.modalRefDelete){
                  this.modalRefDelete.hide();
                }
                this.toDelete = null;
                
                if(this.typeToDelete == null){
                  this.toastr.success(result.message);
                }
                this.typeToDelete = null
            }
        },
        err => 
        {
            console.log(err);
        }
      );

  }



  onItemDrop(event, pos, poschair, table_id){

    if(this.archiveProject){
      return;
    }
    let preRelation = 0;
    
    if(this.tablesList[pos]['ListChairs'][poschair]['id_guest'] != 0){
      preRelation = this.tablesList[pos]['ListChairs'][poschair]['id']
    }
    
    let body = new FormData();
    body.append('id', 0+'');
    body.append('table_id', table_id+'');
    body.append('guest_id', event.dragData.id+'');
    body.append('position', poschair+'');
    this.service.queryPost('table-guest', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            if(result.success)
            {
              this.toDelete = preRelation;
              this.typeToDelete = 'guestTable';
              this.deleteElement();

              this.tablesList[pos]['ListChairs'][poschair]['guest'] = event.dragData.name;
              this.tablesList[pos]['ListChairs'][poschair]['id_guest'] = event.dragData.id;
              this.tablesList[pos]['ListChairs'][poschair]['id'] = result.table_guest.id;
              //We need to delete this guest from list
              for(let i = 0; i < this.guestGroups.length; i++){
                let guest = this.guestGroups[i].guests;
                if(guest.length > 0){
                  for(let j = 0; j < guest.length; j++){
                    if(guest[j].id == event.dragData.id){
                      guest.splice(j, 1);
                    }
                  }
                }
              }

              
              this.getGuestGroups();
            }
            else
            {
                this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    ); 

  }

  export(type){
    /**
     * 
     * when I want to export check list, note, schedule and budget I need to send "1"
     * with these a send id as a project_template_id, when is documenta send project_document_id and 
     * other I send project_id
     */

    
    let temp = {id: this.project, type: type, report_type: 4};
    this.dataService.changeModal(temp);
  }

  public projectData = {id: 0, department_id: 0, city_id: 0, color: '', date: '', image:'', name:'', name_project_type: '',  other_type_name:'',project_type_id: 0, user_id: 0, url_image_project: ''};
  previewfunc(id){
    
    this.dataService.currentProject.subscribe(project => {
      if(null != project){
          this.projectData['name'] = project.name_project;
          this.projectData['name_project_type'] = project.name_project_type; 
          this.projectData['url_image_project'] = project.url_image_project;
        }
    });

    var prtContent = document.getElementById(id);
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    var titulo = '<title>acomodacion-'+this.projectData['name']+'</title>';
    WinPrint.document.write('<style >body{font-family: Roboto, sans-serif; font-size: 12px;} img {display: none;} .logo {display: block;} .title{font-weight: 700;padding: 25px;font-size: 2em;color: #A3777E;} .table-header {padding: 7px; background: #F1C1B0;color: #FFF;padding: 5px 0;text-align: left; vertical-align: bottom; border: 2px solid #F1C1B0;} .col-md-3.table-a {display: inline-grid; margin: 21px; width: 25%;} span.table-header-number {display: inline-block;font-size: 30px;font-weight: 500;padding: 0px 10px;} .table-name-print { color: black; display: inline-block;font-size: 12px;font-weight: 300;margin-bottom: 2px;vertical-align: middle;margin-left: 14px;} .table-body {margin: 0px;background: #f2f2f2;border: 2px solid #F1C1B0;} .italic-text{font-style: italic;} footer {font-style: italic;color: #AAB7A0;margin-top: 15px;} </style>');

    WinPrint.document.write(titulo);
    //WinPrint.document.write('<div><img class="logo" src="../../assets/images/logo/marca_header_landing.svg"> <div class="title">'+this.projectData['name']+'</div></div>');
    WinPrint.document.write('<div class="title">'+this.projectData['name']+'</div>');
    
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write('<footer>Generado por Confetti Planner - confettiplanner.com - Registra tus eventos y comparte</footer>');

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();


    /*let body = new FormData();
    body.append('id', this.project+'');
    body.append('type', 'pdf');
    body.append('report_type', 4+'');
    body.append('view', 1+'');

    this.service.queryPost('project/exports', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        debugger;
        if(result.success){
          debugger;
            let pdf = result.url;
            this.singleton.pdf = result.url;
            this.router.navigate([`/project/${this.projectIdEncry}/preview`]);
        }
                
      },
      err => 
      {
        console.log(err);
      }
    );  */
  }

    cancelCreate(){
        this.table = {id: 0, project_id: 0, number: '', name: '', chairs: '', form: 0, before:0};
        this.getTables('');
        this.getGuestGroups();
        this.modalRef.hide();
    }
}
