import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';



@Component({
  selector: 'app-notes-project',
  templateUrl: './notes-project.component.html',
  styleUrls: ['./notes-project.component.scss']
})
export class NotesProjectComponent implements OnInit {

	public myConfig:any = null;

    modalRefDelete: BsModalRef | null;
    modalRefCreate: BsModalRef | null;

   	public toDelete = 0;

   	public pagesNumber = 10;
   	public search = "";
    public Editor = DecoupledEditor;

   	public currentPage= 1;
	public pages = null;

    public ListNotes = true;
    public FormView = false;

    public notesTemplateslist = [];
    public notesList = [];
    public project = 0;
    private projectIdEncry = '';

    public templateSelected = "";
    public templateDescription = "";
    public categorySelected = "";
    public typeNote = 1;

    public categoriesList = [];
    public newNote = {id: 0, category_id: 0, categories:[], project_id: 0, project_template_id: 0, name: '', description: '', restrictions: 0};

    public restrictionsId = null;
    public restrictionsModalTitle = "";
    public disabledbtn = false;
    public restrictionsData = [];
    public checkAllRestrictions = false;


    public restrictionsWP = false;
    public restrictionsClientsShow = false;
    public restrictionsClientsEdit = false;

    public lastUpdate = "";
    public userCreate = "";
    public userUpdate = "";


    public userSession = null;
    public onlyView = false;

    public archiveProject = false;

	constructor(public service: ApirestService,
	    public router: Router,
	    public toastr: ToastrService,
	    public translate: TranslateService,
	    public singleton: SingletonService,
        public lastlocation: Location,
        public dataService:DataServiceService,
	    public modalService: BsModalService,
        public route: ActivatedRoute,
        public SessionService: SessionService) 
    { 
		window.scroll(0,0);
        this.myConfig = this.singleton.configCkEditor;
    }

	ngOnInit() {
        this.SessionService.validUserSession(); 
		let id = localStorage.getItem('project');
        this.projectIdEncry = id;
        this.project = this.singleton.decrypt(id);
        this.route.queryParams.subscribe(params=> {
            if(params['view']){
				this.editNote(this.singleton.decrypt(params['view']), false);
			}
			
		})
        
        let user = JSON.parse(localStorage.getItem('user'));
        if(localStorage.getItem('show') == '0'){
            this.archiveProject = true;
        }
        this.singleton.getProjectData(this.project);

        this.userSession = user;    

        this.listNotes('');
        this.getTemplateNotes();
        this.getCategories();
        
	}

    public onReady( editor ) {
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }

	listNotes(page){
        this.restrictionsId = null;
        this.restrictionsModalTitle = "";
        this.disabledbtn = false;
        this.restrictionsData = [];
        this.checkAllRestrictions = false;
        this.lastUpdate = "";
        this.userCreate = "";
        this.userUpdate = "";

		let url = 'project/note?projectId='+ this.project +'&rows=' + this.pagesNumber;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.notesList = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getTemplateNotes(){
		let url = "template?templateType=2&rows=100";

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.notesTemplateslist = result.templates.data;
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getCategories(){
		let user = JSON.parse(localStorage.getItem('user'));
		let url = "categories?companyId="+ user['company_id'] +"&rows=100";

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                let temp = result.data

                this.categoriesList = [];
                for(let i = 0; i < temp.length; i++){
                  this.categoriesList.push({display: temp[i].name, value: temp[i].id});
                }

            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getTemplateContent(){
        
        if(this.typeNote == 1){
            let url = "template/"+this.templateSelected+'?type=2';

            this.service.queryGet(url).subscribe(
                response=>
                {      
                    let result = response.json();
                    if(!result.success || false !== result.success)
                    {
                        this.newNote['name'] = "";
                        this.newNote['description'] = result.template_note[0].description;
                        this.templateDescription = result.description;
                    }
                },
                err => 
                {
                    console.log(err);
                }
            );
        }else{
            this.newNote['name'] = "";
            this.newNote['description'] = "";
        }
	}


	viewForm(){
        if(1 == this.typeNote){
            if("" == this.templateSelected){
                this.toastr.error('Debe seleccionar una plantilla para crear la nota');
                return;
            }
        }

        if(this.modalRefCreate){
            this.modalRefCreate.hide();
            if(this.typeNote == 0){
                this.templateSelected = "";
            }
        }
		this.FormView = true;
        this.ListNotes = false; 

        window.scroll(0,0);
	}

	viewList(){
		this.FormView = false;
        this.ListNotes = true;
        this.search = '';
        this.listNotes('');
        this.templateSelected = "";
        this.typeNote = 1;
        this.newNote = {id: 0, category_id: 0, categories:[], project_id: 0, project_template_id: 0, name: '', description: '', restrictions: 0}
        this.onlyView = false;
	}

    cancelCreate(){
        this.modalRefCreate.hide();
        this.templateSelected = "";
        this.typeNote = 1;
    }


	saveNote(){
		let body = new FormData;
        if(this.newNote['name'] == ''){
            this.toastr.error('El campo nombre es requerido');
            return;
        }
		body.append('id', this.newNote['id']+'');
	    //body.append('category_id', this.categorySelected+'');
		body.append('project_id', this.project+'');
        body.append('template_id', this.templateSelected+'');
        body.append('template_description', this.templateDescription);
		body.append('template_type_id','2');
		body.append('name', this.newNote['name']+'');
		body.append('description', this.newNote['description']+'');

		this.service.queryPost('project/template', body).subscribe(
			response=>
			{      
				let result = response.json(); 
               
				if(result.success)
				{
                    
                    let id = result.dataTemplate[0].id;
                    let idNote = result.projectTemplate.id;
                    let lengthCategories = this.newNote['categories'];
                    if(0 === this.newNote['id'])
                    {
                        for(let i = 0; i < lengthCategories.length; i++)
                        {
                            let idCategory = lengthCategories[i].value;
                            this.addNoteToCategory(idNote,idCategory);
                        }
                    }


                    this.addRestrictionitem(id);
                    //Show success message
                    this.toastr.success(result.message);
					this.viewList();
					this.newNote = {id: 0, category_id: 0, categories:[], project_id: 0, project_template_id: 0, name: '', description: '', restrictions: 0}
				}
				else
				{
					let message = '';
					if(result.message.length > 0)
					{
						result.message.forEach(function(element) {
						  message += element+'<br>';
						});
					}

					this.toastr.error(message);
				}
			},
			err => 
			{
				console.log(err);
			}
		);
	}

	editNote(id, restriction){
        
        if(2 == restriction && 0 == this.userSession['rol_id']){
            this.onlyView = true;
        }

        if(this.archiveProject){
            this.onlyView = true;
        }
        let url = 'project/template/'+id;
    
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.newNote['id'] = result.id;
                this.newNote['categories'] = result.project_note.categories;
                this.newNote['project_id'] = result.project_id;
                this.newNote['project_template_id'] = result.project_template_id;
                this.newNote['name'] = result.name;
                this.newNote['description'] = result.description;

                if(result.category_id != null){
                    this.categorySelected = result.category_id;
                }

                if(result.project_template_id != null){
                    this.templateSelected = result.project_template_id;
                    this.templateDescription = result.template_description;
                }

                if(result.project_note.name_user){
                    this.lastUpdate = result.project_note.updated_at ? result.project_note.updated_at : '';
                    this.userCreate = result.project_note.name_user ? result.project_note.name_user : '';
                    this.userUpdate = result.project_note.updated_by_name ? result.project_note.updated_by_name : '' ;
                }
                

                
                let restriction = result.project_note.type_restriction;
                
                switch(restriction){
                    case 1:
                        this.restrictionsWP = true;    
                        this.restrictionsClientsEdit = false;
                        this.restrictionsClientsShow = false;
                    break;
                    case 2:
                        this.restrictionsWP = false;    
                        this.restrictionsClientsEdit = false;
                        this.restrictionsClientsShow = true; 
                    break;
                    case 3:
                        this.restrictionsWP = false;    
                        this.restrictionsClientsEdit = true;
                        this.restrictionsClientsShow = false; 
                    break;
                    default:
                        this.restrictionsWP = false;    
                        this.restrictionsClientsEdit = false;
                        this.restrictionsClientsShow = false; 
                    break;
                }
                
                this.FormView = true;
                this.ListNotes = false; 

                window.scroll(0,0);
                //this.openRestrictionsModal(result.project_note.id);
                /*this.viewForm();*/
            },
            err => 
            {
                console.log(err);
            }
        ); 
        
	}


	confirmDelete(template, id){
      this.toDelete = id;;
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    cancelDelete(){
      this.modalRefDelete.hide();
      this.toDelete = null;
    }

    deleteNote(){
    	this.service.queryDelete('project/note/'+this.toDelete).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the list users
                    this.listNotes('');
                    this.modalRefDelete.hide();
                    this.toDelete = null;
                    this.toastr.success(result.message);
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }


    openModalCreate(template){
        this.modalRefCreate = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
        document.getElementById('typeNote').focus();
    }

    export(id,type){
        /**
         * 
         * when I want to export check list, note, schedule and budget I need to send "1"
         * with these a send id as a project_template_id, when is documenta send project_document_id and 
         * other I send project_id
         */

        let report_type = 1 
        let temp = {id: id, type: type, report_type: report_type};
        this.dataService.changeModal(temp);
    }


    previewNotefunc(id){
        let url = 'project/document/'+id;
        let body = new FormData();
        body.append('id', id+'');
        body.append('type', 'pdf');
        body.append('report_type', 1+'');
        body.append('view', 1+'');

        this.service.queryPost('project/exports', body).subscribe(
			response=>
			{      
				let result = response.json(); 
                if(result.success){
                    let pdf = result.url;
                    this.singleton.pdf = result.url;
                    this.router.navigate([`/project/${this.projectIdEncry}/preview`]);
                }
                
			},
			err => 
			{
				console.log(err);
			}
		);  
    }


    /* openRestrictionsModal(id){
        this.restrictionsId = id;
        
        this.service.queryPost('get-all-roles-to-company', FormData).subscribe(
          response=>
          {      
              
              let result = response.json(); 
              this.restrictionsData = result;
              if(id != 0){
                this.getRestrictions(id);
              }
              
              
          },
          err => 
          {
              console.log(err);
          }
        );
    } */
    
    /* getRestrictions(id){
        let url = "project/restriction?project_id="+this.project+"&project_note_id="+id
        this.service.queryGet(url).subscribe(
            response=>
            {      
                
                let result = response.json(); 
                
                let restrictions = result[1];
                let totalItems = this.restrictionsData.length;
                let cont = 0;
                for(let i = 0; i <  this.restrictionsData.length; i++){
                    for(let j = 0; j < restrictions.length; j++){
                        if(restrictions[j].id == this.restrictionsData[i].id && restrictions[j].can_view == 1){
                        this.restrictionsData[i]['can_view'] = 1;
                        cont++;
                        }
                    }
                }
                
                if(totalItems == cont){
                this.checkAllRestrictions = true;
                }else{
                this.checkAllRestrictions = false;
                }

            },
            err => 
            {
                console.log(err);
            }
        );
    } */

    checkAll(){
        if(this.checkAllRestrictions){
          for(let i = 0; i < this.restrictionsData.length; i++){
             this.restrictionsData[i].can_view = true;
          }
        }else{
          for(let i = 0; i < this.restrictionsData.length; i++){
            this.restrictionsData[i].can_view = false;
         }
        }
    }
    
    addRestrictionitem(id){

        let type_restriction = null;
        if(this.restrictionsWP){
        type_restriction =  1;
        }

        if(this.restrictionsClientsShow){
        type_restriction =  2;
        }

        if(this.restrictionsClientsEdit){
        type_restriction =  3;
        }

        let data = {
                      "project_id": this.project, 
                      "project_note_id": id, 
                      "type": type_restriction
                    }
       
        
        let body = new FormData();
        body.append('data_restrictions', JSON.stringify(data)+'');
    
        let url = "project/restriction";
    
        this.service.queryPost(url, body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {
                //Show success message
               // this.toastr.success(result.message);
               this.restrictionsWP = false;
               this.restrictionsClientsShow = false;
               this.restrictionsClientsEdit = false;
            }
            else
            {
    
            }
          },
          err => 
          {
            console.log(err);
          }
        );
    }


    validateRestrictions(type){
        switch (type) {
            case 'wp':
                this.restrictionsWP = this.restrictionsWP;
                this.restrictionsClientsEdit = false;
                this.restrictionsClientsShow = false;
                break;
            case 'cos':
                this.restrictionsWP = false;
                this.restrictionsClientsEdit = false;
                this.restrictionsClientsShow = this.restrictionsClientsShow;
                break;
            case 'cce':
                this.restrictionsWP = false;
                this.restrictionsClientsEdit = this.restrictionsClientsEdit;
                this.restrictionsClientsShow = false;
                break;
            default:
                break;
        }
    }

    add(event)
    {
        if(event.display == event.value){
          //We need to create and after we need to add a contact
          let user = JSON.parse(localStorage.getItem('user'));
          let body = new FormData();
          body.append('id', 0+'');
          body.append('company_id', user.company_id+'');
          body.append('name', event.value);

          this.service.queryPost('categories', body).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.success)
                {
                    if(0 === this.newNote['id'])
                    {
                        this.newNote['categories'].push({value: result.category.id, display: event.display});
                    }
                    else
                    {
                        this.addNoteToCategory(this.newNote['id'],result.category.id);
                    }
                }
                else
                {
                  let message = '';
                  if(result.message.length > 0)
                  {
                      result.message.forEach(function(element) {
                          message += element+'<br>';
                      });
                  }
                }
            },
            err => 
            {
                console.log(err);
            }
          );
        }
        else
        {
            if(0 === this.newNote['id'])
            {
                this.newNote['categories'].push({value: event.value, display: event.display});
            }
            else
            {
                this.addNoteToCategory(this.newNote['id'], event.value);
            }
        }
    }

    addNoteToCategory(idNote, idCategory)
    {
        let body = new FormData;
        body.append('category_id',idCategory);
        body.append('project_note_id',idNote);

        this.service.queryPost('project-note-categories', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                if(result.success)
                {

                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                }
            },
            err => 
            {
              console.log(err);
            }
        );
    }

    remove(event)
    {
        if(0 !== this.newNote['id'])
        {
            this.removeCategoryNote(event.id_relation);
        }else
        {
            let obj = this.newNote['categories'].find(o => o.display === event.display);
            if(undefined != obj)
            {
                let pos = this.newNote['categories'].indexOf(obj);
                if(-1 < pos)
                {
                    let lengthCatgories = this.newNote['categories'].length;
                    let newArray = [];
                    for(let i = 0; i < this.newNote['categories'].length; i++)
                    {
                        if(i !== pos)
                        {
                            newArray.push(this.newNote['categories'][i]);
                        }
                    }
                    this.newNote['categories'] = newArray;
                }
            }
        }
    }

    removeCategoryNote(id_relation){
        this.service.queryDelete('project-note-categories/'+ id_relation).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {

                }
            },
            err => 
            {
              console.log(err);
            }
        );
    }


    goToTemplates(){
        this.cancelCreate();
        this.router.navigate([`/templates`]);
    }
}
