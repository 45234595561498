import { Component, OnInit, Input } from '@angular/core';
import { ApirestService } from '../apirest.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {


	/**
   * Inputs vars
   */
  @Input() _datasets: any;
  @Input() _router: string;
  @Input() _module: string;
  @Input() _data: any;


	public category = "";


 	constructor(public service: ApirestService,) { }

	ngOnInit() {
		console.log(this._data);
	}
	remove(event)
	{
		if('note' == this._module)
		{
			this.removeCategoryNote(event.id_relation);
		}
	}
	removeCategoryNote(id_relation){
    this.service.queryDelete('project-note-categories/'+ id_relation).subscribe(
      response=>
      {      
          let result = response.json(); 

          if(result.success)
          {

          }
      },
      err => 
      {
          console.log(err);
      }
    );
  }

  add(event){
    let create = true;
    
    for(let i = 0; i < this._datasets.length; i++){
      if(this._datasets[i].display == event.display){
        create = false;
      }
    }

        
    if((event.display == event.value)  && (create)){
      //We need to create and after we need to add a contact
      let user = JSON.parse(localStorage.getItem('user'));
      let body = new FormData();
      body.append('id', 0+'');
      body.append('company_id', user.company_id+'');
      body.append('name', event.value);

      this.service.queryPost('categories', body).subscribe(
        response=>
        {      
            let result = response.json();
            if(result.success)
            {
            	if("note" == this._module)
            	{
            		this.addNoteToCategory(result.category.id);
            	}
            }
            else
            {
              let message = '';
              if(result.message.length > 0)
              {
                  result.message.forEach(function(element) {
                      message += element+'<br>';
                  });
              }
            }
        },
        err => 
        {
            console.log(err);
        }
      );
    }
  }


  addNoteToCategory(idCategory)
  {
  	let body = new FormData;
  	body.append('category_id',idCategory);
  	body.append('project_note_id',this._data.id);

  	this.service.queryPost('project-note-categories', body).subscribe(
      response=>
      {      
          let result = response.json(); 
          console.log(result);
          if(result.success)
          {

          }
          else
          {
            let message = '';
            if(result.message.length > 0)
            {
                result.message.forEach(function(element) {
                    message += element+'<br>';
                });
            }
          }
      },
      err => 
      {
          console.log(err);
      }
    );
  }
}
