import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { ToastrService } from 'ngx-toastr';
// import '../../assets/js/payu.js';
import * as $ from 'jquery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var payU: any;

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss']
})
export class PaymentSettingsComponent implements OnInit, AfterViewInit {

	// variables privadas
	private urlToken:string;
	private publicKey:string;
	private accountId:string;
	public user: any = {plan_id:0};
	private id: number = 0;
	private totalEventsCompany = 0;
    private companyPlanId: number = 0;
    // Variables publicas
    public userId:number = 0;
    public planSelected: number = 0;
	public monthlyPlanValue: number = 0;
	public plans:any = [];
	public cardTokenList: any = [];
	public newCardData:boolean = true;
	public _data = {
		number_card : '',
		name : '',
		mm : '',
		aa : '',
		cvv : ''
	}
  	public planDescription = '';
  	public minEvents = 1;
  	public maxEvents = 0;
	public planValue = 0;
    public planName = '';
    public toDelete = 0;

	private responseHandler: any; 
    modalRefDelete: BsModalRef | null;
    @ViewChild('pruebas') pruebas;
    modalRef: BsModalRef;

  	constructor(
  		private service: ApirestService,
  		private cdr: ChangeDetectorRef,
        private modalService: BsModalService,
   		private toastr: ToastrService) { 
  	}

  	ngOnInit() {
  		
  		this.index();
  		this.user = JSON.parse(localStorage.getItem('user'));
  		this.planSelected = this.user.company_plan_plan_id;
		this.companyPlanId = this.user.company_plan_id;
        this.cdr.detectChanges();
        this.getPlans();
  	}

  	ngAfterViewInit()
  	{
  	}

  	private getDataPayu()
  	{
  		let url = 'get-data-payu';
  		this.service.queryPost(url, new FormData).subscribe(
  			response => {
  				let result = response.json();
  				this.urlToken = result.urlToken;
  				this.publicKey = result.publicKey;
  				this.accountId = result.accountId;
				this.userId = result.userId;
				payU.setURL(this.urlToken);
				payU.setPublicKey(this.publicKey);
				payU.setAccountID(this.accountId);
				payU.setLanguage("es"); // optional
				payU.setListBoxID("mylistID");
				payU.getPaymentMethods();
  			},
  			err => {

  			}
  		);
  	}

  	public validate(event)
  	{
       let value = event.target.value;
  	   payU.validateCard(value);
       payU.getPaymentMethods();
  	}
  	
  	public submitForm()
  	{    
        if(0 == this.planSelected || undefined == this.planSelected)
        {
            this.toastr.error('Debes seleccionar un plan');
            return;
		}
			
		
        // function de respuesta
		let $form = $('#create-form');
		
		// Disable the submit button to prevent repeated clicks
		$form.find('button').prop('disabled', true);
		let self = this;	
		
		payU.createToken(function(response) {
			var $form = $('#create-form');
			self.cdr.detectChanges();
	        if (response.error) 
	        {
	            // Show the errors on the form
	            $form.find('.create-errors').text(decodeURIComponent(escape(response.error)));
				$form.find('button').prop('disabled', false);
	        } 
	        else 
	        {
	            // token contains id, last4, and card type
	            let token = response.token;
	            let card_number = $("#card_number").val();
	            let last_numbers = card_number.slice(-4);
	            let method = response.method;
	            let url = "save-card-token";
	            let data = new FormData;
	            data.append('id', self.id.toString());
	            data.append('token', token);
	            data.append('last_numbers', last_numbers);
	            data.append('method', method);
	            data.append('plan_id', self.planSelected.toString());
				self.cdr.detectChanges();
	            self.service.queryPost(url,data).subscribe(
	            	response => {
	            		let result = response.json();

	            		if(result.success)
	            		{    
	            			//self.toastr.success(result.message);
							//self.modalRef = self.modalService.show(self.modalRefPlan, { class: 'modal-md modal-dialog-centered' });
							self.cdr.detectChanges();
							self.index(false);
							self.cdr.detectChanges();
	            		}
	            	},
	            	err => 
	            	{

	            	}
	            );
	            
	            // Save the token ...k
	            // ...
	            // go somewhere
	            // do something
	            $form.find('button').prop('disabled', false);
	        }
		}, $form);
  	}

  	private getPlans()
  	{
  		this.service.queryGet('get-plans').toPromise().then(
  			success => 
  			{
  				let result = success.json();
  				this.plans = result;
  				this.changePlanSelected();
  			},
  			err =>
  			{

  			}
  		);
  	}

  	public changePlanSelected()
  	{
  		let planSelected = this.plans.find(o => o.id == this.planSelected);
  		if(planSelected){
  			this.monthlyPlanValue = planSelected.value;
            this.maxEvents = planSelected.num_max_project;
            let valueByEvent = 0;
            valueByEvent = this.monthlyPlanValue / this.maxEvents ;
            this.planValue = valueByEvent;
            this.planName = planSelected.name;
  			this.cdr.detectChanges();
  			if(this.totalEventsCompany > planSelected.num_max_project)
  			{
  				let message = 'No puedes escoger este plan, ya que cuentas con más eventos de los que permite el plan.';
  				this.toastr.warning(message, '', {enableHtml: false, positionClass: 'toast-top-center'});
  				planSelected = this.plans.find(o => o.num_max_project >= this.totalEventsCompany);
  				if(planSelected)
  				{	
  					this.monthlyPlanValue = planSelected.value;
  					this.planSelected = planSelected.id;
  					this.cdr.detectChanges();
  				}
  			}
  		}
  	}

  	private index(init = true)
  	{    
  		let url = 'payments';
  		this.service.queryGet(url).toPromise().then(
  			response => {
  				let result = response.json();
  				this.totalEventsCompany = result[1].total_events;
  				this.cardTokenList = result[0];
  				if(0 < this.cardTokenList.length){
  					this.newCardData = false;
  					this.id = this.cardTokenList[0].id;
                    $('#description').click();
  				}
  				else
				{
  					this.newCardData = true;
                    this.getDataPayu();
  				}
  			},
  			err => {

  			}
  		);
  	}

  	public showForm()
  	{
  		this.newCardData = true;
  	}

  	public cancelForm()
  	{
  		this.newCardData = false;
  	}

  	public updatePlan()
  	{
      $("#confirmButton").attr('disabled', true);  
      let body = new FormData;
      let url = `company-plan/${this.companyPlanId}`
      body.append('plan_id', this.planSelected.toString());
      body.append('_method', 'PUT');
  		this.service.queryPost(url, body).toPromise().then(
        response =>{
            let result = response.json();
            if(result.success){
                this.toastr.success(result.message);
                this.modalRef.hide();
                if(result.user != undefined){
                    this.user = result.user;
                    localStorage.setItem('user', JSON.stringify(result.user));
                    window.location.reload();
                }

            } else {
                $("#confirmButton").removeAttr('disabled');
                this.toastr.error(result.message);
            }  
        },
        err => {
            $("#confirmButton").removeAttr('disabled');
        }
      );
  	}

    public confirmDelete(cardId, template){
        this.toDelete = cardId;
        this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    public deleteCard(){
        let url = "destroy-card-token/" + this.toDelete;

        this.service.queryDelete(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                if(result.success)
                {
                    this.modalRefDelete.hide();
                    this.toDelete = null;
                    this.toastr.success(result.message);
                    this.index();
                }
            },
            err => 
            {
                  console.log(err);
            }
        );
    }

    public async openModalUpdate(){
		this.modalRef = this.modalService.show(this.pruebas, { class: 'modal-md modal-dialog-centered' });
        this.cdr.detectChanges();
    }

    public cancelDelete(){
        this.modalRefDelete.hide();
        this.toDelete = null;
    }

    cancelAnswer(){
        this.modalRef.hide();
    }

    numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		return false;
		}
		return true;
    }
}
