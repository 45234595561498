import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"nulltext"
})
export class NullTextPipe implements PipeTransform{
  
  transform(value:string) : string{
    if(value != 'null'){
        return value;
    }else{
        return '';
    }
  }

}