import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-budget-project',
  templateUrl: './budget-project.component.html',
  styleUrls: ['./budget-project.component.scss']
})
export class BudgetProjectComponent implements OnInit {

    public templateId = null;
    public template = {id: 0, branding: 0, company_id: 0, description: '', name: '', template_budget_items: null, template_type_id: 0, user_id: 0};

    public itemsDisplay = [];
    public valuetotal = 0;
    public paidOut = 0;
    public balance = 0;

    public postUpdate = null;

    public toDelete = null;
    public typeDelete = null;

    public toAdd = null;
    public typeAdd = null;
    public newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
    public newCategory = {id: 0, project_id: 0, name: '', order:0};
    public categoriesList = [];

    public newBudgetItem = {id: 0, category_id: 0, contact_id: 0, value: 0, name: '', paidOut: '', balance: '', notes: '', contact_id_current:0};

    public budgetTemplates = [];
    public templateSelected = "";
    public templateDescription = "";
    public templateSelectedData = null;
    public newAssociation = {id: 0, project_id: 0, template_id: 0, template_type_id: 0, name: "", description: ""};
    public projectTemplate = {id: 0, name: '', description: '', project_id: '', template_id: '', template_type_id: ''};
    public contactsProject = [];

    public showSelectTemplates = false;
    public project = 0;
    private projectIdEncry = '';
    public showCategoryInput = false;
    public categoryInput = null;

    public rowInputItems = false;
    public rowPostToAdd = -1;

    public postUpdateCategory = null;
    public activeCat = null;

    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;


    public userSession = null;
    public archiveProject = false;
    private canAction = true;


    constructor(private cdr: ChangeDetectorRef,
        private dataService:DataServiceService,
        private lastlocation: Location,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private router: Router,
        private SessionService: SessionService,
        private singleton: SingletonService,
        private toastr: ToastrService,
        private translate: TranslateService,
        public service: ApirestService) 
    { 
        window.scroll(0,0);
    }

    ngOnInit() {
        this.SessionService.validUserSession();  
        let id = localStorage.getItem('project');
        this.projectIdEncry = id;
        this.project = this.singleton.decrypt(id);
        this.singleton.getProjectData(this.project);
        this.getbudgetTemplatesList();
        this.templateId = id;

        this.validateTemplateProject();
        this.getCategories();
        this.getContactsProject();

        let user = JSON.parse(localStorage.getItem('user'));
        this.userSession = user;  

        if(localStorage.getItem('show') == '0'){
            this.archiveProject = true;
        }
    }

    getContactsProject(){
        let url = 'project-contact?projectId=' + this.project + '&rows=100';
        this.service.queryGet(url).subscribe(
            response=>
            {      
              let result = response.json(); 
              
              this.contactsProject = result.data;

            },
            err => 
            {
              console.log(err);
            }
        );
    }

    getCategories(){
      let user = JSON.parse(localStorage.getItem('user'));
      let url = 'categories?companyId='+user['company_id'];
      
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                let data = result.data;
                for(let i = 0; i < data.length; i++){
                   let temp = {value: data[i].id, display: data[i].name};
                   this.categoriesList.push(temp);
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    updateCategoryName(id, name){

      if(name == ''){
        this.toastr.error("Debe ingresar un nombre para la categoria");
        return;
      }

      let body = new FormData();
      body.append('id', id);
      body.append('name', name);

      this.service.queryPost('categories', body).subscribe(
        response=>
        {      
          let result = response.json(); 
          this.getCategories();
          if(result.success)
          {
            this.postUpdateCategory = null;
            this.validateTemplateProject();
          }
          else
          {
            this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }

    getbudgetTemplatesList(){
      let url = "template?templateType=1&rows=100";

		  this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.budgetTemplates = result.templates.data;
            },
            err => 
            {
                console.log(err);
            }
      );
    }

    validateTemplateProject(){
      let url = "project/template?projectId="+this.project+"&templateType=1";

      this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.data.length == 0){
                  this.showSelectTemplates = true;
                }else{
                  this.getbudgetTemplate(result.data[0].id)
                }
                
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getbudgetTemplate(id){
        this.service.queryGet('project/template/'+id).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.itemsDisplay = [];
                this.projectTemplate = result;

                //Recorremos el array de items que llega para agruparlos por categoria
                for(let i = 0; i < result.items.length; i++ ){
                    //Si es el primer item de la lista se crea el array a visualizar y se le inserta el primer dato
                    if(i == 0){
                      let object = {id: result.items[i].category_id, name: result.items[i].name_category, order:result.items[i].category_order, items: [], showItems: 1};
                      this.itemsDisplay.push(object);
                      let contact = 0;
                      if(Number.isInteger(result.items[i].contact_id)){
                        contact = result.items[i].contact_id
                      }
                      let note = '';
                      if(result.items[i].notes != 'null' || result.items[i].notes != null){
                        note = result.items[i].notes;
                      }
                      if(result.items[i].name != 'default'){
                        let itemsList = {category_id:result.items[i].category_id,  id: result.items[i].id, name: result.items[i].name, value: result.items[i].value, order: result.items[i].order, notes: note, contact_id: contact, contact_name: result.items[i].name_contacts, showItems: 1};

                        this.itemsDisplay[0].items.push(itemsList);
                      }
                      
                    }else{
                        //Asumimos que cuando no es el primer item del array su cateogia aun no ha sido ingresada en el array de visualización, por ende procedemos a validar ésto
                        let existCategory = false;
                        let posExist = 0;
                        //Recorremos el array de visualización buscando el id de la categoria
                        for(let j = 0; j < this.itemsDisplay.length; j++){
                          //Si encontramos un id = al id de la categoria es porque ya ha sido añadida dicha categoria y solo debemos adicionar el item
                          if(this.itemsDisplay[j].id == result.items[i].category_id){
                            existCategory = true;
                            posExist = j;
                          }
                        }
                        if(existCategory){
                          let contact = 0;
                          if(Number.isInteger(result.items[i].contact_id)){
                            contact = result.items[i].contact_id
                          }

                          let note = '';
                          if(result.items[i].notes != 'null' || result.items[i].notes != null){
                            note = result.items[i].notes;
                          }
                          if(result.items[i].name != 'default'){
                            let itemsList = {category_id:result.items[i].category_id, id: result.items[i].id, name: result.items[i].name, value: result.items[i].value, order: result.items[i].order, notes: note, contact_id: contact, contact_name: result.items[i].name_contacts};

                            this.itemsDisplay[posExist].items.push(itemsList);
                          }

                        }else{
                          let object = {id: result.items[i].category_id, name: result.items[i].name_category, order:result.items[i].category_order, items: [], showItems: 1};
                          this.itemsDisplay.push(object);
                          
                          //Recorremos el array de visualización buscando el id de la categoria
                          for(let j = 0; j < this.itemsDisplay.length; j++){
                            //Si encontramos un id = al id de la categoria es porque ya ha sido añadida dicha categoria y solo debemos adicionar el item
                            if(this.itemsDisplay[j].id == result.items[i].category_id){
                              let contact = 0;
                              if(Number.isInteger(result.items[i].contact_id)){
                                contact = result.items[i].contact_id
                              }
                              let note = '';
                              if(result.items[i].notes != 'null' || result.items[i].notes != null){
                                note = result.items[i].notes;
                              }
                              if(result.items[i].name != 'default'){
                                let itemsList = {category_id: result.items[i].category_id, id: result.items[i].id, name: result.items[i].name, value: result.items[i].value, order: result.items[i].order, notes: note, contact_id: contact, contact_name: result.items[i].name_contacts};
                                this.itemsDisplay[j].items.push(itemsList); 
                              }
                            }
                          }

                        }
                    }
                }
                this.calculateTotals();
            },
            err => 
            {
                console.log(err);
            }
      );
    }

    openModalRegisterBudgetTemplate(template){
        this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
        document.getElementById('template').focus();
    }

    addTemplateProject(){
        if(this.templateSelected == ""){
            this.toastr.error('Debe seleccionar una plantilla para crear el presupuesto');
            return;
        }
      
      let body = new FormData();
      body.append('id', this.newAssociation['id']+'');
      body.append('project_id', this.project+'');
      body.append('template_id', this.templateSelected);
      body.append('template_type_id', '1');
      body.append('name', this.templateSelectedData['name']);
      body.append('description', this.templateSelectedData['description']);
  
      let url = "project/template";
  
      this.service.queryPost(url, body).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          if(result.success)
          {
              this.showSelectTemplates = false;
              this.validateTemplateProject();
              this.modalRef.hide();
          }
          else
          {
            let message = '';
            if(result.message.length > 0)
            {
              result.message.forEach(function(element) {
                message += element+'<br>';
              });
            }
  
            this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
  
    }

    changeValueItem(event, id, eventPress=null, lastValue)
    {
      let value = event.target.valueAsNumber;
      let contact = this.contactsProject.find(o => o.id == this.newBudgetItem['contact_id_current'])
      if(lastValue > value && value > parseInt(contact['total_balance']))
      {
        let message = `El proveedor ${contact['name']} ya tiene un pago realizado, para realizar el cambio debes eliminar el pago.`
        this.toastr.warning(message,'Atención!',{positionClass: 'toast-top-center'});
        this.newBudgetItem.value = lastValue;
        this.cdr.detectChanges();
        return;
      }
      this.saveItemBudget(id, eventPress);
    }

    changeSelect(){
      if(this.templateSelected != ""){
        this.service.queryGet('template/'+this.templateSelected+'?type=1').subscribe(
            response=>
            {      
                let result = response.json(); 
                this.templateSelectedData = result;
                this.templateDescription = result.description;
            },
            err => 
            {
                console.log(err);
            }
        );
      }else{

      }
      
    }

    calculateTotals(){
      let total = 0;
      let tempBalance = 0;
      let tempPaidOut = 0;

      for(let i = 0; i < this.itemsDisplay.length; i++){
        let tempItems = this.itemsDisplay[i].items;
        for(let j = 0; j < tempItems.length; j++){
          total = total + tempItems[j].value;
          if(tempItems[j].paid_value != null){
            tempPaidOut = tempPaidOut + tempItems[j].paid_value;
          }

          if(tempItems[j].balance_value != null){
            tempBalance = tempBalance + tempItems[j].balance_value;
          }

        }
      }
      this.valuetotal = total;
      this.paidOut = tempPaidOut;
      this.balance = tempBalance;
    }

    confirmDelete(template, id, typeDelete, data=null){

      if('category' === typeDelete)
      {
        let obj = this.itemsDisplay.find(c => c.id === id);
        if(undefined != obj){

          let items = obj.items

          for(let i = 0; i < items.length; i++)
          {
            let value = items[i].value;
            let contact = this.contactsProject.find(o => o.id == items[i].contact_id)
            if(undefined !== contact)
            {
              if(0 < contact['have_payment'] && value > parseInt(contact['total_balance']))
              {
                let message = `El proveedor ${contact['name']} ya tiene un pago realizado, para realizar el cambio debes eliminar el pago.`
                this.toastr.warning(message,'Atención!',{positionClass: 'toast-top-center'});
                this.cdr.detectChanges();
                return;
              }
            }
          }

        }
      }

      if('itemcat' === typeDelete)
      {
        let value = data.value;
        let contact = this.contactsProject.find(o => o.id == data.contact_id)
        if(undefined !== contact)
        {
          if(0 < contact['have_payment'] && value > parseInt(contact['total_balance']))
          {
            let message = `El proveedor ${contact['name']} ya tiene un pago realizado, para realizar el cambio debes eliminar el pago.`
            this.toastr.warning(message,'Atención!',{positionClass: 'toast-top-center'});
            this.cdr.detectChanges();
            return;
          }
        }
      }
      this.toDelete = id;
      this.typeDelete = typeDelete;
      
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    OpenModalForm(template, id = null, typeAdd){
      this.typeAdd = typeAdd;
      //Si typeAdd == 0, registramos un item, si = 1, registramos una categoria
      if(this.typeAdd == 0){
        this.newItem.category_id = id;
        this.newItem.template_id = this.templateId;
      }

      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }

    saveItemBudget(idCategory, event = null, posCat = null, posItem = null){
      if('' == this.newBudgetItem['name'] || null == this.newBudgetItem['name']){
        this.cancelAddItem();
      } else {
        let value = 0;
        if(this.newBudgetItem['value'] != null){
          value = this.newBudgetItem['value'];
        }
        if(0 < value && this.newBudgetItem['contact_id'] != this.newBudgetItem['contact_id_current'])
        {
          let contact = this.contactsProject.find(o => o.id == this.newBudgetItem['contact_id_current'])
          if(undefined !== contact)
          {
            if(0 < contact['have_payment'] && value > parseInt(contact['total_balance']))
            {
              let message = `El proveedor ${contact['name']} ya tiene un pago realizado, para realizar el cambio debes eliminar el pago.`
              this.toastr.warning(message,'Atención!',{positionClass: 'toast-top-center'});
              this.newBudgetItem['contact_id'] = this.newBudgetItem['contact_id_current'];
              event.target.value = this.newBudgetItem['contact_id'];
              this.cdr.detectChanges();
              return;
            }
          }
        }

        var body = new FormData();
        let url = "";
          body.append('id', this.newBudgetItem['id']+'');
          body.append('project_id', this.project+'')
          body.append('project_template_id', this.projectTemplate['id']+'')
          body.append('contact_id', this.newBudgetItem['contact_id']+'');
          body.append('category_id', idCategory+'');
          body.append('name', this.newBudgetItem['name']);
          body.append('value',value +'');
          body.append('notes', this.newBudgetItem['notes']);

          url = "project/budget/item";
          this.service.queryPost(url, body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {
             
              if(posCat != null && posItem != null){
                let contact_name = "";

                for(let i = 0; i < this.contactsProject.length; i++){
                  if(result.projectBudgetItem['contact_id'] ==  this.contactsProject[i]['id']){
                    contact_name = this.contactsProject[i]['name'];
                  }
                }
                this.itemsDisplay[posCat].items[posItem]['contact_name'] = contact_name;
              }
              this.newBudgetItem['contact_id_current'] = this.newBudgetItem['contact_id']
              //Show success message
             // this.toastr.success(result.message);
              if(event == 'enter'){
                this.cancelAddItem();
                this.typeAdd = null;
                this.newBudgetItem = {id: 0, category_id: 0, contact_id: 0, value: 0, name: '', paidOut: '', balance: '', notes: '',contact_id_current:0};
                this.newCategory = {id: 0, project_id: 0, name: '', order: 0};

                this.postUpdate = null;
                this.validateTemplateProject();
              }
              this.getContactsProject();
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
          },
          err => 
          {
            console.log(err);
          }
        );
      }    
    }

    async dobleclick(id, categoryId, type){
      if(this.archiveProject){
        return;
      }
      this.rowInputItems = false;
      this.showCategoryInput = false;
      this.postUpdateCategory = -1;

      if(id != '' && categoryId != ''){
        
        this.newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
        this.newBudgetItem = {id: 0, category_id: 0, contact_id: 0, value: 0, name: '', paidOut: '', balance: '', notes: '',contact_id_current:0};
        await this.getDataToUpdateItem(id, categoryId);
        this.postUpdate = id;

        let idInput = type+id;
        await this.singleton.sleep(100);
        document.getElementById(idInput).focus();
      }
    }

    async showCategoryToInput(){
      this.postUpdate = -1;
      this.rowInputItems = false;
      this.showCategoryInput = true;
      this.postUpdateCategory = -1;
      let idInput = "newCategory";
      await this.singleton.sleep(100);
      let parent = document.getElementById(idInput);
      let childP1 = parent.children[0];
      let childP2 = childP1.children[0];
      let childP3 = childP2.children[0];
      let childP4 = childP3.children[0];
      let childP5 = childP4.children;
      childP5[0].setAttribute("id", "_"+idInput);
      document.getElementById('_'+idInput).focus();
      childP5[0].removeAttribute("id");
      // childP5.focus();
    }

    async dobleclickCategory(id, categoryId){
      this.showCategoryInput = false;      
      this.postUpdate = -1;
      this.rowInputItems = false;
      this.postUpdateCategory = id;

      let idInput = "category"+id;
      await this.singleton.sleep(100);
      document.getElementById(idInput).focus();
    }

    getDataToUpdateItem(id, categoryId){
      
     //Recorremos el array de items
       for(let i = 0; i < this.itemsDisplay.length; i++){
        let items = this.itemsDisplay[i].items;
        for(let j = 0; j < items.length; j++){
          if(items[j].id == id){
            
            this.newBudgetItem['id'] = id;
            this.newBudgetItem['category_id'] = items[j].category_id;
            this.newBudgetItem['contact_id'] = items[j].contact_id;
            this.newBudgetItem['contact_id_current'] = items[j].contact_id;
            this.newBudgetItem['value'] = items[j].value;
            this.newBudgetItem['name'] = items[j].name;
            this.newBudgetItem['notes'] = items[j].notes;
          }
        }
      }
    }

    async showAddItem(pos, idCategory){
      
      this.showCategoryInput = false;
      this.postUpdateCategory = -1;
      this.postUpdate = -1;
      this.newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
      this.newBudgetItem = {id: 0, category_id: 0, contact_id: 0, value: 0, name: '', paidOut: '', balance: '', notes: '',contact_id_current:0};
      this.rowInputItems = true;
      this.rowPostToAdd = pos;

      let idInput = "newItem"+pos;
      await this.singleton.sleep(100);
      document.getElementById(idInput).focus();
    }

    addCategoryInput(event){
      this.showCategoryInput = false;
      let name = "";
      let validateInsert = true;

      //We need to validate if its a new category
      //when its true-> we need to add on itemsDisplay else, we need to register on DB and then insert on itemsDisplay
      if(event.value != event.display){
        this.newCategory['id'] = event.value;

        for(let i = 0; i < this.itemsDisplay.length; i++){
          if(this.itemsDisplay[i].id == this.newCategory.id){
            validateInsert = false;
          }
        }

        if(validateInsert){
          this.itemsDisplay.push({id: this.newCategory.id, name: event.display, items: [], showItems:1});
          this.newCategory = {id: 0, project_id: 0, name: '', order: 0};
          //this.addCategoryToBudget(event.display);
        }else{
          this.toastr.warning('Ésta categoría ya ha sido insertada');
        }
      }else{
        this.addCategory(event.value);
      }
    }

    addCategory(name){
      if('' == name || null == name){
        this.showCategoryInput = false;
        
      } else {
        let user = JSON.parse(localStorage.getItem('user'));
        let company = user['company_id'];
        var body = new FormData();
        body.append('id', '0');
        body.append('company_id', company+'')
        body.append('name', name+'')

        this.service.queryPost('categories', body).subscribe(
          response=>
          {      
            let result = response.json(); 
            if(result.success)
            {
              let category = result.category;
              this.itemsDisplay.push({id: category.id, name: category.name, items: []});
              let position = this.itemsDisplay.length;
              if(position > 0){
                position = position - 1;
              }
              this.activeCat = position;
              //this.addCategoryToBudget(category.name);
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
          },
          err => 
          {
            console.log(err);
          }
        );
      }  
    }

    cancelAddCategory(){
      this.newCategory = {id: 0, project_id: 0, name: '', order: 0};
      this.showCategoryInput = false;
    }

    cancelAddItem(){
      this.rowInputItems = false;
      this.rowPostToAdd = -1;
      this.postUpdate = -1;
      this.newBudgetItem = {id: 0, category_id: 0, contact_id: 0, value: 0, name: '', paidOut: '', balance: '', notes: '',contact_id_current:0};
    }

    addCategoryToBudget(name){
      let body = new FormData();
      body.append('id', 0+'');
      body.append('project_id', this.project+'');
      body.append('project_template_id', this.projectTemplate['id']+'');
      body.append('name', name);

      this.service.queryPost('categories', body).subscribe(
        response=>
        {      
          let result = response.json(); 
          if(result.success)
          {
            let projectBudgetCategory = result.projectBudgetCategory;

          }
          else
          {
            this.toastr.error(result.message);
          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }
    
    removeCategoryInput(){

    }

    /**
     * Función para eliminar una categoria completa
     *
     * @param
     * @return
     */
    deleteFromCategory(){
      let categoryId = this.toDelete;
      this.modalRefDelete.hide();
      let category = this.itemsDisplay.find(c => c.id === categoryId);
      if(undefined !== category){
        let items = category.items;
        if(0 !== items.length)
        {
          for(let j = 0; j < items.length; j++){
            this.toDelete = items[j].id;
            this.typeDelete = 'itemcat';
            this.deleteElement();
          }
        }
        else
        {
          let pos = this.itemsDisplay.indexOf(category);
          let totalCategories = this.itemsDisplay.length;
          let newArray = [];
          for(let i = 0; i < totalCategories; i++)
          {
            if(i !== pos)
            {
              newArray.push(this.itemsDisplay[i]);
            }
          }
          this.itemsDisplay = newArray;
        }
      }
    }

    /**
     * Función para eiminar un item de una categria
     *
     * @param
     * @return
     */
    deleteElement(){

      let url = "";
      
      switch (this.typeDelete) {
        case 'itemcat':
          url = `project/budget/item/${this.toDelete}`;
          break;
        case 'category':
          url = 'project/budget/categories/'+this.toDelete;
          break;
        case 'budget':
          url = "project/budget/"+this.toDelete;
      }

      this.service.queryDelete(url).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                this.validateTemplateProject();
                this.modalRefDelete.hide();
                this.toDelete = null;
                //this.toastr.success(result.message);
                this.projectTemplate = {id: 0, name: '', description: '', project_id: '', template_id: '', template_type_id: ''};
                this.itemsDisplay = [];
            }
        },
        err => 
        {
            console.log(err);
        }
      );

    }

    cancelDelete(){
      this.modalRefDelete.hide();
      this.toDelete = null;
      this.typeDelete = null;
    }


    closeModal(){
      this.modalRef.hide();
      this.toAdd = null;
      this.typeAdd = null;
      this.templateDescription = "";
    }

    
    export(id,type){
      /**
       * 
       * when I want to export check list, note, schedule and budget I need to send "1"
       * with these a send id as a project_template_id, when is documenta send project_document_id and 
       * other I send project_id
       */

      let report_type = 1 
      let temp = {id: id, type: type, report_type: report_type};
      this.dataService.changeModal(temp);
    }

    public projectData = {id: 0, department_id: 0, city_id: 0, color: '', date: '', image:'', name:'', name_project_type: '',  other_type_name:'',project_type_id: 0, user_id: 0, url_image_project: ''};

    previewfunc(id){

      this.dataService.currentProject.subscribe(project => {
        if(null != project){
            this.projectData['name'] = project.name_project;
            this.projectData['name_project_type'] = project.name_project_type; 
            this.projectData['url_image_project'] = project.url_image_project;
        }
    });
  
      var prtContent = document.getElementById(id);
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      var title = this.projectData['name'];
      var title_html = '<title>presupuesto -'+title+'</title>';
      WinPrint.document.write('<style > body{font-family: Roboto, sans-serif; font-size: 12px;}  a{display: none;} img{display: none;} .logo {display: block;} .title{font-weight: 700;padding: 25px;font-size: 2em;color: #A3777E;} thead {padding: 7px; background: #F1C1B0;color: #FFF;padding: 5px 0;text-align: left;width: 100%;vertical-align: bottom; border-bottom: 2px solid #F1C1B0;}  .col-md-3.table-a {display: inline-grid; margin: 21px; width: 25%;} span.table-header-number {display: inline-block;font-size: 30px;font-weight: 500;padding: 0px 10px;} .table-name-print { color: black; display: inline-block;font-size: 12px;font-weight: 300;margin-bottom: 2px;vertical-align: middle;margin-left: 14px;} .item-table {margin: 0px;background: #d8d8d8; font-weight:700} .italic-text{font-style: italic;} td, th {padding: 4px 0px;} tbody {font-size: 12px;} tfoot {background-color: #AAB7A0; font-size: 12px;font-weight: 700;}tfoot td {border-top: 2px solid #AAB7A0;} footer {font-style: italic;color: #AAB7A0;margin-top: 15px;} thead th {border-bottom: 2px solid #F1C1B0;} .item-table td {border-bottom: 2px solid #d8d8d8;}</style>');
  
      WinPrint.document.write(title_html);
      WinPrint.document.write('<div class="title">'+title+'</div>');
      
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.write('<footer>Generado por Confetti Planner - confettiplanner.com - Registra tus eventos y comparte</footer>');

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
  
      /*let body = new FormData();
      body.append('id', id+'');
      body.append('type', 'pdf');
      body.append('report_type', 1+'');
      body.append('view', 1+'');
  
      this.service.queryPost('project/exports', body).subscribe(
        response=>
        {      
          let result = response.json(); 
          if(result.success){
          debugger;
              let pdf = result.url;
              this.singleton.pdf = result.url;
              this.router.navigate([`/project/${this.projectIdEncry}/preview`]);
          }
                  
        },
        err => 
        {
          console.log(err);
        }
      );  */
    }

    onItemDrop(event, catPos, itemPos){ 
      if(!event.dragData.items)
      {
        let prevPos = this.itemsDisplay[catPos].items.indexOf(event.dragData);
        this.itemsDisplay[catPos].items[prevPos].order = itemPos;
        let length =this.itemsDisplay[catPos].items.length;
        
        for (let i = 0; i < length; i ++) 
        {
          if(i != prevPos)
          {
            if(i <= itemPos && itemPos > prevPos && 0 != this.itemsDisplay[catPos].items[i].order)
            {
              this.itemsDisplay[catPos].items[i].order--;
            }
            else if(i >= itemPos && itemPos < prevPos && prevPos >= i)
            {
              this.itemsDisplay[catPos].items[i].order++;
            }
          }
        }

        this.itemsDisplay[catPos].items.sort(function (i1,i2) {
           if (i1.order < i2.order) { //comparación lexicogŕafica
              return -1;
           } else if (i1.order > i2.order) {
              return 1;
           } 
           return 0;
        });
        this.updateOrder();
      }
    }

    onItemDropCat(event, catNewPos){ 
      if(event.dragData.items)
      {
        let prevPos = this.itemsDisplay.indexOf(event.dragData);
        this.itemsDisplay[prevPos].order = catNewPos;
        let length = this.itemsDisplay.length;
        for (let i = 0; i < length; i ++) 
        {
          if(i != prevPos)
          {
            if(catNewPos > prevPos && i <= catNewPos && 0 < this.itemsDisplay[i].order)
            {
              this.itemsDisplay[i].order--;
            }
            else if(catNewPos < prevPos && i >= catNewPos && prevPos >= i)
            {
              this.itemsDisplay[i].order++;
            }
          }
        }

        this.itemsDisplay.sort(function (i1,i2) {
          if (i1.order < i2.order) { //comparación lexicogŕafica
            return -1;
          } else if (i1.order > i2.order) {
            return 1;
          } 
          return 0;
        });

        this.updateOrder();
      }
    }

    async updateOrder()
    {
      
      let lengthCat = this.itemsDisplay.length;
      for(let i = 0; i < lengthCat; i ++)
      {
        let lengthItem = this.itemsDisplay[i].items.length;
        for(let j = 0; j < lengthItem; j++)
        {
         let newItem = {
            id: this.itemsDisplay[i].items[j].id, 
            category_id: this.itemsDisplay[i].id, 
            category_order:this.itemsDisplay[i].order,
            contact_id: this.itemsDisplay[i].items[j].contact_id,
            template_id: this.templateId,
            name: this.itemsDisplay[i].items[j].name,
            value: this.itemsDisplay[i].items[j].value,
            notes: this.itemsDisplay[i].items[j].notes,
            order:this.itemsDisplay[i].items[j].order,
          };
          await this.singleton.sleep(200);
          this.updateItem(newItem);
        }
      }
    }

    updateItem(data){
      var body = new FormData();
      let url = '';
      body.append('id', data['id'].toString());
      body.append('project_id', this.project.toString())
      body.append('project_template_id', this.projectTemplate['id'].toString())
      body.append('contact_id', data['contact_id'].toString());
      body.append('category_id', data['category_id'].toString());
      body.append('name', data['name']);
      body.append('category_order', data['category_order'].toString());
      body.append('order', data['order'].toString());
      if(null != data['value']){
        body.append('value', data['value'].toString());
      } else {
        body.append('value', 0+'');
      }
      body.append('notes', data['notes']);
      url = 'project/budget/item';

      this.service.queryPost(url, body).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          if(result.success)
          {
            
            //Show success message
            //this.toastr.success(result.message);
            // this.postUpdate = null;
            // this.typeAdd = null;
            // this.newItem = {id: 0, category_id: 0, category_order:0, contact_id: 0, template_id: 0, name: '', value: '', notes: '', order:0};
            // this.newCategory = {id: 0, project_id: 0, name: '', order:0};
          }
          else
          {
            //this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }

    openCategory(position){
      let currentShow = this.itemsDisplay[position]['showItems'];
      if(0 == currentShow || undefined == currentShow){
          this.itemsDisplay[position].showItems = 1;
          //this.activeCat = position;
      } else {
          this.itemsDisplay[position].showItems = 0;; 
      }
      this.activeCat = null;
      /*if(position != this.activeCat){
        this.activeCat = position;
      } else {
        this.activeCat = null;
      }*/
    }

    goToTemplates(){
        this.closeModal();
        this.router.navigate([`/templates`]);
    }
}
