import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { ToastrService } from 'ngx-toastr';
import { DataServiceService } from '../data-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	public user = {id: 0, name: '', phone: '', department_id: '', city_id: '', address: '', email: '',postal_code: '', image: '', password: '', confirm_password: '', company: null, name_role: '', email_notifications: false};


	public company = null;
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public cropperReady = false;


	public departmentsList = [];
	public departmentSelected = "";
	public countrySelected = "";
	public citiesList = [];
	public countriesList = [];
	public deleteImageVali = false;
	public projectId = 0;
	public validMinLengthPass:boolean = false;
	public validMatchPass:boolean = false;

  	constructor(public translate: TranslateService,
                public service: ApirestService,
                public singleton: SingletonService,
                public toastr: ToastrService,
                public route: ActivatedRoute,
               	public router: Router,
				public dataService:DataServiceService,
				private SessionService: SessionService) 
  	{
  		window.scroll(0,0);
  	}

  	ngOnInit() 
  	{
		this.SessionService.validUserSession();  
		this.getUser();
		this.getCountryList();
		this.countrySelected = this.user['country_id'];
		this.getDepartmentsList();
	}

	getCountryList(){
        let url = 'get-countries'
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.countriesList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
    }
	  
	getDepartmentsList(){
        let url = 'get-department/'+this.countrySelected;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.departmentsList = result;
                this.changeDepartment();
            },
            err => 
            {
                console.log(err);
            }
        ); 
	}
	
	changeDepartment(){
		if(this.user.department_id != ''){
			let url = 'get-cities/'+this.user.department_id;
			this.service.queryGet(url).subscribe(
				response=>
				{      
					let result = response.json(); 
					this.citiesList = result;
				},
				err => 
				{
					console.log(err);
				}
			);
		}
        
    }

  	/**
  	*  It gets the user from the local storage
  	**/
  	getUser()
  	{
  		let user = JSON.parse(localStorage.getItem('user'));
  		this.user['id'] = user.id;
  		this.user['password'] = '';
		this.user['confirm_password'] = '';
		this.user['email'] = user.email ? user.email : '';
		this.user['name'] = user.name ? user.name : '';
		this.user['phone'] = user.phone_number ? user.phone_number : '';
		this.user['role_id'] = user.rol_id;
		if(user.department_id == null){
			user.department_id = ""
		}
		this.user['department_id'] = user.department_id ? user.department_id : '';
		this.user['city_id'] = user.city_id ? user.city_id : '';
		this.user['email_notification'] = user.email_notification ? user.email_notification : '';
		this.user['image'] = user.url_image ? user.url_image : '';
		this.user['name_role'] = user.name_role ? user.name_role : '';
		this.user['address'] = user.address ? user.address: '';
		this.user['postal_code'] = user.postal_code ? user.postal_code : '';
		
		if(this.user['role_id'] == 0){
			this.projectId = user['project_id'];
		}
		this.user.email_notifications = false;
		if(1 == user.email_notifications)
		{
			this.user.email_notifications = true;
		}
		this.company = user.company;
		this.user['country_id'] = this.company.country_id;
  	}

  	fileChangeEvent(event: any): void 
  	{
  		console.log(event);
    	this.imageChangedEvent = event;
	}

	imageCropped(image: string) 
	{
		console.log(image);
	    this.croppedImage = image;
	}

	imageLoaded() 
	{
	    this.cropperReady = true;
	}

	loadImageFailed() 
	{
	    // show message
	}

	uploadImage()
	{
		document.getElementById('upload-image').click();
	}

	deleteImage(){
		this.croppedImage = '';
		this.deleteImageVali = true;
		this.saveProfile();

	}
	/**
	 * Saves the user profile
	 */
	saveProfile()
	{
		this.validMinLengthPass = false;
		this.validMatchPass = false;
		if(0 < this.user['password'].length && 6 > this.user['password'].length){
			this.toastr.error('La contraseña debe tener mínimo 6 caracteres', 'Error');
			this.validMinLengthPass = true;
			return;
		}

		var body = new FormData();

		if('' != this.croppedImage)
		{
			var block = this.croppedImage.split(";");
			// Get the content type of the image
			var contentType = block[0].split(":")[1];// In this case "image/gif"
			// get the real base64 content of the file
			var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

			// Convert it to a blob to upload
			var blob = this.singleton.b64toBlob(realData, contentType);

			// Create a FormData and append the file with "image" as parameter name
			
			body.append("image", blob, 'image.jpg');
			
		}

		let emailNotification = 0;
		if(this.user.email_notifications)
		{
			emailNotification = 1;
		}
		if(this.user['password'] == this.user['confirm_password']){

			body.append('id', this.user['id']+'');
			body.append('name', this.user['name']);
			body.append('phone', this.user['phone']);
			body.append('department_id', this.user['department_id']);
			body.append('city_id', this.user['city_id']);
			body.append('address', this.user['address']);
			body.append('email', this.user['email']);
			body.append('postal_code', this.user['postal_code']);
			body.append('delete_image', this.deleteImageVali+'');
			body.append('password', this.user['password']);
			body.append('role_id', this.user['role_id']);
			body.append('email_notifications', emailNotification+'')

			this.service.queryPost('update-profile', body).subscribe(
				response=>
				{      
					let result = response.json(); 
					let name_role = this.user['name_role'];
					
					if(result.success)
					{
						this.user = result.user;
						this.user.company = this.company
						this.user.name_role = name_role;
						
						if(this.user['rol_id'] == "0" || this.user['rol_id'] == 0){
							this.user['project_id'] = this.projectId;
							localStorage.setItem('project', JSON.stringify(this.projectId));
						}
						
						localStorage.setItem('user', JSON.stringify(this.user));
						this.dataService.changeUser(JSON.stringify(this.user));
						this.imageChangedEvent = '';
						this.croppedImage = '';
						this.cropperReady = false;
						this.deleteImageVali = false;
						//Show success message
						this.toastr.success(result.message);
						this.getUser();
						this.router.navigate(['/home']);
					}
					else
					{
						let message = '';
						if(result.message.length > 0)
						{
							result.message.forEach(function(element) {
								message += element+'<br>';
							});
						}

						this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
					}
				},
				err => 
				{
					console.log(err);
				}
			);
		}else{
			this.validMatchPass = true;
			this.user['password'] = '';
			this.user['confirm_password'] = '';
			let message = "El campo contraseña y confirmar contraseña deben coincidir"
			this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
		}
	}

	/**
	 * Cancel the cropped image
	 */
	cancelUpload()
	{
		this.imageChangedEvent = '';
		this.croppedImage = '';
		this.cropperReady = false;
	}

	/**
	 * Función para validar 
	 *
	 * @param e EEventHtml
	 */
	public validateNumber(e)
	{
		if(e.code !== "Backspace" && e.code !== "Tab" && e.code !== "ShiftRight" 
			&& e.code !== "Home" && e.code !== "ShiftLeft" && e.code !== "End" && e.code !== "MetaLeft"){
			var key = window.event ? e.which : e.keyCode;
		  	if (key < 48 || key > 57) {
			    e.preventDefault();
		  	}
		}
	}
}
