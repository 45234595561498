import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  	public templateType = 1;
  	public templatesTypesList = [];
  	public selectedType = 0;
    public templatesList = [];
    public templatesTypesListDocument = [];

  	public pagesNumber = 10; //Default value
    public search = '';
    public pages = [];
    public currentPage = 1;

    public template = {id: 0, template_type_id: '', name: '', description: '', branding: ''};
    public scheduleName = "";
    public switchlabel = true;
    public listTemplates = false;
    public createTemplateStep1 = false;
    public editbudget = false;

    public toDelete = null;

    modalRefDelete: BsModalRef | null;

    constructor(public service: ApirestService,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private modalService: BsModalService,
                private lastlocation: Location,
                private SessionService: SessionService) 
    { 
    window.scroll(0,0);
    }

	ngOnInit() {
        this.SessionService.validUserSession();  

        if(this.singleton.editTemplate != 0){
            this.getTemplatesTypes();
            this.editTemplate(this.singleton.editTemplate, this.singleton.typeTemplate);
        }else{
            this.getthemesList('');
            this.listTemplates = true;
        }
	}

	getthemesList(page){
        let url = 'template?templateType=' + this.templateType + '&rows=10&search='+this.search;
        
        if(page != '')
        {
            url += '&page=' + page;            
        }
        
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                this.templatesTypesList = result.templatesTypes;
                
                let total = 0;
                for(let i = 0; i < this.templatesTypesList.length; i++){
                    total = total + this.templatesTypesList[i].total_template;
                }
                let obj = {id: 0, name: 'Todos', total_template: total};
                this.templatesTypesList.unshift(obj);
                this.templatesList = result.templates.data;
               
                this.currentPage = result.templates['current_page'];
                this.pages = this.singleton.pagination(result.templates);
                

                this.filterType(page, 0);
                this.singleton.editTemplate = 0;
            },
            err => 
            {
                console.log(err);
            }
        );
    }
    
    getTemplatesTypes(){
		let url = 'get-template-types';
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.templatesTypesList = result;
                
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	filterType(page, id){
		this.selectedType = id;
        let url = 'template?templateType=' + id + '&rows=10&search='+this.search;
        
        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.templatesList = result.templates.data;
               
              	this.currentPage = result.templates['current_page'];
                this.pages = this.singleton.pagination(result.templates);
            },
            err => 
            {
                console.log(err);
            }
        );
    }
    

    createTemplate(){
        this.listTemplates = false;
        this.createTemplateStep1 = true;
    }

    cancelCreate(){
        this.template = {id: 0, template_type_id: '', name: '', description: '', branding: ''};
        this.listTemplates = true;
        this.createTemplateStep1 = false;
        this.getthemesList('');
    }


    RegisterTemplate(){
        
        document.getElementById('register-template').setAttribute('disabled', 'disabled');
        let validate = true;

        if(this.template.name == ''){
            validate = false;
            this.toastr.error('Debe ingresar el nombre de la plantilla');
        }
        if(this.template.template_type_id == ''){
            validate = false;
            this.toastr.error('Debe seleccionar el tipo de plantilla');
        }
        
        if(validate){
           let body = new FormData;
            body.append('id', this.template.id+'');
            body.append('template_type_id', this.template.template_type_id);
            body.append('name', this.template.name);
            body.append('description', this.template.description);
            
            if(this.switchlabel == false){
                body.append('branding', 0+'');
            }else{
                body.append('branding', 1+'');
            }
            this.service.queryPost('template', body).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    
                    if(result.success) 
                    {
                        //Plantilla de presupuesto
                        if(result.template.template_type_id == 1){
                            let id = this.singleton.encrypt(result.template.id);
                            this.router.navigate(['budgettemplate', id]);
                        }

                        //Plantilla de notas
                        if(result.template.template_type_id == 2){
                            let id = this.singleton.encrypt(result.template.id);
                            this.router.navigate(['notestemplate', id]);
                        }

                        //Plantilla de lista de chequeo
                        if(result.template.template_type_id == 3){
                            let id = this.singleton.encrypt(result.template.id);
                            this.router.navigate(['checklisttemplate', id]);
                        }

                        //Plantilla de cronograma
                        if(result.template.template_type_id == 4){
                            let id = this.singleton.encrypt(result.template.id);
                            this.router.navigate(['scheduletemplate', id]);
                        }

                        //Plantilla de documento
                        if(result.template.template_type_id == 5){
                            let id = this.singleton.encrypt(result.template.id);
                            this.router.navigate(['documenttemplate', id]);
                        }
                        
                        //this.toastr.success(result.message);
                    } 
                    else
                    {
                        let message = '';
                        if(result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }
                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                },
                err => 
                {
                    console.log(err);
                }
            ); 
        }else{document.getElementById('register-template').removeAttribute('disabled');}
    }


    RegisterScheduledata(){
        let body = new FormData;
        body.append('id', this.template.id+'');
        body.append('template_type_id', this.template.template_type_id);
        body.append('name', this.template.name);
        body.append('description', this.template.description);
        body.append('branding', 1+'');
        if(this.switchlabel == false){
            body.append('branding', 1+'');
        }
        this.service.queryPost('template', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.success) 
                {

                    //Plantilla de cronograma
                    if(result.template.template_type_id == 4){
                        let id = this.singleton.encrypt(result.template.id);
                        this.router.navigate(['scheduletemplate', id]);
                    }
                    this.toastr.success(result.message);
                } 
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }


    continue(id, type){
        let userid = this.singleton.encrypt(id);
        //Plantilla de presupuesto
        if(type == 1){
            let templateid = this.singleton.encrypt(id);
            this.router.navigate(['budgettemplate', templateid]);
        }

        //Plantilla de notas
        if(type == 2){
            let templateid = this.singleton.encrypt(id);
            this.router.navigate(['notestemplate', templateid]);
        }

        //Plantilla de lista de chequeo
        if(type == 3){
            let templateid = this.singleton.encrypt(id);
            this.router.navigate(['checklisttemplate', templateid]);
        }

        //Plantilla de cronograma
        if(type == 4){
            let templateid = this.singleton.encrypt(id);
            this.router.navigate(['scheduletemplate', templateid]);
        }

        //Plantilla de documento
        if(type == 5){
            let templateid = this.singleton.encrypt(id);
            this.router.navigate(['documenttemplate', templateid]);
        }
    }

    editTemplate(id, type){
        this.service.queryGet(`template/${id}?type=${type}`).subscribe(
            response=>
            {      
                let result = response.json();
                if(false === result.success)
                {
                    if(result.message)
                    {
                        if('redirect' == result.message)
                        {
                            window.history.back();
                        }
                    }
                }
                else
                {
                    this.template['id'] = result.id;
                    this.template['template_type_id'] = result.template_type_id;
                    this.template['name'] = result.name;
                    this.template['description'] = result.description == 'null' ?  JSON.parse(result.description): result.description;
                    this.template['branding'] = result.branding;

                    if(result.branding == 1)
                    {
                        this.switchlabel = true;
                    }else
                    {
                        this.switchlabel = false;
                    }

                    this.createTemplate();
                }

            },
            err => 
            {
                console.log(err);
            }
        );
    }

    confirmDelete(template, id){
        this.toDelete = id;;
        this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    deleteTemplate(){
        this.service.queryDelete('template/'+this.toDelete).subscribe(
          response=>
          {      
              let result = response.json(); 
              this.modalRefDelete.hide();
              if(result.success)
              {
                  //Reload the list users
                  this.getthemesList('');
                  
                  this.toDelete = null;
                  this.toastr.success(result.message);
              }else{
                  this.toastr.error(result.message);
              }
          },
          err => 
          {
              console.log(err);
          }
        );
      }

    cancelDelete(){
        this.modalRefDelete.hide();
        this.toDelete = null;
    }


    getPreview(id){
        let body = new FormData();
        body.append('id', id+'');
        this.service.queryPost('export-template', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                debugger;
                if(result.success){
                    let pdf = result.url;
                    this.singleton.pdf = result.url;
                    this.router.navigate(['/preview']);
                }
                
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getPage(page)
    {
        if(page != this.currentPage)
        {
            this.getthemesList(page);
        }
    }
}
