// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: false,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	secret_key: 'confetti secret key',
	//api_url: 'http://apiconfetti.cinnco.co/api/v1/'
	//api_url: 'http://apiconfetti.local/api/v1/'
	api_url: 'https://api.confettiplanner.com/api/v1/'
	
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
