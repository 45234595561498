import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-projects-layout',
  templateUrl: './projects-layout.component.html',
  styleUrls: ['./projects-layout.component.scss']
})
export class ProjectsLayoutComponent implements OnInit, OnDestroy {

    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    public user:any = {};
    public showCanvasLeft = false;
    public showCanvasRight = false;
    public menuXs = false;
    public showOverlay = false;
    public mediaQuery = 'lg';

    public showSearch = true;

    public showUserMenu = false;
    public showNotifications = false;
    public showEmployeeCompany = false;

    public menu = "";
    public role_id = 0;
    private project = null
    public projectData = {id: 0, department_id: 0, city_id: 0, color: '', date: '', image:'', name:'', name_project_type: '',  other_type_name:'',project_type_id: 0, user_id: 0, url_image_project: ''};
    
    modalRef: BsModalRef | null;
    @ViewChild('modalForm') modalForm;
    public formData = null;
    public emailForm = {subject: '', message: '', emails: []};
    public emails = null;

    public date = new Date ();
    public year = this.date.getFullYear();

    
    public listNotifications = [];
    public listNotificationWithOutRead = 0;

    // variable para almacenar el intervalo que consulta las notificaciones nuevas
    private serviceNotification: any =  null;
    public projectId: string ='';

    constructor(public service: ApirestService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        public singleton: SingletonService,
        private lastlocation: Location,
        private modalService: BsModalService,
        private dataService: DataServiceService,
        private route: ActivatedRoute,
        private SessionService: SessionService) 
        { 
    
        }

    ngOnInit() 
  	{    
        this.SessionService.validUserSession(); 
        this.singleton.validateSession();
        this.dataService.changeNotifiacionHome(false);

        this.dataService.notificationProject.subscribe(notification => {
            if(false === notification && null !== this.serviceNotification)
            {
                clearInterval(this.serviceNotification);
            }
        });

        if(null == localStorage.getItem('token') || 'null' == localStorage.getItem('token') || undefined == localStorage.getItem('token'))
        {
            this.router.navigate(['/login']);
        }
        else
        {
  		    this.dataService.user.subscribe(data=>{
                this.getUser();
            });
        }
        let id = this.route.snapshot.paramMap.get("project");
        this.projectId = id;
        localStorage.setItem('project', id);
        this.project = this.singleton.decrypt(id);

        const openModalSubscribe = this.dataService.modalObject.subscribe(data=>{
             if(data != null && this.formData != data){
                this.formData = data;
                this.OpenModalForm();
            }else{
                this.closeModalForm();
            } 
        })
        this.unsubscribe.push(openModalSubscribe);
        
        this.dataService.currentProject.subscribe(project => {
            if(null != project && {} != project && this.projectData != project){
                this.projectData['name'] = project.name_project;
                this.projectData['name_project_type'] = project.name_project_type;
                this.projectData['url_image_project'] = project.url_image_project;
            }
        });

        this.getProjectData();
        this.getMediaQuery();

        this.getNotifications();
        this.dataService.changeNotifiacionProject(true);
        let e = this;
        this.serviceNotification = setInterval(function(){e.getNotifications();}, 60000);
  	}

    ngOnDestroy()
    {
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }

    getMediaQuery()
    {
        let width = screen.width;

        if(width <= 575.98)
        {
            this.mediaQuery = 'xs';
            this.menuXs = true;
        }
        else if(width <= 767.98)
        {
            this.mediaQuery = 'sm';
            this.menuXs = true;
        }
        else if(width <= 991.98)
        {
            this.mediaQuery = 'md';
        }
        else if(width <= 1199.98)
        {
            this.mediaQuery = 'lg';
        }
    }

    getProjectData(){
       let url = "project-show/"+this.project;
		this.service.queryGet(url).subscribe(
			response=>
			{      
                let result = response.json();

                if(undefined != result.success && !result.success)
                {
                    if('inauthorized' == result.message){
                        window.history.back();
                    }
                }
                else
                {
                    this.projectData['id'] = this.project;
                    this.projectData['department_id'] = result.department_id;
                    this.projectData['city_id'] = result.city_id;
                    this.projectData['color'] = result.color;
                    this.projectData['date'] = result.date;
                    this.projectData['image'] = result.image;
                    this.projectData['name'] = result.name_project;
                    this.projectData['name_project_type'] = result.name_project_type;
                    this.projectData['other_type_name'] = result.other_type_name;
                    this.projectData['project_type_id'] = result.project_type_id;
                    this.projectData['user_id'] = result.user_id;
                    this.projectData['url_image_project'] = result.url_image_project;
                }


			},
			err => 
			{
				console.log(err);
			}
		);
    }

  	/**
  	*  It gets the user from the local storage
  	**/
  	getUser()
  	{   
  		this.user = JSON.parse(localStorage.getItem('user'));
        this.user["name"] = this.user["name"].split(" ")[0];
        this.role_id = this.user["rol_id"];
        // this.user['name'] = this.user['name'].split(" ")[0];
        if('Wedding Planner Master' == this.user['name_role'])
        {
            this.user['name_role'] = 'MASTER';
        }
        this.translate.use('es');
  	}

    /**
     * Logout from api and redirect to login
     */
    logout()
    {
        let body = new FormData();

        this.service.queryPost('logout', body).subscribe(
            response=>
            {      
                localStorage.setItem('user', null);
                localStorage.setItem('token', null);

                this.router.navigate(['/']);
            },
            err => 
            {
                
                if(err['status'] == 401)
                {
                    localStorage.setItem('user', null);
                    localStorage.setItem('token', null);

                    this.router.navigate(['/']);
                }
            }
        );
    }

    openCanvas(canvas)
    {
        this.showUserMenu = false;
        if(canvas == 'left')
        {
            this.showCanvasLeft = true;
           // this.showOverlay = true;
        }
        else if(canvas == 'right')
        {
            this.showCanvasRight = true;
           // this.showOverlay = true;
        }
    }

    closeCanvas()
    {
        if(this.showCanvasLeft)
        {
            this.showCanvasLeft = false;
            this.showOverlay = false;
        }
        else
        {
            this.showCanvasRight = false;
            this.showOverlay = false;
        }
    }

    openMenu(route){
        switch (route) {
            case "start":
                this.router.navigate(['/home']);
                this.menu = "start";
                this.showSearch = true;
                break;

             case "documents":
                this.router.navigate(['/documents']);
                this.menu = "documents";
                this.showSearch = false;
                break;

            case "calendar":
                this.router.navigate(['/calendar']);
                this.menu = "calendar";
                this.showSearch = false;
                break;
            case "contacts":
                this.router.navigate(['/contacts']);
                this.menu = "contacts";
                this.showSearch = false;
                break;
            case "help":
                this.router.navigate(['/help']);
                this.menu = "help";
                this.showSearch = false;
                break;
            default:
                // code...
                break;
        }
    }

    goTocompany(to){
       
        this.showCanvasLeft = false;
        this.showSearch = false;
        this.router.navigate([to]);
    }

    goTo(to){
        this.showCanvasLeft = false;
        this.showSearch = false;
        let id = localStorage.getItem('project');

        switch(to){
            case 'home':
                this.router.navigate(['/project/'+id+'/home']);
            break;
            case 'categories':
                this.router.navigate(['/project/'+id+'/categories']);
            break;
            case 'notes':
                this.router.navigate(['/project/'+id+'/notes']);
            break;
            case 'inspiration':
                this.router.navigate(['/project/'+id+'/inspiration']);
            break;
            case 'check':
                this.router.navigate(['/project/'+id+'/check']);
            break;
            case 'providers':
                this.router.navigate(['/project/'+id+'/providers']);
            break;
            case 'budget':
                this.router.navigate(['/project/'+id+'/budget']);
            break;
            case 'guest':
                this.router.navigate(['/project/'+id+'/guest']);
            break;
            case 'accommodations':
                this.router.navigate(['/project/'+id+'/accommodations']);
            break;
            case 'calendar':
                this.router.navigate(['/project/'+id+'/calendar']);
            break;
            case 'schedule':
                this.router.navigate(['/project/'+id+'/schedule']);
            break;
            case 'participants':
                this.router.navigate(['/project/'+id+'/participants']);
            break
        }
    }

    OpenModalForm(){
        this.modalRef = this.modalService.show(this.modalForm, { class: 'modal-md modal-dialog-centered',backdrop: true, ignoreBackdropClick: true });
        document.getElementById('subject').focus();
    }

    closeModalForm(){
        if(this.modalRef){
            this.modalRef.hide();
        }
        this.emailForm = {subject: '', message: '', emails: []};
        this.emails = null;
        this.dataService.changeModal(null);
    }

    removeEmails(item: any){
        let pos = this.emailForm.emails.indexOf(item.value);
        this.emailForm.emails.splice(pos, 1);
    }

    addEmails(item: any){
        if(this.validateEmail(item.value)){
            this.emailForm['emails'].push(item.value);
        }else{
            this.toastr.error('Debe ingresar un correo valido');
            let pos = null;
            for(let i = 0; i < this.emails.length; i++){
                if(item.value == this.emails[i].value){
                    pos = i;
                }
            }
            this.emails.splice(pos, 1);
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    sendForm(){
        
        /**
         * when I want to export check list, note, schedule and budget I need to send "1"
         * with these a send id as a project_template_id, when is documenta send project_document_id and 
         * other I send project_id
         */

        if(this.emailForm['emails'].length == 0){
            this.toastr.error('El campo correo electrónico es requerido');
            return;
        }
        document.getElementById("sed-form").setAttribute("disabled","disabled");
        let body = new FormData();
        body.append('id', this.formData['id']+'');
        body.append('type', this.formData['type']+'');
        body.append('report_type', this.formData['report_type']+'');
        body.append('subject', this.emailForm['subject']+'');
        body.append('message', this.emailForm['message']+'');
        body.append('emails', JSON.stringify(this.emailForm['emails']));

        if(this.formData['marcation'] != null){
            body.append('type_marcation', this.formData['marcation']+'');
        }

        this.service.queryPost('project/exports', body).subscribe(
			response=>
			{      
				let result = response.json(); 
				
                //Show success message
                this.toastr.success('Mensaje enviado correctamente');
                this.emailForm = {subject: '', message: '', emails: []};
                this.formData = null;
                this.emails = null;
                this.dataService.changeModal(null);
                this.modalRef.hide();
				document.getElementById("sed-form").removeAttribute("disabled");
			},
			err => 
			{
				console.log(err);
			}
		);
    }


    goToHome(){
        this.user = JSON.parse(localStorage.getItem('user'));
        if(this.user['project_id']){
            
        }else{
            this.router.navigate(['/home']);
        }
    }


    async updateNotification(){
        this.showNotifications = !this.showNotifications;
        if(this.showNotifications){
            for(let i = 0; i < this.listNotifications.length; i++){
                let id = this.listNotifications[i].id;

                await this.singleton.sleep(500);
                let url = "notification/"+id;
                    this.service.queryPost(url, FormData).subscribe(
                        async success=>
                        {
                            await this.singleton.sleep(200);
                            
                        },
                        async err=>
                        {
                            await this.singleton.sleep(200);
                        }
                    );

            }
        }
    }


    getNotifications(){
        
        //this.user = JSON.parse(localStorage.getItem('user'));
        let url = "notification?rows=5&userId="+this.user['id'] + "&lastFive=1";
        this.service.queryGet(url).subscribe(
            success=>
            {
                let result = success.json();
                
                this.listNotifications = result.data;
                
                let j = 0;
                for(let i = 0; i < this.listNotifications.length; i++){
                    if(this.listNotifications[i].read == 0){
                        j++
                    }
                }
                this.listNotificationWithOutRead = j;
                
            },
            err=>
            {
                console.log(err);
            }
        );
    }

    gotoNotification(id, link, type){
        let url = "notification/"+id;
        
        this.service.queryPost(url, FormData).subscribe(
            success=>
            {
                let result = success.json();
                this.getNotifications();
                this.singleton.redirectNotification(link, type);
            },
            err=>
            {

            }
        );

    }

    gotoNotificationsList(){
        let id = localStorage.getItem('project');
        this.router.navigate(['/project/'+this.singleton.decrypt(id)+'/notifications']);
    }

    goToRoute(to) {
        this.showCanvasLeft = false;
        this.showSearch = false;
        this.router.navigate([to]);
    }
}
