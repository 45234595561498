import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';
import { isArray } from 'util';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  public templateType = 1;
  public templatesTypesList = [];
  public selectedType = 0;
  public contactsList = [];
  public categoriesList = [];
  public templatesTypesListDocument = [];

  public pagesNumber = 10; //Default value
  public search = '';
  public pages = [];
  public currentPage = 1;

  public provider = {id: 0, category_id: 0, company_id: 0, user_id: '', name: '', email: '', cellphone: '', private_notes: '', public_notes: '' , type: null, website: '', address: ''};
  public scheduleName = "";
  public switchlabel = true;
  public listTemplates = true;
  public ViewContactForm = false;
  public createTemplateStep1 = false;
  public editbudget = false;

  public typeToDelete = null;
  public toDelete = null;
  public postContactPointToDelete = null;
  public company = 0;
  public orderBy = 'id';


  public contactpointList = [];

  public showNewContactPoint = false;
  public newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};

  public showNewContactType = false;
  public newContactType = {id: 0, name:''};
  public contactTypesList = [];
  public listContactTypesToinput = [];

  public contactTypesListFilter = [];
  public contactTypeToAddContact = [];
  public newRelationContact = {id: 0, contact_id: 0, contact_type_id: 0};
  modalRefDelete: BsModalRef | null;

  public onlyView = false;
  public filter = "";

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private modalService: BsModalService,
    private lastlocation: Location,
    private SessionService: SessionService) 
  { 
    window.scroll(0,0);
  }

  ngOnInit() {
   // this.SessionService.validUserSession();  
    let user = JSON.parse(localStorage.getItem('user'));
    this.company = user['company_id'];
    this.getContacts('');
    //this.getCategories();

    this.getTypesContacts();
  }


  getTypesContacts(){
    let user = JSON.parse(localStorage.getItem('user'));
    let url = 'contact-types?companyId=' + user['company_id'];
    this.contactTypeToAddContact = [];
      this.service.queryGet(url).subscribe(
          response=>
          {      
            let result = response.json(); 
            let legth = result.length;
            for(let i = 0; i < legth; i++){
                let temp = {value: result[i].id, display: result[i].name, idrelation: 0};
                this.contactTypeToAddContact.push(temp);
            }

          },
          err => 
          {
              console.log(err);
          }
      );
  }

 

  addTypeToContact(event){
    if((event.display == event.value)){
      //We need to create and after we need to add a contact
          let user = JSON.parse(localStorage.getItem('user'));
          let body = new FormData();
          body.append('id', 0+'');
          body.append('company_id', user.company_id+'');
          body.append('name', event.value);

          this.service.queryPost('contact-types', body).subscribe(
              response=>
              {      
                  let result = response.json(); 
                  
                  if(result.success)
                  {
                      this.newContactType = {id: 0, name: ''};
                      //Show success message
                      //this.toastr.success(result.message);
                      this.newRelationContact = {id: 0, contact_id: this.provider['id'], contact_type_id: result.contactType.id};
                      this.saveTypetoContact();
                  }
                  else
                  {
                      let message = '';
                      if(result.message.length > 0)
                      {
                          result.message.forEach(function(element) {
                              message += element+'<br>';
                          });
                      }

                      this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                  }
              },
              err => 
              {
                  console.log(err);
              }
          );
    }else{
      //add to contact
      console.log(event);
      this.newRelationContact = {id: 0, contact_id: this.provider['id'], contact_type_id: event.value};
      this.saveTypetoContact();
    }
  }

  saveTypetoContact(){
    let user = JSON.parse(localStorage.getItem('user'));

    let body = new FormData();
    body.append('id', this.newRelationContact['id']+'');
    body.append('contact_id', this.newRelationContact['contact_id']+'');
    body.append('contact_type_id', this.newRelationContact['contact_type_id']+'');

    this.service.queryPost('contact-type', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success)
            {
                this.newRelationContact = {id: 0, contact_id: 0, contact_type_id: 0};
                //Show success message
                //this.toastr.success(result.message);
                this.getContacts('');
                this.getTypesContacts();
                this.editContact(this.provider['id']);
            }
            else
            {
                  this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  removeTypeTocontact(event){
    this.service.queryDelete('contact-type/'+ event.idrelation).subscribe(
      response=>
      {      
          let result = response.json(); 

          if(result.success)
          {
             
              this.toDelete = null;
              this.toastr.success(result.message);

             this.editContact(this.provider['id']);
          }
      },
      err => 
      {
          console.log(err);
      }
    );
  }

  getCategories(){
    let user = JSON.parse(localStorage.getItem('user'));
    let url = "categories?companyId="+ user['company_id'] +"&rows=100";

    this.service.queryGet(url).subscribe(
        response=>
        {      
            let result = response.json(); 
            this.categoriesList = result.data
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  getContacts(page){
    

    let url = 'contact?companyId=' + this.company + '&rows='+this.pagesNumber;
    url += '&search=' + this.search;

    if(page != '')
    {
        url += '&page=' + page;            
    }
    url += "&orderby=" +this.orderBy;

    this.service.queryGet(url).subscribe(
      response=>
      {      
        let result = response.json(); 
        this.contactsList = [];
        this.templatesTypesList = result.contactTypes;
        this.contactsList = result.contacts.data;
        
        this.contactTypesList = result.contactTypes;

        let total = result.total;
        /* for(let i = 0; i < this.templatesTypesList.length; i++){
            total = total + this.templatesTypesList[i].total_contacts;
        } */
        let obj = {id: 0, name: 'Todas', total_contacts: total};
        this.templatesTypesList.unshift(obj);

        this.contactTypesListFilter = this.templatesTypesList;
        this.contactsList = result.contacts.data;
        
        this.currentPage = result.contacts['current_page'];
        this.pages = this.singleton.pagination(result.contacts);
        this.filterType(page,0);
      },
      err => 
      {
        console.log(err);
      }
    );
  }


  getPage(page)
  {
      if(page != this.currentPage)
      {
          this.getContacts(page);
      }
  }

  editContact(id, onlyView = this.onlyView){
    this.onlyView = onlyView;
    
    this.service.queryGet('contact/'+id).subscribe(
        response=>
        {      
            let result = response.json(); 
            this.listContactTypesToinput = [];

            this.provider['id'] = result.id;
            //this.provider['category_id'] = result.cate
            this.provider['company_id'] = result.company_id;
            this.provider['user_id'] = result.user_id;
            this.provider['name'] = result.name;
            this.provider['email'] = result.email;
            this.provider['cellphone'] = result.cellphone;
            this.provider['private_notes'] = result.private_notes;
            this.provider['public_notes'] = result.public_notes;
            this.provider['type'] = result.contact_type_id;
            this.provider['website'] = result.website;
            this.provider['address'] = result.address;
            this.newContactPoint['contact_id'] = this.provider['id'];
            this.getContactPoints(result.id);
            this.newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};
            this.viewContactForm();

            let length = result.contact_types.length;

            for(let i = 0; i < length; i++){
              let temp = {value: result.contact_types[i].id, display: result.contact_types[i].name, idrelation: result.contact_types[i].idrelation};
              this.listContactTypesToinput.push(temp);
            }
            
        },
        err => 
        {
            console.log(err);
        }
    );
  }


  addContactType(){
    let user = JSON.parse(localStorage.getItem('user'));

    let body = new FormData();
    body.append('id', this.newContactType['id']+'');
    body.append('company_id', user.company_id+'');
    body.append('name', this.newContactType['name']);
    

    this.service.queryPost('contact-types', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success)
            {
                this.newContactType = {id: 0, name: ''};
                
                //Show success message
                //this.toastr.success(result.message);
                this.getContacts('');
                this.getTypesContacts();
            }
            else
            {
                let message = '';
               if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }else{
                            message = result.message;
                        }

                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  async ViewContactPointOption(){
    
    if(this.contactpointList.length > 0){
      let pos = this.contactpointList.length - 1;
      if(this.contactpointList[pos]['name'] == '' || this.contactpointList[pos]['name'] == null){
        this.toastr.error('Debe ingresar el nombre del punto de contacto antes de crear uno nuevo');
      }else{
        this.contactpointList.push({
          id: 0,
          name: '',
          phone: '',
          position: '',
          email: '',
        });
        pos = this.contactpointList.length - 1;
        let id = 'name'+pos;
        
        await this.singleton.sleep(200);
        document.getElementById(id).focus();
      }
    }else{
      this.contactpointList.push({
        id: 0,
        name: '',
        phone: '',
        position: '',
        email: '',
      });
      
      let id = 'name0';
      await this.singleton.sleep(200);
      document.getElementById(id).focus();
    }
    
  }

  editContactPoint(pos, event = null, from = null){
    if(event != null && from != null){
      let id = null;
      switch(from){
        case 'name':
          id = 'position'+pos;
          document.getElementById(id).focus();
        break;
        case 'position':
          id = 'email'+pos;
          document.getElementById(id).focus();
        break;
        case 'email':
          id = 'phone'+pos;
          document.getElementById(id).focus();
        break;
      }
    }

  }

  cancelAddContactPoint(){
    let pos = this.contactpointList.length - 1;
    
    if(this.contactpointList[pos]['name'] == '' || this.contactpointList[pos]['name'] == null){
      //Delete the last position if this has't name
      this.contactpointList.pop();
    }
  }
  

  saveContact(event = null){

    if(this.contactpointList.length > 0){
      for(let i = 0; i < this.contactpointList.length; i++){
        this.newContactPoint['id'] = this.contactpointList[i].id;
        this.newContactPoint['name'] = this.contactpointList[i].name;
        this.newContactPoint['position'] = this.contactpointList[i].position;
        this.newContactPoint['phone'] = this.contactpointList[i].phone;
        this.newContactPoint['email'] = this.contactpointList[i].email;
        if(this.newContactPoint['name'] !=  '' || this.newContactPoint['name'] != null){
          this.saveContactPoint();
        }
      }
      
    }

    let user = JSON.parse(localStorage.getItem('user'));
    if(this.provider['name'] == ''){
        this.toastr.error('Debe ingresar el nombre del contacto para continuar');
        return;
    }
    let body = new FormData();
    body.append('id', this.provider['id']+'');
    if(this.provider['type'] != null){
      body.append('contact_type_id', this.provider['type']+'');
    }
    body.append('company_id', this.company+'');
    body.append('user_id', user.id);
    body.append('name', this.provider['name']);
    
    if(this.provider['email'] != ''){
      body.append('email', this.provider['email']);
    }

    if(this.provider['cellphone'] != ''){
      body.append('cellphone', this.provider['cellphone']);
    }

    if(this.provider['private_notes'] != ''){
      body.append('private_notes', this.provider['private_notes']);
    }

    if(this.provider['public_notes'] != ''){
      body.append('public_notes', this.provider['public_notes']);
    }

    if(this.provider['website'] != ''){
      body.append('website', this.provider['website']);
    }

    if(this.provider['address'] != ''){
      body.append('address', this.provider['address']);
    }

    this.service.queryPost('contact', body).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Show success message
                if(this.provider['id'] != 0){
                  this.toastr.success(result.message);
                  this.ViewContactForm = false;
                  this.listTemplates = true;
                }
                //Reinit the global user variable
                //this.provider = {id: 0, category_id: 0, company_id: 0, user_id: '', name: '', email: '', cellphone: '', 
                //private_notes: '', public_notes: '', type: 0};
                this.provider['id'] = result.contact.id;
                this.provider['name'] = result.contact.name;
                this.provider['email'] = 'null' == result.contact.email? null : result.contact.email;
                this.provider['cellphone'] = 'null' == result.contact.cellphone? null : result.contact.cellphone;
                this.provider['website'] = 'null' == result.contact.website? null : result.contact.website;
                this.provider['address'] = 'null' == result.contact.address? null : result.contact.address;

                 this.listContactTypesToinput = [];
                

                this.newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};
                this.getContactPoints(this.provider['id']);
                //Reload the list users
                this.getContacts('');
                if(event !=null){
                  this.viewList();
                }
            }
            else
            {
                this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  addContactPointToArray(){
    this.showNewContactPoint = false;
    this.contactpointList.push({
      id: 0,
      name: this.newContactPoint['name'],
      phone: '',
      position: '',
      email: '',
    });
    let pos = this.contactTypesList.length - 1;
    let id = 'email'+pos;
    document.getElementById(id).focus();
    this.newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};
  }

  saveContactPoint(){
    let body = new FormData();
    body.append('id', this.newContactPoint['id']+'');
    body.append('contact_id', this.provider['id']+'');
    body.append('name', this.newContactPoint['name']);
    
    if(this.newContactPoint['phone'] != null){
      body.append('phone', this.newContactPoint['phone']);
    }

    if(this.newContactPoint['email'] != null){
      body.append('email', this.newContactPoint['email']);
    }

    if(this.newContactPoint['position'] != null){
      body.append('position', this.newContactPoint['position']);
    }
  
    this.service.queryPost('contact-points', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success)
            {
              let refresh = null;
              if(this.newContactPoint['id'] == 0){
                refresh = 'refresh'
              }
              this.getContactPoints(this.provider['id'], refresh);
              //this.showNewContactPoint = false;
              // this.toastr.success(result.message);
              this.newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};
            }
            else
            {
                //this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  saveContactPoints(event = null){
    if(this.contactpointList.length > 0){
      for(let i = 0; i < this.contactpointList.length; i++){
        this.newContactPoint['id'] = this.contactpointList[i].id;
        this.newContactPoint['name'] = this.contactpointList[i].name;
        this.newContactPoint['position'] = this.contactpointList[i].position;
        this.newContactPoint['phone'] = this.contactpointList[i].phone;
        this.newContactPoint['email'] = this.contactpointList[i].email;

        if(this.newContactPoint['name'] == '' && this.newContactPoint['position'] == ''
         && this.newContactPoint['phone'] == '' && this.newContactPoint['email'] == '')
        {
          this.toastr.error('Debe ingresar los datos del punto de contacto');
          return;
        }

        if(this.newContactPoint['name'] !=  '' || this.newContactPoint['name'] != null){
          this.saveContactPoint();
        }

      }
      
      this.toastr.success("Contacto actualizado");
    }
  }  

  getContactPoints(contact_id, action = null){
    if(contact_id > 0){
      this.service.queryGet('contact-points?contactId='+contact_id).subscribe(
        async response=>
        {      
            let result = response.json(); 
            
             this.contactpointList = result.contactsPoints;

             if(action != null){
              await this.singleton.sleep(100);
              let lastId = this.contactpointList.length;
              
              let id = 'email'+ this.contactpointList[lastId - 1].id;
              
              document.getElementById(id).focus();
            }
        },
        err => 
        {
            console.log(err);
        }
    );
    }
    
  }

  confirmDelete(template, id, type = null, pos = null){
    
    if(pos !=  null){
      this.postContactPointToDelete = pos
    }
    this.toDelete = id;
    if(type != null){
      this.typeToDelete = type;
    }
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }


  deleteTemplate(){
    let url = "contact/";
    if(this.typeToDelete == 'point'){
      url = "contact-points/";

      if(this.toDelete == 0 && this.postContactPointToDelete != null){
        this.contactpointList.splice(this.postContactPointToDelete, 1);
        this.modalRefDelete.hide();
        this.toDelete = null;
        this.postContactPointToDelete = null;
        this.getContacts('');
        return;
      }
    }
    if(this.typeToDelete == 'typeContact'){
      url = "contact-types/";
    }
    this.service.queryDelete(url+this.toDelete).subscribe(
      response=>
      {      
          let result = response.json(); 
          this.modalRefDelete.hide();
          if(result.success)
          {
              //Reload the list users
              
              this.toDelete = null;
              
              if(this.typeToDelete == 'point'){
                this.getContactPoints(this.provider['id']);
              }else{
                this.getContacts('');
                this.toastr.success(result.message);
              }
          }else{
            this.toastr.error(result.message);
          }
      },
      err => 
      {
          console.log(err);
      }
    );
  }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = null;
  }

  filterType(page, id){
    this.selectedType = id;
    let url = 'contact?contactTypeId=' + id + '&companyId='+this.company+'&rows='+this.pagesNumber+'&search='+ this.search;

    if(page != '')
    {
        url += '&page=' + page;            
    }

    url += "&orderby=" +this.orderBy;

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                this.contactsList = result.contacts.data;
               
                this.currentPage = result.contacts['current_page'];
                this.pages = this.singleton.pagination(result.contacts);
            },
            err => 
            {
                console.log(err);
            }
        );
  }

  async viewContactForm(){
    this.ViewContactForm = true;
    this.listTemplates = false;
    await this.singleton.sleep(200);
    if(this.provider['id'] == 0){
      document.getElementById('contactNew').focus();
      
    }
  }

  viewList(){
    this.ViewContactForm = false;
    this.listTemplates = true;
    this.provider = {id: 0, category_id: 0, company_id: 0, user_id: '', name: '', email: '', cellphone: '', private_notes: '', public_notes: '' , type: null, website: '', address: ''}
    this.contactpointList = [];
    this.showNewContactPoint = false;
    this.newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};
    this.listContactTypesToinput = [];
    this.getContacts('');
    this.getTypesContacts();
  }

  filterTypeContact()
  {
    this.contactTypesListFilter = this.templatesTypesList;
    this.contactTypesListFilter = this.contactTypesListFilter.filter(o => o.name.toUpperCase().includes(this.filter.toUpperCase()));
  }

   
  addPoint(){
      let newContactPoint = {id: 0, contact_id: 0, name: '', phone: '', email: '', position: ''};
      this.contactpointList.push(newContactPoint);
  }

}
