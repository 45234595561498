import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataServiceService } from '../data-service.service';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SingletonService } from '../singleton.service';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {

    modalRef: BsModalRef | null;

    public branding = {id: 0, company_id: 0, profile_image: null, cover_image: null, register_url: '', register_image: null, mail_header_image: null, mail_footer_image: null, mail_footer_text: '<p>Escribe aquí el nombre de la empresa u otra información</p>', document_header_image: null, document_footer_image: null, document_footer_text: '', url_profile_image: '', url_cover_image: '', url_register_image: '', url_mail_header_image: '', url_mail_footer_image:'', url_document_header_image: '', url_document_footer_image: ''};
    
    public editorValue: string = '';
    public textTo = "";

    public imageChangedEvent: any = '';
	public croppedImage: any = '';
    public cropperReady = null;


    public viewTextAreaEmail = false;
    public viewTextAreaDocument = false;


    public urlsite = '';

    public configCkEditor;
    
    constructor(public service: ApirestService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public singleton: SingletonService,
    private translate: TranslateService,
    private dataService:DataServiceService,
    private modalService: BsModalService,
    private lastlocation: Location,
    private SessionService: SessionService) 
    { 
        window.scroll(0,0);

        //this.urlsite = this.service.apiUrl;
        this.urlsite = "https://www.confettiplanner.com/wp/";
        this.configCkEditor = this.singleton.configCkEditorAuxiliar;
    }

    ngOnInit() {

        this.SessionService.validUserSession();
        this.getBrandingCompany();
        // InlineEditor
        //     .create( document.querySelector( '#editorMailFooterText' ) )
        //     .then( editor => {
        //         console.log(editor);
        //     } )
        //     .catch( err => {
        //         console.error( err.stack );
        //     } );
    }

    validateSlug(){
        let values = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
        if(!values.test((this.branding.register_url))){
            // this.userlogin.document = "";
            let text = this.branding.register_url;
            this.branding.register_url = text.slice(0, -1);
        }
    }

    tab(e, id)
    {
        var keyCode = e.keyCode || e.which; 
        if (keyCode == 9) 
        { 
            e.preventDefault(); 
            var start = e.target.selectionStart; 
            var end = e.target.selectionEnd; 
            // set textarea value to: text before caret + tab + text after caret 
            e.target.value = e.target.value.substring(0, start) + "\t" + e.target.value.substring(end);

            // put caret at right position again 
            e.target.selectionStart = e.target.selectionEnd = start + 1; 

            this.branding[id] = e.target.value;
            
        }
    }


    getBrandingCompany(){

        this.service.queryGet('branding').subscribe(
            response=>
            {      
                let result = response.json(); 
               
                this.branding.id = result.id;
                this.branding.url_profile_image = result.url_profile_image;
                this.branding.url_cover_image = result.url_cover_image;
                this.branding.register_url = result.register_url;

                if(result.register_url == 'null'){
                    this.branding.register_url = '';
                }

                this.branding.url_register_image = result.url_register_image;
                this.branding.url_mail_header_image = result.url_mail_header_image;
                this.branding.url_mail_footer_image = result.url_mail_footer_image;
                this.branding.mail_footer_text = result.mail_footer_text.replace('&#9', '\t');


                if(result.mail_footer_text == 'null'){
                    this.branding.mail_footer_text = '';
                }

                this.branding.url_document_header_image = result.url_document_header_image;
                this.branding.url_document_footer_image = result.url_document_footer_image;
                this.branding.document_footer_text = result.document_footer_text.replace('&#9', '\t');

                if(result.document_footer_text == 'null'){
                    this.branding.document_footer_text = '';
                }

                this.branding['profile_image'] = result.profile_image;
                this.branding['mail_header_image'] = result.mail_header_image;
                this.branding['document_header_image'] = result.document_header_image;

                
                this.croppedImage = this.branding.url_profile_image;
                let user = JSON.parse(localStorage.getItem('user'));
                
                user.company['url_cover'] = result.url_cover_image;

                user.company['url_profile'] = result.url_profile_image;

                localStorage.setItem('user', JSON.stringify(user));
                this.dataService.changeUser(JSON.stringify(user));

                // console.log(this.branding);

            },
            err => 
            {
                console.log(err);
            }
        );
    }

    updateBrandingCompany(){

        let body = new FormData;
        
        if(this.croppedImage && '' != this.croppedImage)
        {
            var block = this.croppedImage.split(";");
            if(block.length > 1){
                
                // Get the content type of the image
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

                // Convert it to a blob to upload
                var blob = this.singleton.b64toBlob(realData, contentType);

                // Create a FormData and append the file with "image" as parameter name
                
                body.append("profile_image", blob, 'image.jpg');
            }else{
                body.append('profile_image', this.branding.profile_image);
            }
            
        }else{
            body.append('profile_image', this.branding.profile_image);
        }


        body.append('id', this.branding.id+'');
        
        body.append('cover_image', this.branding.cover_image);
        body.append('register_url', this.branding.register_url);
        body.append('register_image', this.branding.register_image);
        body.append('mail_header_image', this.branding.mail_header_image);
        body.append('mail_footer_image', this.branding.mail_footer_image);
        body.append('document_header_image', this.branding.document_header_image);
        body.append('document_footer_image', this.branding.document_footer_image);
        this.branding.mail_footer_text = this.branding.mail_footer_text.replace('\t', '&#9');
        this.branding.document_footer_text = this.branding.document_footer_text.replace('\t', '&#9');
        body.append('mail_footer_text', this.branding.mail_footer_text);
        body.append('document_footer_text', this.branding.document_footer_text);

        this.service.queryPost('branding', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.success) 
                {
                    this.toastr.success(result.message);
                    this.getBrandingCompany();
                    this.croppedImage = '';
                    this.cropperReady = null;
                    this.router.navigate(['home']);
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    uploadImage(inputid){
        
        document.getElementById(inputid).click();
    }

    imageCropped(image: string) 
	{
	    this.croppedImage = image;
    }
    
    imageLoaded() 
	{
	    this.cropperReady = true;
	}

    getFiles(event, type){
        const files: FileList = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        switch(type){
            case 'profile':
                this.branding['profile_image'] = files[0];
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.branding['url_profile_image'] = event.target['result'];
                    }
                this.imageChangedEvent = event;
            break;
            case 'cover':
                // this.branding['cover_image'] = files[0];
                // reader.onload = (event) => { // called once readAsDataURL is completed
                //     this.branding['url_cover_image'] = event.target['result'];
                //     }
                    
            break;
            case 'register':
                // this.branding['register_image'] = files[0];
                // reader.onload = (event) => { // called once readAsDataURL is completed
                //     this.branding['url_register_image'] = event.target['result'];
                //     }
            break;
            case 'mailheader':
                this.branding['mail_header_image'] = files[0];
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.branding['url_mail_header_image'] = event.target['result'];
                    }
            break;
            case 'mailfooter':
                this.branding['mail_footer_image'] = files[0];
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.branding['url_mail_footer_image'] = event.target['result'];
                    }
            break;
            case 'documentheader':
                this.branding['document_header_image'] = files[0];
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.branding['url_document_header_image'] = event.target['result'];
                    }
            break;
            case 'documentfooter':
                this.branding['document_footer_image'] = files[0];
                reader.onload = (event) => { // called once readAsDataURL is completed
                    this.branding['url_document_footer_image'] = event.target['result'];
                    }
            break;
        }
    }

    deleteImage(type){
        /*
        1. imagen perfil
        2. correo
        3. documento
        */
       let image_name = null;
       switch (type) {
           case 1:
               image_name = this.branding['profile_image'];
               break;
           case 2:
               image_name = this.branding['mail_header_image'];
               break;
           case 3:
               image_name = this.branding['document_header_image'];
               break;
       }
       
       let body = new FormData;
       body.append('branding_id', this.branding.id+'');
       body.append('type', type+'');
       body.append('image_name', image_name);
       
        this.service.queryPost('branding-delete-image', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result[0].success) 
                {
                    this.toastr.success(result[0].message);
                    this.getBrandingCompany();
                    this.branding['profile_image'] ? this.branding['profile_image'] : null;
                    this.branding['mail_header_image'] ? this.branding['mail_header_image'] : null;
                    this.branding['document_header_image'] ? this.branding['document_header_image'] : null;
                    switch (type) {
                       case 1:
                           this.branding['profile_image'] = null;
                           this.croppedImage = null;
                           this.cropperReady = null;
                           this.branding['url_profile_image'] = "";
                           break;
                       case 2:
                           this.branding['mail_header_image'] = null;
                           this.branding['url_mail_header_image'] = "";
                           break;
                       case 3:
                           this.branding['document_header_image'] = null;
                           this.branding['url_document_header_image'] = "";
                           break;
                    }
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    //this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }


}
