import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { UsersComponent } from './users/users.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { PermissionsComponent } from './permissions/permissions.component';
import { ProfileComponent } from './profile/profile.component';

import { UiSwitchModule } from 'ngx-ui-switch';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DocumentsComponent } from './documents/documents.component';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AgmCoreModule } from '@agm/core';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HomeComponent } from './home/home.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ContactsComponent } from './contacts/contacts.component';
import { HelpComponent } from './help/help.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { LOCALE_ID } from '@angular/core';
import { CompanyComponent } from './company/company.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { TeamcompanyComponent } from './teamcompany/teamcompany.component';

import { ModalModule } from 'ngx-bootstrap';

import { BrandingComponent } from './branding/branding.component';
import { TemplatesComponent } from './templates/templates.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { CKEditorModule } from 'ngx-ckeditor';
import { BudgetTemplateComponent } from './budget-template/budget-template.component';
import { NotesTemplateComponent } from './notes-template/notes-template.component';
import { CheckListComponent } from './check-list/check-list.component';
import { DocumentTemplateComponent } from './document-template/document-template.component';
import { ScheduleTemplateComponent } from './schedule-template/schedule-template.component';
import { RegisterProjectComponent } from './register-project/register-project.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsLayoutComponent } from './projects-layout/projects-layout.component';
import { HomeprojectComponent } from './homeproject/homeproject.component';
import { AdminCategoriesProjectComponent } from './admin-categories-project/admin-categories-project.component';
import { NotesProjectComponent } from './notes-project/notes-project.component';
import { InspirationProjectComponent } from './inspiration-project/inspiration-project.component';
import { CheckListProjectComponent } from './check-list-project/check-list-project.component';
import { ProvidersProjectComponent } from './providers-project/providers-project.component';
import { BudgetProjectComponent } from './budget-project/budget-project.component';
import { GuestsProjectComponent } from './guests-project/guests-project.component';
import { AccommodationsProjectComponent } from './accommodations-project/accommodations-project.component';
import { CalendarProjectComponent } from './calendar-project/calendar-project.component';
import { ScheduleProjectComponent } from './schedule-project/schedule-project.component';
import { ParticipantsProjectComponent } from './participants-project/participants-project.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartModule } from 'angular2-chartjs';
import 'chartjs-plugin-labels';
import { TagInputModule } from 'ngx-chips';

import { NgxUploaderModule } from 'ngx-uploader';
import { LightboxModule } from 'ngx-lightbox';
import { ColorPickerModule } from 'ngx-color-picker';
import { CutTextPipe } from './pipes/cuttext.pipe';
import { NullTextPipe } from './pipes/nulltext.pipie';

import { LoginCompanyComponent } from './login-company/login-company.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { ClickOutsideModule } from 'ng-click-outside';
import { PasswordsComponent } from './passwords/passwords.component';
import { PreviewsComponent } from './previews/previews.component';
import { PreviewTemplateComponent } from './preview-template/preview-template.component';
import { ListnotificationsComponent } from './listnotifications/listnotifications.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { TagsComponent } from './tags/tags.component';
import { WidgetsModule } from './components/widgets/widgets.module';
import { BlockedComponent } from './blocked/blocked.component';
import { PaymentSettingsComponent } from './payment-settings/payment-settings.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

import { PopoverModule } from 'ngx-bootstrap/popover';

registerLocaleData(es);
defineLocale('es', esLocale); 
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
	// Change this to your upload POST address:
	url: '',
	maxFilesize: 50,
	acceptedFiles: null
};

@NgModule({
	declarations:
		[
			AppComponent,
			CutTextPipe,
			NullTextPipe,
			LoginComponent,
			LayoutComponent,
			UsersComponent,
			PermissionsComponent,
			ProfileComponent,
			DocumentsComponent,
			LandingPageComponent,
			HomeComponent,
			CalendarComponent,
			ContactsComponent,
			HelpComponent,
			CompanyComponent,
			NotificationsComponent,
			TeamcompanyComponent,
			BrandingComponent,
			TemplatesComponent,
			BudgetTemplateComponent,
			NotesTemplateComponent,
			CheckListComponent,
			DocumentTemplateComponent,
			ScheduleTemplateComponent,
			RegisterProjectComponent,
			ProjectsLayoutComponent,
			HomeprojectComponent,
			AdminCategoriesProjectComponent,
			NotesProjectComponent,
			InspirationProjectComponent,
			CheckListProjectComponent,
			ProvidersProjectComponent,
			BudgetProjectComponent,
			GuestsProjectComponent,
			AccommodationsProjectComponent,
			CalendarProjectComponent,
			ScheduleProjectComponent,
			ParticipantsProjectComponent,
			LoginCompanyComponent,
			PasswordsComponent,
			PreviewsComponent,
			PreviewTemplateComponent,
			ListnotificationsComponent,
			ConfirmEmailComponent,
			BlockedComponent,
			TagsComponent,
			PaymentSettingsComponent,
			ProjectsListComponent
		],
	imports:
		[
			TagInputModule,
			WidgetsModule,
			ClickOutsideModule,
			LightboxModule,
			NgxUploaderModule,
			BrowserModule,
			BrowserAnimationsModule,
			HttpModule,
			AppRoutingModule,
			HttpClientModule,
			ColorPickerModule,
			NgbModule,
			FormsModule,
			AngularFontAwesomeModule,
			UiSwitchModule,
			PopoverModule.forRoot(),
			AccordionModule.forRoot(),
			BsDatepickerModule.forRoot(),
			ToastrModule.forRoot(),
			ImageCropperModule,
			CKEditorModule,
			DropzoneModule,
			NgDragDropModule.forRoot(),
			ChartModule,
			ModalModule.forRoot(),
			TabsModule.forRoot(),
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient]
				}
			}),
			CalendarModule.forRoot({
				provide: DateAdapter,
				useFactory: adapterFactory
			}),
			AgmCoreModule.forRoot({
				apiKey: 'AIzaSyDaNU3U4WCLVcT-Z_g--085HQwISFrZbos'
			})
		],
	providers: [{
		provide: LOCALE_ID,
		useValue: "es-ES"
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

