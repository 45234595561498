import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SingletonService } from '../singleton.service';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-teamcompany',
  templateUrl: './teamcompany.component.html',
  styleUrls: ['./teamcompany.component.scss']
})
export class TeamcompanyComponent implements OnInit {

  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;
  modalRefPermissions: BsModalRef | null;

  public roles = [];

  public newUser = {id:0, name:'', email: '', role: '', description: ''};
  public role = {id:0, name: ''};

  public teamWorkFilter = [];
  public teamWork = [];


  public toDelete = null;
  public typeToDelete = '';
  public search = "";

  public showTextArea = true;
  public roleSelected = 0;
  public sections = [];

  public postToUpdate = -1;
  public showAddRole = false;
  public validEmail: boolean = false;
  public validName: boolean = false;
  public validRol: boolean = false;
  public sendingEmail: boolean = false;
  public textValidEmail: string = '';
  public btn_text = "Guardar";

  constructor(public service: ApirestService,
                private router: Router,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private modalService: BsModalService,
                private lastlocation: Location,
                private SessionService: SessionService) 
  	{ 
      window.scroll(0,0);
  	}

    ngOnInit() {
      this.SessionService.validUserSession(); 
      this.getRoles();
      this.getUsers();
    }

    getRoles(){
        let body = new FormData();

        this.service.queryPost('get-roles-company-permissions', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.roles = result;
            },
            err => 
            {
                console.log(err);
            }
        ); 
    }

    getUsers(){

      let url = 'user/work-team?search='+this.search;
      
        this.service.queryPost(url, '').subscribe(
            response=>
            {      
              this.teamWorkFilter = response.json();
              
              let userSession = JSON.parse(localStorage.getItem('user'));
              let user = {id: userSession['id'], 
              name: userSession['name'], 
              rol_id: userSession['rol_id'], 
              role_name: userSession['name_role'], 
              url_image: userSession['url_image'],
              status: userSession['status']
            }

            this.teamWorkFilter.push(user);
            this.teamWork = this.teamWorkFilter;
            },
            err => 
            {
                console.log(err);
            }
        ); 
    }

    openModal(template){
      this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered', ignoreBackdropClick: true});
    }

    save(){
      document.getElementById("save-user").setAttribute("disabled","disabled");
      this.validEmail = false;
      this.validName = false;
      this.validRol = false;
      this.textValidEmail = '';
      if('' === this.newUser['name'])
      {
        this.validName = true;
        document.getElementById("save-user").removeAttribute("disabled");
        return;
      }
      if('' === this.newUser['role'])
      {
        this.validRol = true;
        document.getElementById("save-user").removeAttribute("disabled");
        return;
      }
      if('' === this.newUser['email'])
      {
        this.textValidEmail = 'El campo es requerido';
        this.validEmail = true;
        document.getElementById("save-user").removeAttribute("disabled");
        return;
      }

      if(!this.singleton.validateEmail(this.newUser['email'])){
        this.textValidEmail = 'El formato del correo electrónico es inválido';
        this.validEmail = true;
        document.getElementById("save-user").removeAttribute("disabled");
        return;
      }

      if(this.newUser['id'] != 0){
        this.updateUser();
      }else{
        this.sendingEmail = true;
        var body = new FormData();
        body.append('id', this.newUser['id']+'');
        body.append('name', this.newUser['name']);
        body.append('role_id', this.newUser['role']);
        body.append('email', this.newUser['email']);
        body.append('text', this.newUser['description']);

        this.service.queryPost('user-invited', body).subscribe(
          response=>
          {      
            let result = response.json(); 
            this.sendingEmail = false;
            if(result.success)
            {
              //Show success message
              this.toastr.success(result.message);
              this.getUsers();
              /*document.getElementById("save-user").removeAttribute("disabled");*/
              this.modalRef.hide();
              this.newUser = {id:0, name:'', email: '', role: '', description: ''}
            }
            else
            {
              let message = '';
              if(result.message.length > 0)
              {
                result.message.forEach(function(element) {
                  message += element+'<br>';
                });
              }

              this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
              document.getElementById("save-user").removeAttribute("disabled");
            }
            
          },
          err => 
          {  
            this.sendingEmail = false;
            console.log(err);
          }
        );
      }
        
    }

    updateUser(){
        var body = new FormData();
        body.append('id', this.newUser['id']+'');
        body.append('name', this.newUser['name']);
        body.append('role_id', this.newUser['role']);
        body.append('email', this.newUser['email']);

        this.service.queryPost('update-profile', body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {
              
              //Show success message
              this.toastr.success(result.message);
              this.getUsers();
              this.modalRef.hide();
              this.newUser = {id:0, name:'', email: '', role: '', description: ''}
               this.showTextArea = true;
            }
            else
            {
              let message = '';
              if(result.message.length > 0)
              {
                result.message.forEach(function(element) {
                  message += element+'<br>';
                });
              }

              this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
            document.getElementById("save-user").removeAttribute("disabled");
          },
          err => 
          {
            console.log(err);
          }
        );
    }

    editEmployee(modal, id, status){
      if(0 == status){
        this.btn_text = "Reenviar invitación";
      } else {
        this.btn_text = "Guardar";
      }

      let url = 'user/'+id;
      
      this.service.queryGet(url).subscribe(
          response=>
          {      
            let result = response.json(); 
             
            this.newUser['id'] = result.id;
            this.newUser['name'] = result.name;
            this.newUser['role'] = result.rol_id;
            this.newUser['email'] = result.email;
            this.showTextArea = false;
            this.openModal(modal);
          },
          err => 
          {
              console.log(err);
          }
      ); 
    }


    confirmDelete(template, id, type){
      this.toDelete = id;
      this.typeToDelete = type;
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    cancelDelete(){
      this.modalRefDelete.hide();
      this.toDelete = null;
      this.newUser = {id:0, name:'', email: '', role: '', description: ''}
       this.showTextArea = false;
    }

    closeModal(){
      this.modalRef.hide();
      this.newUser = {id:0, name:'', email: '', role: '', description: ''}
      this.validEmail = false;
      this.validName = false;
      this.validRol = false;
      this.textValidEmail = '';
      this.showTextArea = true;
    }

    deleteUser(){

      let url = "";

      if(this.typeToDelete == 'user'){
        url = 'user/';
      }

      if(this.typeToDelete == 'role'){
        url = 'roles/';
      }
      this.service.queryDelete(url+this.toDelete).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                this.getUsers();
                this.getRoles();
                this.modalRefDelete.hide();
                this.toDelete = null;
                //this.toastr.success(result.message);
            }else{
              this.toastr.error(result.message);
            }
        },
        err => 
        {
            console.log(err);
        }
      );
    }


    addRole(close){
      var body = new FormData();
        body.append('id', this.role['id']+'');
        body.append('name', this.role['name']);
        body.append('type', '2');

        this.service.queryPost('roles', body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {
              //Show success message
              //this.toastr.success(result.message);
              if(1 == close){
                this.role = {id:0, name:''};
                this.cancelAdd();
                this.roles = [];
                this.getRoles();
                if(this.modalRef){
                  this.modalRef.hide();
                }
              }
             
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
          },
          err => 
          {
            console.log(err);
          }
        );
    }

    dobleclick(id, name, companyId){
      if(null !== companyId)
      {
        this.role['id'] = id;
        this.role['name'] = name;
        this.postToUpdate = id;
        this.showAddRole = false;
      }
    }

    cancelAdd(){
      this.role['id'] = 0;
      this.role['name'] = "";
      this.postToUpdate = -1;
      this.showAddRole = false;
    }

    showAddNewRole(){
      this.role['id'] = 0;
      this.role['name'] = "";
      this.postToUpdate = -1;
      this.showAddRole = true;
    }

    getPermissions()
    {
        this.service.queryGet('permissions/'+this.roleSelected).subscribe(
            response=>
            {      
                let permissions = response.json(); 

                let sections = [];

                if(permissions.length > 0)
                {
                    this.sections = [];
                    let index = -1;
                    let section = {};

                    for (var i = 0; i < permissions.length; ++i) 
                    {
                        index = sections.indexOf(permissions[i]['section']);
                        if(index == -1)
                        {
                            section = {name: permissions[i]['section_name'], 
                                        permissions: [{id: permissions[i]['id'], 
                                                        name: permissions[i]['permission_name'],
                                                        allowed: permissions[i]['allowed'],
                                                        real_name: permissions[i]['name']}]};
                            sections.push(permissions[i]['section']);

                            this.sections.push(section);
                        }
                        else
                        {
                            section = this.sections[index];
                            section['permissions'].push({id: permissions[i]['id'], 
                                                        name: permissions[i]['permission_name'],
                                                        allowed: permissions[i]['allowed'],
                                                        real_name: permissions[i]['name']});
                            this.sections[index] = section;
                        }
                    }
                }

            },
            err => 
            {
                console.log(err);
            }
        );
    }

    changePermissions(roleid, permissionId, pos)
    {
        let sectionPermissions = [];
        let permissions = [];

        if(this.roles[pos].permission_2){
          permissions.push('contacts');
        }
        if(this.roles[pos].permission_3){
          permissions.push('calendar');
        }
        if(this.roles[pos].permission_4){
          permissions.push('documents');
        }
        if(this.roles[pos].permission_5){
          permissions.push('projects');
        }
        

       let permissionsJoin = permissions.join();

        let body = new FormData();
        body.append('role_id', roleid+'');
        body.append('permissions', permissionsJoin);

        this.service.queryPost('sync-permissions', body).subscribe(
            response=>
            {      
                this.getRoles();
                //this.showInputRole = false;
                let result = response.json();

                //this.toastr.success(result.message);
                this.singleton.getSession();
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    openPermissionModal(template, id){
      this.roleSelected = id;
      this.getPermissions();
      this.modalRefPermissions = this.modalService.show(template, { class: 'modal-lg' });
    }

    closePermissionModal(){
      this.modalRefPermissions.hide();
    }

    filterTeamWork()
    {
      this.teamWorkFilter = this.teamWork;
      this.teamWorkFilter = this.teamWorkFilter.filter(o => o.name.toUpperCase().includes(this.search.toUpperCase()) || o.role_name.toUpperCase().includes(this.search.toUpperCase()));
    }

    cancel(){
        this.router.navigate(["/home"]);
    }
}
