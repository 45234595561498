import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { SessionService } from '../session.service';
import { TabsetComponent } from 'ngx-bootstrap';
import * as jquery from 'jquery';
import { isArray } from 'util';

@Component({
  selector: 'app-providers-project',
  templateUrl: './providers-project.component.html',
  styleUrls: ['./providers-project.component.scss']
})
export class ProvidersProjectComponent implements OnInit {
    @ViewChild('staticTabs') staticTabs: TabsetComponent;

    public categoriesList = [];
    public project = 0;
    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;
    modalRefValidate: BsModalRef | null;
    modalRefFormContact: BsModalRef | null;

    public provider = {
        id: 0, 
        category_id: 0, 
        company_id: 0, 
        user_id: '', 
        name: '', 
        email: '', 
        cellphone: '', 
        private_notes: '', 
        public_notes: '', 
        address: '', 
        website: '', 
        project_information: ''
    };
    public toDelete = 0;
    public typeToDelete = '';
    public pagesNumber = 10; //Default value
    public search = '';
    public pages = [];
    public currentPage = 1;

    public providersList = [];

    public listView = true;
    public providerView = false;
    public providerCategories = [];
    public publicNotes = [];
    public privateNotes = [];
    public addnote = true;
    public contactSelected = 0;
    public contactsList = [];
    public haveRelations = null;
    public havePayment = 0;

    public showContactPointsForm = false;
    public newContactPoint = {id: 0, name: '', phone: ''};
    public contactPointsList = [];
    public deleteContactPoint = 0;

    public contactTypesList = [];
    public contactTypeToAddContact = [];
    public selectedType = 0;
    public templateType = 1;
    public templatesTypesList = [];

    public categoriesProvider = [];

    public messagesProviders = [];
    public newMessageProvider = {id: 0, message: ''};

    public filesProvider = [];
    public newFile = null;
    public file = null;
    
    public paymentData = {id: 0, contact_id: 0, project_id: 0, total_value: null, total_paid: null, total_balance: null};

    public paymentDetails = [];
    public paymentLists = [];


    public listContactTypesToinput = [];
    public tempCategories = [];

    public user = null;
    public archiveProject = false;
    public posToEdit:number = -1;

    public activePayment = null;

    constructor(private lastlocation: Location,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private router: Router,
        private SessionService: SessionService,
        private singleton: SingletonService,
        private toastr: ToastrService,
        private translate: TranslateService,
        public service: ApirestService) 
        { 
            window.scroll(0,0);
        }

    ngOnInit() {
        
        this.SessionService.validUserSession()
        let id = localStorage.getItem('project');
        this.project = this.singleton.decrypt(id);
        this.singleton.getProjectData(this.project);
        this.user =  JSON.parse(localStorage.getItem('user'));
        this.getCategories();
        this.getProviders('');
        this.getContactsList();
        this.getTypesContacts();


        if(localStorage.getItem('show') == '0'){
            this.archiveProject = true;
        }

        this.route.queryParams.subscribe(params=> {
            if(params['view']){
                this.ViewProvider(this.singleton.decrypt(params['view']), 'query');
            }
        })
        
    }

    getContactsList(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = "contact?companyId="+ user['company_id'] +"&rows=100";
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.templatesTypesList = result.contactTypes;
                this.contactsList = result.contacts.data
                this.contactTypesList = result.contactTypes;

                let total = 0;
                for(let i = 0; i < this.templatesTypesList.length; i++){
                    total = total + this.templatesTypesList[i].total_contacts;
                }
                let obj = {id: 0, name: 'Todas', total_contacts: total};
                this.templatesTypesList.unshift(obj);

                this.filterType(0);
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getTypesContacts(){
        let user = JSON.parse(localStorage.getItem('user'));
        let url = 'contact-types?companyId=' + user['company_id'];
        this.contactTypeToAddContact = [];
          this.service.queryGet(url).subscribe(
              response=>
              {      
                let result = response.json(); 
                let legth = result.length;
                for(let i = 0; i < legth; i++){
                   let temp = {value: result[i].id, display: result[i].name, idrelation: 0};
                   this.contactTypeToAddContact.push(temp);
                }
                
              },
              err => 
              {
                  console.log(err);
              }
          );
    }

    filterType(id){
        this.selectedType = id;
        let user = JSON.parse(localStorage.getItem('user'));
        let url = 'contact?contactTypeId=' + id + '&companyId='+user['company_id']+'&rows=100&search='+ this.search;
    
        url += "&orderby=id";
    
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                this.contactsList = result.contacts.data;
                
                //this.currentPage = result.contacts['current_page'];
                //this.pages = this.singleton.pagination(result.contacts);
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getCategories(){
		let user = JSON.parse(localStorage.getItem('user'));
		let url = "categories?companyId="+ user['company_id'] +"&rows=100";

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.categoriesList = result.data;

                for(let i = 0; i< this.categoriesList.length; i++){
                    this.categoriesList[i]['checked'] = false;
                }
            },
            err => 
            {
                console.log(err);
            }
        );
	}

    getProviders(page){
      let session = JSON.parse(localStorage.getItem('user'));
      let url = 'project-contact?projectId=' + this.project + '&rows='+this.pagesNumber;
      url += '&search=' + this.search;

      if(page != '')
      {
          url += '&page=' + page;            
      }

      this.service.queryGet(url).subscribe(
        response=>
        {      
          let result = response.json(); 
          this.providersList = result.data;
          
          this.currentPage = result['current_page'];
          this.pages = this.singleton.pagination(result);
        },
        err => 
        {
          console.log(err);
        }
      );
    }


    editProviderNameFile(i)
    {
        if(!this.archiveProject){
            if(this.posToEdit === i)
            {
                this.posToEdit = -1;
            }else{
                this.posToEdit = i;
            }
        }
    }

    /**
     * Function to create new contact
     */
    saveProvider()
    {
        let user = JSON.parse(localStorage.getItem('user'));

        let body = new FormData();
        body.append('id', this.provider['id']+'');
        //body.append('category_id', this.provider['category_id']+'');
        body.append('company_id', user.company.id);
        body.append('user_id', user.id);
        body.append('name', this.provider['name']);
        body.append('email', this.provider['email']);
        body.append('cellphone', this.provider['cellphone']);
        body.append('address', this.provider['address']);
        body.append('private_notes', this.provider['private_notes']);
        body.append('public_notes', this.provider['public_notes']);
        body.append('project_information', this.provider['project_Information']);

        this.service.queryPost('contact', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    this.contactSelected = result.contact.id;
                    if(this.listContactTypesToinput.length > 0 || this.tempCategories.length > 0){
                        
                        this.addTypeContactToContact(this.contactSelected)
                    }
                    this.addContactToProject();
                    //Reinit the global user variable
                    this.provider = {id: 0, category_id: 0, company_id: 0, user_id: '', name: '', email: '', cellphone: '', private_notes: '', public_notes: '', address: '', website: '', project_information: ''};
                    //Show success message
                    this.toastr.success(result.message);
                    if(this.provider['id'] == 0){
                        //Reload the list users
                    }

                    if(this.modalRef){
                        this.modalRef.hide();
                    }

                    if(this.modalRefFormContact){
                        this.modalRefFormContact.hide();
                    }
                    //this.getProviders('');
                }
                else
                {
                    let message = '';
                    if(isArray(result.message) && result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    addTypeContactToContact(contact_id){
        
            
        if(this.tempCategories.length > 0){
          //We need to create and after we need to add a contact

            let user = JSON.parse(localStorage.getItem('user'));
            for(let i = 0; i < this.tempCategories.length; i++){
                let body = new FormData();
                body.append('id', 0+'');
                body.append('contact_id', contact_id+'');
                body.append('contact_type_id', this.tempCategories[i]+'');

                this.service.queryPost('contact-type', body).subscribe(
                    response=>
                    {      
                        let result = response.json(); 
                        
                        if(result.success)
                        {
                           
                        }
                        else
                        {
                            this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                        }
                    },
                    err => 
                    {
                        console.log(err);
                    }
                );
            }
                
        }
    }

    getPage(page)
    {
        if(page != this.currentPage)
        {
            this.getProviders(page);
        }
    }


    deleteElement(){

      let url = "";

      if(this.typeToDelete == 'contact'){
          url = "project-contact/"+this.toDelete;
      }

      if(this.typeToDelete == 'comment'){
        url = "project-contact-comments/"+this.toDelete
      }

      if(this.typeToDelete == 'file'){
        url = "project-contact-files/"+this.toDelete
      }

      if(this.typeToDelete == 'itemPaiment'){
        url = "project/payments-details/"+this.toDelete
      }

      this.service.queryDelete(url).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                if(this.typeToDelete == 'comment'){
                    this.getMessagesProvider(this.provider['id']);
                }

                if(this.typeToDelete == 'contact'){
                    this.toastr.success(result.message);
                    this.getProviders('');
                }

                if(this.typeToDelete == 'file'){
                    this.getFilesProvider(this.provider['id']);
                }

                if(this.typeToDelete == 'itemPaiment'){
                   this.getPaymentDataByProvider(this.provider['id']);
                  }
                //Reload the list users
                
                this.modalRefDelete.hide();
                this.toDelete = null;
                
            }else{
                this.toastr.error(result.error);
            }
        },
        err => 
        {
            console.log(err);
        }
      );

    }


    editProvider(template = null, id)
    {
        this.service.queryGet('contact/'+id).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.provider['id'] = result.id;
                this.provider['name'] = result.name;
                if(result.category_id != null){
                    this.provider['category_id'] = result.category_id;
                }
                this.provider['email'] = result.email;
                this.provider['user_id'] = result.user_id;
                this.provider['company_id'] = result.company_id;
                this.provider['cellphone'] = result.cellphone;
                this.provider['private_notes'] = result.private_notes;
                this.provider['public_notes'] = result.public_notes;
                this.provider['address'] = result.address;
                this.provider['website'] = result.website;
                
 
                this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered', ignoreBackdropClick: true });
                
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    ViewProvider(id, goto = null){
        this.providerCategories = [];
        this.service.queryGet('contact/'+id).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.provider['id'] = result.id;
                this.provider['name'] = result.name;
                if(result.category_id != null){
                    this.provider['category_id'] = result.category_id;
                    for(let i = 0; i < this.categoriesList.length; i++){
                        if(this.categoriesList[i].id == result.category_id){
                            this.providerCategories.push(this.categoriesList[i]);
                        }
                    }
                }
                this.provider['email'] = result.email;
                this.provider['user_id'] = result.user_id;
                this.provider['company_id'] = result.company_id;
                this.provider['cellphone'] = result.cellphone;
                this.provider['address'] = result.address;
                this.provider['private_notes'] = result.private_notes;
                this.provider['public_notes'] = result.public_notes;
                this.provider['project_information'] = result.project_information;
                
                
                this.viewProviderData();
                this.getCategoriesProvider();
                this.getMessagesProvider(id);
                this.getFilesProvider(id);
                this.getPaymentDataByProvider(id);

                if(goto == 'query'){
                    //jquery('#gotobutton').click();
                    //console.log(document.getElementById('gotobutton'));
                    //this.staticTabs.tabs[1].active = true;
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    addContactToProject(id = null){
        if(id != null){
            this.contactSelected = id;
        }
        let user = JSON.parse(localStorage.getItem('user'));

        if(this.contactSelected != 0){
            let body = new FormData();
            body.append('id', 0+'');
            body.append('project_id', this.project+'');
            body.append('contact_id', this.contactSelected+'');

            this.service.queryPost('project-contact', body).subscribe(
                response=>
                {      
                    let result = response.json(); 

                    if(result.success)
                    {
                        //Reinit the global user variable
                        this.contactSelected = 0;
                        if(this.modalRefValidate){
                            this.modalRefValidate.hide();
                        }
                        this.getProviders('');
                    }
                    else
                    {
                        let message = '';
                        if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }else{
                            message = result.message;
                        }

                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                },
                err => 
                {
                    console.log(err);
                }
            );
        }else{
            this.toastr.error('Selecciona un proveedor');
        }
    }



    getCategoriesProvider(){
        let url = "project-contact-category?projectId="+ this.project +"&contactId="+this.provider['id']
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                for(let i = 0; i < result.length; i++){
                    for(let j = 0; j < this.categoriesList.length; j++){
                        if(result[i].category_id == this.categoriesList[j].id){
                                this.categoriesList[j].checked = true;
                        }
                    }
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    changeInput(categoryId){
        let insert = true;
        for(let i = 0; i < this.categoriesProvider.length; i++){
            if(this.categoriesProvider[i] == categoryId){
                this.categoriesProvider.splice(i, 1);
                insert = false
            }
        }
        if(insert){
            this.categoriesProvider.push(categoryId);
        }
    }


    saveProviderCategories(){
        let user = JSON.parse(localStorage.getItem('user'));

        for(let i = 0; i < this.categoriesProvider.length; i++){
            let body = new FormData();
            body.append('id', 0+'');
            body.append('project_id', this.project+'');
            body.append('contact_id', this.provider['id']+'');
            body.append('category_id', this.categoriesProvider[i]+'');

            this.service.queryPost('project-contact-category', body).subscribe(
                response=>
                {      
                    let result = response.json(); 

                    if(result.success)
                    {
                        if(i == this.categoriesProvider.length-1 ){
                            this.getCategoriesProvider();
                            this.toastr.success(result.message,'', {enableHtml: true, positionClass: 'toast-top-center'});
                        }

                    }
                    else
                    {
                        let message = '';
                        
                        if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }

                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                },
                err => 
                {
                    console.log(err);
                }
            );
        }
        
    }

    ValidateModalForm(template){
        this.modalRefValidate = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
    }

    OpenModalForm(template){
      this.modalRefValidate.hide();
      this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    cancelDelete(){
      
      if(this.modalRefDelete){
        this.modalRefDelete.hide();
      }

      if(this.modalRefValidate){
        this.modalRefValidate.hide();
      }
      
      this.toDelete = null;
      this.typeToDelete = '';
    }

    confirmDelete(template, id, type, haveRelations=null, havePayment=0){
      this.toDelete = id;
      this.typeToDelete = type;
      this.haveRelations = haveRelations;
      if(1 === havePayment){
        let message = `El proveedor ya tiene pagos realizados, para eliminarlo debes eliminar el pago.`
        this.toastr.warning(message,'Atención!',{positionClass: 'toast-top-center'});
        return;
      }
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }


    closeModal(){
        this.provider = {id: 0, category_id: 0, company_id: 0, user_id: '', name: '', email: '', cellphone: '', private_notes: '', public_notes: '', address: '', website: '', project_information: ''};
        if(this.modalRef){
            this.modalRef.hide();
        }

        if(this.modalRefValidate){
            this.modalRefValidate.hide();
        }

        if(this.modalRefFormContact){
            this.modalRefFormContact.hide();
        }
        
    }

    viewList(){
        this.provider = {id: 0, category_id: 0, company_id: 0, user_id: '', name: '', email: '', cellphone: '', private_notes: '', public_notes: '', address: '', website: '',  project_information: ''};
        this.providerCategories = [];
        this.publicNotes = [];
        this.privateNotes = [];
        this.addnote = true;
        this.listView = true;
        this.providerView = false;
        this.getProviders('');
    }

    viewProviderData(){
        this.listView = false;
        this.providerView = true;
    }


    goto(template){

        if(this.modalRef){
            this.modalRef.hide();
        }
        if(this.modalRefValidate){
            this.modalRefValidate.hide();
        }
        this.modalRefFormContact = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
        document.getElementById('providerName').focus();
    }

    getMessagesProvider(id){
        this.service.queryGet('project-contact-comments?projectId='+this.project+'&contactId='+id).subscribe(
              response=>
              {  
                this.messagesProviders = [];
                let result = response.json(); 
                
                this.messagesProviders = result;
              },
              err => 
              {
                  console.log(err);
              }
          );
    }

    addComment(){
        document.getElementById('message').blur();
        if(this.newMessageProvider['message'] == ''){
            this.toastr.error('Ingresa un comentario');
            return;
          }
      
          let body = new FormData;
          let user = JSON.parse(localStorage.getItem('user'));
      
          body.append('id', this.newMessageProvider['id']+'');
          body.append('project_id', this.project+'');
          body.append('contact_id', this.provider['id']+'');
          body.append('user_id', user['id']+'');
          body.append('comment', this.newMessageProvider['message']+'');
      
          this.service.queryPost('project-contact-comments', body).subscribe(
            response=>
            {      
              let result = response.json(); 
      
              if(result.success)
              {
                this.getMessagesProvider(this.provider['id']);
                //this.toastr.success(result.message);
                this.newMessageProvider['message'] = '';
              }
              else
              {
                //this.toastr.error(message);
              }
            },
            err => 
            {
              console.log(err);
            }
          );
    }


    fileChangeEvent(event: any): void 
  	{

        if(event.target.files && event.target.files.length > 0) 
    	{ 
            this.newFile = event.target.files[0];
            this.addFileProvider();
        }
       
    }
    
    getFilesProvider(id){
        
        this.service.queryGet('project-contact-file?projectId='+this.project+'&contactId='+id).subscribe(
              response=>
              {  
                this.filesProvider = [];
                let result = response.json(); 
                
                
                for(let i = 0; i < result.length; i++){
                    let filename = result[i].file;
                    let arrayFile = filename.split('.');
                    
                    if(arrayFile[1] == 'png' || arrayFile[1] == 'jpg' || arrayFile[1] == 'gif' ){
                        result[i].type = 'image';
                    }else{
                        result[i].type = 'file';
                    } 
                }
                this.filesProvider = result;
              },
              err => 
              {
                  console.log(err);
              }
        );
    }

    addFileProvider(){
        let user = JSON.parse(localStorage.getItem('user'));
        let body = new FormData();
        body.append('id', 0+'');
        body.append('project_id', this.project+'');
        body.append('contact_id', this.provider['id']+'');
        body.append('user_id', user['id']+'');
        body.append('file', this.newFile);

        this.service.queryPost('project-contact-file',body).subscribe(
            response=>
            {  
              
              let result = response.json(); 
              
              if(result.success){
               // this.toastr.success(result.message);
                this.getFilesProvider(this.provider['id']);
                this.newFile = null;
                this.file = null;
              }else{
      
               // this.toastr.error(message);
              }
            },
            err => 
            {
                console.log(err);
            }
        );
    }


    changeName(pos){
        
        let id = 0;
        let name = "";
        
        id = this.filesProvider[pos].id;
        name = this.filesProvider[pos].name;
        
        
        if(name == ""){
            this.toastr.error('Debes ingresar un nombre para el archivo');
            return;
        }
        
        let body = new FormData();
        body.append('id', id+'');
        body.append('name', name+'');

        this.service.queryPost('project-contact-file-update',body).subscribe(
            response=>
            {  
              
              let result = response.json(); 
              
              if(result.success){
               // this.toastr.success(result.message);
                this.getFilesProvider(this.provider['id']);
                this.newFile = null;
                this.posToEdit = -1;
              }else{
    
               // this.toastr.error(message);
              }
            },
            err => 
            {
                console.log(err);
            }
        );
    }


    view(fileUrl){
        window.open(fileUrl, '_blank');
    }


    //------------------ functions to "Plan de pagos" ----------------

    getPaymentDataByProvider(contact_id){
        let body = new FormData();
        body.append('contact_id', contact_id+'');
        body.append('project_id', this.project+'');

        this.service.queryPost('project/payments-summary', body).subscribe(
            response=>
            {      
                let result = response.json(); 
            
                this.paymentData = {id: result.id, contact_id: result.contact_id, project_id: result.project_id, total_value: result.total_value, total_paid: result.total_paid, total_balance: result.total_balance};
                this.getPaymentsList(contact_id);
            },
            err => 
            {
              console.log(err);
            }
        );
    }

    getPaymentsList(contact_id){
        let url = "project/payments-details?contact_id="+contact_id+"&project_id="+this.project;
        this.service.queryGet(url).subscribe(
            response=>
            {      
              let result = response.json(); 
            
              let balanceTemp  = 0;
              if(result.length > 0){
                for(let i = 0; i < result.length; i++){
                    balanceTemp = balanceTemp + result[i].value;
                }
              }
              let newPayment = this.paymentData['total_value'] - balanceTemp;
              this.paymentLists = result;
              
              if(newPayment > 0){
                this.paymentLists.push({
                    id: 0,
                    contact_id: contact_id,
                    project_id: this.project,
                    date: null,
                    value: newPayment,
                    paid: 0,
                    paid_date: null,
                    notes: ''
                });
                this.activePayment = 0;
              }

            },
            err => 
            {
              console.log(err);
            }
        );
    }

    updatePayment(pos, close){
         
        let elementValue = document.getElementById('value-'+pos);
        let elementDate = document.getElementById('date-'+pos);
        let elementPaid = document.getElementById('paid-'+pos);
        let elementNote = document.getElementById('note-'+pos);

        if(elementValue)
        {
            elementValue.setAttribute("disabled","disabled");
        }
        if(elementDate)
        {
            elementDate.setAttribute("disabled","disabled");
        }
        if(elementPaid)
        {
            elementPaid.setAttribute("disabled","disabled");
        }
        if(elementNote)
        {
            elementNote.setAttribute("disabled","disabled");
        }

        let id = this.paymentLists[pos].id;
        let contact_id = this.paymentLists[pos].contact_id;
        let project_id = this.paymentLists[pos].project_id;
        let date = this.paymentLists[pos].date;
        let value = this.paymentLists[pos].value;
        let paid = this.paymentLists[pos].paid;
        let notes = this.paymentLists[pos].notes;
        let validate = parseInt(this.paymentData['total_paid']) + parseInt(value);
        if(!paid)
        {
            this.paymentData['total_paid'] = this.paymentData['total_paid']+parseInt(this.paymentData['total_balance']);
        }
        if(validate > this.paymentData['total_value'] && this.paymentData['total_paid'] < this.paymentData['total_value']){
            this.toastr.error("El valor ingresado supera el valor total del contrato");
            this.getPaymentDataByProvider(contact_id);
           // this.paymentLists[pos].value = 0;
            this.paymentLists[pos].paid = false;
            return;
        }else{
            let body = new FormData();
            body.append('id', id+'');
            body.append('contact_id', contact_id+'');
            body.append('project_id', project_id+'');
            if(date != null){
                body.append('date', date+'');
            }
            
            body.append('value', value+'');
            if(paid){
                body.append('paid', 1+'');
                let date = moment().format('YYYY-MM-DD hh:mm:ss');
                body.append('paid_date', date+'');
            }else{
                body.append('paid', 0+'');
            }

            if(notes != '' && notes != null){
                body.append('notes', notes+'');
            }

            this.service.queryPost('project/payments-details', body).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    if(!result.success){
                        this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                    this.getPaymentDataByProvider(contact_id);
                    let elementValue = document.getElementById('value-'+pos);
                    let elementDate = document.getElementById('date-'+pos);
                    let elementPaid = document.getElementById('paid-'+pos);
                    let elementNote = document.getElementById('note-'+pos);

                    if(elementValue)
                    {
                        elementValue.removeAttribute("disabled");
                    }
                    if(elementDate)
                    {
                        elementDate.removeAttribute("disabled");
                    }
                    if(elementPaid)
                    {
                        elementPaid.removeAttribute("disabled");
                    }
                    if(elementNote)
                    {
                        elementNote.removeAttribute("disabled");
                    }

                    if(close == 1){
                        this.activePayment = null;
                    }    
                },
                err => 
                {
                console.log(err);
                }
            );
        }
    }

    removeTypeTocontact(event: any){
        let pos = this.tempCategories.indexOf(event.value);
        if(pos > -1){
            this.tempCategories.splice(pos, 1);
        }
    }

    addTypeToContact(event){
        if(event.display != event.value){
            this.tempCategories.push(event.value);
        }else{
            let user = JSON.parse(localStorage.getItem('user'));
            let body = new FormData();
            body.append('id', 0+'');
            body.append('company_id', user.company_id+'');
            body.append('name', event.value);

            this.service.queryPost('contact-types', body).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    
                    if(result.success)
                    {
                        this.tempCategories.push(result.contactType.id);
                    }
                    else
                    {
                        let message = '';
                        if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }

                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                },
                err => 
                {
                    console.log(err);
                }
            );
        }
    }

    cancelInlineEdit()
    {
        this.posToEdit = -1;
        this.getFilesProvider(this.provider['id'])
    }

    /**
     * Función para validar 
     *
     * @param e EEventHtml
     */
    public validateNumber(e)
    {
        if(e.code !== "Backspace" && e.code !== "Tab" && e.code !== "ShiftRight" 
            && e.code !== "Home" && e.code !== "ShiftLeft" && e.code !== "End" && e.code !== "MetaLeft"){
            var key = window.event ? e.which : e.keyCode;
              if (key < 48 || key > 57) {
                e.preventDefault();
              }
        }
    }

    editPayment(paymentId){
        this.activePayment = paymentId;
    }
}
