import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { DOCUMENT} from '@angular/common';
import { PageScrollService  } from 'ngx-page-scroll-core';
import * as jquery from 'jquery';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

@HostListener("window:scroll", ['$event'])
export class LandingPageComponent implements OnInit {



  doSomethingOnWindowsScroll($event:Event)
  {
    let scrollOffset = jquery(window).scrollTop();
    this.getMediaQuery();
    if(1 < scrollOffset)
    {
      jquery('#menu').addClass('nav-fix');
    }
    else
    {
      jquery('#menu').removeClass('nav-fix');
    }
  }
  
  private idMenu = '';
  private plans = [];

  public _responsive = false;
  public _menuSelected = '';
  public date = new Date ();
  public year = this.date.getFullYear();

  public positionUl = '';  

  constructor(public service: ApirestService, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) { }

  ngOnInit() 
  {

    localStorage.setItem('user', null);
    localStorage.setItem('token', null);

    jquery('.ir-arriba').click(function(){
      jquery('body, html').animate({
        scrollTop: '0px'
      }, 300);
    });
   
    jquery(window).scroll(function(){
      if( jquery(this).scrollTop() > 0 ){
        jquery('.ir-arriba').slideDown(300);
      } else {
        jquery('.ir-arriba').slideUp(300);
      }
    });
    this.plans = [
      {description: "",id: 0,is_private: 0,name: null,num_max_project: 0,value: 0},
      {description: "",id: 0,is_private: 0,name: null,num_max_project: 0,value: 0},
      {description: "",id: 0,is_private: 0,name: null,num_max_project: 0,value: 0},
      {description: "",id: 0,is_private: 0,name: null,num_max_project: 0,value: 0},
      {description: "",id: 0,is_private: 0,name: null,num_max_project: 0,value: 0},
    ];
    this.getPlans();
    this.getMediaQuery();
  }

  getPlans()
  {
    let url = 'get-plans';
    this.service.queryGet(url).subscribe(
        response=>
        {      
          let result = response.json(); 
          this.plans = result;     
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  getMediaQuery()
  {
      let width = jquery('body, html').width();
      if(width > 1024)
      {
        this._responsive = false;
      }
      else if(width <= 1024)
      {
        this._responsive = true;
      }
  }


  scroll(id)
  {
    let auxDiv = null;
    let auxNav = null;
    let scrollPos = 0;
    let scrollActual = jquery(window).scrollTop();
    let scrollNum = 1;
    if(100 > scrollActual){
      //Si es el primer menu debe quedar posicionado 2 veces el alto del menu menu puesto que al fix del menu se reduce el pos de la capa destino (queEs)
      scrollNum = 2;
    }
    if('queEs' == id)
    {
      this._menuSelected = '_queEs';
    }
    else if('paraQueSirve' == id)
    {
      this._menuSelected = '_paraQueSirve';
    }
    else if('cuantoVale' == id)
    {
      this._menuSelected = '_cuantoVale';
    }

    if(this._responsive)
    {
      this.openMenuR()
    }

    if('' != this.idMenu && this.idMenu != id)
    {
      jquery('#li'+id).addClass('active');
      jquery('#li'+this.idMenu).removeClass('active');
    }
    else
    {
      jquery('#li'+id).addClass('active');
    }
    this.idMenu = id;
    scrollPos = parseInt(jquery("#" + id).offset().top)-(parseInt(jquery("#menu").height())*scrollNum);   
    jquery("html, body").animate({
        scrollTop: scrollPos
      }, {
        duration: 2000
    });
  }

  openMenuR(){
    if('' == this.positionUl){
       this.positionUl = 'show-menu';
    } else{
      this.positionUl = '';
    }
  }
}

