import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.scss']
})
export class PreviewTemplateComponent implements OnInit {

  public pdf = null;

  constructor(public service: ApirestService,
	    public router: Router,
	    public toastr: ToastrService,
	    public translate: TranslateService,
	    public singleton: SingletonService,
	    public lastlocation: Location,
      public modalService: BsModalService,
      public sanitizer: DomSanitizer,
      private SessionService: SessionService) 
    { 
        window.scroll(0,0);
        
    }

  ngOnInit() {
    this.SessionService.validUserSession(); 
    if(this.singleton.pdf == null){
      this.lastlocation.back();
    }else{
      this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.singleton.pdf);
    }
    window.scroll(0,0);
  }

}
