import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApirestService } from '../apirest.service';
import { DataServiceService } from '../data-service.service';
import { SingletonService } from '../singleton.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BlockedService } from '../components/services/blocked.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	locale = 'es';
	public toDelete = 0;
	public typeToDelete = '';
	public user = null;
	public projectsList = [];

	//Variables for list with (pagination, select rows, search)
	public users = [];
	public pagesNumber = 100; //Default value
	public search = '';
	public pages = [];
	public currentPage = 1;

	public mediaQuery = 'lg';

	public projectToShare = 0;
	public userToshare = 0;
	public listUsersShareProject = [];
	public listUsers = [];
	public statusProject = '1';
	public companies:any = [];
	public company = {name:'', city_name:'', department_id:0};
	public idToEdit:number = 0;
	public countriesList:any = [];
	public departmentsList:any = [];
	public citiesList:any = [];
	public historyPayments: any = [];
	public messageChangeStatus: string = '';
	public filterDate:any = []
	public _showModalBlocked = false;

	// Variables privadas
	private idCompany: number = 0;
	private statusCompany:number = 0;

  	public showSecondColumn = true;
  	public searchEvent = "";
  	public showSearch = true;


	modalRefDelete: BsModalRef | null;
	modalRefArchive: BsModalRef | null;
	modalRedShare: BsModalRef | null;
	modalRefInfoCompany: BsModalRef | null;
	modalRefHistoryPayment: BsModalRef | null;
	modalRefChangeStatus: BsModalRef | null;


	constructor(public service: ApirestService,
			  private router: Router,
			  public singleton: SingletonService,
	          private dataService:DataServiceService,
	          private localeService: BsLocaleService,
	          private modalService: BsModalService,
	          private toastr: ToastrService,
			  private route: ActivatedRoute,
			  private blockedService: BlockedService,
			  private SessionService: SessionService) 
	{ 
		window.scroll(0,0);
	}

	ngOnInit() {
		this.localeService.use(this.locale);
		this.SessionService.validUserSession();  
		this.user = JSON.parse(localStorage.getItem('user'));
		//Asignamos una imagen por defecto en caso de que la empresa no tenga una asignada
		if(this.user.company.url_cover == "" || !this.user.company.url_cover){
			this.user.company.url_cover = this.service.apiUrl+"assets/images/login-background.jpg";
		}

		if(this.user.company.created_at == this.user.company.updated_at){
			//this.router.navigate(['/company']);
		}
		this.dataService.user.subscribe(data=>{
			this.getUser();
		});

		if(1 == this.user.rol_id)
		{
			this.getCompanies('');
			this.getCountryList();
			this.getDepartmentsList();
			this.dataService.searchEvent.subscribe(data=>{
				if(this.search != null && '' != this.search){
					this.search = data;
					this.getCompanies(this.currentPage);
				}
			});
		}
		else{
			this.getEvent('');
			this.dataService.searchEvent.subscribe(data=>{
				if(this.search != null){
					this.search = data;
					this.getEvent(this.currentPage);
				}
			});
		}

		this.getMediaQuery();
		this.getUsersList();
	}


	getMediaQuery()
    {
        let width = screen.width;

        if(width <= 1920)
        {
            this.mediaQuery = 'col-md-2';
        }else if(width > 1920){
        	this.mediaQuery = 'col-md-1';
        }
    }


	getUser()
  	{
  		this.user = JSON.parse(localStorage.getItem('user'));
	}
	  
	getEvent(page){
		let url = 'project?rows=' + this.pagesNumber;
        url += '&search=' + this.search;
        url += '&status='+this.statusProject;
        if(page != '')
        {
            url += '&page=' + page;            
        }

		this.service.queryGet(url).subscribe(
			response=>
			{      
				let result = response.json();
				
				this.projectsList = result;
				
			},
			err => 
			{
				console.log(err);
			}
		);
	}

	goToDetailsProject(id, status){
		if(0 == this.user.blocked){
			localStorage.setItem('show', status);
			this.router.navigate(['/project/'+this.singleton.encrypt(id)+'/home']);
			return;
		}
		this._showModalBlocked = false;
        if(1 == this.user.blocked)
        {
            this._showModalBlocked = true;
            this.blockedService.setOpenValue(this._showModalBlocked);
            return;
        }
	}

	editProject(id){
		this.singleton.editEventId = id;
		this.router.navigate(['registerproject'], { queryParams: { edit: this.singleton.encrypt(id) } });
	}

	confirmeArchive(template ,id){
		this.toDelete = id;
      	this.modalRefArchive = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
	}

	cancelArchive(){
      this.modalRefArchive.hide();
      this.toDelete = null;
    }

    archiveProject(){
    	let body = new FormData();
    	body.append('id', this.toDelete+'');

    	this.service.queryPost('project-filed', body).subscribe(
	        response=>
	        {      
	            let result = response.json(); 

	            if(result.success)
	            {
	                //Reload the list users
	                this.getEvent('');
	                this.modalRefArchive.hide();
	                this.toDelete = null;
	                this.toastr.success(result.message);
	            }
	        },
	        err => 
	        {
	            console.log(err);
	        }
	    );
    }

	confirmDelete(template, id, typeToDelete = null){
	  this.toDelete = id;
	  this.typeToDelete = typeToDelete;
      this.modalRefDelete = this.modalService.show(template, { class: '' });
	}
	
	confirmDeleteShare(template, id, typeToDelete = null){
		this.toDelete = id;
	  	this.typeToDelete = typeToDelete;
		this.modalRedShare = this.modalService.show(template, {class: ''})
	}
	
	closeModalShareDelete(){
		if(this.modalRedShare){
			this.modalRedShare.hide();
		}
	}

    cancelDelete(){
      this.modalRefDelete.hide();
      this.toDelete = null;
    }

    deleteproject(){
        this.modalRefDelete.hide();
    	this.service.queryDelete('project/'+this.toDelete).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                this.getEvent('');
                this.toDelete = null;
                this.toastr.success(result.message);
            }
        },
        err => 
        {
            console.log(err);
        }
      );
	}
	
	getUsersList(){
		this.service.queryGet('get-role-permissions-projects').subscribe(
			response=>
			{
				let result = response.json();
				this.listUsers = result;
			},
			err=>
			{
				console.log(err);
			}
		);
	}

	OpenModalshareProject(projectId, template){
		this.projectToShare = projectId;
		let body = new FormData();
		body.append('project_id', this.projectToShare+'');
		this.service.queryPost('project-shared-index', body).subscribe(
			response => 
			{
				let result = response.json();
				this.listUsersShareProject = result;
				this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
			},
			err=>
			{

			}
		);
	}

	closeModal(){
		if(this.modalRefDelete){
			this.modalRefDelete.hide();
		}
		if(this.modalRefArchive){
			this.modalRefArchive.hide();
		}
		if(this.modalRefInfoCompany){
			this.modalRefInfoCompany.hide();
		}
		if(this.modalRefHistoryPayment){
			this.modalRefHistoryPayment.hide();
		}


		this.projectToShare = 0;
		this.userToshare = 0;
	}

	addUserToProject(){

		let body = new FormData();
		body.append('type', 2+'');
		body.append('project_id', this.projectToShare+'');
		body.append('user_id', this.userToshare+'');

		this.service.queryPost('project-shared', body).subscribe(
            response=>
            {      
                let result = response.json(); 
				
                if(result.success)
                {
                    this.listUsersShareProject.push({id: result.project_shared_id, name: result.user_name});
                }else{
					this.toastr.error(result.message);
				}
				this.userToshare = 0;
                
            },
            err => 
            {
                console.log(err);
            }
        );
	}


	deleteUserFromProject(){
		
		let url = "project-shared/"+this.toDelete;
		this.service.queryDelete(url).subscribe(
			response=>
			{
				for(let i = 0; i < this.listUsersShareProject.length; i++){
					if(this.listUsersShareProject[i].id == this.toDelete){
						this.listUsersShareProject.splice(i, 1);
					}
				}
				this.userToshare = 0;
				this.toDelete = 0;
				this.typeToDelete = '';
				this.closeModalShareDelete();
			},
			err=>
			{
				console.log(err)
			}
		);
	}

	changeStatusProject(value)
	{
		//TODO revisar desarchivar plan, verificar plan, verificar cantidad actual
		this.statusProject = value;
		this.getEvent(this.currentPage);
	}

	/**
   * Calls the elements of the given page
   * @param int page Next page to paginate
   */
  	getPage(page)
  	{
      	if(page != this.currentPage)
      	{
          	this.getCompanies(page);
      	}
 	 }

	private getCompanies(page)
	{
		let date = '';
		if(this.filterDate && 0 < this.filterDate.length)
		{
			let startDate = moment(this.filterDate[0]).format('YYYY-MM-DD');
			let endDate = moment(this.filterDate[1]).format('YYYY-MM-DD');
			date = JSON.stringify([startDate+' 00:00:00',endDate+' 23:59:59']);
		}
		let url = `get-companies?search=${this.search}&date=${date}`;

		if(page != '')
        {
            url += '&page=' + page;            
        }

		this.service.queryPost(url, new FormData).toPromise().then(
			response => {
				let result = response.json();
				this.companies = result.data;
				this.currentPage = result['current_page'];
            	this.pages = this.singleton.pagination(result);
			},
			err =>
			{
				console.log(err);
			}
		);
	}

	public updateInfoPlan(data)
	{
		let url = `update-company-plan`;
		let body = new FormData;
		body.append('id',data.company_plan_id.toString());
		body.append('total_events',data.total_events);
		body.append('due_date',data.due_date);
		this.service.queryPost(url, body).toPromise().then(
			response => {
				let result = response.json();
				this.getCompanies(this.currentPage);
				this.toastr.success(result.message);
			},
			err => {

			}
		);
		this.idToEdit = -1;
	}

	public editline(index)
	{
	    this.idToEdit = this.companies[index].company_plan_id;
	}

	public cancelEditLine()
	{
    	this.idToEdit = -1;
    }

    public getCompany(id, template)
    {
    	let url = `company/${id}`;
    	this.service.queryPost(url, new FormData).toPromise().then(
    		response => {
    			let result = response.json();
    			this.company = result;
    			this.changeDepartment();
    			this.modalRefInfoCompany = this.modalService.show(template, { class: 'modal-lg' });
    		},
    		err => {

    		}
    	);
    }

    private getCountryList(){
        let url = 'get-countries'
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.countriesList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    private getDepartmentsList(){

		let url = 'get-department/'+this.user.company.department_id;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.departmentsList = result;
                //this.changeDepartment();
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    public changeDepartment(){
        
        if(this.company.department_id != 0){
            this.company.city_name = "";
            let url = 'get-cities/'+this.company.department_id;
            this.service.queryGet(url).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    this.citiesList = result;
                },
                err => 
                {
                    console.log(err);
                }
            );
        }
    }

    public getHistoryPayment(template, companyId)
    {
    	let url = `transactions?company_id=${companyId}`;
    	
    	this.service.queryGet(url).toPromise().then(
    		response => {
    			let result = response.json();
    			this.historyPayments = result;
    			this.modalRefHistoryPayment = this.modalService.show(template, { class: 'modal-lg' });
    		},
    		err => {
    			console.log(err);
    		}
    	);
    }

    public openModalChangeStatus(template, company)
    {
    	this.idCompany = company.id;
    	this.messageChangeStatus = `<br/>¿Estás seguro de cambiar el estado?<br/>Los usuarios de está empresa no podran ingresar a la plataforma`;
    	if(0 == company.company_status){
    		this.statusCompany = 1;
    		this.messageChangeStatus = '¿Estás seguro de cambiar el estado?';
    	}
    	this.modalRefChangeStatus = this.modalService.show(template, { class: 'modal-md modal-dialog-centered'});
    }

    public changeStatusCompany()
    {
    	document.getElementById('changeStatus').setAttribute('disabled', 'disabled');
    	let url = `company-update-status`;
    	let body = new FormData;
    	body.append('id', this.idCompany.toString());
    	body.append('status',  this.statusCompany.toString());
    	this.service.queryPost(url, body).toPromise().then(
    		response => {
    			let result = response.json();
    			this.idCompany = 0;
    			this.statusCompany = 0;
    			this.modalRefChangeStatus.hide();
    			this.getCompanies(this.currentPage);
    		},
    		err => {
    			document.getElementById('changeStatus').removeAttribute('disabled');
    		}
    	);
    }

    public cancelChangeStatus()
    {
    	this.idCompany = 0;
    	this.statusCompany = 0;
    	this.modalRefChangeStatus.hide();
    }

    public exportCompanies()
    {
    	let date = '';
		if(this.filterDate && 0 < this.filterDate.length)
		{
			let startDate = moment(this.filterDate[0]).format('YYYY-MM-DD');
			let endDate = moment(this.filterDate[1]).format('YYYY-MM-DD');
			date = JSON.stringify([startDate+' 00:00:00',endDate+' 23:59:59']);
		}

    	let url = `export-companies`;
    	let body = new FormData;
    	body.append('date', date);
    	body.append('search', this.search);
    	this.service.queryPost(url, body).toPromise().then(
    		response => {
    			let result = response.json();
    			window.open(result);
    		},
    		err => {

    		}
    	);
    }

    public addProject(){
    	if(1 == this.user.blocked)
        {
            this._showModalBlocked = true;
            this.blockedService.setOpenValue(this._showModalBlocked);
            return;
        }

        this.router.navigate(['/registerproject']);
    }

    goTo(to) {
		this.router.navigate([to]);
	}

	hideSecondColumn() {
		this.showSecondColumn = false;
	}

	filterSearchEvent() {
	    this.searchEvent;
	    this.dataService.changeSearchEvent(this.searchEvent);
	}

	generalConfigurations(){
		this.SessionService.updateConfigurations(true);
	}
}
