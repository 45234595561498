import { Component, OnInit, ViewChild, Input, Output, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApirestService } from '../../../apirest.service';
import { ContactFormServiceService } from '../../services/contact-form-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit  {
  @ViewChild('modalForm') modalForm;
  modalRef: BsModalRef | null;

  @Input()showFloatBtn = true;
  public newMessage = {
    name: null,
    email: null,
    message: null
  }
  private unsubscribes: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private toasrt: ToastrService,
    private service: ApirestService,
    public contactService: ContactFormServiceService
  ) { }

  ngOnInit() {
    const dataSuscribe = this.contactService.openModal.subscribe(data => {
      if(data)
      {
        this.openModalForm();
      }
    });

    this.unsubscribes.push(dataSuscribe);

  }

  ngOnDestroy() {
     this.unsubscribes.forEach(sb => sb.unsubscribe());
  }

  openModalForm() {
    this.modalRef = this.modalService.show(this.modalForm, { class: 'modal-md modal-dialog-centered' });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    this.clearData();
  }

  clearData() {
    this.newMessage = {
      name: null,
      email: null,
      message: null
    }
    this.contactService.setOpenValue(false);
  }


  sendForm() {
    let validate = true;
    if (this.newMessage['name'] == null || this.newMessage['name'] == '') {
      this.toasrt.error('Debes ingresar el campo nombre');
      validate = false;
    }

    if (this.newMessage['email'] == null || this.newMessage['email'] == '') {
      this.toasrt.error('Debes ingresar el campo email');
      validate = false;
    } else {
      if (!this.contactService.validateEmail(this.newMessage['email'])) {
        this.toasrt.error('Debes ingresar un email válido');
        validate = false;
      }

    }

    if (this.newMessage['message'] == null || this.newMessage['message'] == '') {
      this.toasrt.error('Debes ingresar el campo message');
      validate = false;
    }

    if (validate) {

      let body = new FormData();

      body.append('name', this.newMessage['name']);
      body.append('email', this.newMessage['email']);
      body.append('message', this.newMessage['message']);


      this.service.queryPost('send-email', body).subscribe(
        success => {
          let response = success.json();
          if (response.success) {
            this.toasrt.success(response.message);
            this.closeModal();
          } else {
            this.toasrt.error(response.message);
          }
        },
        err => {

        }
      );
    }
  }

}
