import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { SingletonService } from './singleton.service';

@Injectable({
  providedIn: 'root'
})
export class TeamGuardService {

  constructor(
    private singleton: SingletonService,
    private router: Router) 
  { 

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){    
    let currentRoute = state.url.substring(1);
    
    if(-1 < this.singleton.permissions.indexOf(currentRoute)){
      return true;
    }else{
      this.router.navigate(['/home']);
     return false;
    }
   
    
	}
}
