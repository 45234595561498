import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import * as $ from 'jquery';
import { SessionService } from '../session.service';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
// import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
    
    public toDelete:number = null;
    // public Editor = ClassicEditorBase;
    public Editor = DecoupledEditor;

    public sessionUser = {};
    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;
    modalRefForm: BsModalRef | null;
    //Form variables
    public document = {id: 0, title: '', url_file: ''};
    public addedFile = false;

    //Variables for documents list with (pagination, select rows, search)
    public documents = [];
    public pagesNumber = 10; //Default value
    public search = '';
    public pages = [];
    public currentPage = 1;

    public documentsTemplates = [];

    public viewListDocument = true;
    public viewDocument = false;
    public previewDocument = false;

    public templateSelected = "";
    public templateDocument = {id: 0, name:'', description: '', status: 0, observations: '', template_description:''};
    public typeDocument = 1;

    public myConfig:any = null;
    
    public formData = null;
    public emailForm = {subject: '', message: '', emails: []};
    public emails = null;
    public pdf = null;
    public created_date = "";
    public updateTemplateName = false;

	constructor(public service: ApirestService,
	    private router: Router,
	    private toastr: ToastrService,
	    private translate: TranslateService,
	    private singleton: SingletonService,
	    private lastlocation: Location,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer,
        private SessionService: SessionService
        ) 
    { 
        window.scroll(0,0);
        // this.Editor.defaultConfig = {
        //     toolbar: {
        //         items: [
        //             "heading",
        //             "|", 
        //             'indent', 
        //             '|',
        //             "fontsize",
        //             "|",
        //             // "bold",    
        //             "italic",
        //             "underline",
        //             "strikethrough",
        //             "highlight",
        //             "|",
        //             "alignment",
        //             "|",
        //             "numberedList",
        //             "bulletedList",
        //             "|",
        //             "link",
        //             "blockquote",
        //             "imageUpload",
        //             "insertTable",
        //             "mediaEmbed",
        //             "|",
        //             "undo",
        //             "redo",
        //         ],
        //     },
        //     // This value must be kept in sync with the language defined in webpack.config.js.
        //     language: 'es'
        // }

        this.myConfig = this.singleton.configCkEditor;
    }

  	ngOnInit() 
  	{
        this.SessionService.validUserSession();  
        this.getUser();
        //Function to get the source data
        this.getDocumentsTemplates();
        this.getDocuments('');
        
  	}
    public onReady( editor ) {
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }

    getDocumentsTemplates(){
        let url = "template?templateType=5&rows=100";

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.documentsTemplates = result.templates.data;
            },
            err => 
            {
                console.log(err);
            }
        );
    }

  	/**
     * Gets the session user
     */
  	getUser()
  	{
  		this.sessionUser = JSON.parse(localStorage.getItem('user'));
  	}

  	/**
  	 * Get the documents list to show in the table
  	 * @param {string} page
  	 */
  	getDocuments(page){
  		let url = 'project/documents?rows='+this.pagesNumber+'&userId='+this.sessionUser['id'];
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.documents = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                console.log(err);
            }
        );
    }


	saveDocument()
	{
		let body = new FormData();
        body.append('id', this.templateDocument['id']+'');
        body.append('name', this.templateDocument['name']+'');
        body.append('content', this.templateDocument['description']+'');
        if(this.templateDocument['status'] == 0){
            this.toastr.error('Debe seleccionar un estado del documento');
            return;
        }
        body.append('document_status_id', this.templateDocument['status']+'');
        
        body.append('company_id', this.sessionUser['company_id']+'');
        body.append('user_id', this.sessionUser['id']+'');

        if(this.templateDocument['template_id']){
            body.append('project_template_id', this.templateDocument['template_id']+'');
        }
        if(this.templateDocument['observations'] != '' && this.templateDocument['observations'] != null){
            body.append('observation', this.templateDocument['observations']+'');
        }


        this.service.queryPost('project/document', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reinit the global user variable
                    this.templateDocument = {id: 0, name:'', description: '', status: 0, observations: '', template_description:''};
                    //Show success message
                    this.toastr.success(result.message);
                    //Reload the list users
                    this.getDocuments('');
                    this.cancelCreate();
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	editDocument(id)
	{
        this.typeDocument = 0;
		let url = 'project/document/'+id;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.templateDocument['id'] = result.id;
                this.templateDocument['name'] = result.name;
                this.templateDocument['description'] = result.content;
                this.templateDocument['status'] = result.status_id;
                this.templateDocument['observations'] = result.observation;
                this.templateDocument['template_id'] = result.project_template_id;
                this.viewForm();
            },
            err => 
            {
                console.log(err);
            }
        );
	}


    confirmDelete(template, id){
        this.toDelete = id;
        this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    cancelToDelete(){
        this.toDelete = null;
        if(this.modalRefDelete){
            this.modalRefDelete.hide();
        }
    }

    deleteItem(){
        this.service.queryDelete('project/document/'+this.toDelete).subscribe(
            response=>
            {      
                let result = response.json(); 
                if(result.success)
                {
                    //Reload the list users
                    this.getDocuments('');
                    if(this.modalRefDelete){
                        this.modalRefDelete.hide();
                    }
                    this.toDelete = null;
                    this.toastr.success(result.message);
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }


    OpenModal(template){
        this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered',backdrop: true, ignoreBackdropClick: true });
        document.getElementById('typeDocument').focus();
    }

    cancelCreate(){
        this.getDocuments('');
        if(this.modalRef){
            this.modalRef.hide();
        }
        this.templateSelected = "";
        this.templateDocument = {id: 0, name:'', description: '', status: 0, observations: '', template_description:''};
        this.viewListDocument = true;
        this.viewDocument = false;
    }

    getTemplateContent(){ 
        if(this.typeDocument == 1){
            let url = "template/"+this.templateSelected+"?type=5";

            this.service.queryGet(url).subscribe(
                response=>
                {      
                    let result = response.json();
                    if(0 < result.template_document.length)
                    {
                        this.templateDocument['name'] =  '';
                        this.templateDocument['description'] = result.template_document[0].content;
                        this.templateDocument['template_id'] = result.id;
                        this.templateDocument['template_description'] = result.description;
                    }
                },
                err => 
                {
                    console.log(err);
                }
            );
        }else{
            this.templateDocument['name'] = "";
            this.templateDocument['description'] = "";
        }
	}

    viewForm(){
        if(1 == this.typeDocument && "" == this.templateSelected){
            this.toastr.error('Debe seleccionar una plantilla para crear el documento');
            return;
        }

        if(this.templateDocument.name == ""){
            this.toastr.error("Debe ingresar el campo nombre");
            return;
        }
        if(this.modalRef){
            this.modalRef.hide();
            if(this.typeDocument == 0){
                this.templateSelected = "";
            }
        }
		this.viewDocument = true;
        this.viewListDocument = false; 
        this.previewDocument = false;
        window.scroll(0,0);
    }

    export(id, type, template){
        /**
         * 
         * when I want to export check list, note, schedule and budget I need to send "1"
         * with these a send id as a project_template_id, when is documenta send project_document_id and 
         * other I send project_id
         */
        
        this.modalRefForm = this.modalService.show(template, { class: 'modal-md modal-dialog-centered',backdrop: true, ignoreBackdropClick: true });
        this.formData = {id: id, type: type, report_type: 2};
        document.getElementById('subject').focus();
    }

    closeModalForm(){
        if(this.modalRefForm){
            this.modalRefForm.hide();
        }
    }

    removeEmails(item: any){
        let pos = this.emailForm.emails.indexOf(item.value);
        this.emailForm.emails.splice(pos, 1);
    }

    addEmails(item: any){
        if(this.validateEmail(item.value)){
            this.emailForm['emails'].push(item.value);
        }else{
            this.toastr.error('Debe ingresar un correo valido');
            let pos = null;
            for(let i = 0; i < this.emails.length; i++){
                if(item.value == this.emails[i].value){
                    pos = i;
                }
            }
            this.emails.splice(pos, 1);
        }
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    sendForm(){
        
        /**
         * when I want to export check list, note, schedule and budget I need to send "1"
         * with these a send id as a project_template_id, when is documenta send project_document_id and 
         * other I send project_id
         */

        if(this.emailForm['emails'].length == 0){
            this.toastr.error('El campo correo electrónico es requerido');
            return;
        }
        document.getElementById("send-email").setAttribute("disabled", "disabled");
        let body = new FormData();
        body.append('id', this.formData['id']+'');
        body.append('type', this.formData['type']+'');
        body.append('report_type', this.formData['report_type']+'');
        body.append('subject', this.emailForm['subject']+'');
        body.append('message', this.emailForm['message']+'');
        body.append('emails', JSON.stringify(this.emailForm['emails']));

        this.service.queryPost('project/exports', body).subscribe(
			response=>
			{      
				let result = response.json(); 
				
                //Show success message
                this.toastr.success('Mensaje enviado correctamente');
                this.emailForm = {subject: '', message: '', emails: []};
                this.formData = null;
                this.emails = null;
                this.modalRefForm.hide();
				document.getElementById("send-email").removeAttribute("disabled");
			},
			err => 
			{
                this.toastr.success('Ha ocurrido un error inesperado.');
				console.log(err);
                document.getElementById("send-email").removeAttribute("disabled");
			}
		);
    }


    previewDocumentfunc(id, date){
        this.created_date = date;
        let url = 'project/document/'+id;
        let body = new FormData();
        body.append('id', id+'');
        body.append('type', 'pdf');
        body.append('report_type', 2+'');
        body.append('view', 1+'');

        this.service.queryPost('project/exports', body).subscribe(
			response=>
			{      
				let result = response.json(); 
                if(result.success){
                    let pdf = result.url;
                    this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(pdf);;
                }
                this.viewPrev();
			},
			err => 
			{
				console.log(err);
			}
		);  
    }

    viewPrev(){
        this.viewDocument = false;
        this.viewListDocument = false; 
        this.previewDocument = true;
    }

    viewListDocuments(){
        this.created_date = "";
        this.emailForm = {subject: '', message: '', emails: []};
        this.formData = null;
        this.emails = null;
        this.viewDocument = false;
        this.viewListDocument = true; 
        this.previewDocument = false;
        this.pdf = null;
        window.scroll(0,0);
    }

    async toprint(url){
        //var url = 'proxy.html'; // We're not loading the PDF but a proxy which will load the PDF in another iframe.
        console.log(url);
        var iframe = $('<iframe src="' + url + '"></iframe>').appendTo($('#main'));
        console.log(iframe);
        iframe.on('load', function(){
          iframe.get(0).contentWindow.print();
        }); 
    }

    /**
     * Funcion para buscar un activo movil despues de la tercer letra
     *
     *@param event elementHtml
     */
    public getSearchDocument(event){
        let total = event.target.value.length
        if(3 <= total){
           this.getDocuments(''); 
        }
        if('' === event.target.value)
        {
            this.getDocuments('');
        }
    }

    editTemplateName(){
        this.updateTemplateName = true;
    }

    updateName(){
        let body = new FormData();
        body.append('id', this.templateDocument['id']+'');
        body.append('name', this.templateDocument['name']+'');
        


        this.service.queryPost('project/document', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reinit the global user variable
                    this.updateTemplateName = false;
                    //Show success message
                    //this.toastr.success(result.message);
                    
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
        
    }

    goToTemplates(){
        this.cancelCreate();
        this.router.navigate([`/templates`]);
    }
}
