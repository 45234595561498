import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef  } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { getEventsInPeriod } from 'calendar-utils';
import { SessionService } from '../session.service';
import * as $ from 'jquery';

registerLocaleData(localeEs);

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calendar-project',
  templateUrl: './calendar-project.component.html',
  styleUrls: ['./calendar-project.component.scss']
})
export class CalendarProjectComponent implements OnInit {
  public project = "";
  locale = 'es';
  view: CalendarView = CalendarView.Month;
  
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  public event = {id: 0, project_id: 0, event_type_id: 0, event: '', date: ''};
  public eventDetails = {
    project_id: "",
    project_name: "",
    project_type_id: "",
    type_name: "",
    event_id: '',
    name_activity: "",
    start: "",
    end: "",
    color: null,
    checkList: false, 
    done: false
  }

  public eventTypes = [];
  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;
  public archiveProject = false;

  @ViewChild('eventForm')eventForm;
  @ViewChild('eventDetail')eventDetail
  constructor(private service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private cdr: ChangeDetectorRef,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }

  ngOnInit() {
    this.SessionService.validUserSession();  
    let id = localStorage.getItem('project');
    this.project = this.singleton.decrypt(id);
    this.getEvents();
    this.getEventTypes();
    
    if(localStorage.getItem('show') == '0'){
      this.archiveProject = true;
    }

  }

  getEventTypes(){
    let url = 'get-event-type';
      
    this.service.queryGet(url).subscribe(
        response=>
        {      
            let result = response.json(); 
            this.eventTypes = result;
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  openModal(template){
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered'});
    this.event = {id: 0, project_id: 0, event_type_id: 0, event: '', date: ''};
    if(this.event.id == 0){
      //document.getElementById('eventType').focus();
    }
  }

  handleEvent(type, event, template){
    this.event['id'] = event.project_type_id;
    this.event['event_type_id'] = event.type;
    this.event['event'] = event.title;
    this.event['date'] = event.end;
    //this.openModal(this.eventForm);
    
    if(event.type_name == "calendario"){
      this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered'});
    }else{
      this.eventDetails = {
        project_id: this.project,
        project_name: event.project_name,
        project_type_id: event.type,
        type_name: event.type_name,
        event_id: event.project_type_id,
        name_activity: event.name_activity,
        start: event.start,
        end: event.end,
        color: null,
        checkList: false, 
        done: false
      }

      if(this.eventDetails['type_name'] == "Lista de chequeo"){
        this.eventDetails['checkList'] = true;
        
        let url = "project/checklist/task/"+event.type;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                if(result.done == 1){
                  this.eventDetails['done'] = true;
                }else{
                  this.eventDetails['done'] = false;
                }
                this.modalRef = this.modalService.show(this.eventDetail, { class: 'modal-md modal-dialog-centered' });
            },
            err => 
            {
                console.log(err);
            }
        );
  
      }else if(this.eventDetails['type_name'] == "plan pagos"){
        this.eventDetails['checkList'] = true;
        
        let url = "project/payments-details/"+event.project_type_id;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                if(result.success)
                {
                  if(result.detail.paid == 1){
                    this.eventDetails['done'] = true;
                  }else{
                    this.eventDetails['done'] = false;
                  }
                  this.modalRef = this.modalService.show(this.eventDetail, { class: 'modal-md modal-dialog-centered' });
                }
                else{
                  this.toastr.error(result.message, 'Error', { positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
      }else {
        this.modalRef = this.modalService.show(this.eventDetail, { class: 'modal-md modal-dialog-centered' });
      }

    }
    

    if(this.event.id == 0){
      document.getElementById('eventType').focus();
    }
  }


  

  closeModal(){
    this.modalRef.hide();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }



  getEvents(){
    let user = JSON.parse(localStorage.getItem('user'));
		let url = "project/calendar?projectId="+this.project;
    this.events = [];
		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                //this.events = result

                for(let i = 0; i < result.length; i++){
                  let title = result[i].name_activity;
                  

                  let event = {
                    project_id: result[i].project_id,
                    type_name: result[i].type_name,
                    type: result[i].type,
                    project_name: result[i].project_name,
                    project_type_id: result[i].project_type_id,
                    title: title,
                    name_activity: result[i].name_activity,
                    start: addHours(startOfDay(result[i].date), 8),
                    end: result[i].date,
                    color: {
                      primary: result[i].color,
                      secondary: "#ccc"
                    },
                    draggable: false,
                    resizable: {
                      beforeStart: true,
                      afterEnd: true
                    },
                  };
                  this.events.push(event);
                }
                
                //document.getElementById('today').click();

            },
            err => 
            {
                console.log(err);
            }
        );
  }

  saveEvent(){
        document.getElementById("save-event").setAttribute("disabled","disabled");
        let body = new FormData();
        body.append('id', this.event['id']+'');
        body.append('project_id', this.project);
        //body.append('event_type_id', this.event['event_type_id']+'');
        body.append('event_type_id', '4');
        body.append('event', this.event['event']);
        body.append('date', this.event['date']);

        this.service.queryPost('project/calendar', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Hide the canvas
                    this.closeModal();
                    this.toastr.success(result.message);
                    this.getEvents();
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
                document.getElementById("save-event").removeAttribute("disabled");
            },
            err => 
            {
                console.log(err);
            }
        );
  }


  viewDetails(){
    
    let url = "";
    let id = localStorage.getItem('project');
    let projectId = localStorage.getItem('project');
    switch (this.eventDetails['type_name']) {
      case "calendario":
        url = "project/"+projectId+"/calendar"
        this.router.navigate([url]);
        break;
      case "Lista de chequeo":
        url = "project/"+projectId+"/check"
        console.log(url);
        this.router.navigate([url]);
        break;
      case "plan pagos":
        url = "project/"+projectId+"/providers"
        let provider = this.singleton.encrypt(parseInt(this.eventDetails['project_type_id']));
        this.router.navigate([url],  { queryParams: {view: provider}});
        break;
      case "cronograma":
        url = "project/"+projectId+"/schedule";
        let data = this.singleton.encrypt(parseInt(this.eventDetails['project_type_id']));
        this.router.navigate([url], { queryParams: {view: data} });
        break;
      case "evento":
        url = "project/"+projectId+"/home"
        this.router.navigate([url]);
        break;
      default:
        break;
    }
    
    if(this.modalRef){
      this.modalRef.hide();
    }
    
  }

  updatecheckListTask(){
      this.cdr.detectChanges();
      let done = 0;
      if(this.eventDetails['done']){
          done = 1;
      }

      let body = new FormData();
      let url = `project/checklist/task-update`;
      if('plan pagos' === this.eventDetails['type_name']){
        url = `project/payments-details/${this.eventDetails['event_id']}`
        body.append('_method', 'PATCH');
      }
      body.append('id', this.eventDetails['project_type_id']+'');
      body.append('done', done+'');
      
      this.service.queryPost(url, body).subscribe(
          response=>
          {      
              let result = response.json(); 

              if(result.success)
              {
                  //Hide the canvas
                  //this.toastr.success(result.message);
                  
              }
              else
              {
                
              }
          },
          err => 
          {
              console.log(err);
          }
      );
  }

  public changeViewCalendar(data)
  {
    this.view = data
    if('week' == data || 'day' == data)
    {
      $("html, body").animate({
          scrollTop: 700
      }, 2000);
    }
  }
}