import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {

	public projects:any = [];

	public pagesNumber:number = 20
	public search: string = '';
	public currentPage: number = 1;
	public pages: any = [];

	private userId: string = '';

	public companyName: string = '';
	public userName: string = '';


  	constructor(public service: ApirestService,
                private router: Router,
                private route: ActivatedRoute,
                private location: Location,
                private toastr: ToastrService,
                private singleton: SingletonService,
                private SessionService: SessionService) { }

  	ngOnInit() 
  	{

	  	//Function to get the source data
	    this.userId = this.route.snapshot.params.user_id;
	    let companyId = this.route.snapshot.params.company_id;

	    this.getCompany(companyId);
	    this.getUser();
	    this.getProjects('');
  	}

  	private getCompany(id)
    {
        let url = `company/${id}`;
        this.service.queryPost(url, new FormData).toPromise().then(
            response => {
                let result = response.json();
                this.companyName = result.name;
            },
            err => {

            }
        );
    }

    private getUser()
    {
    	let url = `user/${this.userId}`;
        this.service.queryGet(url).toPromise().then(
            response => {
                let result = response.json();
                this.userName = result.name
            },
            err => {

            }
        );
    }

  	public getProjects(page)
  	{
  		let url = `project?user_id=${this.userId}&rows=${this.pagesNumber}&search=${this.search}`;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
              let result = response.json(); 
              this.projects = result;
            },
            err => 
            {
                console.log(err);
            }
        );
  	}

  	public goToDetailsProject(id, status){
		  localStorage.setItem('show', status);
		  this.router.navigate(['/project/'+this.singleton.encrypt(id)+'/home']);
	  }

    public exportProject()
    {
      let url = `export-projects`;
      let body = new FormData;
      body.append('user_id', this.userId);
      body.append('search', this.search);
      this.service.queryPost(url, body).toPromise().then(
        response => {
          let result = response.json();
          window.open(result);
        },
        err => {

        }
      );
    }

}
