import { Component, OnInit } from "@angular/core";
import { ApirestService } from "../apirest.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgbCalendar, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SingletonService } from "../singleton.service";
import { SessionService } from "../session.service";
import * as $ from "jquery";
@Component({
  selector: "app-register-project",
  templateUrl: "./register-project.component.html",
  styleUrls: ["./register-project.component.scss"],
})
export class RegisterProjectComponent implements OnInit {
  //public departmentsList = [];
  public departmentSelected = "";
  public citiesList = [];

  public eventTypes = [];

  public newProject = {
    id: 0,
    date: null,
    department_id: 0,
    city_id: "",
    project_type_id: 0,
    name: "",
    color: "",
    image: "",
    other_type: "",
    customers: "",
    estimated_budget: "",
    description: "",
    place: "",
  };
  model: NgbDateStruct;
  date: { year: number; month: number };
  public isDisabled;
  public file: any;
  public filename = null;

  public personOne = "";
  public persontwo = "";
  public roleOne = "";
  public roleTwo = "";
  public emailOne = "";
  public emailTwo = "";

  public customerRoles = [];
  public projectId = 0;

  public disabledBnt = false;

  public validMinLengthName = false;
  public validRequiredName = false;
  public validMinLengthPlace = false;

  bsInlineValue = new Date();
  constructor(
    public service: ApirestService,
    public router: Router,
    public translate: TranslateService,
    public singleton: SingletonService,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public SessionService: SessionService
  ) {
    window.scroll(0, 0);
  }

  ngOnInit() {
    this.SessionService.validUserSession();
    //this.getDepartmentsList();
    this.getEventsTypes();
    this.getCustomerRoles();

    this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      if (params["edit"]) {
        this.projectId = +this.singleton.decrypt(params["edit"]) || 0;
      }
    });

    if (this.projectId != 0) {
      this.editEvent(this.projectId);
    }
  }

  editEvent(id) {
    let url = "project-show/" + id;
    this.service.queryGet(url).subscribe(
      (response) => {
        let result = response.json();
        this.newProject["id"] = result.id;
        //this.newProject['department_id'] = result.
        this.newProject["project_type_id"] = result.project_type_id;
        this.newProject["name"] = result.name_project;
        this.newProject["color"] = result.color;
        this.newProject["image"] = result.url_image_project;
        this.filename = result.url_image_project;
        //this.newProject['other_type'] = result.other_type;
        this.newProject["date"] = result.date_project;
        this.newProject["place"] = result.place_name;

        if (result.project_type_id == 2) {
          this.personOne = result.participants[0].name;
          this.roleOne = result.participants[0].role_name;
          this.persontwo = result.participants[1].name;
          this.roleTwo = result.participants[1].role_name;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /*getDepartmentsList() {
    let url = "get-department";
    this.service.queryGet(url).subscribe(
      (response) => {
        let result = response.json();
        this.departmentsList = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }*/

  getCustomerRoles() {
    let url = "get-roles-customer";
    this.service.queryGet(url).subscribe(
      (response) => {
        let result = response.json();
        this.customerRoles = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onDateSelect(event) {
    this.newProject["date"] =
      this.model.year + "-" + this.model.month + "-" + this.model.day;
  }

  changeDepartment() {
    let url = "get-cities/" + this.departmentSelected;
    this.service.queryGet(url).subscribe(
      (response) => {
        let result = response.json();
        this.citiesList = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getEventsTypes() {
    let url = "get-types-project";
    this.service.queryGet(url).subscribe(
      (response) => {
        let result = response.json();
        this.eventTypes = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //Function to create an event
  saveInfo() {
    this.validMinLengthName = false;
    this.validMinLengthPlace = false;
    this.validRequiredName = false;
    if (this.newProject.date == "" || this.newProject.date == null) {
      this.toastr.error("El campo fecha del evento es requerido");
      return;
    }

    if (this.newProject.project_type_id == 0) {
      this.toastr.error("El campo tipo de evento es requerido");
      return;
    }
    if (this.newProject.name == "") {
      this.toastr.error("El nombre del evento es requerido");
      this.validRequiredName = true;
      return;
    }
    if (4 > this.newProject.name.length) {
      this.toastr.error(
        "El nombre del evento tiene que tener mínimo 4 caracteres"
      );
      this.validMinLengthName = true;
      return;
    }

    if (0 < this.newProject.place.length && 4 > this.newProject.place.length) {
      this.toastr.error(
        "El lugar del evento tiene que tener mínimo 4 caracteres"
      );
      this.validMinLengthPlace = true;
      return;
    }
    if (this.newProject["project_type_id"] == 2) {
      if (this.roleOne == "" || this.roleTwo == "") {
        this.toastr.error("Los campos roles son requeridos");
        return;
      }

      if (this.personOne == "" || this.persontwo == "") {
        this.toastr.error("Los campos nombre son requeridos");
        return;
      }
    }

    let customers = [
      [this.personOne, this.roleOne],
      [this.persontwo, this.roleTwo],
    ];

    let body = new FormData();
    body.append("id", this.newProject.id + "");
    body.append("date", this.newProject.date);
    body.append("department_id", this.departmentSelected + "");
    body.append("city_id", this.newProject.city_id);
    body.append("place", this.newProject.place);
    body.append("project_type_id", this.newProject.project_type_id + "");
    body.append("name", this.newProject.name);
    body.append("color", this.newProject.color);
    body.append("image", this.file);

    if (this.newProject.project_type_id != 1) {
      body.append("customers", JSON.stringify(customers));
    }
    this.disabledBnt = true;
    this.service.queryPost("project", body).subscribe(
      (response) => {
        let result = response.json();

        if (result.success) {
          this.toastr.success(result.message);
          //this.router.navigate(['home']);
          let id = result.project.id;
          this.router.navigate([
            "/project/" + this.singleton.encrypt(id) + "/home",
          ]);
          this.disabledBnt = false;
        } else {
          let message = "";
          if (result.message.length > 0) {
            result.message.forEach(function (element) {
              message += element + "<br>";
            });
          }
          this.toastr.error(message, "Error", { enableHtml: true });
          this.disabledBnt = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  uploadImage(inputid) {
    document.getElementById(inputid).click();
  }

  getFiles(event, type) {
    const files: FileList = event.target.files;
    this.file = files[0];
    //this.file = event.target.files;

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url
    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.filename = event.target["result"];
    };
  }
}
