import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlockedService {

	@Output() openModal: EventEmitter<boolean> = new EventEmitter();

  	constructor() {
	    this.openModal.emit(false);
	}

	setOpenValue(data: boolean)
	{
		this.openModal.emit(data);
	}
}
