import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { SingletonService } from './singleton.service';
@Injectable({
  providedIn: 'root'
})
export class ClientGuardService implements CanActivate{

  constructor(
    private singleton: SingletonService,
    private router: Router) 
  { 

  }

  canActivate(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(user){
      if(user['rol_id'] == 0){
        this.router.navigate(['/project/'+this.singleton.encrypt(user['project_id'])+'/home']);
        return false
      }else{
  			return true;
  		}
    }
    this.router.navigate(['/']);
    return false;
	}
}
