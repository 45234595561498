import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-check-list-project',
  templateUrl: './check-list-project.component.html',
  styleUrls: ['./check-list-project.component.scss']
})
export class CheckListProjectComponent implements OnInit {
 
    public project = 0;
    private projectIdEncry = '';

    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;

    public typeDelete = "";
    public toDelete = 0;
    public pagesNumber = 10; //Default value
    public search = '';
    public pages = [];
    public currentPage = 1;

    public newAssociation = {id: 0, project_id: 0, template_id: 0, template_type_id: 0, name: "", description: ""};
    public projectTemplate = {id: 0, name: '', description: '', project_id: '', template_id: '', template_type_id: '', category: [], category_id: 0};
    public newItem = {name: ''};
    public checkListTemplates = [];
    public templateSelected = "";
    public templateDescription = "";
    public templateData =  [];

    public showSelectTemplates = false;
    public participantsList = [];
    public typeCheckList = 1; //Check list from template, 1 without template

    public categoriesList = [];
    public categoryInput:any = [];
    public showInputRow = false;
    public activeTask = null;

    public showType = 'date' //type to show on date input

    public restrictionsId = null;
    public restrictionsModalTitle = "";
    public disabledbtn = false;
    public restrictionsData = [];
    public checkAllRestrictions = false;

    public groupEdit = -1;
    public posToEdit = -1;
    public idToEdit = -1;
    public visualization = 1; //0->table, 1->monts, 2->responsable, 3->category


    public restrictionsWP = true;
    public restrictionsClientsShow = false;
    public restrictionsClientsEdit = false;

    public userSession = null;

    public archiveProject = false;
    public openGroup = null;
    

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private dataService:DataServiceService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }

  ngOnInit() {
      this.SessionService.validUserSession();
      let id = localStorage.getItem('project');
      this.projectIdEncry = id;
      this.project = this.singleton.decrypt(id);
      this.singleton.getProjectData(this.project);
      this.getChecklistTemplates();
      this.validateTemplateProject();
      this.getParticipants();
      this.getCategories();
      let user = JSON.parse(localStorage.getItem('user'));
      this.userSession = user;
      if(localStorage.getItem('show') == '0'){
        this.archiveProject = true;
      }
  }

  getCategories(){
    let user = JSON.parse(localStorage.getItem('user'));
    this.userSession = user;
    let url = 'categories?companyId='+user['company_id'];
      
    this.service.queryGet(url).subscribe(
        response=>
        {      
            let result = response.json(); 
            this.categoriesList = [];
            let data = result.data;
            for(let i = 0; i < data.length; i++){
               let temp = {value: data[i].id, display: data[i].name};
               this.categoriesList.push(temp);
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }


  removeCategoryInput(event, id){
   
  }

  addCategoryInput(event, group, item){
    console.log(event, group, item);
    if(event.value != event.display){
     //add category to item
      this.updatedata(group, item, 0, event.value);
    }else{
      this.addCategory(group, item, event.value);
    }
  }


  addCategory(group, item, name){
    let user = JSON.parse(localStorage.getItem('user'));
    let company = user['company_id'];
    var body = new FormData();
    body.append('id', '0');
    body.append('company_id', company+'')
    body.append('name', name+'')

    this.service.queryPost('categories', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        
        if(result.success)
        {
          this.getCategories();

          let category = result.category;
          this.updatedata(group, item, 0, category.id);
        }
        else
        {
          let message = '';
          if(result.message.length > 0)
          {
            result.message.forEach(function(element) {
              message += element+'<br>';
            });
          }

          this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }
      },
      err => 
      {
        console.log(err);
      }
    );
  }


  getParticipants(){
    let url = "get-responsible/"+this.project;
    this.service.queryGet(url).subscribe(
      response=>
      {      
          let result = response.json();

          this.participantsList = result;

      },
      err => 
      {
          console.log(err);
      }
    );
  }

  validateTemplateProject(){
    this.openGroup = null;
    let url = "project/template?projectId="+this.project+"&templateType=3";

		this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json();

              if(result.data.length == 0){
                this.showSelectTemplates = true;
              }else{
                this.getChecklistItemsById(result.data[0].id);
              }
              
          },
          err => 
          {
              console.log(err);
          }
      );
  }

  getChecklistItemsById(id){
		this.service.queryGet('project/template/'+id+'?group='+this.visualization).subscribe(
          response=>
          {      
              let result = response.json();
              
              this.projectTemplate['id'] = result.id
              this.projectTemplate['name'] = result.name
              this.projectTemplate['description'] = result.description
              this.projectTemplate['project_id'] = result.project_id
              this.projectTemplate['template_id'] = result.template_id
              this.projectTemplate['template_type_id'] = result.template_type_id

              this.projectTemplate['category_id'] = result.category_id;

              let tasks = result.tasks;
              
              if(tasks.length > 0){
                
                for(let i = 0; i < tasks.length; i++){
                  tasks[i]['showItems'] = 1;
                  let itemsTasks = tasks[i].task;

                  if(itemsTasks.length > 0){
                    for(let j = 0; j < itemsTasks.length; j++){
                      itemsTasks[j]['category'] = [];
                      
                      for(let k = 0; k < this.categoriesList.length; k++){
                        if(itemsTasks[j]['category_id'] == this.categoriesList[k].value){
                          itemsTasks[j]['category'].push({display: this.categoriesList[k].display, value: this.categoriesList[k].value});
                        }
                      }
                      
                      if(itemsTasks[j]['done'] == 1){
                        itemsTasks[j]['check_user'] = true;
                      }else{
                        itemsTasks[j]['check_user'] = false;
                      }

                      if(itemsTasks[j]['months_before'] != null){
                        itemsTasks[j]['showType'] = 'select';
                      }else{
                        itemsTasks[j]['showType'] = 'date';
                      }

                    }
                  }
                }
                this.templateData = tasks;
              }else{
                this.templateData = result.tasks;
              }
          },
          err => 
          {
              console.log(err);
          }
      );
  }

  getChecklistTemplates(){

		let url = "template?templateType=3&rows=100";

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
               
                this.checkListTemplates = result.templates.data;
            },
            err => 
            {
                console.log(err);
            }
        );
  }

    addItem(id, group){

        if(this.newItem.name == '' || this.newItem.name == null){
          this.showInputRow = false;
          
        } else {

            let body = new FormData();
            body.append('id', id+'');
            body.append('project_template_id', this.projectTemplate['id']+'');
            body.append('name', this.newItem.name+'');
            body.append('group', group);
            body.append('checked_by_client',0+'');
            body.append('done', 0+'');

            let url = "project/checklist/task";

            this.service.queryPost(url, body).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    this.newItem = {name: ''};
                    this.showInputRow = !this.showInputRow;
                    this.activeTask = null;
                    if(result.success)
                    {
                        //Show success message
                        this.getChecklistItemsById(this.projectTemplate['id']);
                        this.idToEdit = result.projectChecklistTask.id;
                        this.groupEdit = group;
                        this.posToEdit = result.projectChecklistTask;
                        //this.cancelEditLine();
                    }
                    else
                    {
                    this.toastr.error(result.message);
                    }
                },
                err => 
                {
                  console.log(err);
                }
            );
        }
    }

  changeSelect(){
    if(this.templateSelected != ""){
      this.service.queryGet('template/'+this.templateSelected+'?type=3').subscribe(
          response=>
          {      
              let result = response.json(); 
              this.templateData = result.tasks;
              this.templateDescription = result.description;
          },
          err => 
          {
              console.log(err);
          }
      );
    }else{
      this.templateData = [];
    }
    
  }

  addTemplateProject(){

    if(1 == this.typeCheckList && "" == this.templateSelected){
      this.toastr.error('Debe seleccionar una plantilla para crear la planeación');
      return;
    }
    //We need to get input select data to send 
    let name = "";
    let description = "";

    if(this.typeCheckList == 1){
      for(let i = 0; i < this.checkListTemplates.length; i++){
        name = this.checkListTemplates[i].name;
        description = this.checkListTemplates[i].description;
      }
    }else{
      name = this.newAssociation['name'];
    }

    if(name == "" && this.typeCheckList == 0){
      name = "-";
    }
    
    let body = new FormData();
    body.append('id', this.newAssociation['id']+'');
    body.append('project_id', this.project+'');
    if(this.typeCheckList != 0){
      body.append('template_id', this.templateSelected);
      body.append('template_description', this.templateDescription);
    }
    body.append('template_type_id', '3');
    body.append('name', name);
    body.append('description', description);

    let url = "project/template";

    this.service.queryPost(url, body).subscribe(
      response=>
      {      
        let result = response.json(); 
        
        if(result.success)
        {
            //Show success message
            this.toastr.success(result.message);
            this.templateData = result.dataTemplate;
            this.projectTemplate['id'] = result.projectTemplate.id
            this.projectTemplate['name'] = result.projectTemplate.name
            this.projectTemplate['description'] = result.projectTemplate.description
            this.projectTemplate['project_id'] = result.projectTemplate.project_id
            this.projectTemplate['template_id'] = result.projectTemplate.template_id
            this.projectTemplate['template_type_id'] = result.projectTemplate.template_type_id
            this.showSelectTemplates = false;
            
            this.modalRef.hide();
            this.validateTemplateProject();
        }
        else
        {
          let message = '';
         /*  if(result.message.length > 0)
          {
            result.message.forEach(function(element) {
              message += element+'<br>';
            });
          } */

          this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }
      },
      err => 
      {
        console.log(err);
      }
    );

  }


  changedata(id, category_id = null, event = null){

    //get data from task array
    let data = null;

    for (let i = 0; i < this.templateData.length; i++) {
      if(this.templateData[i].id == id){
        data = this.templateData[i];
        break;
      }
    }

    if(data != null){
      let body = new FormData();

      body.append('id', data.id+'');
      body.append('project_template_id', this.projectTemplate['id']+'');

      if(data.id_responsible !=  null){
        body.append('responsible_id', data.id_responsible+'');
      }


      if(category_id != null){
        body.append('category_id', category_id+'');
      }

      let date = "";

      if(data.date != undefined || data.date != null){
        date = data.date;
      }

      if(data.months_before != 'null' && data.months_before != null){
        body.append('months_before', data.months_before);
      }

      if(data.comments != undefined || data.comments != null){
        body.append('comments', data.comments+'');
      }

      body.append('name', data.name+'');
     // body.append('order', data.order+'');
      body.append('date', date+'');

      
      let checked = 0;
      let date_checked = null;

      if(data.check_user){
        checked = 1;
        date_checked = moment().format('YYYY-MM-DD');

      }

      body.append('done', checked+'');

      if(date_checked !=  null){
        body.append('done_date', date_checked+'');
      }
      

      let url = "project/checklist/task";

      this.service.queryPost(url, body).subscribe(
        response=>
        {      
          let result = response.json(); 

          if(result.success)
          {
              //Show success message
              //this.toastr.success(result.message);
              if(event == 'enter'){ 
                this.getChecklistItemsById(this.projectTemplate['id']);
              }
          }
          else
          {

          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }
    
  }

  updatedata(group, item, close, category_id = null, event = null){
    let data = null;
    data = this.templateData[group].task[item];
    this.saveRestriction(data.type_restriction, data.id)
    this.openGroup = group;
    if(data){
      let body = new FormData();

      body.append('id', data.id+'');
      body.append('project_template_id', this.projectTemplate['id']+'');

      if(data.id_responsible !=  null){
        body.append('responsible_id', data.id_responsible+'');
      }


      if(category_id != null){
        body.append('category_id', category_id+'');
      }

      let date = "";
      

      if(data.showType == 'date'){
        date = data.date;
        body.append('months_before', null);
      }else{
        body.append('months_before', data.months_before); 
      }

      /* if(data.months_before != 'null' && data.months_before != null){
        
          body.append('months_before', data.months_before);
        
      } */

      if(data.comments != undefined || data.comments != null){
        body.append('comments', data.comments+'');
      }

      body.append('name', data.name+'');
     // body.append('order', data.order+'');
      body.append('date', date+'');

      
      let checked = 0;
      let date_checked = null;

      if(data.check_user){
        checked = 1;
        date_checked = moment().format('YYYY-MM-DD');
      }

      body.append('done', checked+'');

      if(date_checked !=  null){
        body.append('done_date', date_checked+'');
      }

      let url = "project/checklist/task";

      this.service.queryPost(url, body).subscribe(
        response=>
        {      
          let result = response.json(); 

          if(result.success)
          {
              //Show success message
              
              if(event == 'enter'){ 
                this.getChecklistItemsById(this.projectTemplate['id']);
                this.groupEdit = -1;
                this.posToEdit = -1;
                this.idToEdit = -1;
                if(1 == close){
                  this.cancelEditLine();
                }

              }
          }
          else
          {
            this.toastr.error(result.message);
          }
        },
        err => 
        {
          console.log(err);
        }
      );
    }
  }


  openModalRegisterCheckListTemplate(template){
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    document.getElementById('template').focus();
  }

  openRestrictionsModal(template, id, name, type_restriction){
    this.restrictionsId = id;
    this.restrictionsModalTitle = name;
    
    switch(type_restriction){
      case 1:
        this.restrictionsWP = true;
        this.restrictionsClientsEdit = false;
        this.restrictionsClientsShow = false;
      break;
      case 2:
          this.restrictionsWP = false;
          this.restrictionsClientsEdit = false;
          this.restrictionsClientsShow = true;
      break;
      case 3:
          this.restrictionsWP = false;
          this.restrictionsClientsEdit = true;
          this.restrictionsClientsShow = false;
      break;
      default:
          this.restrictionsWP = false;
          this.restrictionsClientsEdit = false;
          this.restrictionsClientsShow = false;
        break;
    }
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });    
  }


  confirmDelete(template, id, type){
    
    this.toDelete = id;
    this.typeDelete = type;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = null;
    this.typeDelete = "";
  }

  closeModal(){
    this.templateSelected = "";
    if(this.modalRef){
      this.modalRef.hide();
    }
    this.templateDescription = "";
    this.restrictionsId = null;
    this.restrictionsModalTitle = "";
  }

  deleteElement(){

    let url = "";
    if(this.typeDelete == 'task'){
      url = "project/checklist/task/"+this.toDelete;
    }

    if(this.typeDelete == 'checklist'){
     url = "project/checklist/"+this.toDelete;
    }
      this.service.queryDelete(url).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                if(this.typeDelete == 'task'){
                  this.getChecklistItemsById(this.projectTemplate['id']);
                }else{
                  this.validateTemplateProject();
                  this.templateData = [];
                  this.toastr.success(result.message);
                }
                this.modalRefDelete.hide();
                this.toDelete = null;
                
            }
        },
        err => 
        {
            console.log(err);
        }
      );
  }

  export(id,type){
    /**
     * 
     * when I want to export check list, note, schedule and budget I need to send "1"
     * with these a send id as a project_template_id, when is documenta send project_document_id and 
     * other I send project_id
     */

    let report_type = 1 
    let temp = {id: id, type: type, report_type: report_type};
    this.dataService.changeModal(temp);
  }

  public projectData = {id: 0, department_id: 0, city_id: 0, color: '', date: '', image:'', name:'', name_project_type: '',  other_type_name:'',project_type_id: 0, user_id: 0, url_image_project: ''};

  previewfunc(id){

    this.dataService.currentProject.subscribe(project => {
      if(null != project){
          this.projectData['name'] = project.name_project;
          this.projectData['name_project_type'] = project.name_project_type; 
          this.projectData['url_image_project'] = project.url_image_project;
        }
    });

    var title = this.projectData['name'];
    var title_html = '<title>planeación-'+title+'</title>';

    var prtContent = document.getElementById(id);
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(title_html);
    WinPrint.document.write('<style >body{font-family: Roboto, sans-serif; font-size: 12px;} .title{font-weight: 700;padding: 25px;font-size: 2em;color: #A3777E;} .table{font-size: 12px} .add-items {display:none} .btn-icon {display:none} .icon {display:none} body {font-family: Robot,sans-serif;font-size: 12px} .header-groups { background-color:  #f2f2f2; font-weight: 700; padding: 9px; border-bottom: 2px solid #f2f2f2;} .table thead th { vertical-align: bottom; border-bottom: 2px solid #F1C1B0;color: #FFF;background-color: #F1C1B0;} input {display: none;} td {padding: 3px;} footer {font-style: italic;color: #AAB7A0;margin-top: 15px;}</style>');
    WinPrint.document.write('<div class="title">'+title+'</div>');

    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write('<footer>Generado por Confetti Planner - confettiplanner.com - Registra tus eventos y comparte</footer>');
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();

   /* let body = new FormData();
    body.append('id', id+'');
    body.append('type', 'pdf');
    body.append('report_type', 1+'');
    body.append('view', 1+'');

    this.service.queryPost('project/exports', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        if(result.success){
          debugger;
            let pdf = result.url;
            this.singleton.pdf = result.url;
            this.router.navigate([`/project/${this.projectIdEncry}/preview`]);
        }
                
      },
      err => 
      {
        console.log(err);
      }
    );  */
  }


  async showInputRowFunc(position){
    this.showInputRow = true;
    this.activeTask = position;
    await this.singleton.sleep(200);
    let input = 'newItemName' + position;
    document.getElementById(input).focus();
  }

  checkAll(){
    if(this.checkAllRestrictions){
      for(let i = 0; i < this.restrictionsData.length; i++){
         this.restrictionsData[i].can_view = true;
      }
    }else{
      for(let i = 0; i < this.restrictionsData.length; i++){
        this.restrictionsData[i].can_view = false;
     }
    }
  }

    saveRestriction(type_restriction, id){
        this.restrictionsId = id;

        let data = {
                  "project_id": this.project, 
                  "project_checklist_task_id": this.restrictionsId, 
                  "type": type_restriction
                }
        
        let body = new FormData();
        body.append('data_restrictions', JSON.stringify(data)+'');

        let url = "project/restriction";

        this.service.queryPost(url, body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {  
                //this.cancelEditLine();
                //this.validateTemplateProject();
                //this.closeModal();
                //Show success message
                //this.toastr.success(result.message);
            }
            else
            {

            }
          },
          err => 
          {
            console.log(err);
          }
        );
    }

   /* addRestrictionitem(type_restriction, id){
        this.restrictionsId = id;
        /*let type_restriction = null;
        if(this.restrictionsWP){
          type_restriction =  1;
        }

        if(this.restrictionsClientsShow){
          type_restriction =  2;
        }

        if(this.restrictionsClientsEdit){
          type_restriction =  3;
        }*/
        
        /*let data = {
                      "project_id": this.project, 
                      "project_checklist_task_id": this.restrictionsId, 
                      "type": type_restriction
                    }
        
        let body = new FormData();
        body.append('data_restrictions', JSON.stringify(data)+'');

        let url = "project/restriction";

        this.service.queryPost(url, body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {  
                this.cancelEditLine();
                //this.validateTemplateProject();
                //this.closeModal();
                //Show success message
                this.toastr.success(result.message);
                this.restrictionsClientsEdit = false;
                this.restrictionsWP = false;
                this.restrictionsClientsShow = false;
                
            }
            else
            {

            }
          },
          err => 
          {
            console.log(err);
          }
        );
    }*/

    addRestrictionitem(newState, group, subgroup){
        this.templateData[group].task[subgroup].type_restriction = newState;
        if(1 == newState){
          this.toastr.success('Visible sólo a planners');
        } else if(3 == newState){
          this.toastr.success('Visible para todos');
        }
    }  

  editline(group, item, restriction){
    if(this.userSession['rol_id'] != 0 && !this.archiveProject){
      this.groupEdit = group;
      this.posToEdit = item;

      this.idToEdit = this.templateData[group].task[item].id;
    }
    
  }

  cancelEditLine(){
    this.groupEdit = -1;
    this.posToEdit = -1;
    this.idToEdit = -1;
  }


  validateRestrictions(type){
    switch (type) {
        case 'wp':
            this.restrictionsWP = this.restrictionsWP;
            this.restrictionsClientsEdit = false;
            this.restrictionsClientsShow = false;
            break;
        case 'cos':
            this.restrictionsWP = false;
            this.restrictionsClientsEdit = false;
            this.restrictionsClientsShow = this.restrictionsClientsShow;
            break;
        case 'cce':
            this.restrictionsWP = false;
            this.restrictionsClientsEdit = this.restrictionsClientsEdit;
            this.restrictionsClientsShow = false;
            break;
        default:
            break;
    }
  }

  async viewCalendar(i, j){
    let id = "calendar"+j;
    this.templateData[i].task[j].showType = 'date';
    await this.singleton.sleep(500);
    document.getElementById(id).click();
   
  }

    openAuxGroup(position){
      let currentShow = this.templateData[position]['showItems'];
      if(0 == currentShow || undefined == currentShow){
        this.templateData[position].showItems = 1;
      } else {
        this.templateData[position].showItems = 0;; 
      }
      this.openGroup = null;
        /*if(position == this.openGroup){
        } else {

            this.openGroup = position;
        }*/
    }

    goToTemplates(){
      this.closeModal();
      this.router.navigate([`/templates`]);
    }
}
