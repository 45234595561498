import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SingletonService } from '../singleton.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    modalRef: BsModalRef | null;
    modalRefAuthorization: BsModalRef;
    public user = {email: '', password: ''};
    public company = {id: 0, company_name: '', city: '', city_name:'', phone: '',name: '', email: '', password: '', confirmPassword: '', terms: null};
    public viewRegister = false;
    /*public viewDeparment =  true;*/
    public planId = null;

    public countriesList = [];
    public departmentsList = [];
    public departmentSelected = "";
    public countrySelected = "42";
    public citiesList = [];
    public companyData = null;

    public email = null;

    public company_id = null;
    public rol_id = null;
    public project_id = null

    public showRecover = false;

    public valdateResult = false;
    public textValidate = '';
    public positionUl = '';

  	constructor(public service: ApirestService,
                  private router: Router,
                  private route: ActivatedRoute,
                  private singleton: SingletonService,
                  private modalService: BsModalService,
                  private toastr: ToastrService) 
  	{ 

  	}

  	ngOnInit() 
  	{

  		if('null' != localStorage.getItem('token') && null != localStorage.getItem('token') && undefined != localStorage.getItem('token'))
  		{
  			this.router.navigate(['/home']);
        }
        this.viewRegister = this.route.snapshot.queryParams['register'];
        if(null != this.route.snapshot.queryParams['email'])
        {
            this.user.email = this.route.snapshot.queryParams['email'];
            this.router.navigate(['login'], {queryParams:{}});
        }
        this.getDepartmentsList();
        this.getCountryList();

  	}



    getCompanyData(id){
        let body = new FormData();

        this.service.queryPost('company/'+ id, body).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.companyData = result;
            },
            err => 
            {
                
            }
        ); 
    }

    getCountryList(){
        let url = 'get-countries'
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.countriesList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
        
    }

    getDepartmentsList(){
        console.log("test");
        
        let url = 'get-department/'+this.countrySelected;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.departmentsList = result;
            },
            err => 
            {
                
            }
        );
        
    }

    changeDepartment(){

        let url = 'get-cities/'+this.departmentSelected;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.citiesList = result;
            },
            err => 
            {
                
            }
        );
    }

    changeCountry()
    {
        console.info("get departments");
        let url = 'get-department/'+this.countrySelected;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.departmentsList = result;
                //this.changeDepartment();
            },
            err => 
            {
                console.log(err);
            }
        );
     /*   this.viewDeparment = true;   
        if("42" != this.countrySelected)
        {
            this.viewDeparment = false;
        }*/
    }


    registerCompany(){
        /*if(this.company.terms != true){
            this.toastr.error("Debes aceptar terminos y condiciones", 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        }*/
        if(this.modalRefAuthorization){
            this.modalRefAuthorization.hide();
        }

        document.getElementById("save-company").setAttribute("disabled","disabled");
        let body = new FormData;
        body.append('company_name', this.company.company_name);
        body.append('country_id', this.countrySelected);
        body.append('department_id', this.departmentSelected);
        body.append('city_id', this.company.city);
        body.append('city_name', this.company.city_name);
        
        body.append('user_name', this.company.name);
        body.append('email', this.company.email);
        body.append('password', this.company.password);
        
        this.service.queryPostRegular('signup', body).subscribe(
            response=>
            {      
                let result = response.json(); 
               
                if(result.status == "success") 
                {
                    // this.user.email = result.data.email;
                    // this.user.password = this.company.password;
                    // this.login();
                    this.viewRegister = false;
                    this.toastr.success('El registro se ha completado correctamente, te hemos enviado un correo electrónico para confirmar tu cuenta');
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
                document.getElementById("save-company").removeAttribute("disabled");
            },
            err => 
            {
                document.getElementById("save-company").removeAttribute("disabled");
            }
        ); 
    }

  	login()
  	{
        if('' === this.user.email && '' === this.user.password){
            this.toastr.error('Debes ingresar un correo electrónico y contraseña','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        } else if('' === this.user.email){
            this.toastr.error('Debes ingresar un correo electrónico','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        } else if('' === this.user.password){
            this.toastr.error('Debes ingresar una contraseña','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        }
        if(!this.validate(this.user.email))
        {
            return;
        }
        document.getElementById("login").setAttribute("disabled","disabled");
  		let body = new FormData;
  		body.append('email', this.user.email);
  		body.append('password', this.user.password);

  		this.service.queryPostRegular('login', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.success) 
                {
                    let token = 'Bearer '+ result.token ; 
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(result.user));
                    console.log(result.user);
                    if(result.user.project_id){
                        this.router.navigate(['/project/'+this.singleton.encrypt(result.user.project_id)+'/home']);
                        // console.log(['/project/'+this.singleton.encrypt(result.user.project_id)+'/home']);
                    }else{
                       this.router.navigate(['/home']); 
                    }
                }
                else
                {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                 	
                }
                document.getElementById("login").removeAttribute("disabled");
            },
            err => 
            {
            }
        );
  	}


    showRecoverPassword(){
        this.user = {email: '', password: ''};
        this.showRecover = true;
        this.viewRegister = false;
    }

    cancelRecover(){
        this.user = {email: '', password: ''};
        this.showRecover = false;
        this.viewRegister = false;
    }

    recover(){
        if('' === this.user.email){
            this.toastr.error('Debes ingresar un correo electrónico','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        }
        if(!this.validate(this.user.email))
        {
            return;
        }
        document.getElementById("recover").setAttribute("disabled","disabled");
        let body = new FormData;
        body.append('email', this.user.email);
      
        this.service.queryPostRegular('recover-password', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.success) 
                {
                    //Show success message
                    this.toastr.success(result.message);

                    this.cancelRecover();
                }
                else
                {             
                    this.toastr.error(result.message);
                }
                document.getElementById("recover").removeAttribute("disabled");
            },
            err => 
            {
                document.getElementById("recover").removeAttribute("disabled");
            }
        );
    }

    OpenModal(template){
      this.modalRef = this.modalService.show(template, { class: 'modal-lg',ignoreBackdropClick: true  });
    } 

    closeModal()
    {
        if(this.modalRef){
            this.modalRef.hide();
        }
    }

    openAuthorization(template){
        this.modalRefAuthorization = this.modalService.show(template, {class: 'modal-lg', ignoreBackdropClick: true});
    }
    /**
     * Funcion para validar campos de tipo correo
     *
     * @param email
     * @return boolean
     */
    private validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * Funcion para validar campos de tipo correo
     *
     * @param email
     * @return boolean
     */
    public validate(email) {
        this.textValidate = '';
        this.valdateResult = this.validateEmail(email);
        if (!this.valdateResult) {
            this.textValidate = 'El correo ingresado no es valido.';
            document.getElementById("text-validate").style.color = "red";
        }
        return this.valdateResult;
    }

    openMenuR(){
    if('' == this.positionUl){
        this.positionUl = 'show-menu';
    } else{
        this.positionUl = '';
    }
    }
}
