import { Injectable } from '@angular/core';
import { ApirestService } from './apirest.service';
import { DataServiceService } from './data-service.service';
import { Router } from '@angular/router';
import Base64UploaderPlugin from './@ckeditor/base64upload';
import * as CryptoJS from 'crypto-js';
import { environment } from '../environments/environment';
// import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';// import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// import UnderlineUI from './@ckeditor/underline';
// import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// import es from '../../../node_module/@ckeditor/ckeditor5-build-classic/build/translations/es'
let SECRET_KEY = '';


@Injectable({
  providedIn: 'root'
})
export class SingletonService {
  public sessionUser = {id:0};
  public editEventId = 0;
  public permissions = [];
  public editTemplate = 0;
  public typeTemplate = 0;
  public apiUrl: string = 'http://confetti.local/api/v1/';

  private static $Key = "c0nff3t!@c!14c0";

  public configCkEditor = {
            language: 'es',
            extraPlugins: [ Base64UploaderPlugin],
            removePlugins: ['FontFamily'],
            bodyClass: 'document-editor',
  };

  public configCkEditorAuxiliar = {
    scayt_sLang: 'es_ES',
    wsc_lang : 'es_ES',
    disableNativeSpellChecker: false,
    scayt_autoStartup: true,
    tabSpaces: 4,
    toolbarGroups:[ 
      { name: 'paragraph', groups: ['indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    ],
    bodyClass: 'document-editor',
    contentsCss: [ 'https://cdn.ckeditor.com/4.8.0/full-all/contents.css'],
    removeButtons: 'list, doctools, mode, undo,aboutothers,tools,links,forms,document,colors,insert,clipboard,Source,Save,Templates,Preview,NewPage,Print,PasteText,PasteFromWord,Undo,Redo,Replace,Find,SelectAll,Form,Checkbox,Radio,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,Outdent,Blockquote,CreateDiv,Language,BidiRtl,BidiLtr,Link,Unlink,Anchor,Flash,Smiley,PageBreak,Iframe,HorizontalRule,ShowBlocks,Maximize,About,SpecialChar,RemoveFormat'
  };

  public pdf = null;

  constructor(public service: ApirestService,
              private dataService: DataServiceService,
              private router: Router
              ) 
  { 
    SECRET_KEY = environment.secret_key;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Returns the pages to render in pagination
   * @param array result
   */
  pagination(result)
  {
  	let currentPage = result['current_page'];
  	//let first_page_url = result['first_page_url'];
  	let lastPage = result['last_page'];

  	let pages = [];
  	let from = 1;
  	let to = lastPage;

      if(result['data'].length > 0)
      {
    		pages.push(['Primera', 1, '']);

    		if(currentPage == 1)
    		{
    			pages.push(['&lt;', 1, '']);
    		}
    		else if(currentPage > 1)
    		{
    			pages.push(['&lt;', currentPage - 1, '']);

    			if(currentPage > 3)
    			{
    				from = currentPage - 2;
    			}
    		}

    		if(from + 5 > lastPage)
    		{
    			to = lastPage;
    		}
    		else
    		{
    			to = from + 5;
    		}

    		for (var i = from; i <= to; ++i) 
    		{
    			if(i == currentPage)
    			{
    				pages.push([i+'', i, 'active']);
    			}
    			else
    			{
    				pages.push([i+'', i, '']);
    			}
    		}

    		if(currentPage + 1 < lastPage)
    		{
    			pages.push(['&gt;', currentPage + 1, '']);
    		}
    		else
    		{
    			pages.push(['&gt;', lastPage, '']);
    		}

    		pages.push(['Última', lastPage, '']);
      }

  	return pages;
  }

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   * 
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  b64toBlob(b64Data, contentType, sliceSize = 512) 
  {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);

          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
  }
	

  //The set method is use for encrypt the value.
  encrypt(data) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);
    return ciphertext.toString().replace("/", "&&");
  }

  //The get method is use for decrypt the value.
  decrypt(data) {
    const bytes = CryptoJS.AES.decrypt(data.toString().replace("&&","/"), SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

	getSession(){
		this.sessionUser = JSON.parse(localStorage.getItem('user'));
		this.service.queryGet('permissions-user/'+this.sessionUser.id).subscribe(
			response=>
			{      
				let result = response.json();
        this.permissions = result.permissions;
			},
			err => 
			{
				console.log(err);
			}
		);
	}

  validateSession(){

    this.service.queryPost('me', '').subscribe(
      response=>
        {      
           /* localStorage.setItem('user', null);
            localStorage.setItem('token', null);*/
        },
        err => 
        {
            if(err['status'] == 401)
            {
                localStorage.setItem('user', null);
                localStorage.setItem('token', null);
                localStorage.setItem('project', null);
                localStorage.destroy();

                this.router.navigate(['/']);
            }
        }
    );
  }

  redirectNotification(link, type){

    let url = "";
    switch (type) {
      case "Calendario":
        url = "project/"+this.encrypt(parseInt(link))+"/calendar"
        localStorage.setItem('project', this.encrypt(parseInt(link)));

        this.router.navigate([url]);
        break;

      case "Tarea":
        let idsTask = link.split(',');
        url = "project/"+this.encrypt(parseInt(idsTask[0]))+"/check"
        localStorage.setItem('project', this.encrypt(parseInt(idsTask[0])));
        this.router.navigate([url]);
        break;

      case "Actividad realizada":
        let idsTasktwo = link.split(',');
        url = "project/"+this.encrypt(parseInt(idsTasktwo[0]))+"/check"
        localStorage.setItem('project', this.encrypt(parseInt(idsTasktwo[0])));
        this.router.navigate([url]);
        break;

      case "Actividad por realizar":
        let idsTasktree = link.split(',');
        url = "project/"+this.encrypt(parseInt(idsTasktree[0]))+"/check"
        localStorage.setItem('project', this.encrypt(parseInt(idsTasktree[0])));
        this.router.navigate([url]);
        break;

      case "Asignacion responsable":
        let responsible = link.split(',');
        url = "project/"+this.encrypt(parseInt(responsible[0]))+"/check"
        localStorage.setItem('project', this.encrypt(parseInt(responsible[0])));
        this.router.navigate([url]);
        break;

      case "Pago Pendiente Proveedor":
        let idsbudget = link.split(',');
        url = "project/"+this.encrypt(parseInt(idsbudget[0]))+"/providers"
        localStorage.setItem('project', this.encrypt(parseInt(idsbudget[0])));
        let provider = this.encrypt(parseInt(idsbudget[1]));
        this.router.navigate([url],  { queryParams: {view: provider}});
        break;

      case "Pago realizado Proveedor":
        let idsbudgetcheck = link.split(',');
        url = "project/"+this.encrypt(parseInt(idsbudgetcheck[0]))+"/providers"
        localStorage.setItem('project', this.encrypt(parseInt(idsbudgetcheck[0])));
        let providerpaymentcheck = this.encrypt(parseInt(idsbudgetcheck[1]));
        this.router.navigate([url],  { queryParams: {view: providerpaymentcheck}});
        break;
        
      case "Comentarios a proveedor":
        let idCommentsProvider = link.split(',');
        url = "project/"+this.encrypt(parseInt(idCommentsProvider[0]))+"/providers"
        localStorage.setItem('project', this.encrypt(parseInt(idCommentsProvider[0])));
        let providerComments = this.encrypt(parseInt(idCommentsProvider[1]));
        this.router.navigate([url],  { queryParams: {view: providerComments}});
        break;
      
      case "Adicionar proveedor":
        let addProvider = link.split(',');
        url = "project/"+this.encrypt(parseInt(addProvider[0]))+"/providers"
        localStorage.setItem('project', this.encrypt(parseInt(addProvider[0])));
        let provideradd = this.encrypt(parseInt(addProvider[1]));
        this.router.navigate([url],  { queryParams: {view: provideradd}});
        break;

      case "Cargar archivo a proveedor":
        let addProviderFile = link.split(',');
        url = "project/"+this.encrypt(parseInt(addProviderFile[0]))+"/providers"
        localStorage.setItem('project', this.encrypt(parseInt(addProviderFile[0])));
        let providerFile = this.encrypt(parseInt(addProviderFile[1]));
        this.router.navigate([url],  { queryParams: {view: providerFile}});
        break;

      case "Actividad Cronograma":
        let idsSchedule = link.split(',');
        url = "project/"+this.encrypt(parseInt(idsSchedule[0]))+"/schedule";
        localStorage.setItem('project', this.encrypt(parseInt(idsSchedule[0])));
        let data = this.encrypt(parseInt(idsSchedule[1]));
        this.router.navigate([url], { queryParams: {view: data} });
        break;

      case "Evento":
        url = "project/"+this.encrypt(parseInt(link))+"/home"
        localStorage.setItem('project', this.encrypt(parseInt(link)));
        this.router.navigate([url]);
        break;
      
      case "Agendo evento":
        url = "project/"+this.encrypt(parseInt(link))+"/calendar"
        localStorage.setItem('project', this.encrypt(parseInt(link)));
        this.router.navigate([url]);
        break;
        
      case "Nota modificada":
        let notesIds = link.split(',');
        url = "project/"+this.encrypt(parseInt(notesIds[0]))+"/notes"
        localStorage.setItem('project', this.encrypt(parseInt(notesIds[0])));
        let note = this.encrypt(parseInt(notesIds[1]));
        this.router.navigate([url], { queryParams: {view: note} });
        break;

      case "Comentarios a imagen de album":
        let galleryIds = link.split(',');
        url = "project/"+this.encrypt(parseInt(galleryIds[0]))+"/inspiration"
        localStorage.setItem('project', this.encrypt(parseInt(galleryIds[0])));
        let gallery = this.encrypt(parseInt(galleryIds[1]));
        this.router.navigate([url], { queryParams: {view: gallery} });
        break;

      case "Carga de imagen":
        let galleryImage = link.split(',');
        url = "project/"+this.encrypt(parseInt(galleryImage[0]))+"/inspiration"
        localStorage.setItem('project', this.encrypt(parseInt(galleryImage[0])));
        let galleryImg = this.encrypt(parseInt(galleryImage[1]));
        this.router.navigate([url], { queryParams: {view: galleryImg} });
        break;

      case "Nuevo album":
        let galleryAlbum = link.split(',');
        url = "project/"+this.encrypt(parseInt(galleryAlbum[0]))+"/inspiration"
        localStorage.setItem('project', this.encrypt(parseInt(galleryAlbum[0])));
        let galleryAlb = this.encrypt(parseInt(galleryAlbum[1]));
        this.router.navigate([url], { queryParams: {view: galleryAlb} });
        break;

      default:
        break;
    } 
  }


  getProjectData(id){
    if(0 < id)
    {
      let url = 'project-show/'+id;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                let user = JSON.parse(localStorage.getItem('user'));
                this.dataService.changeProject(result);
                localStorage.setItem('show', result.status_project);
                if(1 == user.rol_id){
                  localStorage.setItem('show', '0');
                }
            },
            err => 
            {
                console.log(err);
            }
        );
      }
  }


   /**
   * Funcion para validar campos de tipo correo
   *
   * @param email
   * @return boolean
   */
  public validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
  }
	
  /**
   * Función para validar números
   *
   * @param e EventHtml
   */
  public validateNumber(e)
  {
    if(e.code !== "Backspace" && e.code !== "Tab" && e.code !== "ShiftRight" 
      && e.code !== "Home" && e.code !== "ShiftLeft" && e.code !== "End" && e.code !== "MetaLeft"){
      var key = window.event ? e.which : e.keyCode;
        if (key < 48 || key > 57) {
          e.preventDefault();
        }
    }
  }

}
