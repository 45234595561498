import { Injectable , EventEmitter, Output} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormServiceService {

	@Output() openModal: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    this.openModal.emit(false);
  }


	validateEmail(email: any) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


	setOpenValue(data: boolean)
	{
		this.openModal.emit(data);
	}
}
