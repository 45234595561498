import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


@Component({
  selector: 'app-notes-template',
  templateUrl: './notes-template.component.html',
  styleUrls: ['./notes-template.component.scss']
})
export class NotesTemplateComponent implements OnInit {
  public templateId = null;
  public templateType = null;
  public template = {id: 0, branding: 0, description: '', name: '', template_type_id: 0};
  public noteTemplate = {id: 0, template_id: 0, template_type_id: 0, document_type_id: 0, name: null, description: null, content: null, file: null, order: null};
 
              
  public myConfig: any = null;


   public ListNotes = true;
   public FormView = false;
   public Editor = DecoupledEditor;
  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
      this.myConfig = this.singleton.configCkEditor;
    }

  ngOnInit() {
      this.SessionService.validUserSession(); 
      let id = this.singleton.decrypt(this.route.snapshot.paramMap.get("id"));

      this.templateId = id;
      this.getTemplateDetails();
  }

  public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
  }

  getTemplateDetails(){
    let url = 'template/' + this.templateId+'?type=2';
    
      this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json(); 

              if(false === result.success)
              {
                  if(result.message)
                  {
                      if('redirect' == result.message)
                      {
                        window.history.back();
                      }
                  }
              }
              else
              {
                this.template['id'] = this.templateId;
                this.templateType = result.template_type_id;
                this.template['branding'] = result.branding;
                this.template['name'] = result.name;
                this.template['template_type_id'] = result.template_type_id;
                  
                if(result.template_note.length > 0)
                {
                  this.noteTemplate['id'] = result.template_note[0].id;
                  this.noteTemplate['content'] = result.template_note[0].description;
                }
                else
                {
                 this.noteTemplate['id'] = 0;
                 this.noteTemplate['content'] = '';
                }
                this.noteTemplate['template_id'] = this.templateId;
                this.noteTemplate['name'] = result.name;
                this.noteTemplate['template_type_id'] = result.template_type_id;
              }
          },
          err => 
          {
              console.log(err);
          }
      );
  }


  saveInfo(){
    var body = new FormData();
      body.append('id', this.noteTemplate['id']+'');
      body.append('template_id', this.noteTemplate['template_id']+'');
      body.append('name', this.noteTemplate['name']);
      body.append('description', this.noteTemplate['content']);
      body.append('template_type_id', this.noteTemplate['template_type_id']+'');
      
      let url = "template/type";

      this.service.queryPost(url, body).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          if(result.success)
          {
            
            //Show success message
            this.toastr.success(result.message);
            this.singleton.editTemplate = 0;
            this.router.navigate(['templates']);
           this.getTemplateDetails();
          }
          else
          {
            let message = '';
            if(result.message.length > 0)
            {
              result.message.forEach(function(element) {
                message += element+'<br>';
              });
            }

            this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
        },
        err => 
        {
          console.log(err);
        }
      );
  }

  goback(){
    this.singleton.editTemplate = this.templateId;
    this.singleton.typeTemplate = this.templateType;
    this.router.navigate(['/templates']);
  }

}
