import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SingletonService } from '../singleton.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-company',
  templateUrl: './login-company.component.html',
  styleUrls: ['./login-company.component.scss']
})
export class LoginCompanyComponent implements OnInit {

    modalRef: BsModalRef | null;
    modalRefAuthorization: BsModalRef;
    public user = {id: 0, name: '', email: '', password: '', confirmPassword: '', terms: null, role_id: 0};
    public company = {id: 0, company_name: '', city: '', phone: '',name: '', email: '', password: '', confirmPassword: '', terms: null};
    public branding = null;
    public viewRegister = false;
    public planId = null;

    public countriesList = [];
    public departmentsList = [];
    public departmentSelected = "";
    public countrySelected = "";
    public citiesList = [];
    public companyData = null;

    public email = null;

    public company_id = null;
    public user_id = null;
    public project_id = null
    public role_id = null;
    public name = "";
    public slug = "";

    public showRecover = false;

    public textValidate = '';
    public valdateResult = false;

    public date = new Date();
    public year = this.date.getFullYear();

  	constructor(public service: ApirestService,
                  private router: Router,
                  private route: ActivatedRoute,
                  private singleton: SingletonService,
                  private modalService: BsModalService,
                  private toastr: ToastrService) 
  	{ 

  	}

	ngOnInit() {
		this.slug = this.route.snapshot.paramMap.get("slug");


        this.email = this.route.snapshot.queryParams['email'];
        this.role_id = this.route.snapshot.queryParams['role_id'];
        this.name = this.route.snapshot.queryParams['name'];

        this.company_id = this.route.snapshot.paramMap.get("company");
        this.user_id = this.route.snapshot.paramMap.get("userId");
        this.project_id = this.route.snapshot.paramMap.get("project");

        
        if(this.company_id != undefined){
        	this.getCompanyDataById();
        	this.viewRegister = true;
        }

        if(this.user_id != undefined && this.user_id != 0){
        	this.getUserById();
        }


        if(this.user_id == 0 && this.role_id != undefined && this.name != undefined && this.email != undefined){
        	this.user['name'] = this.name;
        	this.user['email'] = this.email;
        	this.user['role_id'] = this.role_id;
        }

        if(this.slug != undefined){

			this.getCompanyDataBySlug();
        }
		
        this.getCountryList();
		this.getDepartmentsList();
	}

	getUserById(){
        this.service.queryGet('user/'+this.user_id).subscribe(
            response=>
            {      
                let result = response.json(); 
               	this.user['id'] = result.id;
               	this.user['name'] = result.name;
               	this.user['email'] = result.email;
                this.user['role_id'] = result.rol_id;
                if(result.company_id != this.company_id)
                {
                    window.history.back();
                }
                if(result.status == 1){
                    this.toastr.warning('El usuario ya se encuentra activado');
                    this.router.navigate(['/slug/'+this.slug]);
                }

            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getCompanyDataById(){
		let body = new FormData();

        this.service.queryPost('company/'+this.company_id, body).subscribe(
            response=>
            {      
                let result = response.json(); 
               	this.company['name'] = result.name;
               	this.departmentSelected = result.department_id;
               	this.changeDepartment();
               	this.company['city'] = result.city_id;
                this.slug = result['branding_company'][0].register_url;
                
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getCompanyDataBySlug(){
		let url = 'company-slug/'+this.slug;

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                
               	if(result.success){
               		this.branding = result.response;
               	}else{                    
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});

                    this.router.navigate(['login']);
               	}
            },
            err => 
            {
                console.log(err);
            }
        );
	}

    getCountryList(){
        let url = 'get-countries'
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.countriesList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
        
    }

	getDepartmentsList(){
        let url = 'get-department'
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.departmentsList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
        
    }

    changeDepartment(){

        let url = 'get-cities/'+this.departmentSelected;;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.citiesList = result;
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    registerUser(){

        if(this.modalRefAuthorization){
            this.modalRefAuthorization.hide();
        }
    	/*if(!this.user['terms']){

    		let message = "Debe aceptar términos y condiciones";
    		this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});

    		return;
        }*/
        
        if(this.user['password'] == '' || this.user['confirmPassword'] == ''){
            this.toastr.error('El campo contraseña es requerido')
            return;
        }
        
    	if(this.user['password'] == this.user['confirmPassword']){
            let buttom = document.getElementById("save-user");
            if(buttom)
            {
                buttom.setAttribute("disabled", "disabled");
            }
            
    		let body = new FormData();
    		body.append('id', this.user['id']+'');
	    	body.append('company_id', this.company_id+'');
	    	body.append('rol_id', this.user['role_id']+'');
	    	body.append('email', this.user['email']+'');
	    	body.append('password', this.user['password']);
            body.append('user_name', this.user['name']);
            body.append('status', 1+'');
	    	if(this.project_id != 0){
				body.append('project_id', this.project_id+'');	    		
	    	}

	    	this.service.queryPostRegular('users', body).subscribe(
	            response=>
	            {      
	                let result = response.json(); 
	                  
	                if(result.success) 
	                {
	                    this.toastr.success('Usuario creado.');
                        this.login();
                        // if(this.company_id != undefined){
                        //     this.router.navigate(['/wp/'+this.slug]);
                        // }
	                }
	                else
	                {
	                    let message = '';
                        if(result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                            this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                        }
                        
	                }
                    if(buttom)
                    {
                        buttom.removeAttribute("disabled");
                    }
	            },
	            err => 
	            {
	                console.log(err);
	            }
	        ); 
    	}else{
    		let message = "Las contraseñas ingresadas no coinciden";
    		this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
    	}
    }


    login()
  	{

        if('' === this.user.email && '' === this.user.password){
            this.toastr.error('Debes ingresar un correo electrónico y contraseña','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        } else if('' === this.user.email){
            this.toastr.error('Debes ingresar un correo electrónico','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        } else if('' === this.user.password){
            this.toastr.error('Debes ingresar una contraseña','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        }
        if(!this.validate(this.user.email))
        {
            return;
        }
        let buttom = document.getElementById("login");
        if(buttom){
            buttom.setAttribute("disabled","disabled");
        }
  		let body = new FormData;
  		body.append('email', this.user.email);
  		body.append('password', this.user.password);

  		this.service.queryPostRegular('login', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.success) 
                {
                    let token = 'Bearer '+ result.token ; 
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(result.user));
                    
                    if(result.user.project_id){
                        this.router.navigate(['/project/'+this.singleton.encrypt(result.user.project_id)+'/home']);
                    }else{
                       this.router.navigate(['/home']); 
                    }
                }
                else
                {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                 	
                }
                if(buttom){
                    buttom.removeAttribute("disabled");
                }
            },
            err => 
            {
            	console.log(err);
            }
        );
    }
      

    showRecoverPassword(){
        this.user = {id: 0, name: '', email: '', password: '', confirmPassword: '', terms: null, role_id: 0};
        this.showRecover = true;
        this.viewRegister = false;
    }

    cancelRecover(){
        this.user = {id: 0, name: '', email: '', password: '', confirmPassword: '', terms: null, role_id: 0};
        this.showRecover = false;
        this.viewRegister = false;
    }

    recover(){
        if('' === this.user.email){
            this.toastr.error('Debes ingresar un correo electrónico','Error', {enableHtml: true, positionClass: 'toast-top-center'});
            return;
        }
        if(!this.validate(this.user.email))
        {
            return;
        }
        document.getElementById("recover").setAttribute("disabled","disabled");
        let body = new FormData;
        body.append('email', this.user.email);
      
        this.service.queryPostRegular('recover-password', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.success) 
                {
                    //Show success message
                    this.toastr.success(result.message);

                    this.cancelRecover();
                }
                else
                {             
                    this.toastr.error(result.message);
                }
                document.getElementById("recover").removeAttribute("disabled");
            },
            err => 
            {
                document.getElementById("recover").removeAttribute("disabled");
                this.toastr.error('Ha ocurrido un error inesperado.');
            }
        );
    }

    openModal(template){
      this.modalRef = this.modalService.show(template, { class: 'modal-lg',ignoreBackdropClick: true  });
    } 

    closeModal()
    {
        if(this.modalRef){
            this.modalRef.hide();
        }
    }

    openAuthorization(template){
        this.modalRefAuthorization = this.modalService.show(template, {class: 'modal-lg', ignoreBackdropClick: true});
    }

    /**
     * Funcion para validar campos de tipo correo
     *
     * @param email
     * @return boolean
     */
    private validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    
    /**
     * Funcion para validar campos de tipo correo
     *
     * @param email
     * @return boolean
     */
    public validate(email) {
        this.textValidate = '';
        this.valdateResult = this.validateEmail(email);
        if (!this.valdateResult) {
            this.textValidate = 'El correo ingresado no es valido.';
            document.getElementById("text-validate").style.color = "red";
        }
        return this.valdateResult;
    }

}
