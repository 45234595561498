import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-participants-project',
  templateUrl: './participants-project.component.html',
  styleUrls: ['./participants-project.component.scss']
})
export class ParticipantsProjectComponent implements OnInit {
  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;

  public roles = [];

  public newUser = {id:0, name:'', email: '', role: '', description: ''};

  public participants = [];


  public toDelete = 0;

  public pagesNumber = 10;
  public search = "";

  public currentPage= 1;
  public pages = null;
  public project = 0;

  public showTextArea = true;
  public sessionUser;
  public archiveProject = false;
  public textButton = 'Enviar invitación';
  public sendEmail = true;

	constructor(public service: ApirestService,
    public router: Router,
    public toastr: ToastrService,
    public translate: TranslateService,
    public singleton: SingletonService,
    public lastlocation: Location,
    public modalService: BsModalService,
    public route: ActivatedRoute,
    public SessionService: SessionService) 
  { 
    window.scroll(0,0);
  }

  ngOnInit() {
    this.SessionService.validUserSession();
    let id = localStorage.getItem('project');
    this.project = this.singleton.decrypt(id);
    this.singleton.getProjectData(this.project);
    this.getParticipants('');
    // this.getRoles();
    
    this.sessionUser = JSON.parse(localStorage.getItem('user'));
    if(localStorage.getItem('show') == '0'){
      this.archiveProject = true;
    }
  }

  getParticipants(page){
    let url = 'participants?rows=10&projectId='+this.project+'&search='+this.search;
      
    this.service.queryGet(url).subscribe(
        response=>
        {      
          let result = response.json();
          this.participants = result.data

          this.currentPage = result['current_page'];
          this.pages = this.singleton.pagination(result);
        },
        err => 
        {
            console.log(err);
        }
    ); 
  }

  updateUser(sendMail){
    document.getElementById("update-user").setAttribute("disabled","disabled");
    document.getElementById("send-user").setAttribute("disabled","disabled");
    var body = new FormData();
    body.append('id', this.newUser['id']+'');
    body.append('name', this.newUser['name']);
    body.append('role_name', this.newUser['role']);
    body.append('email', this.newUser['email']);
    body.append('project_id', this.project+'');
    body.append('send_mail', JSON.stringify(this.sendEmail));

    this.service.queryPost('participants', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        
        if(result.success)
        {
          //Show success message
          this.toastr.success(result.message);
          this.getParticipants('');
          this.modalRef.hide();
          this.newUser = {id:0, name:'', email: '', role: '', description: ''}
          this.showTextArea = true;
        }
        else
        {
          let message = '';
          if(result.message.length > 0)
          {
            result.message.forEach(function(element) {
              message += element+'<br>';
            });
          }

          this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }

        document.getElementById("send-user").removeAttribute("disabled");
        document.getElementById("update-user").removeAttribute("disabled");
      },
      err => 
      {
        console.log(err);
      }
    );
  }

  editParticipant(modal, id, status){
    let url = 'participants/'+id;
    console.log(status);
    this.service.queryGet(url).subscribe(
        response=>
        {      
          let result = response.json(); 
           
          this.newUser['id'] = result.id;
          this.newUser['name'] = result.name;
          this.newUser['role'] = result.role_name;
          this.newUser['email'] = result.email;
          this.showTextArea = false;
          if(0 == status || null == status){
            this.textButton = 'Reenviar invitación';
            this.sendEmail = true;
          } else {
            this.textButton = 'Enviar invitación';
            this.sendEmail = false;
          }
          this.openModal(modal);
        },
        err => 
        {
            console.log(err);
        }
    ); 
  }

  openModal(template){
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered', ignoreBackdropClick: true });

    if(this.newUser.id == 0){
      document.getElementById('userName').focus();
    }
  }

  confirmDelete(template, id){
    this.toDelete = id;;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered', ignoreBackdropClick: true });
  }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = null;
    this.newUser = {id:0, name:'', email: '', role: '', description: ''}
     this.showTextArea = false;
  }

  closeModal(){
    this.modalRef.hide();
    this.newUser = {id:0, name:'', email: '', role: '', description: ''}
    this.showTextArea = false;
    this.sendEmail = true;
  }

  deleteUser(){
    this.service.queryDelete('participants/'+this.toDelete).subscribe(
      response=>
      {      
          let result = response.json(); 

          if(result.success)
          {
              //Reload the list users
              this.getParticipants('');
              this.modalRefDelete.hide();
              this.toDelete = null;
              this.toastr.success(result.message);
          }
      },
      err => 
      {
          console.log(err);
      }
    );
  }

}
