import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  selector: 'app-listnotifications',
  templateUrl: './listnotifications.component.html',
  styleUrls: ['./listnotifications.component.scss']
})
export class ListnotificationsComponent implements OnInit {

  modalRefDelete: BsModalRef | null;
  
  public toDelete = null;
  public userSession = null;
  public listNotifications = [];
  public openEvent = null;

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
      private modalService: BsModalService,
      private sanitizer: DomSanitizer,

      ) 
    { 
        window.scroll(0,0);

    }

  ngOnInit() {
    this.userSession = JSON.parse(localStorage.getItem('user'));

    this.getNotifications();
  }

  getNotifications(){
        
    
    let url = "notification?rows=100&userId="+this.userSession['id'];
    this.service.queryGet(url).subscribe(
        success=>
        {
            let result = success.json();
            
            this.listNotifications = result;
            
        },
        err=>
        {
            console.log(err);
        }
    );
  }

  gotoNotification(id, link, type){
      let url = "notification/"+id;

      
      this.service.queryPost(url, FormData).subscribe(
          success=>
          {
              let result = success.json();
              this.singleton.redirectNotification(link, type);
              
          },
          err=>
          {

          }
      );

  }

  confirmDelete(template, id){
    this.toDelete = id;;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  deleteNotification(){
    this.service.queryDelete('notification/'+this.toDelete).subscribe(
      response=>
      {      
          let result = response.json(); 
          if(result.success)
          {
              //Reload the list users
              this.getNotifications();
              this.cancelToDelete();
          }
      },
      err => 
      {
          console.log(err);
      }
  );
  }

  cancelToDelete(){
    this.toDelete = null;
    if(this.modalRefDelete){
        this.modalRefDelete.hide();
    }
}
   
   showEvent(position){
     if(position == this.openEvent){
            this.openEvent = null;
        } else {

            this.openEvent = position;
        }
   }


}
