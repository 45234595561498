import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApirestService } from "../apirest.service";
import { Router } from "@angular/router";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { DataServiceService } from "../data-service.service";
import { SingletonService } from "../singleton.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SessionService } from "../session.service";
import { BlockedService } from "../components/services/blocked.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef | null;

  public user = {
    rol_id: null,
    blocked: 0,
    url_profile: "",
    company: { name: "", url_profile: null },
  };
  public showCanvasLeft = false;
  public showCanvasRight = false;
  public menuXs = false;
  public showOverlay = false;
  public mediaQuery = "lg";

  public showSearch = true;

  public showUserMenu = false;
  public showNotifications = false;
  public showEmployeeCompany = false;

  public menu = "";
  public role_id = 0;
  public modalFlag = false;

  public date = new Date();
  public year = this.date.getFullYear();

  public listNotifications = [];
  public listNotificationWithOutRead = 0;
  public searchEvent = "";

  public _showModalBlocked = false;

  private serviceNotification: any = null;

  @ViewChild("modalForm") modalForm;
  constructor(
    private translate: TranslateService,
    public service: ApirestService,
    private dataService: DataServiceService,
    public singleton: SingletonService,
    private modalService: BsModalService,
    private router: Router,
    private blockedService: BlockedService,
    private SessionService: SessionService
  ) {}

  ngOnInit() {
    this.SessionService.validUserSession();
    this.dataService.changeNotifiacionProject(false);

    this.dataService.notificationHome.subscribe((notification) => {
      if (false === notification && null !== this.serviceNotification) {
        clearInterval(this.serviceNotification);
      }
    });

    this.SessionService.configurations.subscribe((configurations) => {
      if(configurations){
        this.openCanvas('left');
      }
    })

    if (
      null == localStorage.getItem("token") ||
      "null" == localStorage.getItem("token") ||
      undefined == localStorage.getItem("token")
    ) {
      this.router.navigate(["/login"]);
    } else {
      this.dataService.user.subscribe((data) => {
        this.getUser();
      });
      this.singleton.validateSession();
      this.singleton.getSession();

      /* this.user = JSON.parse(localStorage.getItem('user'));
            if(this.user['project_id']){
                this.router.navigate(['/project/'+this.singleton.encrypt(this.user['project_id'])+'/home']);
            } */
    }

    this.getNotifications();
    this.dataService.changeNotifiacionHome(true);
    let e = this;
    // every 2 minutes
    // setInterval(function(){e.getNotifications();}, 120000);
    this.serviceNotification = setInterval(function () {
      e.getNotifications();
    }, 60000);

    this.getMediaQuery();
  }

    ngOnDestroy() {
      this._showModalBlocked = false;
    }

  getMediaQuery() {
    let width = screen.width;

    if (width <= 575.98) {
      this.mediaQuery = "xs";
      this.menuXs = true;
    } else if (width <= 767.98) {
      this.mediaQuery = "sm";
      this.menuXs = true;
    } else if (width <= 991.98) {
      this.mediaQuery = "md";
    } else if (width <= 1199.98) {
      this.mediaQuery = "lg";
    }
  }

  /**
   *  It gets the user from the local storage
   **/
  getUser() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if(1 == this.user['blocked'])
    {
        this._showModalBlocked = true;
    }
    this.user["name"] = this.user["name"].split(" ")[0];
    this.role_id = this.user["rol_id"];
    if ("Wedding Planner Master" == this.user["name_role"]) {
      this.user["name_role"] = "MASTER";
    }
    this.translate.use("es");
  }

  /**
   * Logout from api and redirect to login
   */
  logout() {
    let body = new FormData();

    this.service.queryPost("logout", body).subscribe(
      (response) => {
        localStorage.setItem("user", null);
        localStorage.setItem("token", null);

        this.router.navigate(["/"]);
      },
      (err) => {
        console.log(err);
        console.log(err["status"]);
        if (err["status"] == 401) {
          localStorage.setItem("user", null);
          localStorage.setItem("token", null);

          this.router.navigate(["/"]);
        }
      }
    );
  }

  openCanvas(canvas) {
    this.showUserMenu = false;
    if (canvas == "left") {
      this.showCanvasLeft = true;
      // this.showOverlay = true;
    } else if (canvas == "right") {
      this.showCanvasRight = true;
      // this.showOverlay = true;
    }
  }

  closeCanvas() {
    if (this.showCanvasLeft) {
      this.showCanvasLeft = false;
      this.showOverlay = false;
    } else {
      this.showCanvasRight = false;
      this.showOverlay = false;
    }
  }

  openMenu(route) {
    this._showModalBlocked = false;
    if(1 == this.user.blocked && route != 'start')
    {
        this._showModalBlocked = true;
        this.blockedService.setOpenValue(this._showModalBlocked);
        return;
    }
    this.showNotifications = false;
    this.showUserMenu = false;
    this.showEmployeeCompany = false;
    switch (route) {
      case "start":
        this.router.navigate(["/home"]);
        this.menu = "start";
        this.showSearch = true;
        break;

      case "documents":
        this.router.navigate(["/documents"]);
        this.menu = "documents";
        this.showSearch = false;
        break;

      case "calendar":
        this.router.navigate(["/calendar"]);
        this.menu = "calendar";
        this.showSearch = false;
        break;
      case "contacts":
        this.router.navigate(["/contacts"]);
        this.menu = "contacts";
        this.showSearch = false;
        break;
      case "help":
        this.router.navigate(["/help"]);
        this.menu = "help";
        this.showSearch = false;
        break;
      default:
        // code...
        break;
    }
  }

  goTo(to) {
    this._showModalBlocked = false;
    if(1 == this.user.blocked && to != 'payment-setting')
    {
        this._showModalBlocked = true;
        this.blockedService.setOpenValue(this._showModalBlocked);
        return;
    }
    this.showCanvasLeft = false;
    this.showSearch = false;
    this.router.navigate([to]);
  }

  OpenModalForm() {
    this.modalRef = this.modalService.show(this.modalForm, {
      class: "modal-md",
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  closeModalForm() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  filterSearchEvent() {
    this.searchEvent;
    this.dataService.changeSearchEvent(this.searchEvent);
  }

  getNotifications() {
    //this.user = JSON.parse(localStorage.getItem('user'));
    if (undefined !== this.user["id"]) {
      let url = "notification?rows=5&userId=" + this.user["id"] + "&lastFive=1";
      this.service.queryGet(url).subscribe(
        (success) => {
          let result = success.json();

          this.listNotifications = result.data;
          let j = 0;
          for (let i = 0; i < this.listNotifications.length; i++) {
            if (this.listNotifications[i].read == 0) {
              j++;
            }
          }
          this.listNotificationWithOutRead = j;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  updateNotification() {
    if(1 == this.user['blocked'])
    {
        this._showModalBlocked = true;
        this.blockedService.setOpenValue(this._showModalBlocked);
        return;
    }
    this.showNotifications = !this.showNotifications;
    if (this.showNotifications) {
      for (let i = 0; i < this.listNotifications.length; i++) {
        let id = this.listNotifications[i].id;
        let url = "notification/" + id;
        this.service.queryPost(url, FormData).subscribe(
          async (success) => {
            await this.singleton.sleep(200);
          },
          async (err) => {
            await this.singleton.sleep(200);
          }
        );
      }
    }
  }

  gotoNotification(id, link, type) {
    let url = "notification/" + id;

    this.service.queryPost(url, FormData).subscribe(
      (success) => {
        let result = success.json();
        this.getNotifications();
        this.singleton.redirectNotification(link, type);
      },
      (err) => {}
    );
  }

  gotoListNotifications() {
    this.router.navigate(["/listnotifications"]);
  }
}
