import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public notification = {id: 0, event_notification: null, event_notification_time: '', activity_notification: null, activity_notification_time: '', info_confetti: null};
	
	public user = {rol_id:0};
	constructor(public service: ApirestService,
                private router: Router,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                private translate: TranslateService,
                private SessionService: SessionService) 
  	{ 
      window.scroll(0,0);
  	}

  ngOnInit() {
    this.SessionService.validUserSession();
    this.getNotification();
    this.user = JSON.parse(localStorage.getItem('user'));
  }


  getNotification(){
        
        this.service.queryGet('notification-configuration').subscribe(
            response=>
            {      
                let result = response.json(); 
                
                if(result.length > 0){
                    this.notification.id = result[0].id;
                    this.notification.event_notification_time = result[0].event_notification_time ? result[0].event_notification_time : '';
                    this.notification.activity_notification_time = result[0].activity_notification_time ? result[0].activity_notification_time : '';

                    if(result[0].event_notification == 1)
                    {
                        this.notification.event_notification = true;
                    }

                    if(result[0].activity_notification == 1)
                    {
                        this.notification.activity_notification = true;
                    }

                    if(result[0].info_confetti == 1)
                    {
                        this.notification.info_confetti = true;
                    }
                }
            },
            err => 
            {
                console.log(err);
            }
        );
  }
  
  updateNotifications(){

      let event_notification = '0';
      if(this.notification['event_notification'])
      {
        event_notification = '1';
      }

      let activity_notification = '0';
      if(this.notification['activity_notification'])
      {
        activity_notification = '1';
      }

      let info_confetti = '0';
      if(this.notification['info_confetti'])
      {
        info_confetti = '1';
      }


      let user = JSON.parse(localStorage.getItem('user'));  
      let body = new FormData;
      body.append('id', this.notification.id+'');
      body.append('user_id', user.id);
      body.append('event_notification', event_notification);
      body.append('event_notification_time', this.notification.event_notification_time);
      body.append('activity_notification', activity_notification);
      body.append('activity_notification_time', this.notification.activity_notification_time);
      body.append('info_confetti', info_confetti);

      this.service.queryPost('notification-configuration', body).subscribe(
          response=>
          {      
              let result = response.json(); 
              
              if(result.success) 
              {
                  this.toastr.success(result.message);
                  this.getNotification();
                  this.router.navigate(['/home']);
              } 
              else
              {
                  let message = '';
                  if(result.message.length > 0)
                  {
                      result.message.forEach(function(element) {
                          message += element+'<br>';
                      });
                  }
                  this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
              }
          },
          err => 
          {
              console.log(err);
          }
      );
  }

  cancel(){
    this.router.navigate(['/home']);
  }
}
