import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { SingletonService } from './singleton.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuardService {

  constructor(
    private singleton: SingletonService,
    private router: Router) 
  { 

  }

  canActivate(){
    let user = JSON.parse(localStorage.getItem('user'));
    
    if(2 == user['rol_id']){
      return true;
    }else{
      this.router.navigate(['/home']);
      return false;
    } 
    
	}
}
