import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';
import { Lightbox } from 'ngx-lightbox';
import { SessionService } from '../session.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-inspiration-project',
  templateUrl: './inspiration-project.component.html',
  styleUrls: ['./inspiration-project.component.scss']
})
export class InspirationProjectComponent implements OnInit {

    public pagesNumber = 10;
    public search = "";

    public currentPage= 1;
    public pages = null;

    modalRef: BsModalRef | null;
    modalRefDelete: BsModalRef | null;
    modalRefImage: BsModalRef | null;

    public imageToView = '';
    public comments = [];
    public next = null;
    public prev = null;

    public typeToDelete = '';
    public toDelete = 0;
    public project = 0;
    public categoriesList = [];
    public categorySelected = "";

    public galleryNew = {id: 0, category_id: 0, project_id: 0, name: ''};

    public ListView = true;
    public formView = false;
    public ViewGallery = false;

    public category = {id: 0, name: ''};

    public galleryList = [];

    public galleryId = 0;
    public galleryName = "";

    public album = [];

    public categoryInput:any = [];
    public newCategory = {id: 0, project_id: 0, name: ''};
    public imageComments = [];
    public newComment = {id: 0, project_galery_image_id: 0, user_id: 0, comment: ''};
    public idImageViewed = 0;

    public archiveProject = false;
    public userSession = null;

    url = '';
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;



	constructor(public service: ApirestService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        private singleton: SingletonService,
        private lastlocation: Location,
        private modalService: BsModalService,
        private lightbox: Lightbox,
        private route: ActivatedRoute,
        private _sanitizer: DomSanitizer,
        private SessionService: SessionService) 
    { 
		window.scroll(0,0);
        this.options = { concurrency: 1, maxUploads: 20 };
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
        this.url = this.service.apiUrl  + 'project/galery/image';
        this.dragOver = true;
    }

	ngOnInit() {
    
		let id = localStorage.getItem('project');
        this.project = this.singleton.decrypt(id);
        this.SessionService.validUserSession();  
        this.getCategories();
        this.listGallery('');

        this.singleton.getProjectData(this.project);

        if(localStorage.getItem('show') == '0'){
            this.archiveProject = true;
        }

        let user = JSON.parse(localStorage.getItem('user'));
        this.userSession = user;   

        this.route.queryParams.subscribe(params=> {
            if(params['view']){
                this.editGallery(this.singleton.decrypt(params['view']));
            }
        });
	}


    /** Upload files on item*/
    onUploadOutput(output: UploadOutput): void {
        if(output.type === 'allAddedToQueue') {
             const event: UploadInput = {
             type: 'uploadAll',
             url: this.url,
             headers: ({"Authorization": localStorage.getItem('token')}),
                 method: 'POST',
                 data: { 
                     project_galery_id: this.galleryNew['id']+'',
                     name: 'Imagen',
                     image: output.file+''
                 }
             };
             this.uploadInput.emit(event);
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
           this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
           const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
           this.files[index] = output.file;
        } else if (output.type === 'cancelled' || output.type === 'removed') {
           this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
           this.dragOver = true;
        } else if (output.type === 'dragOut') {
           this.dragOver = false;
        } else if (output.type === 'drop') {
           this.dragOver = false;
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
           /*console.log(output.type);
           console.log(output.file.name + ' rejected');*/
        } else if (output.type === 'done'){
            //console.log(output.file.response);
            let response;
            response = output.file.response;
            if(response.success)
            {
             //this.toastr.success(response.message);
             this.files = [];
             this.getGalleryImage(this.galleryNew['id']);
            }
            else
            {
             this.toastr.error(response.message);
            }
        }

        this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
    }

  cancelUpload(id: string): void {
     this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
     this.uploadInput.emit({ type: 'remove', id: id });
  }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    listGallery(page){
  	    
        let url = `project/galery?projectId=${this.project}&search=${this.search}`;
        
        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.galleryList = result;

               // this.currentPage = result['current_page'];
               // this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    confirm(){
        if(this.galleryNew['id'] != 0){
            this.toastr.success("Álbum creada correctamente");
        }
        this.router.navigate(['project/'+this.project+'/inspiration']);
    }

	getCategories(){
        this.categoriesList = [];
        let user = JSON.parse(localStorage.getItem('user'));
        let url = 'categories?companyId='+user['company_id'];
          
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 

                let data = result.data;
                for(let i = 0; i < data.length; i++){
                   let temp = {value: data[i].id, display: data[i].name};
                   this.categoriesList.push(temp);
                }
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	viewForm(){
		this.ListView = false;
		this.formView = true;
	}

	ViewList(){
        this.ListView = true;
        this.formView = false;
        this.ViewGallery = false;
        this.galleryNew = {id: 0, category_id: 0, project_id: 0, name: ''};
        this.newCategory = {id: 0, project_id: 0, name: ''};
        this.newComment = {id: 0, project_galery_image_id: 0, user_id: 0, comment: ''};
        this.categoryInput = [];
        this.listGallery('');
    }
    
    VieWGallery(){
        this.ListView = false;
        this.formView = false;
        this.ViewGallery = true;
    }


	editGallery(id){
		this.service.queryGet('project/galery/'+id).subscribe(
            response=>
            {      
              let result = response.json(); 
              
              this.galleryNew['id'] = result.id;
              this.galleryNew['name'] = result.name;
              this.galleryNew['project_id'] = result.project_id;
              this.galleryNew['name'] = result.name;
              this.galleryNew['category_id'] = result.category_id;

              
              for(let i  = 0; i < this.categoriesList.length; i++){
                  if(this.categoriesList[i].value == result.category_id){
                    this.categoryInput = this.categoriesList[i];
                  }
              }

              
              this.getGalleryImage(result.id);
              this.viewForm();
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	getGalleryImage(id){
		this.service.queryGet('project/galery/'+id).subscribe(
      response=>
      {      
          let result = response.json(); 

          this.galleryNew['id'] = id;
          this.galleryNew['category_id'] = result.category_id;
          this.galleryNew['name'] = result.name;
          this.galleryNew['project_id'] = result.project_id;
          this.galleryNew['category_id'] = result.category_id;

          
          this.categoryInput = [];
          for(let i  = 0; i < this.categoriesList.length; i++){
              if(this.categoriesList[i].value == result.category_id){
                this.categoryInput.push(this.categoriesList[i]);
              }
          }

          this.service.queryGet('project/galery/image/index?galeryId='+id).subscribe(
              response=>
              {      
                  let result = response.json(); 
                  //this.listGallery = result;
                  
                  this.album = [];
                  for (let i = 0; i < result.length; i++) {
                      let src = result[i].url_image;
                      let caption = result[i].name;
                      let thumb = result[i].url_image;
                      let id = result[i].id;
                      let comments = result[i].have_comments;
                      let new_comments = result[i].new_comments;
                      let album = {
                         src: src,
                         have_comments: comments,
                         new_comments: new_comments,
                         caption: caption,
                         thumb: thumb,
                         id: id
                      };
                 
                      this.album.push(album);
                  }
                  this.VieWGallery();
              },
              err => 
              {
                  console.log(err);
              }
          );
      },
      err => 
      {
          console.log(err);
      }
    );
  }
    
  open(index: number): void {
      // open lightbox
      this.lightbox.open(this.album, index);
  }

  getBackgroundGallery(url)
  {
      if(url == '')
      {
         url = './assets/images/empty_album.jpg'; 
      }
      //console.log(url);
      return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }


  openImageModal(template,i){
    
    let length = this.album.length;

    if(i == 0){
        this.prev = null;
    }else{
        this.prev = i-1;
    }

    if(i+1 == this.album.length){
        this.next = null;
    }else{
        this.next = i+1;
    }
    
    this.getCommentsImage(this.album[i].id);
    this.idImageViewed = this.album[i].id;
    this.imageToView = this.album[i].src;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.newComment['comment']  = '';
    this.album[i].new_comments = 0;
    this.updateCommentsViewed(this.idImageViewed);
  }

  prevImage(newPos){
      let lengthAlbum = this.album.length;
      this.imageToView = this.album[newPos].src;
      this.idImageViewed = this.album[newPos].id;
      this.getCommentsImage(this.album[newPos].id);
      this.updateCommentsViewed(this.album[newPos].id);
      this.album[newPos].new_comments = 0;
      if(newPos-1 < 0){
          this.prev = null;
      }else{
          this.prev = newPos-1;
      }

      if(newPos+1 >= this.album.length){
          this.next = null;
      }else{
          this.next = newPos+1;
      }
  }

  nextImage(newPos){
      let lengthAlbum = this.album.length;
      this.imageToView = this.album[newPos].src;
      this.idImageViewed = this.album[newPos].id;
      this.getCommentsImage(this.album[newPos].id);
      this.updateCommentsViewed(this.album[newPos].id);
      this.album[newPos].new_comments = 0;
      if(newPos-1 < 0){
          this.prev = null;
      }else{
          this.prev = newPos-1;
      }

      if(newPos+1 >= this.album.length){
          this.next = null;
      }else{
          this.next = newPos+1;
      }
  }

  updateCommentsViewed(id){
    
    var body = new FormData();
    body.append('project_galery_image_id', id+'')

    this.service.queryPost('project/galery/image/comment-update-view', body).subscribe(
      response=>
      {      
        let result = response.json();        
      },
      err => 
      {
        console.log(err);
      }
    );
  }


  getCommentsImage(id){
    
    this.service.queryGet('project/galery/image/comment?imageId='+id).subscribe(
          response=>
          {  
            this.imageComments = [];
            let result = response.json(); 
            
             this.imageComments = result;
          },
          err => 
          {
              console.log(err);
          }
      );
  }


  addComment(){
   
    if(this.newComment['comment'] == ''){
      this.toastr.error('Ingresa un comentario');
      return;
    }

    let body = new FormData;
    let user = JSON.parse(localStorage.getItem('user'));

    body.append('id', this.newComment['id']+'');
    body.append('project_galery_image_id', this.idImageViewed+'');
    body.append('user_id', user['id']+'');
    body.append('comment', this.newComment['comment']+'');

    this.service.queryPost('project/galery/image/comment', body).subscribe(
      response=>
      {      
        let result = response.json(); 

        if(result.success)
        {
          this.getCommentsImage(this.idImageViewed);
          
          for(let i = 0; i < this.album.length; i++ ){
           if(this.album[i].id == this.idImageViewed){
            this.album[i]['have_comments'] = 1;
           }
          }
          
          this.newComment['comment'] = '';
        }
        else
        {
          let message = '';
          if(result.message.length > 0)
          {
            result.message.forEach(function(element) {
              message += element+'<br>';
            });
          }

          this.toastr.error(message);
        }
      },
      err => 
      {
        console.log(err);
      }
    );
  }

  close(): void {
      // close lightbox programmatically
      this.lightbox.close();
  }


	saveGallery(){
		let body = new FormData;
    this.album = [];
  	body.append('id', this.galleryNew['id']+'');
	  body.append('category_id', this.galleryNew['category_id']+'');
		body.append('project_id', this.project+'');
		body.append('name', this.galleryNew['name']+'');

		this.service.queryPost('project/galery', body).subscribe(
			response=>
			{      
				let result = response.json(); 

				if(result.success)
				{
            if(this.galleryNew['id'] == 0){
              this.modalRef.hide();
            }

  					//Show success message
  					//this.toastr.success(result.message);
            this.galleryNew['id'] = result.projectGalery.id;
            this.galleryNew['category_id'] = result.projectGalery.category_id;
            this.galleryNew['project_id'] = result.projectGalery.project_id;
            this.galleryNew['name'] = result.projectGalery.name;
             
            this.categoryInput = [];
            /*for(let i  = 0; i < this.categoriesList.length; i++){
                if(this.categoriesList[i].value == result.projectGalery.category_id){
                  this.categoryInput.push(this.categoriesList[i]);
                }
            }*/
            this.getGalleryImage(this.galleryNew['id']);
            //this.VieWGallery();

				}
				else
				{
					let message = '';
					if(result.message.length > 0)
					{
						result.message.forEach(function(element) {
						  message += element+'<br>';
						});
					}

					this.toastr.error(message);
				}
			},
			err => 
			{
				console.log(err);
			}
		);
	}

	confirmDelete(template, id, type = null){
      this.toDelete = id;
      
      this.typeToDelete = type;
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
   }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = null;
  }

  cancelCreate(){
    this.modalRef.hide();
    this.newComment = {id: 0, project_galery_image_id: 0, user_id: 0, comment: ''};
  }


  deleteGallery(){
    let url = "";
    
    if(this.typeToDelete == '' || this.typeToDelete == null){
      url ='project/galery/'+this.toDelete;
    }

    if(this.typeToDelete == 'comment'){
      url = "project/galery/image/comment/"+this.toDelete;
    }

    if(this.typeToDelete == 'image'){
      url = "project/galery/image/"+this.toDelete;
    }

  	this.service.queryDelete(url).subscribe(
      response=>
      {      
          let result = response.json(); 

          if(result.success)
          {
            this.modalRefDelete.hide();
            
              //Reload the list users
              if(this.typeToDelete == '' || this.typeToDelete == null){
                this.listGallery('');
                this.modalRefDelete.hide();
                this.toDelete = null;
                this.toastr.success(result.message);
              }

              if(this.typeToDelete == 'comment'){
                this.getCommentsImage(this.idImageViewed);
              }

              if(this.typeToDelete == 'image'){
                 this.getGalleryImage(this.galleryNew['id']);
              }

          }
      },
      err => 
      {
          console.log(err);
      }
    );
	}
	
	
  OpenModalForm(template){
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    document.getElementById('galleryname').focus();
  }
      
  closeModal(){
    if(this.modalRef){
      this.modalRef.hide();
    }
    if(this.modalRefDelete){
      this.modalRefDelete.hide();
    }
  }


  addCategoryInput(event){
    if(event.value != event.display){
      this.galleryNew['category_id'] = event.value;

      if(this.galleryNew['id'] != 0){
         this.saveGallery();
      }
    }else{
      this.addCategory(event.value);
    }
  }

  

  removeCategoryInput(){
    this.galleryNew['category_id'] = 0;
  }


  addCategory(name){
    let user = JSON.parse(localStorage.getItem('user'));
    let company = user['company_id'];
    var body = new FormData();
    body.append('id', '0');
    body.append('company_id', company+'')
    body.append('name', name+'')

    this.service.queryPost('categories', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        
        if(result.success)
        {
          this.getCategories();
          let category = result.category;
          this.galleryNew['category_id'] = category.id;
          if(this.galleryNew['id'] != 0){
             this.saveGallery();
          }
        }
        else
        {
          let message = '';
          if(result.message.length > 0)
          {
            result.message.forEach(function(element) {
              message += element+'<br>';
            });
          }

          this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }
      },
      err => 
      {
        console.log(err);
      }
    );
  }


}
