import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

	public  user = new BehaviorSubject<any>([]);
	current = this.user.asObservable();

	public  modalObject = new BehaviorSubject<any>(null);

	public searchEvent = new BehaviorSubject<any>('');

	public currentProject = new BehaviorSubject<any>(null);

	// Variables para detener la notificaciones
	public notificationProject = new BehaviorSubject<boolean>(true);
	public notificationHome = new BehaviorSubject<boolean>(true);
	
	constructor() { this.modalObject.asObservable(); }

	changeUser(list: any){
		this.user.next(list);
	}

	changeModal(val: object){
		if(val != this.modalObject.getValue()){
			this.modalObject.next(val);
		}
	}

	changeSearchEvent(term: any){
		if(term != this.searchEvent.getValue()){
			this.searchEvent.next(term);
		}
	}

	changeProject(data: any)
	{
		if(data != this.currentProject.getValue()){
			this.currentProject.next(data);
		}
	}

	/**
	 * Funcion para capturar si se detiene el servicio de las notificaciones en el layout del proyecto
	 *
	 * @param notifiacion boolean
	 */
	changeNotifiacionProject(notification: boolean)
	{
		if(notification != this.notificationProject.getValue()){
			this.notificationProject.next(notification);
		}
	}

	/**
	 * Funcion para capturar si se detiene el servicio de las notificaciones en el home del wedding planner
	 *
	 * @param notifiacion boolean
	 */
	changeNotifiacionHome(notification: boolean)
	{
		if(notification != this.notificationHome.getValue()){
			this.notificationHome.next(notification);
		}
	}

}
