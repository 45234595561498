import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  public newItem = "";
  public newItemMonthsBefore = null;
  public newItemId = 0;


  public templateId = null;
  public templateType = null;
  public template = {id: 0, branding: 0, description: '', name: '', template_type_id: 0, checklist: null};
  public itemsList = [];

  public toDelete = 0;
  modalRefDelete: BsModalRef | null;

  public showInputRow = false;

  public posToUpdate = null;

  public activeItem = null;

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }

  ngOnInit() {
    this.SessionService.validUserSession();
    let id = this.singleton.decrypt(this.route.snapshot.paramMap.get("id"));

    this.templateId = id;
    this.getTemplateDetails();
  }

  getTemplateDetails(){
    let url = 'template/' + this.templateId+'?type=3';
    
      this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json(); 
              if(false === result.success)
              {
                  if(result.message)
                  {
                      if('redirect' == result.message)
                      {
                        window.history.back();
                      }
                  }
              }
              else
              {
                this.template['id'] = this.templateId;
                this.templateType = result.template_type_id;
                this.template['branding'] = result.branding;
                this.template['description'] = result.description;
                this.template['name'] = result.name;
                this.template['template_type_id'] = result.template_type_id;
                this.template['tasks'] = result.tasks;
                
                this.itemsList = result.tasks;
              }
          },
          err => 
          {
              console.log(err);
          }
      );
  }

    addItem(id,close){
        if(null == this.newItem || '' == this.newItem){
         
          this.showInputRow = false;
          this.newItemMonthsBefore = null;

        } else {
          if(1 == close){
            var body = new FormData();
            body.append('id', id+'');
            body.append('template_id', this.templateId+'');
            body.append('name', this.newItem);
            body.append('months_before', this.newItemMonthsBefore);
            //body.append('order', '1');

            let url = "template/checklist/task";

            this.service.queryPost(url, body).subscribe(
              response=>
              {      
                let result = response.json(); 
                
                if(result.success)
                {
                  //Show success message
                  // this.toastr.success(result.message);
                  //this.router.navigate(['templates']);
                  this.newItem = "";
                  this.newItemMonthsBefore = null;
                  this.showInputRow = false;
                  this.getTemplateDetails();
                }
                else
                {
                  
                  this.newItem = "";
                  this.newItemMonthsBefore = null;
                  this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
              },
              err => 
              {
                console.log(err);
              }
            );
          }
        }
    }

  updateItem(id, close){
    this.showInputRow = false;
    var body = new FormData();
    body.append('id', id+'');
    body.append('template_id', this.templateId+'');
    body.append('name', this.newItem);
    body.append('months_before', this.newItemMonthsBefore);
    //body.append('order', '1');

    let url = "template/checklist/task";

    this.service.queryPost(url, body).subscribe(
      response=>
      {      
        let result = response.json(); 
        
        if(result.success)
        {
          
          //Show success message
          //this.toastr.success(result.message);
          //this.router.navigate(['templates']);
          this.getTemplateDetails();
          if(1 == close){
            this.newItem = "";
            this.newItemMonthsBefore = "null";
            this.posToUpdate = null;
            this.activeItem = null;
          }
          let temp = result.template;

         /*  for(let i = 0; i < this.itemsList.length; i++){
            if(this.itemsList[i].id == temp.id){
              this.itemsList[i].name = temp.name;
              this.itemsList[i].months_before = temp.months_before;
            }
          } */
        }
        else
        {
          let message = '';
          if(result.message.length > 0)
          {
            result.message.forEach(function(element) {
              message += element+'<br>';
            });
          }
          this.newItem = "";
          this.newItemMonthsBefore = "";
          this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }
      },
      err => 
      {
        console.log(err);
      }
    );
  }

  saveInfo(){
    this.toastr.success("Planeación guardada correctamente");
    this.singleton.editTemplate = 0;
    this.router.navigate(['templates']);
  }

  confirmDelete(template, id, typeDelete){
    this.toDelete = id;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  async showToInputRow(){
    this.showInputRow = true
    this.posToUpdate = -1;
    this.newItemId = 0;
    this.newItem = "";
    this.newItemMonthsBefore =  "null";

    let idInput = "newItem";
    await this.singleton.sleep(100);
    document.getElementById(idInput).focus();
  }

  async dobleclick(id, type){
    this.posToUpdate = id;
    this.showInputRow = false;
    //Recorremos el array de items
    for(let i = 0; i < this.itemsList.length; i++){
        if(this.itemsList[i].id == id){
          this.newItemId = this.itemsList[i].id;
          this.newItem = this.itemsList[i].name;
          this.newItemMonthsBefore =  this.itemsList[i].months_before;
        }
    }

    let idInput = "item"+id;
    if(type == 'select'){
      idInput = "months_before"+id;
    }
    await this.singleton.sleep(100);
    document.getElementById(idInput).focus();
    this.activeItem = id;
  }

  cancelAddItem(){
    this.posToUpdate = -1;
    this.activeItem = null;
    this.showInputRow = false
  }

  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = 0;
  }

  deleteElement(){


    let url = 'template/checklist/task/'+this.toDelete;

    this.service.queryDelete(url).subscribe(
      response=>
      {      
          let result = response.json(); 

          if(result.success)
          {
              //Reload the list users
              this.getTemplateDetails();
              this.modalRefDelete.hide();
              this.toDelete = 0;
              this.toastr.success(result.message);
          }
      },
      err => 
      {
          console.log(err);
      }
    );

  }

  goback(){
    this.singleton.editTemplate = this.templateId
    this.singleton.typeTemplate = this.templateType;
    this.router.navigate(['/templates']);
  }
}
