import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { ContactFormServiceService } from '../components/services/contact-form-service.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {


	public _openModal = false;
  	constructor(private SessionService: SessionService,
  			 private contactService: ContactFormServiceService) {
      window.scroll(0,0);
  }

  ngOnInit() {
    this.SessionService.validUserSession();  
  }

  openModalContact()
  {
  	this.contactService.setOpenValue(true);
  }

}
