import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { ToastrService } from 'ngx-toastr';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';



@Component({
  selector: 'app-passwords',
  templateUrl: './passwords.component.html',
  styleUrls: ['./passwords.component.scss']
})
export class PasswordsComponent implements OnInit {

	public user = {password: '', confirm_password: ''};
  public token = "";
  
  constructor(public route: ActivatedRoute,
    private toastr: ToastrService,
    public service: ApirestService,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  send()
    {
      if( this.user['password'] !=  this.user['confirm_password']){
        this.toastr.error('Los campos confirmar contraseña y contraseña no coinciden');
        return;
      }
      if('' == this.user['password']){
        this.toastr.error('El campo contraseña es requerido');
      }
      else if('' == this.user['confirm_password']){
        this.toastr.error('El campo confirmar contraseña es requerido');
      }else{
        

        let body = new FormData();

        body.append('token', this.token);
        body.append('password', this.user['password']);
        body.append('confirmPassword', this.user['confirm_password']);

        this.service.queryPost('password-change', body).subscribe(
            response=>
            {   
              

                let result = response.json(); 

                if(result.success)
                {
                    //Show success message
                    this.toastr.success('Contraseña actualizada.');
                    setTimeout(() => { 
                    this.router.navigate(['/login']);
                  }, 1000);
                }
                else
                {
                    let message = '';
            if(Array.isArray(result.message.length) == true)
                      {
                          result.message.forEach(function(element) {
                              message += element+'<br>';
                          });
                      }
                      else
                      {
                          message = result.message;
                      }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-right'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
      }
    }


}
