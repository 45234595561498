import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Chart from 'chart.js';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-homeproject',
  templateUrl: './homeproject.component.html',
  styleUrls: ['./homeproject.component.scss']
})
export class HomeprojectComponent implements OnInit {

    public project = "";
    private projectIdEncry = '';

    public type = 'horizontalBar';
    public type2 = 'pie';

    public execution;
    public budget;
    public guest;
    public guestData = {total_guests: 0, confirmed: 0, to_be_confirmed: 0, no_assistance: 0}; 
    public gallery = {url_1: '', url_2: '', url_3: '', url_4: ''};

    public options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
        display: true,
        fullWidth: true,
        position: 'bottom',
        labels: {
        fontColor: '#000',
        }
        },
        labels: {
        //render: 'value',
        fontSize: 14,
        fontStyle: 'bold',
        fontColor: '#000',
        padding: 50
        //fontFamily: '"Lucida Console", Monaco, monospace'
        },
        width: 400,
        height: 300,
    };


    public budgetExecuted = 0;
    public budgetImplemented = 0;

    constructor(public service: ApirestService,
        private router: Router,
        private toastr: ToastrService,
        private translate: TranslateService,
        public singleton: SingletonService,
        private lastlocation: Location,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private SessionService: SessionService) 
    { 
        window.scroll(0,0);
    }

    ngOnInit() {
        this.SessionService.validUserSession();
        let id = localStorage.getItem('project');
        this.projectIdEncry = id;
        this.project = this.singleton.decrypt(id);
        this.getData();
        this.getProjectData(this.project);
    }


    getProjectData(id){
        let url = 'project-show/'+id;
        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                let user = JSON.parse(localStorage.getItem('user'));
                localStorage.setItem('show', result.status_project);
                if(1 == user.rol_id){
                    localStorage.setItem('show', '0');
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getData(){
        let body = new FormData;
        body.append('project_id', this.project+'');

        this.service.queryPost('statistics', body).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.guestData = result.guest;
                
                this.gallery = result.images;

                this.execution = {
                  labels: ["Actividades pendientes", "Actividades ejecutadas"],
                  datasets: [
                    {
                      label: "Total",
                      data: [result.execute.without_execute, result.execute.total_execute],
                      backgroundColor: [
                        "#b965e5",
                        "#69dde2",
                      ]
                    }
                  ],
                }

                this.budgetExecuted = result.budget.value_planned_budget;
                this.budgetImplemented = result.budget.value_budget_implemented;
                this.budget = {
                  labels: ["Presupuesto definido", "Presupuesto ejecutado"],
                  datasets: [
                    {
                      label: "Total",
                      data: [result.budget.value_planned_budget, result.budget.value_budget_implemented],
                      backgroundColor: [
                        "#e8db62",
                        "#69dde2",
                      ]
                    }
                  ]
                }
               

                let maxValue = result.guest.total_guests + 2;
                var canvas = <HTMLCanvasElement> document.getElementById('chart')
                let ctx = canvas.getContext('2d');
                this.guest = new Chart(ctx, {
                  type: 'bar',
                  data:{
                      labels:  ["", "", "", ""],
                      datasets: [{
                          label: "Cantidad",
                          backgroundColor: [
                            "#f47373",
                            "#e8db62",
                            "#a1ef6e",
                            "#69dde2",
                          ],
                          data: [result.guest.total_guests, result.guest.confirmed, result.guest.to_be_confirmed, result.guest.no_assistance],
                      }],
                  },
                  options: {
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      animation: {
                          onComplete: function(animation) {
                              var chartInstance = this.chart;
                              var ctx = chartInstance.ctx;
                              var height = chartInstance.controller.boxes[0].bottom;
                              ctx.textAlign = "center";
                              Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                Chart.helpers.each(meta.data.forEach(function (bar, index) {
                                  //ctx.fillText(dataset.data[index], bar._model.x, height - ((height - bar._model.y) / 2));
                                  ctx.fillText(dataset.data[index], bar._model.x+3, bar._model.y +1);
                                }),this)
                              }),this);  
                          }
                      },
                      plugins:{
                        labels: {
                          render: () => {}
                        }
                      },
                      scales: {
                          xAxes: [{
                              display: true,
                              scaleLabel: {
                                display: false,
                                labelString: '',
                                fontStyle: 'bold'
                              },
                              ticks: {
                                  max: maxValue,
                                  autoSkip: false,
                                  stepSize: 1
                              },
                              gridLines: {
                                display:false
                              },
                          }],
                          yAxes: [{
                            gridLines: {
                              display:true
                            },
                            ticks: {
                              mirror: false,
                              scaleOverride: true,
                              scaleSteps: 10,
                              scaleStepWidth: 5,
                              scaleStartValue: 0
                            },
                            stacked: false
                          }],
                      }
                  
                  }
              });

            },
            err => 
            {
                console.log(err);
            }
        );
    }

    goto(){
        this.router.navigate([`/project/${this.projectIdEncry}/inspiration`]);
    }

}
