import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-admin-categories-project',
  templateUrl: './admin-categories-project.component.html',
  styleUrls: ['./admin-categories-project.component.scss']
})
export class AdminCategoriesProjectComponent implements OnInit {
  public categoriesList = [];

  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;

  public category = {id: 0, name: ''};
  public toDelete = 0;
  private pagesNumber = 15; //Default value
  public search = '';
  public pages = [];
  private currentPage = 1;


  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }

    ngOnInit() {
      this.SessionService.validUserSession();  
      this.getCategoriesList('');
    }

    getCategoriesList(page){
      let session = JSON.parse(localStorage.getItem('user'));

      let url = 'categories?companyId=' + session['company_id'] + '&rows='+this.pagesNumber;
      url += '&search=' + this.search;

      if(page != '')
      {
          url += '&page=' + page;            
      }

      this.service.queryGet(url).subscribe(
        response=>
        {      
          let result = response.json(); 
          this.categoriesList = result.data;

          this.currentPage = result['current_page'];
          this.pages = this.singleton.pagination(result);
        },
        err => 
        {
          console.log(err);
        }
      );
    }

    saveCategory()
    {
        let user = JSON.parse(localStorage.getItem('user'));
        let body = new FormData();
        body.append('id', this.category['id']+'');
        body.append('name', this.category['name']);
        body.append('company_id', user['company_id']);

        this.service.queryPost('categories', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reinit the global user variable
                    this.category = {id: 0, name: ''};
                    //Show success message
                    this.toastr.success(result.message);
                    //Reload the list users
                    this.getCategoriesList('');
                    this.modalRef.hide();

                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    getPage(page)
    {
        if(page != this.currentPage)
        {
            this.getCategoriesList(page);
        }
    }


    deleteElement(){

      let url = "categories/"+this.toDelete;


      this.service.queryDelete(url).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success)
            {
                //Reload the list users
                this.getCategoriesList('');
                this.modalRefDelete.hide();
                this.toDelete = null;
                this.toastr.success(result.message);
            }
        },
        err => 
        {
            console.log(err);
        }
      );

    }


    editCategory(template, id)
    {
        this.service.queryGet('categories/'+id).subscribe(
            response=>
            {      
                let result = response.json(); 

                this.category['id'] = result.id;
                this.category['name'] = result.name;

                this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    OpenModalForm(template){
      this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }

    cancelDelete(){
      this.modalRefDelete.hide();
      this.toDelete = null;
    }

    confirmDelete(template, id){
      this.toDelete = id;
      this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
    }


    closeModal(){
      this.modalRef.hide();
    }

}
