import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApirestService } from '../apirest.service';
import { DataServiceService } from '../data-service.service';
import { SingletonService } from '../singleton.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

	public sessionUser = {};

    //Variables for users list with (pagination, select rows, search)
    public users = [];
    public pagesNumber = 20; //Default value
    public search = '';
    public pages = [];
    public currentPage = 1;

    // variables privadas
    private companyId = '';s
    public companyName = '';


  	constructor(public service: ApirestService,
                private router: Router,
                private route: ActivatedRoute,
                private location: Location,
                private toastr: ToastrService,
                private singleton: SingletonService,
                private SessionService: SessionService) 
  	{ 
        window.scroll(0,0);
  	}

  	ngOnInit() 
  	{
        this.SessionService.validUserSession(); 
  		  this.getUser();
        //Function to get the source data
        this.companyId = this.route.snapshot.params.company_id;
        this.getCompany(this.companyId);

        this.getUsers('');
    }

    /**
     * Gets the session user
     */
  	getUser()
  	{
  		this.sessionUser = JSON.parse(localStorage.getItem('user'));
  	}

    /**
     * Obtains the list of data with the paging information and search results
     */
    public getUsers(page)
    {
        let url = `users?company_id=${this.companyId}&rows=${this.pagesNumber}&search=${this.search}`;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.users = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                console.log(err);
            }
        );
    }

    /**
     * Calls the elements of the given page
     * @param int page Next page to paginate
     */
    public getPage(page)
    {
        if(page != this.currentPage)
        {
            this.getUsers(page);
        }
    }

    private getCompany(id)
    {
        let url = `company/${id}`;
        this.service.queryPost(url, new FormData).toPromise().then(
            response => {
                let result = response.json();
                this.companyName = result.name;
            },
            err => {

            }
        );
    }

    public goTo(route, userName=null)
    {
        if(userName)
        {
            this.router.navigate([route], { queryParams: { companyName: this.companyName, userName: userName}, skipLocationChange: false });
            return;
        }
        this.router.navigate([route]);
    }

    public exportUser()
    {
      let url = `export-users`;
      let body = new FormData;
      body.append('company_id', this.companyId);
      body.append('search', this.search);
      this.service.queryPost(url, body).toPromise().then(
        response => {
          let result = response.json();
          window.open(result);
        },
        err => {

        }
      );
    }
}
