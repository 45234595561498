import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataServiceService } from '../data-service.service';
import { SessionService } from '../session.service';
import { isArray } from 'util';

@Component({
  selector: 'app-guests-project',
  templateUrl: './guests-project.component.html',
  styleUrls: ['./guests-project.component.scss']
})
export class GuestsProjectComponent implements OnInit {
  public project = "";
  private projectIdEncry = '';

  //Variables for list with (pagination, select rows, search)
	public pagesNumber = 1000; //Default value
	public search = '';
	public pages = [];
	public currentPage = 1;

  //---- variables to display data
  public ViewList = true; //only to test, real value is true
  public ViewInvitationForm = false;
  public participationForm = false;
  public RegisterGuest = false; //only to test, real value is false

  public participation = {id: 0, title: '', name: '', other: ''};
  public invitation = {id: 0, title: '', name: '', other: ''};
  public guest = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', type: '', email: '', phone_number: '', address: '', assistance: 2, notes: ''};
  
  public guestList = [];
  public participationList = [];
  public invitationsList = [];

  public checkboxMan = false;
  public checkboxWoman = false;
  public checkboxkids = false;
  public checkboxAdult = false;

  public groups = [];
  public menuList = [];

  modalRef: BsModalRef | null;
  modalRefDelete: BsModalRef | null;
  public toDelete = 0;
  public typeDelete = null;
  
  public newAssistant;
  public itemsAsObjects = []; 

  public inputGroups;
  public inputMenu;

  public guestAdd = [];
  public idsToAdd = [];

  public posToEdit = -1;
  public temporalEdit = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', other:null, assistance: 0};

  public guestListWithOutMarcation = [];
  public filterBy = "id";
  public userSession = null;

  public archiveProject = false;

  public nameNewColumnTmp = "";
  public nameNewColumn = "";
  private idNewColumn = 0;

  public editColumn = false;


  constructor(private service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private dataService:DataServiceService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
    }

  ngOnInit() {
    
    this.SessionService.validUserSession();  
    let id = localStorage.getItem('project');
    this.projectIdEncry = id;
    this.project = this.singleton.decrypt(id);
    this.singleton.getProjectData(this.project);
    this.getGroups();
    this.getGuest();
    this.getMenu();
    this.getParticipations();
    this.getInvitationsList();
    let user = JSON.parse(localStorage.getItem('user'));
    this.userSession = user;

    if(localStorage.getItem('show') == '0'){
      this.archiveProject = true;
    }

  }  

  getGroups(){
    let user = JSON.parse(localStorage.getItem('user'));
		let url = "get-group-by-project/"+this.project;

		this.service.queryGet(url).subscribe(
            response=>
            {      
                this.groups = [];
                let result = response.json(); 
                for(let i = 0; i < result.length; i++){
                  this.groups.push({value: result[i].id, display: result[i].name}); 
                }
            },
            err => 
            {
                console.log(err);
            }
        );
  }


  removeInputGroups(item: any){
    this.guest.group_id = 0;
    if(this.temporalEdit['id'] != 0){
      this.temporalEdit['group_id'] = 0;
      this.updateInlineItem(null);
    }
  }

  addInputGroup(item: any, i,inLine=false){
    
    if(item.value != item.display){
      this.guest.group_id = item.value;
      if(this.temporalEdit['id'] != 0){
        this.temporalEdit['group_id'] = item.value;
        if(!inLine){
          this.updateInlineItem(i, 'enter');
        }
      }
    }else{
      this.saveNewGroup(item.value, i);
    }
  }

  saveNewGroup(name, pos){
    let body = new FormData;
       body.append('id', 0+'');
       body.append('project_id', this.project+'');
       body.append('name', name+'');
  
       this.service.queryPost('group', body).subscribe(
           response=>
           {      
              let result = response.json(); 
              
              if(result.success) 
              {
                this.guest.group_id = result.group.id;
                if(this.temporalEdit['id'] != 0){
                  this.temporalEdit['group_id'] = result.group.id;
                  this.updateInlineItem(pos, 'enter');
                }
              }
              else
              {
                  //this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
              }
              this.getGroups();
           },
           err => 
           {
               console.log(err);
           }
       );
  }

  getMenu(){
    let user = JSON.parse(localStorage.getItem('user'));
    let url = "menu?rows="+this.pagesNumber+"&projectId="+this.project;

    this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                let temp = result.data

                this.menuList = [];
                for(let i = 0; i < temp.length; i++){
                  this.menuList.push({display: temp[i].name, value: temp[i].id});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
  }

  removeInputMenu(item: any){
    
    this.guest.menu_id = 0;
    if(this.temporalEdit['id'] != 0){
      this.temporalEdit['menu_id'] = 0;
      this.updateInlineItem(null);
    }
  }

  addInputMenu(item: any, i, inLine=false){
    if(item.value != item.display){
      this.guest.menu_id = item.value;
      if(this.temporalEdit['id'] != 0){
        this.temporalEdit['menu_id'] = item.value;
        if(!inLine){
          this.updateInlineItem(i, 'enter');
        }
      }
    }else{
      this.saveNewMenu(item.value, i);
    }
  } 

  saveNewMenu(name, pos){
    let body = new FormData;
    body.append('id', 0+'');
    body.append('project_id', this.project+'');
    body.append('name', name+'');

    this.service.queryPost('menu', body).subscribe(
        response=>
        {      
          let result = response.json(); 
          
          if(result.success) 
          {
            this.guest.menu_id = result.menu.id;
            if(this.temporalEdit['id'] != 0){
              this.temporalEdit['menu_id'] = result.menu.id;
              this.updateInlineItem(pos, 'enter');
            }
          }
          else
          {
              //this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
          }
          this.getMenu();
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  editTag(event){
    let pos = 0; 
    for(let i = 0; i < this.itemsAsObjects.length; i++){
      if(this.itemsAsObjects[i].id == event.id){
        pos = i;
      }
    }
    this.itemsAsObjects.splice(pos, 1);
  }

  saveMarcInvitation(){
      let ids = [];
      for(let i = 0; i < this.guestAdd.length; i++){
        ids.push(this.guestAdd[i].value);
      }
       let body = new FormData;
       body.append('id', this.invitation.id+'');
       body.append('type', 1+'');
       body.append('name', this.invitation.name);
       body.append('title', this.invitation.title);
       if(undefined !== this.invitation.other && null != this.invitation.other)
       {
         body.append('other', this.invitation.other);
       }
       body.append('project_id', this.project);
       body.append('guest_ids', JSON.stringify(ids));
   
       this.service.queryPost('marcation', body).subscribe(
           response=>
           {      
               let result = response.json(); 
   
               if(result.success) 
               {
                   this.toastr.success(result.message);
                   this.getParticipations();
                   this.getInvitationsList();
                   this.ViewListForm();
                   this.invitation = {id: 0, title: '', name: '', other: ''};
               }
               else
               {   
                   this.toastr.error(result.message);
               }
           },
           err => 
           {
               console.log(err);
           }
       );
  }

  getInvitationsList(){
    let url = 'marcation?rows='+this.pagesNumber+'&type=1&projectId=' + this.project;
		this.service.queryGet(url).subscribe(
			response=>
			{      
				let result = response.json(); 
        this.invitationsList = result.data;

			},
			err => 
			{
				console.log(err);
			}
		);
  }

  getGuest(){
    
    let url = 'guest?projectId=' + this.project+"&order_by="+this.filterBy;
		this.service.queryGet(url).subscribe(
			response=>
			{      
				let result = response.json(); 
        this.guestList = result[0];
        if(null != result[1])
        {
          this.nameNewColumn = result[1].column_name;
          this.idNewColumn = result[1].id;
        }

			},
			err => 
			{
				console.log(err);
			}
		);
  }

  getParticipations(){
    let url = 'marcation?rows='+this.pagesNumber+'&type=2&projectId=' + this.project;
		this.service.queryGet(url).subscribe(
			response=>
			{      
				let result = response.json(); 
        this.participationList = result.data;
        this.getGuestWithoutMarcartion();
			},
			err => 
			{
				console.log(err);
			}
		);
  }

  openModal(template){
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  async viewParticipation(){
    if(!this.participationForm){
      window.scroll(0,0);
    }
    if(this.modalRef != null){
      this.modalRef.hide();
    }
    this.participationForm = true;
    this.ViewInvitationForm = false;
    this.ViewList = false;
    this.RegisterGuest = false;
    await this.singleton.sleep(200);
    document.getElementById('titleParticipation').focus();
    this.getGuestWithoutMarcartion();
  }

  async viewInvitation(){
    
    if(!this.ViewInvitationForm){
      window.scroll(0,0);
    }

    if(this.modalRef != null){
      this.modalRef.hide();
    }
    this.getGuestWithoutMarcartion();
    
    this.participationForm = false;
    this.ViewInvitationForm = true;
    this.ViewList = false;
    this.RegisterGuest = false;

    await this.singleton.sleep(200);
    document.getElementById('titleInvitation').focus();
  }

  getGuestWithoutMarcartion(){
    let url = 'get-guest-without-marcation/' + this.project;
		this.service.queryGet(url).subscribe(
			response=>
			{      
        let result = response.json(); 
        this.guestListWithOutMarcation = [];
        for(let i = 0; i < result.length; i++){
          this.guestListWithOutMarcation.push({value: result[i].id, display: result[i].name});
        } 
			},
			err => 
			{
				console.log(err);
			}
		);
  }

  ViewListForm(){
    if(!this.ViewList){
      window.scroll(0,0);
    }
    this.getParticipations();
    this.getInvitationsList();
    this.getGuest();
    this.participationForm = false;
    this.ViewInvitationForm = false;
    this.ViewList = true;
    this.RegisterGuest = false;
    this.inputMenu = null;
    this.inputGroups = null;
    this.posToEdit = -1;
    this.participation = {id: 0, title: '', name: '', other: ''};
    this.invitation = {id: 0, title: '', name: '', other: ''};
    this.guest = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', type: '', email: '', phone_number: '', address: '', assistance: 2, notes: ''};
    this.itemsAsObjects = [];
    this.guestAdd = [];
  }

  async viewGuestForm(){
    if(!this.RegisterGuest){
      window.scroll(0,0);
    }
    if(this.guest['id'] == 0){
      this.inputMenu = null;
      this.inputGroups = null;
    }
    this.posToEdit = -1;
    this.participationForm = false;
    this.ViewInvitationForm = false;
    this.ViewList = false;
    this.RegisterGuest = true;
    await this.singleton.sleep(200);
    document.getElementById('guestName').focus();
  }

  removeGuestMarcation(event: any){
    for(let i = 0; i < this.guestAdd.length; i++){
      if(this.guestAdd[i].value == event.value){
        this.guestAdd.splice(i,1);
      }
    }
  }

  addGuestMarcation(event:any){

    //Asumimos que no existe el valor ingresado en la lista de invitados sin marcación
    let validate = false;
    for(let i = 0; i < this.guestListWithOutMarcation.length; i++){
      if(event.value == this.guestListWithOutMarcation[i].value){
        validate = true;
      }
    }
    if(!validate){
      this.guestAdd.pop();
    }
  }

  validateGroupAdult(type){
    switch (type){
      case 'man':
          this.checkboxWoman = false;
      break;
      case 'woman':
        this.checkboxMan = false; 
      break;
    }  
  }

  validateGroupType(type){
    switch (type){
      case 'kids':
          this.checkboxAdult = false;
      break;
      case 'adult':
          this.checkboxkids = false;
      break;
    }
  }


  editItem(id, type){
    this.guestAdd = [];
    let url = 'marcation/' + id;
		this.service.queryGet(url).subscribe(
			response=>
			{      
        let result = response.json(); 
        
        if(type == 2){
          this.participation['id'] = result.id;
          this.participation['title'] = result.title;
          this.participation['name'] = result.name;
          this.participation['other'] = result.others;
          
          this.viewParticipation();
        }
        if(type == 1){
          this.invitation.id = result.id;
          this.invitation.name = result.name;
          this.invitation.title = result.title;
          this.invitation.other = result.others;
          for(let i = 0; i < result.guests.length; i++){
            this.guestAdd.push({'display': result.guests[i].name, 'value': result.guests[i].id});
          }
          this.viewInvitation();
        }
			},
			err => 
			{
				console.log(err);
			}
    );
  }

  saveParticipation(){
    //type => 1->Invitación - 2->Participación
    let body = new FormData;
    body.append('id', this.participation.id+'');
    body.append('type', 2+'');
    body.append('name', this.participation.name);
    body.append('title', this.participation.title);
    body.append('other', this.participation.other);
    body.append('project_id', this.project);

    this.service.queryPost('marcation', body).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success) 
            {
                this.toastr.success(result.message);
                this.getParticipations();
                this.ViewListForm();
                this.getGuestWithoutMarcartion();
            }
            else
            {
               let message = '';
               if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }else{
                            message = result.message;
                        }

                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  editGuest(id){
    this.cancelInlineEdit(); 
    let url = 'guest/' + id;
		this.service.queryGet(url).subscribe(
			response=>
			{      
				let result = response.json(); 
        this.guest['id'] = result.id;
        this.guest['group_id'] = result.group_id;
        this.guest['menu_id'] = result.menu_id;
        this.guest['project_id'] = result.project_id;
        this.guest['name'] = result.name;
        this.guest['type'] = result.type;
        this.guest['email'] = result.email;
        this.guest['phone_number'] = result.phone_number;
        this.guest['address'] = result.address;
        this.guest['assistance'] = result.assistance;
        this.guest['notes'] = result.notes;

        if(result.gender == 1){
          this.checkboxMan = true;
        }
    
        if(result.gender == 2 ){
          this.checkboxWoman = true;
        }
          
        let type = null;
        if(result.type == 1){
          this.checkboxkids = true;
        }
    
        if(result.type == 2){
          this.checkboxAdult = true;
        }

        let temp = null
        for(let i = 0; i < this.groups.length; i++){
          if(this.groups[i].value == result.group_id){
            temp = {value: this.groups[i].value, display: this.groups[i].display};
            //console.log(temp);
            this.inputGroups = [temp];
          }
        } 
        
        let auxtemp = null

        for(let j = 0; j < this.menuList.length; j++){
          
          if(this.menuList[j].value == result.menu_id){
            auxtemp = {value: this.menuList[j].value, display: this.menuList[j].display};
            
            this.inputMenu = [auxtemp];
          }
        }

        this.viewGuestForm();
			},
			err => 
			{
				console.log(err);
			}
		);
  }

  saveGuest(){
    if(this.guest['name'] == ""){
      this.toastr.error('Debe ingresar el nombre del invitado');
      return
    }

    let body = new FormData;
    body.append('id', this.guest.id+'');
   
    
    if(this.guest['group_id'] != 0 && this.guest['group_id'] != null){
      body.append('group_id', this.guest['group_id']+'');
    }

    if(this.guest['menu_id'] != 0 && this.guest['menu_id'] != null){
      body.append('menu_id', this.guest['menu_id']+'');
    }
    
    body.append('project_id', this.project);
    
    let gender = null;

    if(this.checkboxMan){
       gender = 1;
    }

    if(this.checkboxWoman){
      gender = 2;
    }
     
    let type = null;
    if(this.checkboxkids){
       type = 1;
    }

    if(this.checkboxAdult){
      type = 2;
    }

    if(this.guest['name'] == ''){
      this.toastr.error('El campo nombre es requerido');
      return;
    }

    if(gender != null){
      body.append('gender', gender);
    }

    if(type != null){
      body.append('type', type);
    }
    
    body.append('name', this.guest['name']);
    
    if(this.guest['email'] != null ){
      body.append('email', this.guest['email']);
    }

    if(this.guest['phone_number'] != null ){
      body.append('phone_number', this.guest['phone_number']);
    }
    
    if(this.guest['address'] != null ){
      body.append('address', this.guest['address']);
    }
    
    if(this.guest['assistance'] != null){
      body.append('assistance', this.guest['assistance']+'');
    }
    
    body.append('notes', this.guest['notes']);

    this.service.queryPost('guest', body).subscribe(
        response=>
        {      
            let result = response.json(); 

            if(result.success) 
            {
                this.toastr.success(result.message);
                this.getParticipations();
                this.getGuest();
                this.ViewListForm();
                this.checkboxkids = false;
                this.checkboxAdult = false;
                this.checkboxMan = false;
                this.checkboxWoman = false;
                this.guest = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', type: '', email: '', phone_number: '', address: '', assistance: null, notes: ''}
            }
            else
            {
               let message = '';
               if(isArray(result.message) && result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }else{
                            message = result.message;
                        }

                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }


  cancelDelete(){
    this.modalRefDelete.hide();
    this.toDelete = 0;
  }

  confirmDelete(template, id, type = null){
    this.toDelete = id;
    this.typeDelete = type;
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  deleteItem(){
    let url = 'marcation/'+this.toDelete;

    if(this.typeDelete == 'guest'){
      url = 'guest/'+this.toDelete;
    }
    this.service.queryDelete(url).subscribe(
      response=>
      {      
          let result = response.json(); 

          if(result.success)
          {
              //Reload the list users
              this.getParticipations();
              this.getInvitationsList();
              this.getGuest();
              this.modalRefDelete.hide();
              this.toDelete = null;
              this.toastr.success(result.message);
          }
      },
      err => 
      {
          console.log(err);
      }
    );
  }


  editInlineItem(pos){
    if(!this.archiveProject){
      this.inputMenu  = [];
      this.inputGroups = [];

      this.posToEdit = pos;
      
        
      this.temporalEdit['id'] = this.guestList[pos].id;
      this.temporalEdit['project_id'] = +this.project;
      this.temporalEdit['name'] = this.guestList[pos]['guest_name'];
      this.temporalEdit['other'] = this.guestList[pos]['other'] == 'null'? '' : this.guestList[pos]['other'];
      
      switch(this.guestList[pos].assistance_name){
        case 'No confirmado':
          this.temporalEdit['assistance'] = 0;
        break;
        case 'Confirmado':
          this.temporalEdit['assistance'] = 1;
        break;
        case 'Sin confirmar':
          this.temporalEdit['assistance'] = 2;
        break;
      }

      let temp = null
      for(let i = 0; i < this.groups.length; i++){
        if(this.guestList[pos].name_group ==  this.groups[i].display){
          temp = {value: this.groups[i].value, display: this.groups[i].display};
          this.inputGroups = [temp];
          this.temporalEdit['group_id'] = this.groups[i].value;
        }
      } 
        
      let auxtemp = null
      for(let j = 0; j < this.menuList.length; j++){  
        if(this.menuList[j].display == this.guestList[pos].name_menu){
          auxtemp = {value: this.menuList[j].value, display: this.menuList[j].display};
          this.inputMenu = [auxtemp];
          this.temporalEdit['menu_id'] = this.menuList[j].value;
        }
      }
    }
  }

  editcolumn(){

  }
  
  cancelInlineEdit(){
    this.posToEdit = -1
    this.inputMenu = null;
    this.inputGroups = null;
    this.guest = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', type: '', email: '', phone_number: '', address: '', assistance: 0, notes: ''};
  }


  updateInlineItem(pos, event = null){

    if(this.temporalEdit['name'] == "" || this.temporalEdit['name'] == null || this.temporalEdit['name'] == undefined){
      this.toastr.error('Debe ingresar el nombre del invitado');
      return
    }

    let body = new FormData;

    
    if(this.temporalEdit['group_id'] != 0 && this.temporalEdit['group_id'] != null){
      body.append('group_id', this.temporalEdit['group_id']+'');
    }
    
    if(this.temporalEdit['menu_id'] != 0 && this.temporalEdit['menu_id'] != null){
      body.append('menu_id', this.temporalEdit['menu_id']+'');
    }
    
    
    body.append('project_id', this.project);   
    body.append('name', this.temporalEdit['name']);
    body.append('assistance', this.temporalEdit['assistance']+'');
    body.append('other', this.temporalEdit['other']);
    body.append('_method', 'PATCH');

    this.service.queryPost(`guest/${this.temporalEdit.id}`, body).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success) 
            {
              //if event is 'enter' we need to reaload all information data
              if(event == 'enter'){
                //this.getParticipations();
                this.getGuest();
                /* this.ViewListForm();*/
                this.cancelInlineEdit(); 
                this.temporalEdit = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', other:null, assistance: 0};
              }else{
                for(let i = 0; i < this.guestList.length; i++){
                  if(this.guestList[i].id == this.temporalEdit.id){
                    pos = i;
                  }
                }

                this.temporalEdit['name'] = this.temporalEdit['name'];
                this.guestList[pos]['guest_name'] = this.temporalEdit['name'];

                if(this.temporalEdit['assistance'] == 0){
                  this.guestList[pos]['assistance_name'] = "No confirmado";
                }

                if(this.temporalEdit['assistance'] == 1){
                  this.guestList[pos]['assistance_name'] = "Confirmado";
                }

                if(this.temporalEdit['assistance'] == 2){
                  this.guestList[pos]['assistance_name'] = "Sin confirmar";
                }

                if(this.temporalEdit['menu_id'] != 0 && this.temporalEdit['menu_id'] != null){
                  let temp = null
                  //update menu name 
                  for(let i = 0; i < this.menuList.length; i++){
                    if(this.temporalEdit['menu_id'] == this.menuList[i].value){
                      this.guestList[pos]['name_menu'] = this.menuList[i].display
                    }
                  }
                }
                
                if(this.temporalEdit['group_id'] != 0 && this.temporalEdit['group_id'] != null){
                  let auxtemp = null
                  //We update group name
                  for(let j = 0; j < this.groups.length; j++){
                    if(this.temporalEdit['group_id'] == this.groups[j].value){
                      this.guestList[pos]['name_group'] = this.groups[j].display
                    }
                  }
                }
                
                //this.temporalEdit = {id: 0, group_id: 0, menu_id: 0, project_id: 0, name: '', assistance: 0};
              } 
              
              
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  export(type, reportType, marcation = null){
    /**
     * 
     * when I want to export check list, note, schedule and budget I need to send "1"
     * with these a send id as a project_template_id, when is documenta send project_document_id and 
     * other I send project_id
     */

    let report_type = 1 
    let temp = {id: this.project, type: type, report_type: reportType, marcation: marcation};
    this.dataService.changeModal(temp);
  }

  exportExcel(type, marcation = null){
     
    let body = new FormData();
    body.append('id', this.project+'');
    body.append('type', 'xls');
    body.append('report_type', type+'');
    body.append('view', 1+'');
    if(type == 5 && marcation != null){
      body.append('type_marcation', marcation+'');
    }

    this.service.queryPost('project/exports', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        window.open(result.url, '_blank');
      },
      err => 
      {
        console.log(err);
      }
    );
  }

  previewfunc(type, report_type= null, marcation = null){
    
    let body = new FormData();
    body.append('id', this.project+'');
    body.append('type', type);
    body.append('report_type', 3+'');
    body.append('view', 1+'');

    if(report_type != null && marcation != null){
      body.append('type_marcation', marcation+'');
      body.append('report_type', report_type+'');
    }

    this.service.queryPost('project/exports', body).subscribe(
      response=>
      {      
        let result = response.json(); 
        if(result.success){
            debugger;
            let pdf = result.url;
            this.singleton.pdf = result.url;
            this.router.navigate([`/project/${this.projectIdEncry}/preview`]);
        }
                
      },
      err => 
      {
        console.log(err);
      }
    );  
  }

  saveColumn(){
    let body = new FormData;
    body.append('id', '0');
    body.append('project_id', this.project);
    body.append('column_name', this.nameNewColumnTmp);
    this.service.queryPost('guest-column', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success) 
            {
              this.idNewColumn = result.projectGuestColumn.id;
              this.nameNewColumn = this.nameNewColumnTmp;
              this.nameNewColumnTmp = '';
              //if event is 'enter' we need to reaload all information data
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  editColum()
  {
    if(!this.archiveProject){
      this.editColumn = true;
    }
  }
  cancelEditColumn(){
    this.editColumn = false;
  }

  saveEditColum()
  {
    let body = new FormData;
    body.append('id', this.idNewColumn.toString());
    body.append('column_name', this.nameNewColumn);
    this.service.queryPost('guest-column', body).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success) 
            {
              this.editColumn = false;
              //if event is 'enter' we need to reaload all information data
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  deleteColumn()
  {
    this.modalRefDelete.hide();
    this.service.queryDelete(`guest-column/${this.idNewColumn}`).subscribe(
        response=>
        {      
            let result = response.json(); 
            
            if(result.success) 
            {
              this.nameNewColumn = '';
              this.idNewColumn = 0;
              this.getGuest();
              //if event is 'enter' we need to reaload all information data
            }
            else
            {
              this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        },
        err => 
        {
            console.log(err);
        }
    );
  }

  confirmDeleteColumn(template)
  {
    this.modalRefDelete = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }


  cancelDeleteColumn(){
    this.modalRefDelete.hide();
  }
  addColumn(){
    // Obtengo el elemento de la tabla
    // let table_td = document.getElementById("newColumnGuest");
    // table_td.innerHTML = this.nameNewColumn;
    this.saveColumn();
    // Obtengo la cabecera y agrego la nueva columna
    if(this.modalRef != null){
      this.modalRef.hide();
    }
  }

  /**
   * Función para validar 
   *
   * @param e EEventHtml
   */
  public validateNumber(e)
  {
      if(e.code !== "Backspace" && e.code !== "Tab" && e.code !== "ShiftRight" 
          && e.code !== "Home" && e.code !== "ShiftLeft" && e.code !== "End" && e.code !== "MetaLeft"){
          var key = window.event ? e.which : e.keyCode;
            if (key < 48 || key > 57) {
              e.preventDefault();
            }
      }
  }
}
