import { Component, OnInit, ViewChild } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Location } from '@angular/common';
import { removeSummaryDuplicates } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../session.service';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


@Component({
  selector: 'app-document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.scss']
})
export class DocumentTemplateComponent implements OnInit {
  private templateId = null;
  private templateType = null;
  public template = {id: 0, branding: 0, description: '', name: '', template_type_id: 0};
  public documentTemplate = {id: 0, template_id: 0, template_type_id: 0, document_type_id: 0, name: null, description: null, content: null, file: null, order: null};
  private documentTypesList = [];
  private typedocumetSelected = "";

  public myConfig: any = null;

  public Editor = DecoupledEditor;

  constructor(public service: ApirestService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private singleton: SingletonService,
    private lastlocation: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private SessionService: SessionService) 
    { 
      window.scroll(0,0);
      this.myConfig = this.singleton.configCkEditor;
    }

  ngOnInit() {
    this.SessionService.validUserSession(); 
      let id = this.singleton.decrypt(this.route.snapshot.paramMap.get("id"));

      this.templateId = id;
      this.getTemplateDetails();
      //this.getTypesDocuments();
  }

  public onReady( editor ) {
      editor.ui.getEditableElement().parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
      );
  }

  getTypesDocuments(){
    let url = 'get-template-document-type';
    
      this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json(); 
              this.documentTypesList = result;
          },
          err => 
          {
              console.log(err);
          }
      );
  }

  getTemplateDetails(){
    let url = 'template/' + this.templateId+'?type=5';
    
      this.service.queryGet(url).subscribe(
          response=>
          {      
              let result = response.json(); 

              if(false === result.success)
              {
                  if(result.message)
                  {
                      if('redirect' == result.message)
                      {
                        window.history.back();
                      }
                  }
              }
              else
              {
                this.template['id'] = this.templateId;
                this.templateType = result.template_type_id;
                this.template['branding'] = result.branding;
                this.template['description'] = result.description;
                this.template['name'] = result.name;
                this.template['template_type_id'] = result.template_type_id;

                 
                if(result.template_document.length > 0){
                  this.documentTemplate['id'] = result.template_document[0].id;
                  this.documentTemplate['content'] = result.template_document[0].content;
                  this.typedocumetSelected = result.template_document[0].document_type_id;
                  this.documentTemplate['template_type_id'] = result.template_type_id;
                }else{
                  this.documentTemplate['template_id'] = this.templateId;
                  this.documentTemplate['name'] = result.name;
                  this.documentTemplate['template_type_id'] = result.template_type_id;
                }
              }
          },
          err => 
          {
              console.log(err);
          }
      );
  }


  saveInfo(){
  
        var body = new FormData();
        body.append('id', this.documentTemplate['id']+'');
        body.append('name', this.documentTemplate['name']+'');
        body.append('template_id', this.templateId+'');
        body.append('content', this.documentTemplate['content']+'');
       // body.append('document_type_id', this.typedocumetSelected+'');
        body.append('template_type_id', this.documentTemplate['template_type_id']+'');


        let url = "template/type";

        this.service.queryPost(url, body).subscribe(
          response=>
          {      
            let result = response.json(); 
            
            if(result.success)
            {
              
              //Show success message
              this.toastr.success(result.message);
              this.singleton.editTemplate = 0;
              this.router.navigate(['templates']);

            }
            else
            {
              let message = '';
              if(result.message.length > 0)
              {
                result.message.forEach(function(element) {
                  message += element+'<br>';
                });
              }

              this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
          },
          err => 
          {
            console.log(err);
          }
        );
      
  }

  goback(){
    this.singleton.editTemplate = this.templateId;
    this.singleton.typeTemplate = this.templateType
    this.router.navigate(['/templates']);
  }

}
